import { type FC } from 'react';
import ReactPlayer from 'react-player';

import styled, { css } from 'styled-components';
import type { HeroBasicProps } from './types';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  COLOR_BLUE_3,
  COLOR_YELLOW
} from 'shared/helpers/index';
import { useSales, useWebsiteEditor } from 'shared/hooks/index';
import { HoverableInput } from '../HoverableInput';

import { Wrapper, Button } from '../Common';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  padding: 60px 0px 80px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 50px 0px 70px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 50px 0 70px;
  `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 1067 &&
    `
    flex-direction: column;
  `}
`;

const TitleWrapper = styled.div`
  width: 37%;

  ${({ theme: { $width } }) =>
    $width <= 1350 &&
    `
    width: 47%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 1067 &&
    `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const VideoInner = styled.div`
  position: relative;
  width: 57%;
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { $width } }) =>
    $width <= 1350 &&
    `
    width: 50%;
    height: 477px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 1067 &&
    `
    width: 100%;
    height: 468px;
  `}

${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    height: 398px;
    margin-bottom: 20px;
  `}
`;

const SvgBg = styled.svg`
  opacity: 0.16;
  stroke-width: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const playerStyles = css`
  width: 92% !important;
  height: 74% !important;
  border: none;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1067 &&
    `
    width: 90% !important;
    height: 382px !important;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 352px !important;
  `}

  & div, & .exampleImg {
    width: 100% !important;
    height: 100% !important;
    border: none;
    object-fit: cover;
    object-position: center center;
  }
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  margin-bottom: 26px;
  line-height: 1.2em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
    text-align: center;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space: nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 52px;
  line-height: 1.35em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 40px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    text-align: center;
    margin-bottom: 70px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 50px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space:nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  width: auto;
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 345px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 345px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 80%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
  `}
`;

export const V6: FC<HeroBasicProps> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    backgroundColor = COLOR_BLUE_3,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { purchaseElementId } = useSales();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  return (
    <Hero
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <VideoInner>
          <HoverableInput disabled={!editable} css={playerStyles as never} meta={meta} index={1}>
            {meta['url_1'] ? (
              <ReactPlayer url={meta['url_1']} controls={true} />
            ) : (
              <ImageComp
                alt="placeholder image"
                className="exampleImg"
                width={500}
                height={500}
                src="/img/example_video.png"
              />
            )}
          </HoverableInput>
          <SvgBg
            style={{ fill: secondaryColor }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 732 539">
            <ellipse cx="124.04" cy="157.06" rx="124.04" ry="126.25" />
            <ellipse cx="544.37" cy="348.03" rx="187.63" ry="190.97" />
            <ellipse cx="282.14" cy="30.65" rx="30.11" ry="30.65" />
            <ellipse cx="33.71" cy="314.46" rx="18.88" ry="19.22" />
          </SvgBg>
        </VideoInner>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(backgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            // id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{
              color: filterBackgroundColor(
                'linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.05) 0.01%, #fff 75.52%)',
                textColor
              )
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
      </StyledWrapper>
    </Hero>
  );
};
