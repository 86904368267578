import type { FC } from 'react';
import type { VideoProps } from './types';

import { useMemo } from 'react';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';

import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import { HoverableInput } from '../HoverableInput';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const playerStyles = css`
  width: 100% !important;
  height: 720px !important;
  border: none;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 310px !important;
  `}

  & div, & .exampleImg {
    width: 1280px !important;
    height: 720px !important;
    border: none;

    ${({ theme: { $width } }) =>
      $width <= 1300 &&
      `
      width: 100% !important;
  `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
      height: 100% !important;
  `}

    ${({ theme: { $width } }) =>
      $width <= 768 &&
      `
    height: 310px !important;
  `}
  }
`;

const TitleInner = styled.div`
  margin-bottom: 38px;
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 600;
  margin-bottom: 16px;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V2: FC<VideoProps> = (props) => {
  const { meta = {}, hidden, textColor, benefits = [] } = props;
  const {
    editable,
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    websiteAssembler
  } = useWebsiteEditor();

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const video = useMemo(() => {
    if (benefits.length) {
      return {
        ...benefits[0],
        title: {
          ...benefits[0].title,
          isEmpty: checkTitleBenefits(0)
        },
        content: {
          ...benefits[0].content,
          isEmpty: checkContentBenefits(0)
        }
      };
    }
    return {
      title: {
        ...defaultWebValue,
        isEmpty: checkTitleBenefits(0)
      },
      content: {
        ...defaultWebValue,
        isEmpty: checkContentBenefits(0)
      }
    };
  }, [benefits, checkContentBenefits, checkTitleBenefits]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <TitleInner>
          <Title
            id={video?.title?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(video.title?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={video.title.isEmpty}
            data-benefits={true}
          />
          <Text
            id={video?.content?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(video.content?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={video.content.isEmpty}
            data-benefits={true}
          />
        </TitleInner>
      </Wrapper>
      <Wrapper>
        <HoverableInput disabled={!editable} css={playerStyles as never} meta={meta} index={1}>
          {meta['url_1'] ? (
            <ReactPlayer url={meta['url_1']} controls={true} />
          ) : (
            <img className="exampleImg" src="/img/example_video.png" />
          )}
        </HoverableInput>
      </Wrapper>
    </Container>
  );
};
