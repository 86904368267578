import { AlertMessage } from 'shared/components/Utils';
import { useUtils } from '../hooks';

const Alert = () => {
  const { alert } = useUtils();
  if (!alert) return null;

  return <AlertMessage message={alert.message} severity={alert.severity} open={alert.isOpen} />;
};

export default Alert;
