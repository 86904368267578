import { useMemo, useState, type FC } from 'react';
import type { HeroDetailedProps } from './types';

import styled, { css } from 'styled-components';
import {
  ApiService,
  COLOR_ORANGE,
  COLOR_YELLOW,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Button, Wrapper, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

type BenefitsProps = {
  hasBenefits?: boolean;
};

const HeroDetailed = styled.div<BenefitsProps>`
  position: relative;
  overflow: hidden;
  padding: 55px 0 40px;

  ${({ hasBenefits }) =>
    hasBenefits &&
    `
    padding-bottom: 0px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    padding: 55px 0 40px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 55px 0 40px;
    `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  gap: 50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    gap: 15px;
    `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  width: 520px;
  height: auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 480px;
    height: auto;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 350px;
    height: auto;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: auto;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 80%;
    height: auto;
    `}

  ${({ mockup_id, theme: { $width } }) =>
    mockup_id === 1
      ? `
      width: 520px;
      height: auto;
      left: -30px;
      
      ${
        $width <= 1254 &&
        `
        left: -54px;
        width: 480px;
        height: auto;
        `
      }

      ${
        $width <= 991 &&
        `
        left: -40px;
        width: 350px;
        height: auto;
        `
      }

      ${
        $width <= 768 &&
        `
        left: -20px;
        height: auto;
        `
      }

      ${
        $width <= 575 &&
        `
        width: 80%;
        height: auto;
        `
      }
    `
      : mockup_id === 2
        ? `
      left: -90px;
      margin-bottom: 20px;
      width: 505px;
      height: auto;

      ${
        $width <= 1254 &&
        `
        left: -64px;
        margin-bottom: 0px;
        width: 480px;
        height: auto;
        `
      }

      ${
        $width <= 991 &&
        `
        left: -40px;
        width: 375px;
        height: auto;
        `
      }

      ${
        $width <= 768 &&
        `
        left: -20px;
        `
      }

      ${
        $width <= 575 &&
        `
        left: -10px;
        width: 80%;
        height: auto;
        `
      }
    `
        : mockup_id === 3
          ? `
      height: auto;
      width: 450px;
      margin-bottom: 30px;

      ${
        $width <= 1254 &&
        `
        width: 400px;
        height: auto;
        `
      }

      ${
        $width <= 991 &&
        `
        width: 328px;
        height: auto;
        margin-bottom: 0px;
        `
      }

      ${
        $width <= 575 &&
        `
        width: 70%;
        height: auto;
        `
      }
    `
          : ``}
`;

const TitleWrapper = styled.div`
  width: 660px;
  margin-top: -40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 492px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  font-weight: 700;

  margin-bottom: 25px;
  line-height: 1.15em;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 25px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 58px;
  line-height: 1.35em;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 50px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 45px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 40px;
    `}
  
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 395px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 345px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    `}
`;

const ShapeAbsolute = styled.div`
  width: 52%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    height: 588px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    height: 358px;
    `}
`;

const ShapeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WhiteBg = styled.div`
  position: absolute;
  width: 551px;
  height: 159%;
  top: -278px;
  background: rgb(255, 255, 255);
  right: -301px;
  transform: rotate(330deg);

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 1351px;
    top: auto;
    bottom: -210px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: rotate(345deg);
    height: 450px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 851px;
    top: auto;
    bottom: -300px;
    right: 0;
    left: -100px;
    margin: 0 auto;
    transform: rotate(345deg);
    `}
`;

type BenefitsBgProps = {
  backgroundColor?: string;
};

const Benefits = styled.div<BenefitsBgProps>`
  position: relative;
  z-index: 20;
  padding: 28px 0;
  margin-top: 35px;
  background: ${({ backgroundColor }) => backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 75px;
    `}
`;

const BenefitsWrapper = styled((props) => <Wrapper {...props} />)`
  display: flex;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    `}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 32%;
  gap: 16px;
  background: #fff;
  padding: 12px;
  box-shadow: -2.08px 4.2px 47px 0px rgba(0, 0, 0, 0.13);
  border-radius: 8px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding: 12px 20px;

    &:not(last-of-type) {
      margin-bottom: 7px;
    }
    `}
`;

const Svg = styled.svg`
  width: 28px;
  height: 25px;
  margin-bottom: 8px;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 4px;
    `}
`;

const TextWrapper = styled.div`
  width: 84%;
`;

const TitleBenefit = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  font-weight: 700;
  margin-bottom: 1px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 7px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 1px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const DescriptionBenefit = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V1: FC<HeroDetailedProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    backgroundColor = COLOR_YELLOW,
    textColor,
    secondaryColor = COLOR_YELLOW,
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { isCoverDisabled, pages, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, purchaseElementId, updateImageMeta } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, cover_thumbnail, pagesThumbnails]);

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);

    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <HeroDetailed
      hasBenefits={benefits.length > 0}
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        {bookMockup ? (
          <HoverableDropzone
            isSalePage
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={isLoading}
            alreadyUploaded={Boolean(bookMockup)}
            imageFile={bookMockup}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '3x4',
              pixels: '600x800'
            }}>
            <BookMediaImg
              isImageUploaded={isImageUploaded}
              src={bookMockup}
              width={resize.width || 500}
              height={resize.height || 500}
              alt="Book Mockup"
            />
          </HoverableDropzone>
        ) : (
          <StyledMockup
            mockup={mockup?.file}
            mockup_id={mockup?.id}
            cover_thumbnail={coverThumbnail}
          />
        )}
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
      </StyledWrapper>
      <ShapeAbsolute style={{ background: backgroundColor }}>
        <ShapeWrapper>
          <WhiteBg />
        </ShapeWrapper>
      </ShapeAbsolute>
      {benefits.length > 0 && (
        <Benefits style={{ background: secondaryColor }}>
          <BenefitsWrapper>
            {benefits.map(({ content, icon, title }, index) => {
              const isTitleBenEmpty = checkTitleBenefits(index);
              const isContentBenEmpty = checkContentBenefits(index);
              return (
                <Item key={`${icon?.toString()}-${index}`}>
                  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 25" fill="none">
                    <path
                      fill={COLOR_ORANGE}
                      d="M14.0003 24.4667L12.067 22.7067C5.20033 16.48 0.666992 12.3733 0.666992 7.33333C0.666992 3.22667 3.89366 0 8.00033 0C10.3203 0 12.547 1.08 14.0003 2.78667C15.4537 1.08 17.6803 0 20.0003 0C24.107 0 27.3337 3.22667 27.3337 7.33333C27.3337 12.3733 22.8003 16.48 15.9337 22.72L14.0003 24.4667Z"
                    />
                  </Svg>
                  <TextWrapper>
                    <TitleBenefit
                      id={title?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleBenEmpty}
                    />
                    <DescriptionBenefit
                      id={content?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentBenEmpty}
                    />
                  </TextWrapper>
                </Item>
              );
            })}
          </BenefitsWrapper>
        </Benefits>
      )}
    </HeroDetailed>
  );
};
