import type { HeadlineOnlyProps } from './types';

import { V1 } from './V1';
import { V2 } from './V2';

export type { HeadlineOnlyProps };

export default {
  V1,
  V2
};
