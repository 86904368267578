import type { FC } from 'react';
import styled from 'styled-components';

/**
 * Styled container for the tooltip.
 *
 * @param {Object} props - The properties passed to the container.
 * @param {string} [props.width] - The width of the tooltip.
 * @param {string} [props.backgroundColor] - The background color of the tooltip.
 * @param {string} [props.left] - The left position of the tooltip.
 * @param {string} [props.top] - The top position of the tooltip.
 */
type ContainerProps = {
  width?: string;
  backgroundColor?: string;
  left?: string;
  top?: string;
};
const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.backgroundColor || 'white'};
  width: ${(props) => props.width || '300px'};
  padding: 1rem;
  position: absolute;
  display: none;
  top: ${(props) => props.top || '120%'};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: center;
  left: ${(props) => props.left || 'auto'};
  z-index: 1000;
`;

/**
 * Tooltip component that renders a styled tooltip with customizable properties.
 *
 * @component
 * @param {string} props.className - Additional class name for the tooltip.
 * @param {string} props.width - The width of the tooltip.
 * @param {string} props.text - The text to be displayed inside the tooltip.
 * @param {string} props.backgroundColor - The background color of the tooltip.
 * @param {string} props.left - The left position of the tooltip.
 * @param {string} props.top - The top position of the tooltip.
 * @returns {JSX.Element} The rendered Tooltip component.
 * @example
 * return (
 *   <Tooltip
 *     className="custom-tooltip"
 *     width="200px"
 *     text="This is a tooltip"
 *     backgroundColor="lightblue"
 *     left="50%"
 *     top="100%"
 *   />
 * )
 */
interface Props {
  className?: string;
  width?: string;
  text: string;
  backgroundColor?: string;
  left?: string;
  top?: string;
}
const Tooltip: FC<Props> = (props) => {
  const { className, width, text, backgroundColor, left, top } = props;
  return (
    <Container
      className={className}
      backgroundColor={backgroundColor}
      width={width}
      left={left}
      top={top}>
      {text}
    </Container>
  );
};

export default Tooltip;
