import type {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  TextAlignProps,
  GridColumnProps,
  BoxShadowProps,
  FontWeightProps,
  FontFamilyProps
} from 'styled-system';

import styled, { css } from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  textAlign,
  gridColumn,
  boxShadow,
  fontWeight,
  fontFamily
} from 'styled-system';

export type FlexBoxProps = LayoutProps &
  FlexboxProps &
  SpaceProps &
  PositionProps &
  ColorProps &
  BorderProps &
  BoxShadowProps &
  GridColumnProps &
  FontFamilyProps &
  TextAlignProps & { gap?: number } & { backgroundColorOnHover?: string } & {
    hidden?: boolean;
  } & { cursor?: string } & FontWeightProps & { css?: FlattenSimpleInterpolation };

/**
 * FlexBox component that supports various styled-system props.
 *
 * This component is a flexible container that supports layout, flexbox, space, position,
 * color, border, boxShadow, gridColumn, text alignment, fontWeight, and additional custom styles.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {number} [props.gap] - Gap between flex items in pixels.
 * @param {string} [props.backgroundColorOnHover] - Background color on hover.
 * @param {boolean} [props.hidden] - Determines if the component should be hidden.
 * @param {string} [props.cursor] - Optional cursor style.
 * @example
 * return (
 *   <FlexBox
 *     display="flex"
 *     justifyContent="center"
 *     alignItems="center"
 *     p={3}
 *     bg="lightgray"
 *     border="1px solid gray"
 *     cursor="pointer"
 *     gap={10}
 *   >
 *     Content
 *   </FlexBox>
 * )
 */
export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  ${layout}
  ${flexbox}
  ${space}
  ${textAlign}
  ${position}
  ${color}
  ${border}
  ${gridColumn}
  ${boxShadow}
  ${fontWeight}
  ${fontFamily}
  ${(props) =>
    props.gap &&
    css`
      gap: ${`${props.gap}px`};
    `}
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${`${props.cursor}`};
    `}

    ${(props) => props.css}
`;
