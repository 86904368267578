import { useMemo, useState, type FC } from 'react';
import type { HeroDetailedProps } from './types';

import styled, { css } from 'styled-components';
import {
  ApiService,
  COLOR_ORANGE,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Button, Wrapper, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const HeroDetailed = styled.div`
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    padding: 55px 0 70px;
    margin-bottom: 10px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 55px 0 70px;
    margin-bottom: 0px;
    `}
`;

const TitleWrapper = styled.div`
  width: 79%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 78px;
  text-align: center;
  position: relative;
  z-index: 4;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 84%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 58px;
    `}
`;

const StyledWrapper = styled(Wrapper)`
  max-width: 100%;
  gap: 50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    gap: 15px;
    `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  font-weight: 700;
  max-width: 100%;

  margin-bottom: 25px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 25px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space: nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 42px;
  line-height: 1.35em;
  width: 80%;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 40px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 30px;
    `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 395px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 345px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    `}
`;

const WrapperMockup = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  z-index: 2;
  margin-bottom: -50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      min-height: auto;
      flex-direction: column;
      margin-bottom: 0px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    
    
    `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  width: 560px;
  height: auto;
  object-fit: cover;
  object-position: center center;
  z-index: 5;
  position: relative;
  margin-left: 22px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 438px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 378px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 340px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 370 &&
    `
    width: 270px !important;
  `}
`;

const InnerCircle = styled.div`
  position: absolute;
  width: 1170px;
  height: 704px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  overflow: hidden;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 100%;
      height: 540px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 100%;
      height: 520px;
    `}

    ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      display: none;
    `}
`;

const InnerCircleRelative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SvgCircle = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const Item = styled.div`
  width: 305px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 17px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 21;

  &:nth-child(4) {
    left: -64%;
    top: 14%;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
        top: 9%;
      `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
      position: relative;
      left: 0;
      right: 0;
    `}
  }

  &:nth-child(2) {
    top: 40%;
    flex-direction: row-reverse;
    right: -63%;

    & .circleBenefit {
      left: -4%;
      right: auto;

      ${({ theme: { $width } }) =>
        $width <= 991 &&
        `
        display: none;
      `}
    }

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
        position: relative;
        left: 0;
        right: 0;
        margin-top: 35px;
      `}
  }

  &:nth-child(3) {
    left: -66%;
    bottom: 18%;

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
      position: relative;
      left: 0;
      right: 0;
      `}
  }

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 265px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      position: relative;
      left: 0;
      right: 0;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    `}
`;

const ItemRelative = styled.div`
  position: relative;
  padding: 18px;
  display: flex;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      align-items: center;

    `}
`;

const Svg = styled.svg`
  width: 62px;
  height: 62px;
  margin-right: 12px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 42px;
      height: 42px;
      margin-bottom: 8px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 52px;
    height: 52px;
    margin-right: 18px;
    margin-bottom: 0px;
  `}
`;

const TextWrapper = styled.div`
  width: 73%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
   width: 86%;
    `}
`;

const CircleBenefit = styled.div`
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid;
  right: -4%;
  top: 40%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    display: none;
    `}
`;

const TitleBenefit = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  margin-bottom: 5px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 5px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 1px;
    `}
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const DescriptionBenefit = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ColoredBg = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  z-index: 1;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      height: 100%;
    `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V2: FC<HeroDetailedProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    // backgroundColor = COLOR_YELLOW,
    secondaryColor,
    textColor,
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { isCoverDisabled, pages, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, updateImageMeta, purchaseElementId } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, cover_thumbnail, pagesThumbnails]);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <HeroDetailed
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        paddingTop: '5rem'
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
        <WrapperMockup>
          {bookMockup ? (
            <HoverableDropzone
              isSalePage
              css={imageStyles as never}
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={handleUpload}
              loading={isLoading}
              alreadyUploaded={Boolean(bookMockup)}
              imageFile={bookMockup}
              resizeSelector={editable}
              handleResize={({ width, height }) => {
                setResize({ width, height });
                updateImageMeta(meta['accessorKey'], width, 'width');
                updateImageMeta(meta['accessorKey'], height, 'height');
              }}
              initialPosition={{
                x: meta['x_1'] || 0,
                y: meta['y_1'] || 0,
                width: meta['d_width_1'],
                height: meta['d_height_1']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_1');
                updateImageMeta(meta['accessorKey'], y, 'y_1');
                updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                updateImageMeta(meta['accessorKey'], height, 'd_height_1');
              }}
              imageAspectRatio={{
                ratio: '3x4',
                pixels: '600x800'
              }}>
              <BookMediaImg
                isImageUploaded={isImageUploaded}
                src={bookMockup}
                width={resize.width || 500}
                height={resize.height || 500}
                alt="Book Mockup"
              />
            </HoverableDropzone>
          ) : (
            <StyledMockup
              mockup={mockup?.file}
              mockup_id={mockup?.id}
              cover_thumbnail={coverThumbnail}
            />
          )}

          <>
            {benefits.map(({ content, icon, title }, index) => {
              const isTitleBenEmpty = checkTitleBenefits(index);
              const isContentBenEmpty = checkContentBenefits(index);
              return (
                <Item
                  key={`${icon?.toString()}-${index}`}
                  // style={{
                  //   display: isTitleBenEmpty && isContentBenEmpty && !editable ? 'none' : 'block'
                  // }}
                >
                  <ItemRelative>
                    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                      <circle
                        cx="31"
                        cy="31"
                        r="31"
                        fill={props.button?.color || COLOR_ORANGE}
                        strokeWidth={0}
                      />
                      <path
                        d="m44.85,23.17c-.13.64-.53,1.1-.97,1.55-5.07,5.21-10.13,10.43-15.19,15.65-.4.41-.85.64-1.41.46-.23-.07-.46-.21-.63-.38-2.52-2.58-5.03-5.17-7.54-7.76-.86-.89-.84-2.14.01-3.03.28-.3.57-.59.86-.88.87-.88,2.08-.88,2.94,0,1.5,1.54,3,3.09,4.5,4.63.07.07.1.16.2.31.12-.17.17-.26.25-.34,4.15-4.28,8.3-8.56,12.46-12.84.98-1.01,2.15-1.01,3.13,0,.14.14.27.29.42.43.46.43.86.91.99,1.57v.64h0Z"
                        fill="#fff"
                        strokeWidth={0}
                      />
                    </Svg>
                    <TextWrapper>
                      <TitleBenefit
                        id={title?.id}
                        dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                        style={{ color: filterBackgroundColor('#fff', textColor) }}
                        onClick={updateActiveElement}
                        onBlur={resetActiveElement}
                        data-accessor-parent={meta['accessorKey']}
                        data-accessorkey="title"
                        data-benefits={true}
                        data-placeholder="Your copy here!"
                        editable={editable}
                        isEmpty={isTitleBenEmpty}
                      />
                      <DescriptionBenefit
                        id={content?.id}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeRichText(content?.val, editable)
                        }}
                        style={{ color: filterBackgroundColor('#fff', textColor) }}
                        onClick={updateActiveElement}
                        onBlur={resetActiveElement}
                        data-accessor-parent={meta['accessorKey']}
                        data-accessorkey="content"
                        data-benefits={true}
                        data-placeholder="Your copy here!"
                        editable={editable}
                        isEmpty={isContentBenEmpty}
                      />
                    </TextWrapper>
                    <CircleBenefit
                      style={{ borderColor: secondaryColor }}
                      className="circleBenefit"
                    />
                  </ItemRelative>
                </Item>
              );
            })}
          </>
          <InnerCircle>
            <InnerCircleRelative>
              <SvgCircle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1171 706">
                <path
                  d="m1171,585.5c0,41.3-4.28,81.61-12.42,120.5H12.42c-8.14-38.89-12.42-79.2-12.42-120.5C0,262.14,262.14,0,585.5,0s585.5,262.14,585.5,585.5Z"
                  fill="rgba(255, 255, 255, .32)"
                  strokeWidth={0}
                />
                <path
                  d="m1023,585.5c0,41.79-5.86,82.22-16.81,120.5H164.81c-10.95-38.28-16.81-78.71-16.81-120.5,0-241.62,195.88-437.5,437.5-437.5s437.5,195.88,437.5,437.5Z"
                  fill="rgba(255, 255, 255, .3)"
                  strokeWidth={0}
                />
              </SvgCircle>
            </InnerCircleRelative>
          </InnerCircle>
        </WrapperMockup>
      </StyledWrapper>
      <ColoredBg style={{ background: secondaryColor }} />
    </HeroDetailed>
  );
};
