export const defaultBookBaseColor = {
  id: 0,
  value: '#000000',
  dark: '#FFFFFF',
  name: 'Base Color 1'
};

export const defaultBookBaseColorCollection = [
  {
    id: 0,
    value: '#000000',
    dark: '#FFFFFF',
    name: 'Base Color 1'
  },
  {
    id: 1,
    value: '#002C5C',
    dark: '#FFFFFF',
    name: 'Base Color 2'
  },
  {
    id: 2,
    value: '#606060',
    dark: '#FFFFFF',
    name: 'Base Color 3'
  },
  {
    id: 3,
    value: '#9A9A9A',
    dark: '#FFFFFF',
    name: 'Base Color 4'
  }
];

export const defaultColorPalette = {
  id: 11140,
  name: 'Spring',
  background_color1: '#F7FDFE',
  background_color2: '#FFF9F0',
  background_color3: '#F9FDF2',
  background_color4: '#FFFFFF',
  base_color1: '#000000',
  base_color2: '#002C5C',
  base_color3: '#606060',
  base_color4: '#9A9A9A',
  color1: '#5CE1E6',
  color2: '#1CAEB2',
  color3: '#FFA924',
  color4: '#FDC600',
  color5: '#9ACC3A',
  is_default: true
};

export const defaultColorPaletteCollection = [
  {
    id: 11140,
    name: 'Spring',
    background_color1: '#F7FDFE',
    background_color2: '#FFF9F0',
    background_color3: '#F9FDF2',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#5CE1E6',
    color2: '#1CAEB2',
    color3: '#FFA924',
    color4: '#FDC600',
    color5: '#9ACC3A',
    is_default: true
  },
  {
    id: 11142,
    name: 'Autumn',
    background_color1: '#FFF6F0',
    background_color2: '#F8F2FD',
    background_color3: '#FFF0F3',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#FF6B00',
    color2: '#4E1972',
    color3: '#7E2BBB',
    color4: '#FF9B00',
    color5: '#A40020',
    is_default: false
  },
  {
    id: 11154,
    name: 'Christmas',
    background_color1: '#F0F7FF',
    background_color2: '#FFFAFA',
    background_color3: '#F0F7FF',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#EA2F32',
    color2: '#BB2528',
    color3: '#2AA95E',
    color4: '#155C33',
    color5: '#FBCB44',
    is_default: false
  },
  {
    id: 11150,
    name: 'Citrus',
    background_color1: '#F9FDF2',
    background_color2: '#FFF8F0',
    background_color3: '#FFFEFA',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#99BC56',
    color2: '#F1434C',
    color3: '#FB9215',
    color4: '#FBADB3',
    color5: '#FBD54E',
    is_default: false
  },
  {
    id: 11149,
    name: 'Coastal',
    background_color1: '#F0FFFD',
    background_color2: '#FFFCFA',
    background_color3: '#FBFDFE',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#00B69D',
    color2: '#0E697C',
    color3: '#F9A778',
    color4: '#FBCBB5',
    color5: '#BACDD4',
    is_default: false
  },
  {
    id: 11144,
    name: 'Cottage',
    background_color1: '#FDF1F1',
    background_color2: '#FDF2F2',
    background_color3: '#FBFDFE',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#C41717',
    color2: '#343434',
    color3: '#7A7A7A',
    color4: '#CEC49E',
    color5: '#88B7D5',
    is_default: false
  },
  {
    id: 11141,
    name: 'Denium',
    background_color1: '#F1F9FE',
    background_color2: '#F0F9FF',
    background_color3: '#FBFDFE',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#096198',
    color2: '#2391D5',
    color3: '#33B1FF',
    color4: '#90D5FF',
    color5: '#88B7D5',
    is_default: false
  },
  {
    id: 11151,
    name: 'Digital',
    background_color1: '#F2F3FD',
    background_color2: '#F0F7FF',
    background_color3: '#FFFBF2',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#2B32B3',
    color2: '#17234D',
    color3: '#027FFF',
    color4: '#CBD4DC',
    color5: '#FFC03F',
    is_default: false
  },
  {
    id: 11146,
    name: 'Lakehouse',
    background_color1: '#F0F5FE',
    background_color2: '#FBFCFE',
    background_color3: '#FFF0F1',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#062D88',
    color2: '#3D63BA',
    color3: '#809DCE',
    color4: '#C2D7FF',
    color5: '#F93943',
    is_default: false
  },
  {
    id: 11152,
    name: 'Morocco',
    background_color1: '#F8F2FD',
    background_color2: '#F8F2FD',
    background_color3: '#FFF0F3',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#9A9A9A',
    base_color4: '#C4C4C4',
    color1: '#4A1A74',
    color2: '#FF6D00',
    color3: '#7B2CBF',
    color4: '#FF9E00',
    color5: '#A20021',
    is_default: false
  },
  {
    id: 11153,
    name: 'Nautical',
    background_color1: '#F1FAFE',
    background_color2: '#F2FAFD',
    background_color3: '#FEF7F0',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#9A9A9A',
    base_color4: '#C4C4C4',
    color1: '#063F5C',
    color2: '#F7AD1A',
    color3: '#419EBD',
    color4: '#9FE7F5',
    color5: '#F27F0C',
    is_default: false
  },
  {
    id: 11147,
    name: 'Outdoorsy',
    background_color1: '#FDF2F2',
    background_color2: '#FEFCFB',
    background_color3: '#FBFDFE',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#212121',
    color2: '#AEB884',
    color3: '#DFA36E',
    color4: '#FED078',
    color5: '#BACDD4',
    is_default: false
  },
  {
    id: 11145,
    name: 'Sprinkles',
    background_color1: '#FFFAFC',
    background_color2: '#FAFDFE',
    background_color3: '#FDFAFF',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#FF99C8',
    color2: '#ACE9C4',
    color3: '#99D5F3',
    color4: '#F6DF8E',
    color5: '#E2BDF8',
    is_default: false
  },
  {
    id: 11148,
    name: 'Summer',
    background_color1: '#F1FCFD',
    background_color2: '#FFFBFA',
    background_color3: '#FFFAFB',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#1FCCE2',
    color2: '#008FB3',
    color3: '#FE7966',
    color4: '#FDD97B',
    color5: '#F9647A',
    is_default: false
  },
  {
    id: 11143,
    name: 'Vivid',
    background_color1: '#F0F7FF',
    background_color2: '#FFFAFA',
    background_color3: '#F0F7FF',
    background_color4: '#FFFFFF',
    base_color1: '#000000',
    base_color2: '#002C5C',
    base_color3: '#606060',
    base_color4: '#9A9A9A',
    color1: '#3396FF',
    color2: '#3DDC97',
    color3: '#FF495C',
    color4: '#98ECC7',
    color5: '#002C5C',
    is_default: false
  }
];

export const defaultFont = {
  id: 2,
  name: 'Montserrat',
  system_name: 'Montserrat',
  secondary_system_name: undefined
};

export const defaultFontCollection = [
  {
    id: 2,
    name: 'Montserrat',
    system_name: 'Montserrat',
    secondary_system_name: undefined
  },
  {
    id: 6,
    name: 'Poppins',
    system_name: 'Poppins',
    secondary_system_name: undefined
  },
  {
    id: 5,
    name: 'Roboto Slab',
    system_name: 'Roboto Slab',
    secondary_system_name: undefined
  }
];

export const defaultFontStyle = {
  id: 0,
  name: 'bold',
  title: 'THICK & BOLD',
  content: 'CAPITALIZED'
};

export const defaultFontStyleCollection = [
  {
    id: 0,
    name: 'bold',
    title: 'THICK & BOLD',
    content: 'CAPITALIZED'
  },
  {
    id: 1,
    name: 'lower',
    title: 'lower case',
    content: 'THIN & AIRY'
  },
  {
    id: 2,
    name: 'normal',
    title: 'Classic Headline',
    content: 'Blended Styles'
  }
];
