import type { FC } from 'react';
import type { PhotoProps } from './types';

import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Wrapper } from '../Common';

import {
  ApiService,
  baseMediaEndpoint,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useSales, useWebsiteEditor } from 'shared/hooks/index';

import { HoverableDropzone } from '../HoverableDropzone';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Photo = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  padding: 60px 0;
`}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  padding: 60px 15px;
`}
`;
const Container = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    flex-direction: column-reverse;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column-reverse;
  `}
`;

const TitleContainer = styled.div`
  width: 588px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 100%;
      margin-top: 30px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-top: 0px;
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 600;
  margin-bottom: 16px;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  width: 640px;
  height: 428px;
  border: none;
  position: relative;
  overflow: hidden;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 532px;
    height: 356px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 40px;
  `}

  & img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const V7: FC<PhotoProps> = (props) => {
  const { meta, hidden, textColor, benefits = [] } = props;
  const {
    editable,
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    websiteAssembler
  } = useWebsiteEditor();

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const { salesPageId, updateImageMeta } = useSales();

  const [loading, setLoading] = useState(false);
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const image = useMemo(() => {
    if (benefits.length) {
      return {
        ...benefits[0],
        title: {
          ...benefits[0].title,
          isEmpty: checkTitleBenefits(0)
        },
        content: {
          ...benefits[0].content,
          isEmpty: checkContentBenefits(0)
        }
      };
    }
    return {
      title: {
        ...defaultWebValue,
        isEmpty: checkTitleBenefits(0)
      },
      content: {
        ...defaultWebValue,
        isEmpty: checkContentBenefits(0)
      }
    };
  }, [benefits, checkContentBenefits, checkTitleBenefits]);

  const aboutMeImage = useMemo(() => {
    if (meta['image'] || meta['image_1']) {
      if (baseMediaEndpoint?.includes('localhost'))
        return `${baseMediaEndpoint}${meta['image'] || meta['image_1']}`;
      return meta['image'];
    }
    return '/img/lead_bg.png';
  }, [meta]);

  const handleUpload = async (file: File) => {
    setLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setLoading(false);
  };

  return (
    <Photo
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Container>
        <TitleContainer>
          <Title
            id={image?.title?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(image?.title?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-benefits={true}
            data-placeholder="+"
            editable={editable}
            isEmpty={image.title.isEmpty}
          />
          <Text
            id={image?.content?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(image?.content?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-benefits={true}
            data-placeholder="+"
            editable={editable}
            isEmpty={image.content.isEmpty}
          />
        </TitleContainer>
        <HoverableDropzone
          isSalePage
          css={imageStyles as never}
          disabled={!editable}
          allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
          onUpload={handleUpload}
          loading={loading}
          alreadyUploaded={Boolean(aboutMeImage)}
          imageFile={aboutMeImage}
          resizeSelector={editable}
          handleResize={({ width, height }) => {
            setResize({ width, height });
            updateImageMeta(meta['accessorKey'], width, 'width');
            updateImageMeta(meta['accessorKey'], height, 'height');
          }}
          initialPosition={{
            x: meta['x_1'] || 0,
            y: meta['y_1'] || 0,
            width: meta['d_width_1'],
            height: meta['d_height_1']
          }}
          meta={meta}
          positionEnabled
          handlePosition={(width: number, height: number, x: number, y: number) => {
            updateImageMeta(meta['accessorKey'], x, 'x_1');
            updateImageMeta(meta['accessorKey'], y, 'y_1');
            updateImageMeta(meta['accessorKey'], width, 'd_width_1');
            updateImageMeta(meta['accessorKey'], height, 'd_height_1');
          }}
          imageAspectRatio={{
            ratio: '2x1',
            pixels: '640x340'
          }}>
          <ImageComp
            src={aboutMeImage}
            width={resize.width || 500}
            height={resize.height || 500}
            alt="Photo"
          />
        </HoverableDropzone>
      </Container>
    </Photo>
  );
};
