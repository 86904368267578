import { useMemo, type FC, useState } from 'react';
import type { HeroCoachProps } from './types';

import styled, { css } from 'styled-components';

import {
  ApiService,
  COLOR_YELLOW,
  baseMediaEndpoint,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper, Button, Mockup } from '../Common';
import tinycolor from 'tinycolor2';
import { HoverableDropzone } from '../HoverableDropzone';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Coaching = styled.div`
  position: relative;
  padding: 103px 0 123px;
  background: #fff;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 83px 0;
`}

  ${({ theme: { $width } }) => `
    ${
      $width <= 768 &&
      `
      padding: 73px 0 63px;
    `
    }
  `}

  ${({ theme: { $width } }) => `
    ${
      $width <= 514 &&
      `
      padding: 193px 0 63px;
    `
    }
  `}
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 40px; */

  ${({ theme: { $width } }) => `
    ${
      $width <= 991 &&
      `
      flex-direction: column-reverse;
      align-items: center;
    `
    }
  `}
`;

const TitleInner = styled.div`
  width: 580px;

  ${({ theme: { $width } }) => `
    ${
      $width <= 1254 &&
      `
      width: 460px;
    `
    }
    ${
      $width <= 991 &&
      `
      margin-top: 30px;
      width: 100%;
      text-align: center;
    `
    }
    
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  margin-bottom: 35px;
  position: relative;

  span {
    position: absolute;
    width: 115px;
    height: 114px;
    display: block;
    top: -20px;
    left: -20px;
    z-index: -1;

    ${({ theme: { $width } }) => `
      ${
        $width <= 991 &&
        `
        width: 95px;
        height: 95px;
        top: -16px;
        left: -16px;
       margin-bottom: 25px;
      `
      }
      ${
        $width <= 768 &&
        `
        width: 75px;
        height: 75px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: none;
      `
      }
    `}
  }

  ${({ theme: { $width } }) => `
    ${
      $width <= 991 &&
      `
      margin-bottom: 15px;
    `
    }
    ${
      $width <= 575 &&
      `
      margin-bottom: 20px;
    `
    }
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const SubtitleBot = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.4em;
  margin-bottom: 110px;
  position: relative;

  strong {
    font-weight: 700;
    display: block;
  }

  ${({ theme: { $width } }) => `
    ${
      $width <= 1254 &&
      `
      margin-bottom: 80px;
    `
    }
    ${
      $width <= 991 &&
      `
      margin-bottom: 60px;
    `
    }
    ${
      $width <= 575 &&
      `
      margin-bottom: 40px;
    `
    }
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ButtonStyled = styled(Button)`
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 80%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}
`;

const ImageInner = styled.div`
  display: flex;
  position: relative;
  width: 660px;
  height: 660px;
  border-radius: 50%;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 510px;
    height: 510px;
`}

  ${({ theme: { $width } }) =>
    $width <= 514 &&
    `
    width: 410px;
    height: 410px;
`}
`;

const MockUpStyled = styled((props) => <Mockup {...props} />)`
  width: 480px;
  height: auto;
  position: absolute;
  z-index: 6;
  top: -5%;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 380px;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 300px;
`}


  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 320px;
`}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 80%;
    `}

   ${({ mockup_id, theme: { $width } }) =>
    mockup_id === 3
      ? `width:400px; 

      ${
        $width <= 1254 &&
        `
        width: 309px;
        left: 50px;
      `
      }

      ${
        $width <= 991 &&
        `
        width: 247px;
        left: 50px;
      `
      }

      ${
        $width <= 768 &&
        `
        width: 263px;
        left: 0px;
      `
      }

      ${
        $width <= 575 &&
        `
        width: 70%;
        margin-bottom: 30px;
        left: 30px;
      `
      }
      ${
        $width <= 390 &&
        `
        left: 10px;
      `
      }
        `
      : mockup_id === 2
        ? `
      ${
        $width <= 1254 &&
        `
        width: 363px;
      `
      }

      ${
        $width <= 991 &&
        `
        width: 293px
      `
      }

      ${
        $width <= 768 &&
        `
        width: 313px
      `
      }

      ${
        $width <= 575 &&
        `
        left: -3%;
      `
      }
      ${
        $width <= 390 &&
        `
        width: 263px;
      `
      }
      ${
        $width <= 326 &&
        `
        width: 213px;
      `
      }
      `
        : mockup_id === 1
          ? `

      ${
        $width <= 326 &&
        `
        left: 0px;
      `
      }
      `
          : ``}
`;

const PhotoInner = styled.div<{ editable?: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.editable ? 21 : 40)};
  right: 20%;
  bottom: 25%;

  ${({ theme: { $width } }) =>
    $width <= 514 &&
    `
      right: 0;
    `}
`;

const photoImages = css`
  border-radius: 50%;
  overflow: visible !important;

  & img {
    width: 265px;
    height: 265px;
    // object-fit: cover;
    object-position: center center;
    object-fit: fill;
    border-radius: 50%;
    border: 10px solid;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
      width: 170px;
      height: 170px;
      border: 7px solid;
    `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
      right: 0;
      width: 140px;
      height: 140px;
      border: 5px solid;
      `}


  ${({ theme: { $width } }) =>
      $width <= 575 &&
      `
    display: block;
    `}
  }
`;

const Arrow = styled.img`
  position: absolute;
  z-index: 2;
  width: 210px;
  height: 50px;
  bottom: 10%;
  left: 21%;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  width: 145px;
  height: auto;
  bottom: 3%;
  left: 24%;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 95px;
    height: auto;
    bottom: 3%;
    left: 50%;
`}
${({ theme: { $width } }) =>
    $width <= 326 &&
    `
    display: none;
`}
`;

const OwnerText = styled.div`
  position: absolute;
  right: 50%;
  bottom: 45px;
  width: 180px;
  text-align: center;
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  line-height: 1.4em;
  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  right: 38%;
  bottom: 18px;
`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    bottom: 9px;
    width: 172px;
`}

${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    bottom: -9px;
`}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  top: 0px;
  height: 685px;
  width: 513px;
  left: 40px;
  z-index: 30 !important;

  ${({ theme: { $width } }) =>
    $width <= 514 &&
    `
  top: -95px;
  left: 4px;
`}
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

const WhiteBgCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  opacity: 0.72;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

export const V2: FC<HeroCoachProps> = (props) => {
  const {
    secondaryColor = COLOR_YELLOW,
    backgroundColor = COLOR_YELLOW,
    mockup,
    button = defaultWebValue,
    title = defaultWebValue,
    content = defaultWebValue,
    owner = defaultWebValue,
    textColor,
    hidden,
    meta = {},
    cover_thumbnail
  } = props;

  const { salesPageId, updateImageMeta, pagesThumbnails, purchaseElementId } = useSales();

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { pages, isCoverDisabled, bookMedia } = useBook();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const [isLoading, setIsLoading] = useState([false, false]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, cover_thumbnail, pagesThumbnails]);

  const youHereImage = useMemo(() => {
    if (meta['you_here']) {
      if (baseMediaEndpoint?.includes('localhost') && !meta['you_here'].includes('localhost')) {
        return `${baseMediaEndpoint}${meta['you_here']}`;
      }
      return meta['you_here'];
    }
    return '/img/you_here.png';
  }, [meta]);

  const handleUpload = async (file: File, type: string, index: number) => {
    setIsLoading((prev) => prev.map((_, i) => (i === index - 1 ? true : false)));
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    if (index === 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setResize({ width, height });
          updateImageMeta(meta['accessorKey'], width, 'width');
          updateImageMeta(meta['accessorKey'], height, 'height');
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, type);
    setIsLoading([false, false]);
  };

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  // RETURN HERE!!!

  return (
    <Coaching
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <TitleInner>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <SubtitleBot
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <ButtonStyled to={purchaseElementId} button={button} meta={meta} />
        </TitleInner>
        <ImageInner style={{ background: secondaryColor }}>
          {bookMockup ? (
            <HoverableDropzone
              isSalePage
              css={imageStyles as never}
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={(file) => handleUpload(file, 'image', 0)}
              loading={isLoading[0]}
              alreadyUploaded={Boolean(bookMockup)}
              imageFile={bookMockup}
              resizeSelector={editable}
              handleResize={({ width, height }) => {
                setResize({ width, height });
                updateImageMeta(meta['accessorKey'], width, 'width');
                updateImageMeta(meta['accessorKey'], height, 'height');
              }}
              initialPosition={{
                x: meta['x_1'] || 0,
                y: meta['y_1'] || 0,
                width: meta['d_width_1'],
                height: meta['d_height_1']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_1');
                updateImageMeta(meta['accessorKey'], y, 'y_1');
                updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                updateImageMeta(meta['accessorKey'], height, 'd_height_1');
              }}
              imageAspectRatio={{
                ratio: '3x4',
                pixels: '600x800'
              }}>
              <BookMediaImg
                isImageUploaded={isImageUploaded}
                src={bookMockup}
                width={resize.width || 500}
                height={resize.height || 500}
                alt="Book Mockup"
              />
            </HoverableDropzone>
          ) : (
            <MockUpStyled
              mockup={mockup?.file}
              mockup_id={mockup?.id}
              cover_thumbnail={coverThumbnail}
            />
          )}
          <PhotoInner editable={editable}>
            <HoverableDropzone
              css={
                css`
                  ${photoImages}
                  & img {
                    border-color: ${secondaryColor};
                  }
                ` as never
              }
              disabled={!editable}
              allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              onUpload={(file) => handleUpload(file, 'you_here', 1)}
              loading={isLoading[1]}
              borderRadius="50%"
              alreadyUploaded={Boolean(meta['you_here'])}
              imageFile={meta['you_here']}
              imageAspectRatio={{
                ratio: '1x1',
                pixels: '1000x1000'
              }}
              initialPosition={{
                x: meta['x_2'],
                y: meta['y_2'],
                width: meta['d_width_2'],
                height: meta['d_height_2']
              }}
              meta={meta}
              positionEnabled
              handlePosition={(width: number, height: number, x: number, y: number) => {
                updateImageMeta(meta['accessorKey'], x, 'x_2');
                updateImageMeta(meta['accessorKey'], y, 'y_2');
                updateImageMeta(meta['accessorKey'], width, 'd_width_2');
                updateImageMeta(meta['accessorKey'], height, 'd_height_2');
              }}
              size="sm">
              <ImageComp
                style={{
                  borderColor: backgroundColor
                }}
                src={youHereImage}
                alt="author photo"
                width={500}
                height={500}
              />
            </HoverableDropzone>
          </PhotoInner>
          <Arrow
            src={'/img/book_arrow.svg'}
            style={{
              filter: tinycolor(secondaryColor).isDark()
                ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(103%) contrast(100%)'
                : 'unset'
            }}
          />
          <OwnerText
            id={owner.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(owner.val) }}
            style={{ color: filterBackgroundColor(secondaryColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="owner"
          />
          <WhiteBgCircle />
        </ImageInner>
      </StyledWrapper>
    </Coaching>
  );
};
