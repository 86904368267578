import type ReactQuill from 'react-quill';
import type { FC, PropsWithChildren, MouseEvent, MutableRefObject } from 'react';

import { createContext, useEffect, useRef, useState } from 'react';

import useSales from 'shared/hooks/useSales';
import { EDITOR_ZOOM_SIZE, isBoundingClientRectZoomed } from '../helpers';

type Dimensions = {
  top: number;
  width: number;
} | null;

export type WebsiteEditorContextData = {
  editable: boolean;
  toolbarPosition: Dimensions;
  activeElement: HTMLElement | null;
  containerClassname: string;
  addComponents: boolean;
  showLink: boolean;
  footerActive: boolean;
  showLinkInElement: string;
  websiteAssembler: boolean;
  zoomSize: number;
  updateZoomSize: (value: number) => void;
  updateWebsiteAssembler: (value: boolean) => void;
  updateEditable: (value: boolean) => void;
  updateFooterActive: (value: boolean) => void;
  updateActiveElement: (event: MouseEvent<HTMLElement>, toolbar?: boolean) => void;
  updateAddComponents: (value: boolean) => void;
  resetActiveElement: () => void;
  updateToolbarPosition: () => void;
  resetToolbarPosition: () => void;
  updateShowLink: (value: boolean) => void;
  updateShowLinkInElement: (value: string) => void;
  quillRef: MutableRefObject<ReactQuill | null>;
  initialRange: Range | null;
  updateInitialRange: (data: Range | null) => void;
  widthPercent: number;
  updateWidthPercent: (value: number) => void;
  customElement: boolean;
  updateCustomElement: (value: boolean) => void;
};

const WebsiteEditorContext = createContext<WebsiteEditorContextData>({
  editable: false,
  activeElement: null,
  addComponents: false,
  containerClassname: '',
  footerActive: false,
  toolbarPosition: null,
  showLink: false,
  showLinkInElement: '',
  websiteAssembler: false,
  updateWebsiteAssembler: () => null,
  resetActiveElement: () => null,
  updateActiveElement: () => null,
  updateAddComponents: () => null,
  updateEditable: () => null,
  updateToolbarPosition: () => null,
  updateShowLink: () => null,
  updateShowLinkInElement: () => null,
  quillRef: { current: null },
  initialRange: null,
  updateInitialRange: () => null,
  resetToolbarPosition: () => null,
  updateFooterActive: () => null,
  updateZoomSize: () => null,
  zoomSize: 1,
  widthPercent: 80,
  updateWidthPercent: () => null,
  customElement: false,
  updateCustomElement: () => null
});

const WebsiteEditorProvider: FC<PropsWithChildren> = ({ children }) => {
  const { syncRender } = useSales();

  const [containerClassname] = useState('pagewheel_container');
  const [editable, setEditable] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState<Dimensions>(null);
  const [activeElement, setActiveElement] = useState<HTMLElement | null>(null);
  const [addComponents, setAddComponents] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showLinkInElement, setShowLinkInElement] = useState('');
  const quillRef = useRef<ReactQuill | null>(null);
  const [initialRange, setInitialRange] = useState<Range | null>(null);
  const [footerActive, setFooterActive] = useState(false);
  const [websiteAssembler, setWebsiteAssembler] = useState(false);
  const [zoomSize, setZoomSize] = useState(EDITOR_ZOOM_SIZE);
  const [widthPercent, setWidthPercent] = useState(80);
  const [customElement, setCustomElement] = useState(false);

  const updateWebsiteAssembler = (value: boolean) => {
    setWebsiteAssembler(value);
  };

  const updateCustomElement = (value: boolean) => {
    setCustomElement(value);
  };

  const updateWidthPercent = (value: number) => {
    setWidthPercent(value);
  };

  const updateZoomSize = (value: number) => {
    setZoomSize(value);
  };

  const updateFooterActive = (value: boolean) => {
    setFooterActive(value);
  };

  const updateShowLinkInElement = (id: string) => {
    setShowLinkInElement(id);
  };

  const updateInitialRange = (data: Range | null) => {
    setInitialRange(data);
  };

  const updateShowLink = (value: boolean) => {
    setShowLink(value);
  };

  useEffect(() => {
    const touchStart = (event: TouchEvent) => {
      event.stopPropagation();
      const el = event.currentTarget as HTMLElement;
      if (el) {
        el.style.transform = 'TranslateY(-10000px)';
        el.focus();
        setTimeout(function () {
          el.style.transform = 'none';
        }, 100);
      }
    };
    window.addEventListener('touchstart', touchStart);
    return () => {
      window.removeEventListener('touchstart', touchStart);
    };
  });

  const updateToolbar = (target: HTMLElement) => {
    let currentElement = target;

    while (currentElement) {
      if (currentElement.classList.contains(containerClassname)) break;
      if (currentElement.parentNode) currentElement = currentElement.parentNode as HTMLElement;
      else break;
    }

    const editor = document.getElementById('pw-editor-container');

    requestAnimationFrame(() => {
      const rect = currentElement?.getBoundingClientRect();
      const isZoomed = isBoundingClientRectZoomed(currentElement);
      let initialElementTop = rect.top;
      if (!isZoomed) initialElementTop = rect.top * zoomSize;
      const actualWidth = currentElement.offsetWidth;
      if (editor) {
        const parentRect = editor.getBoundingClientRect();
        const isZoomed = isBoundingClientRectZoomed(editor);
        let initialTop = parentRect.top;

        if (!isZoomed) initialTop = parentRect.top * zoomSize;
        initialElementTop = initialElementTop - initialTop;
      }

      setToolbarPosition({
        top: initialElementTop - (20 + 20 * zoomSize + 30),
        width: actualWidth * zoomSize
      });
    });
  };

  const updateActiveElement = (event: MouseEvent<HTMLElement>, toolbar: boolean = true) => {
    if (editable) {
      let range: Range | null = null;

      if (document?.caretRangeFromPoint) {
        // WebKit, Chrome, Edge
        range = document.caretRangeFromPoint(event.clientX, event.clientY);
      } else if (document.caretPositionFromPoint) {
        // Firefox
        const caretPosition = document.caretPositionFromPoint(event.clientX, event.clientY);
        if (caretPosition && caretPosition.offsetNode) {
          range = document.createRange();
          range.setStart(caretPosition.offsetNode, caretPosition.offset);
        }
      }

      setInitialRange(range);
      if (toolbar) updateToolbar(event.currentTarget);
      setActiveElement(event.currentTarget);
    }
  };

  const resetToolbarPosition = () => {
    setToolbarPosition(null);
    setShowLink(false);
    setEditable(true);
  };

  const updateToolbarPosition = () => {
    if (!activeElement) {
      updateShowLink(false);
      setToolbarPosition(null);
      return;
    }
    updateToolbar(activeElement);
  };

  const resetActiveElement = () => {
    if (editable) {
      setToolbarPosition(null);
      setActiveElement(null);
      setShowLink(false);
      setFooterActive(false);
      syncRender();
    }
  };

  const updateEditable = (value: boolean) => {
    setEditable(value);
  };

  const updateAddComponents = (value: boolean) => {
    setAddComponents(value);
  };

  return (
    <WebsiteEditorContext.Provider
      value={{
        showLink,
        editable,
        toolbarPosition,
        activeElement,
        addComponents,
        showLinkInElement,
        containerClassname,
        updateEditable,
        updateActiveElement,
        updateAddComponents,
        updateToolbarPosition,
        resetActiveElement,
        updateShowLink,
        updateShowLinkInElement,
        quillRef,
        initialRange,
        updateInitialRange,
        resetToolbarPosition,
        footerActive,
        updateFooterActive,
        websiteAssembler,
        updateWebsiteAssembler,
        zoomSize,
        updateZoomSize,
        widthPercent,
        updateWidthPercent,
        customElement,
        updateCustomElement
      }}>
      {children}
    </WebsiteEditorContext.Provider>
  );
};

export type { Dimensions };
export { WebsiteEditorContext };
export default WebsiteEditorProvider;
