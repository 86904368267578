import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FormContext, type IFormContext } from '../../context/FormContext';
import Title from '../Title/Title';
import DefInput from '../Inputs/DefInput';
import QrToggle from '../Inputs/QrToggle';

const MaxLengthError = styled.div`
  margin-top: 10px;
  background: #ff565d;
  color: #fff;
  padding: 5px 10px;

  span {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

const FaceTimeCallForm = () => {
  const { setSuccess, setText, setIsRequared, isRequared, maxInputTextLength, data } = useContext(
    FormContext
  ) as IFormContext;

  const [error, setError] = useState('');
  const [lengthSum, setLengthSum] = useState(0);
  const [input, setInput] = useState<string>('');
  const [maxLengthError, setMaxLengthError] = useState('');
  const [typeFaceTime, setTypeFaceTime] = useState(false);

  useEffect(() => {
    if (data?.email) {
      setInput(data.email);
      setIsRequared(false);
    }
  }, [data, setIsRequared]);

  // Validation
  const validateRequared = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(value);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;

    setInput(e.target.value);

    // Max length of the text for the QR code
    if (lengthSum > maxInputTextLength) {
      return;
    }

    // check requare
    if (input) {
      if (value.trim() === '') {
        setIsRequared(true);
        setError('Email is required field');
      } else if (!validateRequared(value)) {
        setIsRequared(true);
        setError('Invalid email address');
      } else {
        setIsRequared(false);
        setError('');
      }
    } else {
      setIsRequared(false);
    }

    setSuccess(false);
  };

  useEffect(() => {
    // Mask of the email for the QR code
    const onFormSum = typeFaceTime ? `facetime://${input}` : `facetime-audio://${input}`;

    // Max length of the text for the QR code and validation of main input
    setLengthSum(onFormSum.length);

    if (onFormSum.length < maxInputTextLength) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
      setMaxLengthError(`Max length of the text must be ${maxInputTextLength}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, typeFaceTime]);

  return (
    <>
      <Title
        title="FaceTime Call"
        description="Please provide the Apple ID email and choose the call type to generate the QR code"
      />

      <DefInput
        lableName="Enter Email"
        type="text"
        name="urlInput"
        value={input}
        onChange={handleInput}
        placeholder="user@gmail.com"
        errorstyle={!!error}
        error={error}
        required
      />
      <QrToggle
        first="FaceTime Audio"
        second="FaceTime Video"
        isToggled={typeFaceTime}
        onToggle={() => setTypeFaceTime(!typeFaceTime)}
      />
      {lengthSum + 1 > maxInputTextLength ? (
        <MaxLengthError>
          {maxLengthError} / <span>{lengthSum}</span>
        </MaxLengthError>
      ) : null}
    </>
  );
};

export default FaceTimeCallForm;
