import type { FC, PropsWithChildren } from 'react';

import { createContext, useState } from 'react';

interface Alert {
  message: string;
  severity: 'error' | 'success' | 'info' | 'warning';
  isOpen: boolean;
}

export type WinMessageType = 'subscriber' | 'purchase' | 'published';

type UtilsContextData = {
  alert?: Alert;
  setAlert: (value: Alert) => void;
  activeWinMessage: ActiveWinMessage;
  setActiveWinMessage: (value: ActiveWinMessage) => void;
  userProgress: number;
  setUserProgress: (value: number) => void;
  isPageLoading: boolean;
  setIsPageLoading: (value: boolean) => void;
};

export type ActiveWinMessage = {
  type: WinMessageType;
  name: string;
  media?: string;
};

const UtilsContext = createContext<UtilsContextData>({
  alert: {
    message: '',
    severity: 'error',
    isOpen: false
  },
  setAlert: () => null,
  activeWinMessage: {
    type: 'subscriber',
    name: ''
  },
  setActiveWinMessage: () => null,
  userProgress: 0,
  setUserProgress: () => null,
  isPageLoading: false,
  setIsPageLoading: () => null
});

const UtilsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alert, setAlert] = useState<Alert>();
  const [activeWinMessage, setActiveWinMessage] = useState<ActiveWinMessage>({
    type: 'subscriber',
    name: '',
    media: ''
  });
  const [userProgress, setUserProgress] = useState<number>(0);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  return (
    <UtilsContext.Provider
      value={{
        alert,
        setAlert,
        activeWinMessage,
        setActiveWinMessage,
        userProgress,
        setUserProgress,
        isPageLoading,
        setIsPageLoading
      }}>
      {children}
    </UtilsContext.Provider>
  );
};

export type { UtilsContextData };

export { UtilsContext, UtilsProvider };
