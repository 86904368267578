import type { PaymentFormSubscription } from 'shared/components/Sales/RecurringForm/V1';
import type { Stripe } from '../types';

import { ApiService } from 'shared/helpers/api';
import useErrorLogger from './useErrorLogger';
import useSales from './useSales';
import { isLocalhostIP } from '../helpers';
import useAlert from './useAlert';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { loadStripe as LoadStripe } from '@stripe/stripe-js';

interface RecurringFirstValues {
  email: string;
  first_name: string;
  last_name: string;
  interval: string;
}

const useSalesForm = (stripe?: Stripe) => {
  const { t } = useTranslation();

  const { addAlert } = useAlert();
  const { logError } = useErrorLogger();

  const [recurringModalInterval, setRecurringModalInterval] = useState<'month' | 'year' | ''>('');
  const [paymentFormSubscription, setPaymentFormSubscription] = useState<PaymentFormSubscription>();
  const { isSubscription, subDomain, deliveryToken } = useSales();

  const stripePromise = useMemo(() => {
    if (isSubscription && stripe)
      return LoadStripe(stripe.public_key, { stripeAccount: stripe.account });
    return null;
  }, [isSubscription, stripe]);

  const { updateDownloadUrl, amazonURL, deliveryPageValues } = useSales();

  const handleFirstRecurring = async (values: RecurringFirstValues, sub_domain: string) => {
    try {
      const response = await ApiService.post(`/sales/page/${sub_domain}/order-sub/`, values);
      return response.data;
    } catch (error) {
      logError(error);
    }
  };

  const navigateToThankYou = () => {
    if (amazonURL && deliveryPageValues && deliveryPageValues?.['no_delivery']) {
      if (typeof window !== 'undefined') window.location.replace(amazonURL);
      return;
    }
    if (amazonURL) window.open(amazonURL, '_blank');
    if (isLocalhostIP()) {
      window.location.href = `/${subDomain}/thankyou/${deliveryToken}`;
    } else {
      window.location.href = `/thankyou/${deliveryToken}`;
    }
  };

  const handleSecondRecurrent = (email: string, uuid: string) => {
    try {
      const checkDeliveryStatus = setInterval(async () => {
        const params = {
          email: email,
          uuid,
          sub_domain: subDomain
        };
        const status = await ApiService.get(`/sales/page/${subDomain}/order-sub/status/`, {
          params
        });
        if (status.data.status === 'delivered') {
          clearInterval(checkDeliveryStatus);
          const response = await ApiService.get(
            `/sales/page/${subDomain}/delivery/${uuid}/?is_subscription=true`
          );
          updateDownloadUrl(response.data.file_url);
          navigateToThankYou();
        }
      }, 2000);
    } catch (_error) {
      addAlert(t('errors.somethingWentWrong'), 'error');
    }
  };

  return {
    handleFirstRecurring,
    handleSecondRecurrent,
    recurringModalInterval,
    setRecurringModalInterval,
    paymentFormSubscription,
    setPaymentFormSubscription,
    stripePromise
  };
};

export default useSalesForm;
