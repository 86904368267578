import type { UserDetails } from 'shared/types/User';

import { createContext } from 'react';

export interface IFormContext {
  setText: (value: string) => void;
  setSuccess: (value: boolean) => void;
  setIsRequared: (value: boolean) => void;
  isRequared: boolean;
  maxInputTextLength: number;
  data: UserDetails | null;
}

export const FormContext = createContext<IFormContext | null>(null);
