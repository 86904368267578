import type { FC } from 'react';
import type { VideoProps } from './types';

import { useMemo } from 'react';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import { HoverableInput } from '../HoverableInput';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const VideoContainer = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  padding: 60px 0;
`}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  padding: 60px 15px;
`}
`;

const Container = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
  `}
`;

const Video = styled.div`
  width: 47.5%;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;

&:not(:last-of-type) {
  margin-bottom: 40px;
}
  `}
`;

const playerStyles = css`
  width: 100% !important;
  height: 308px !important;
  border: none;
  margin-bottom: 25px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 15px;
  `}

  & div, & .exampleImg {
    width: 100% !important;
    height: 308px !important;
    border: none;
    margin-bottom: 25px;

    ${({ theme: { $width } }) =>
      $width <= 768 &&
      `
    margin-bottom: 15px;
  `}
  }
`;

const TitleContainer = styled.div`
  width: 100%;
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 600;
  margin-bottom: 16px;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.35em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V3: FC<VideoProps> = (props) => {
  const { meta = {}, hidden, textColor, benefits = [] } = props;
  const {
    editable,
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    websiteAssembler
  } = useWebsiteEditor();

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const video = useMemo(() => {
    if (benefits.length) {
      return benefits;
    }
    return [
      {
        title: defaultWebValue,
        content: defaultWebValue
      },
      {
        title: defaultWebValue,
        content: defaultWebValue
      }
    ];
  }, [benefits]);

  return (
    <VideoContainer
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Container>
        {video.map(({ title, content }, index) => {
          const isTitleEmpty = checkTitleBenefits(index);
          const isContentEmpty = checkContentBenefits(index);
          return (
            <Video
              key={`video-${index}`}
              style={{
                display: !websiteAssembler && isTitleEmpty && isContentEmpty ? 'none' : 'block'
              }}>
              <HoverableInput
                disabled={!editable}
                css={playerStyles as never}
                meta={meta}
                index={index + 1}>
                {meta['url_1'] ? (
                  <ReactPlayer url={meta?.[`url_${index + 1}`]} controls={true} />
                ) : (
                  <img className="exampleImg" src="/img/example_video.png" />
                )}
              </HoverableInput>
              <TitleContainer>
                <Title
                  id={title?.id}
                  dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                  style={{ color: filterBackgroundColor('#fff', textColor) }}
                  onClick={updateActiveElement}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="title"
                  data-benefits={true}
                  data-placeholder="+"
                  editable={editable}
                  isEmpty={isTitleEmpty}
                />
                <Text
                  id={content?.id}
                  dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
                  style={{ color: filterBackgroundColor('#fff', textColor) }}
                  onClick={updateActiveElement}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="content"
                  data-benefits={true}
                  data-placeholder="+"
                  editable={editable}
                  isEmpty={isContentEmpty}
                />
              </TitleContainer>
            </Video>
          );
        })}
      </Container>
    </VideoContainer>
  );
};
