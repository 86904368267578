import type { FC } from 'react';
import type { SalesItem, BookBaseColor, Stripe } from 'shared/types/index';
import tinycolor from 'tinycolor2';
import { useMemo } from 'react';
import styled from 'styled-components';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import 'react-phone-number-input/style.css';
import { COLOR_BLUE_6, COLOR_YELLOW } from 'shared/helpers/colors';
import { shade, sanitizeRichText } from 'shared/helpers/utils';
import { filterBackgroundColor } from 'shared/helpers/website';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Wrapper } from '../Common';
import RecurringSecondForm from './RecurringSecondForm';
import useSalesForm from 'shared/hooks/useSalesForm';
import { RecurringFirstForm } from './RecurringFirstForm';

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  padding: 0px 0 94px;
  position: relative;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 62px 0 76px;
  `}
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 4;
  gap: 80px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    justify-content: center;
  `}
`;

// interface TitleContainerProps {
//   isTitleEmpty?: boolean;
//   isContentEmpty?: boolean;
//   editable?: boolean;
// }

// const TitleContainer = styled.div<TitleContainerProps>`
//   display: flex;
//   flex-direction: column;
//   ${({ isTitleEmpty, editable }) => (isTitleEmpty && editable ? `gap: 50px;` : '')}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     width: 100%;
//     margin-bottom: 22px;
//   `}
// `;

// const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
//   font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
//   margin-bottom: 55px;
//   line-height: 1.3em;
//   position: relative;
//   text-align: center;

//   ${({ theme: { $width } }) =>
//     $width <= 1254 &&
//     `
//     font-size: 50px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 991 &&
//     `
//     font-size: 36px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     font-size: 30px;
//     margin-bottom: 16px;
//   `}

//   ${({ isEmpty, editable }) =>
//     isEmpty && editable
//       ? `
//     &:not(:focus):before {
//       content: attr(data-placeholder);
//       cursor: text;
//       color: #aaa;
//       position: absolute;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   `
//       : `
//     &:before {
//       content: none;
//     }
//   `}
// `;

const RecurInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    gap: 30px;
  `}
`;

const Item = styled.div`
  background-color: #fff;
  border-radius: 14px;
  padding: 26px;
  padding-bottom: 70px;
  width: 47.4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
        width: 48.4%;
        padding-bottom: 56px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
`;

const Header = styled.div<{ buttonBackground: string; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.buttonBackground};
  color: ${(props) => props.color};
  text-align: center;
  position: relative;
  padding: 65px 15px;
  padding-bottom: 100px;
  border-radius: 14px;
  margin-bottom: 86px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      padding: 45px 15px;
      padding-bottom: 70px;
        margin-bottom: 70px;
  `}
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Value = styled.div`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
`;

const Name = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
`;

const IconItem = styled.div<{ bgColor: string }>`
  width: 116px;
  height: 116px;
  border-radius: 50%;
  position: absolute;
  bottom: -58px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f3f3f3')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 96px;
      height: 96px;
      padding: 25px;
        bottom: -48px;
  `}

  svg {
    width: 100%;
    height: 100%;
    margin-top: 3px;
  }
`;

const InfoDescription = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
       padding: 0 20px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 0 10px;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`;

const List = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.45em;
  display: flex;
  justify-content: center;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    min-height: 50%;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
     gap: 38px;
  `}
`;

const Description = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  text-align: center;
  line-height: 1.45em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Button = styled.button<{ backgroundColor: string; editable: boolean }>`
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 28px 40px;
  border-radius: 46px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  width: 100%;
  margin: 0 auto;
  ${(props) => props.editable && `cursor: text;`}

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => props.editable && `cursor: text !important;`};
    display: inline-block;
  }
`;

export interface BookOrderSub {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  total: string;
  status: string;
  type: string;
  subscribe: boolean;
  stripe_status: string;
}

export interface PaymentFormSubscription {
  client_secret: string;
  account: string;
  book_order_sub: BookOrderSub;
}

export interface RecurringFormProps extends SalesItem {
  backgroundColor: string;
  secondaryColor?: string;
  textColor: BookBaseColor;
  stripe?: Stripe;
}

export const V1: FC<RecurringFormProps> = (props: RecurringFormProps) => {
  const {
    backgroundColor = COLOR_BLUE_6,
    // title = defaultWebValue,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    button,
    benefits,
    stripe
  } = props;

  const { updateActiveElement, resetActiveElement, containerClassname, editable } =
    useWebsiteEditor();
  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const { salesPageValues, setIsRecurringSubscriptionModalOpen, subDomain } = useSales();
  const {
    recurringModalInterval,
    setRecurringModalInterval,
    paymentFormSubscription,
    setPaymentFormSubscription,
    stripePromise
  } = useSalesForm(stripe);

  // const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  // const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const buttonBackground = useMemo(() => {
    if (button?.color) return button.color;
    if (
      salesPageValues &&
      typeof salesPageValues['topbar_1'] === 'object' &&
      salesPageValues['topbar_1'].button?.color
    ) {
      return salesPageValues['topbar_1'].button?.color;
    }
    return backgroundColor;
  }, [backgroundColor, salesPageValues, button]);

  const [shadedBackgroundColor] = useMemo(() => {
    return [shade(backgroundColor, 0.7)];
  }, [backgroundColor]);

  const color = useMemo(() => {
    return tinycolor(buttonBackground).isDark() ? '#FFFFFF' : '#000000';
  }, [buttonBackground]);

  if (recurringModalInterval && !paymentFormSubscription?.client_secret) {
    const button = benefits?.[recurringModalInterval === 'month' ? 0 : 1]?.button;

    return (
      <RecurringFirstForm
        backgroundColor={backgroundColor}
        callback={(data) => setPaymentFormSubscription(data)}
        button={
          button || {
            color: buttonBackground,
            val: '<p>Purchase</p>'
          }
        }
        meta={meta}
        containerClassname={containerClassname}
        shadedBackgroundColor={shadedBackgroundColor}
        subDomain={subDomain}
        recurringModalInterval={recurringModalInterval}
      />
    );
  }

  if (paymentFormSubscription?.client_secret) {
    return (
      <RecurringSecondForm
        paymentFormSubscription={paymentFormSubscription}
        containerClassname={containerClassname}
        shadedBackgroundColor={shadedBackgroundColor}
        stripePromise={stripePromise}
        backgroundColor={backgroundColor}
        textColor={textColor}
      />
    );
  }

  return (
    <Container
      backgroundColor={shadedBackgroundColor}
      className={containerClassname}
      id="recurring_form">
      <StyledWrapper>
        {/* <TitleContainer
          editable={editable}
          isTitleEmpty={isTitleEmpty}
          isContentEmpty={isContentEmpty}>
          <Title
            id={title?.id}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor),
              display: sanitizeRichText(title.val, editable, true) || editable ? 'block' : 'none'
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(title.val, editable, true)
                ? sanitizeRichText(title.val, editable, false, '<p>Choose the Best Plan</p>')
                : '<p></br><p>'
            }}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="Choose the Best Plan"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
        </TitleContainer> */}
        <RecurInner>
          {benefits?.map((benefit, index) => {
            const isTitleEmpty = checkTitleBenefits(index);
            const isContentEmpty = checkContentBenefits(index);
            const isFirst = index === 0;

            return (
              <Item key={index}>
                <Header
                  onClick={() => {
                    setIsRecurringSubscriptionModalOpen(true);
                  }}
                  buttonBackground={benefit.button?.color || buttonBackground}
                  color={color}>
                  <Price>
                    <Value>
                      ${isFirst ? meta['monthly_price'] : meta['yearly_price']}/
                      {isFirst ? 'm' : 'y'}
                    </Value>
                    <Name>{isFirst ? 'Monthly' : 'Yearly'} Membership</Name>
                  </Price>
                  <IconItem bgColor={shade(benefit?.button?.color || buttonBackground, 0.7)}>
                    {isFirst ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="32"
                        viewBox="0 0 36 32"
                        fill="none">
                        <path
                          d="M35.656 8.5919C35.1425 7.25328 31.3676 2.95561 30.2018 1.71563C29.3553 0.813827 28.6614 0.123382 27.3429 -0.0034342H13.0345H9.19027C6.85873 -0.200704 5.56806 2.03972 4.208 3.56152L2.43159 5.54831H2.44547C1.46012 7.01374 0.16945 8.04236 0.00291186 9.98688C-0.246895 13.115 3.93044 16.9477 5.95665 19.4558L8.21879 22.0063C11.1332 25.3035 14.1587 28.5303 17.2674 31.6584C17.5449 31.9543 17.9474 32.067 18.3499 31.9543C24.4285 26.2476 29.6051 19.7095 34.9898 13.3405C36.0029 11.875 36.2943 10.311 35.6421 8.5919H35.656ZM14.9358 2.18063H20.4038L22.3468 9.39507H13.1872L13.0484 9.12735L14.9358 2.19472V2.18063ZM2.87569 8.39463C3.69451 7.26737 4.86028 5.97103 5.79011 4.88604C6.22034 4.37878 8.03838 2.11017 8.52411 2.11017H12.7431L10.7307 9.38098H2.34832C2.30669 9.00053 2.65364 8.69053 2.87569 8.39463ZM2.47323 11.8469L2.34832 11.3819H10.7307L15.602 26.8394C10.7863 22.274 6.80322 16.8913 2.47323 11.8609V11.8469ZM18.0029 27.3607L13.0068 11.3819H22.3607L18.0168 27.3607H18.0029ZM33.255 12.2273C30.8402 15.595 27.0376 19.8504 24.2342 22.9926C22.9713 24.4017 21.528 25.6698 20.2789 27.0789L24.6228 11.3537H33.6436C33.6853 11.7059 33.4354 11.9596 33.255 12.2132V12.2273ZM24.6228 9.38098L22.6105 2.11017H27.4817C27.7731 2.11017 28.245 2.61744 28.4532 2.8288C29.4524 3.84333 32.9497 7.81691 33.5187 8.90189C33.602 9.05689 33.6991 9.15553 33.6436 9.36689H24.6228V9.38098Z"
                          fill={benefit?.button?.color || buttonBackground}
                        />
                      </svg>
                    ) : (
                      <svg
                        style={{ marginTop: '-3px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 36.79 28.58">
                        <path
                          d="M35.9,6.67c-.7-.56-1.62-.67-2.46-.3l-5.89,3.88L20.36,1.04c-.49-.66-1.21-1.04-1.98-1.04,0,0,0,0,0,0-.75,0-1.45.37-1.95,1.05l-6.12,9.23-6.88-3.85c-.84-.41-1.81-.32-2.52.23C.19,7.22-.14,8.15.06,9.13l2.68,10.32,31.25.08,2.75-10.47c.18-.91-.14-1.82-.84-2.39ZM26.03,11.93l-1.74,5.34-5.17-.02V3.07l6.91,8.86ZM2.23,8.63c-.02-.09,0-.17.05-.21.02-.01.06-.05.12-.02l6.98,3.91,1.6,4.91h-6.52l-2.23-8.59ZM12.31,17.22l.13-.04-1.33-4.08,6.61-9.97v14.11l-4.02-.02h-1.39ZM32.27,17.3l-6.51-.03,1.31-4.03,7.32-4.85c.07-.01.09,0,.11.02.02.02.08.08.07.15l-2.3,8.74Z"
                          fill={benefit?.button?.color || secondaryColor}
                        />
                        <path
                          d="M32.37,24.7v1.67H4.03v-1.67h28.34M32.69,22.5H3.7c-1.04,0-1.87.84-1.87,1.87v2.32c0,1.04.84,1.87,1.87,1.87h28.99c1.04,0,1.87-.84,1.87-1.87v-2.32c0-1.04-.84-1.87-1.87-1.87h0Z"
                          fill={benefit?.button?.color || secondaryColor}
                        />
                      </svg>
                    )}
                  </IconItem>
                </Header>
                <InfoDescription>
                  <TextWrapper>
                    <List
                      id={benefit?.title?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.title?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor('#ffffff', textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleEmpty}
                    />
                  </TextWrapper>
                  <ButtonInner>
                    <Description
                      id={benefit?.content?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.content?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor(shadedBackgroundColor, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentEmpty}
                    />
                    <Button
                      id={benefit?.button?.id}
                      backgroundColor={
                        benefit.button?.color || (isFirst ? buttonBackground : secondaryColor)
                      }
                      editable={editable}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(
                          benefit?.button?.val || `<p>Monthly Plan - $27</p>`,
                          editable
                          // false,
                        )
                      }}
                      onClick={
                        editable
                          ? updateActiveElement
                          : () => setRecurringModalInterval(isFirst ? 'month' : 'year')
                      }
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="button"
                      style={{ color: color }}
                      {...(editable ? { as: 'p' } : {})}
                      data-pindex={index}
                      data-benefits={true}
                    />
                  </ButtonInner>
                </InfoDescription>
              </Item>
            );
          })}
        </RecurInner>
      </StyledWrapper>
    </Container>
  );
};
