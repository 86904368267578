import styled, { keyframes, css } from 'styled-components';

const DefImage = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoInner = styled.div`
  width: 130px;
  height: 130px;
  box-shadow: 0px 4px 15.7px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  position: relative;
  z-index: 4;
  border-radius: 25px;

  @media screen and (max-width: 991px) {
    width: 100px;
    height: 100px;
    border-radius: 15px;
  }
`;

const rotateWithPause = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const ImgFlower = styled.img<{ isActive: boolean }>`
  width: 90px;
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 15%;
  z-index: 2;
  animation: ${(props) =>
    props.isActive &&
    css`
      ${rotateWithPause} 7s ease-in-out infinite
    `};

  @media screen and (max-width: 991px) {
    width: 70px;
    height: 70px;
    border-radius: 15px;
  }
`;

const ImgBotLogo = styled.img`
  bottom: 0;
  position: absolute;
  left: -4px;
  right: 0;
  margin: 0 auto;
  z-index: 1;
`;

const AbsoluteStarContainer = styled.div`
  position: absolute;
  width: 70%;
  height: 70%;
  left: -5%;
  right: 0;
  margin: 0 auto;
  top: 10%;
  z-index: 3;
`;

const StarContainer = styled.div`
  position: relative;
  width: 70%;
  height: 70%;
`;

const blink = keyframes`
  0%, 100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
`;

// star item
const Star = styled.div<{ delay: number; left: number; top: number }>`
  position: absolute;
  width: 15px;
  height: 15px;
  opacity: 0;

  img {
    width: 100%;
    height: 100%;
  }

  // star animation styles
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  animation: ${blink} 2s infinite;
  animation-delay: ${({ delay }) => `${delay}s`};
`;

const BlackOverlay = styled.div`
  background: rgba(46, 197, 206, 0.02);
  backdrop-filter: blur(4px);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

const Image = styled.img`
  width: 102%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 1;
  top: 0;
  left: -2px;
`;

const BottomText = styled.div`
  position: absolute;
  bottom: 4%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 3;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.05em;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    bottom: 6%;
  }
`;

interface DefaultImageProps {
  isLoading?: boolean;
}

const DefaultImage = (props: DefaultImageProps) => {
  const { isLoading = false } = props;

  const starsCount = 30; // quantity of stars
  const containerWidth = 260;
  const containerHeight = 260;

  // generation of stars
  const stars = Array.from({ length: starsCount }, () => ({
    left: Math.random() * containerWidth,
    top: Math.random() * containerHeight,
    delay: Math.random() * 2
  }));

  return (
    <DefImage>
      <LogoInner>
        <ImgFlower isActive={isLoading} src="/img/logoFlowerAi.png" />
        <ImgBotLogo src="/img/flowerPartAi.png" />
      </LogoInner>
      {isLoading && (
        <AbsoluteStarContainer>
          <StarContainer>
            {stars.map((star, index) => (
              <Star key={index} left={star.left} top={star.top} delay={star.delay}>
                <img src="/img/magicStar.svg" />
              </Star>
            ))}
          </StarContainer>
        </AbsoluteStarContainer>
      )}
      {isLoading && <BlackOverlay />}
      <Image src="/img/defAiImage.jpg" />
      {isLoading ? (
        <BottomText style={{ color: '#000000' }}>
          Applying Magic...
          <br />
          <span
            style={{
              fontSize: '12px'
            }}>
            It can take up to one minute...
          </span>
        </BottomText>
      ) : (
        <BottomText> Your image will appear here</BottomText>
      )}
    </DefImage>
  );
};

export default DefaultImage;
