import type { FC } from 'react';
import type { VideoProps } from './types';

import ReactPlayer from 'react-player';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Wrapper } from '../Common';
import { HoverableInput } from '../HoverableInput';

import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const VideoContainer = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  padding: 60px 0;
`}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
  padding: 60px 15px;
`}
`;

const Container = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column-reverse;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column-reverse;
  `}
`;

const TitleContainer = styled.div`
  width: 458px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 358px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 600;
  margin-bottom: 16px;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const playerStyles = css`
  width: 640px !important;
  height: 360px !important;
  border: none;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 532px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 380px !important;
  `} 

${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100% !important;
    margin-bottom: 40px;
  `}
  & div, & .exampleImg {
    width: 640px !important;
    height: 308px !important;
    border: none;
    position: relative;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
    width: 532px !important;
  `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
    width: 380px !important;
  `} 

${({ theme: { $width } }) =>
      $width <= 768 &&
      `
    width: 100% !important;
    margin-bottom: 40px;
  `}
  }
`;

export const V1: FC<VideoProps> = (props) => {
  const { meta = {}, hidden, textColor, benefits = [] } = props;
  const {
    editable,
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    websiteAssembler
  } = useWebsiteEditor();

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const video = useMemo(() => {
    if (benefits.length) {
      return {
        ...benefits[0],
        title: {
          ...benefits[0].title,
          isEmpty: checkTitleBenefits(0)
        },
        content: {
          ...benefits[0].content,
          isEmpty: checkContentBenefits(0)
        }
      };
    }
    return {
      title: {
        ...defaultWebValue,
        isEmpty: checkTitleBenefits(0)
      },
      content: {
        ...defaultWebValue,
        isEmpty: checkContentBenefits(0)
      }
    };
  }, [benefits, checkContentBenefits, checkTitleBenefits]);

  return (
    <VideoContainer
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Container>
        <TitleContainer>
          <Title
            id={video?.title?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(video.title?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-benefits={true}
            data-placeholder="+"
            editable={editable}
            isEmpty={video.title.isEmpty}
          />
          <Text
            id={video?.content?.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(video.content?.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-benefits={true}
            data-placeholder="+"
            editable={editable}
            isEmpty={video.content.isEmpty}
          />
        </TitleContainer>

        <HoverableInput disabled={!editable} css={playerStyles as never} meta={meta} index={1}>
          {meta['url_1'] ? (
            <ReactPlayer url={meta['url_1']} controls={true} />
          ) : (
            <img className="exampleImg" src="/img/example_video.png" />
          )}
        </HoverableInput>
      </Container>
    </VideoContainer>
  );
};
