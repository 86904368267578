import type { FC } from 'react';
import type { SelectChangeEvent } from '@mui/material';

import { FormControl, InputLabel, Select as MuiSelect, MenuItem } from '@mui/material';

/**
 * Option type definition.
 *
 * @typedef {Object} Option
 * @property {number} id - The unique identifier for the option.
 * @property {string} label - The display label for the option.
 * @property {string} value - The value of the option.
 */
export type Option = {
  id: number;
  label: string;
  value: string;
};

export type SelectProps = {
  label: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  fontSize?: string;
};

/**
 * Select component that renders a Material-UI Select with customizable options.
 *
 * @component
 * @param {string} props.label - The label for the select input.
 * @param {string} props.value - The currently selected value.
 * @param {Option[]} props.options - The list of options to display.
 * @param {function(string):void} props.onChange - The callback function to handle value changes.
 * @param {string} props.fontSize - Optional font size for the label and options.
 * @returns {JSX.Element} The rendered select component
 * @example
 * return (
 *   <Select
 *     label="Choose an option"
 *     value="option1"
 *     options={[
 *       { id: 1, label: 'Option 1', value: 'option1' },
 *       { id: 2, label: 'Option 2', value: 'option2' },
 *     ]}
 *     onChange={(value) => handleChange(value)}
 *     fontSize="1.2em"
 *   />
 * )
 */
export const Select: FC<SelectProps> = (props) => {
  const { label, value, options, onChange, fontSize } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        style={{
          fontSize: fontSize || '1.2em',
          display: 'flex',
          alignItems: 'center',
          transformOrigin: 'center left',
          height: '47px'
        }}
        id="select-label">
        {label}
      </InputLabel>
      <MuiSelect
        labelId="select-label"
        id="select"
        value={value}
        onChange={handleChange}
        style={{
          fontSize: fontSize || '1.2em',
          width: '100%',
          height: '34px',
          textAlign: 'center'
        }}>
        {options.map((option) => (
          <MenuItem
            style={{
              fontSize: fontSize || '1em'
            }}
            key={option.id}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
