import { useContext } from 'react';
import { UtilsContext } from '../context/Utils';

function useUtils() {
  const context = useContext(UtilsContext);
  if (!context) {
    throw new Error('useUtilsContext must be used within a UtilsProvider');
  }
  return context;
}

export default useUtils;
