import type { FC } from 'react';
import type { UpsellVIPFormProps } from './V1';
import tinycolor from 'tinycolor2';

import { useMemo } from 'react';
import styled from 'styled-components';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import 'react-phone-number-input/style.css';
import { COLOR_BLUE_6, COLOR_YELLOW } from 'shared/helpers/colors';
import { shade, sanitizeRichText } from 'shared/helpers/utils';
import { filterBackgroundColor } from 'shared/helpers/website';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Wrapper } from '../Common';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 0 94px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 62px 0 76px;
  `}
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 4;
  gap: 80px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    justify-content: center;
    
  `}
`;

// const TitleContainer = styled.div<{
//   isTitleEmpty?: boolean;
//   isContentEmpty?: boolean;
//   editable?: boolean;
// }>`
//   display: flex;
//   flex-direction: column;
//   ${({ isTitleEmpty, editable }) => (isTitleEmpty && editable ? `gap: 50px;` : '')}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     width: 100%;
//     margin-bottom: 22px;
//   `}
// `;

// const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
//   font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
//   margin-bottom: 70px;
//   line-height: 1.3em;
//   position: relative;
//   text-align: center;

//   ${({ theme: { $width } }) =>
//     $width <= 1254 &&
//     `
//     font-size: 50px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 991 &&
//     `
//     font-size: 36px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     font-size: 30px;
//     margin-bottom: 16px;
//   `}

//   ${({ isEmpty, editable }) =>
//     isEmpty && editable
//       ? `
//     &:not(:focus):before {
//       content: attr(data-placeholder);
//       cursor: text;
//       color: #aaa;
//       position: absolute;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   `
//       : `
//     &:before {
//       content: none;
//     }
//   `}
// `;

const RecurInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    gap: 50px;
  `}
`;

const Item = styled.div<{ bgColor: string }>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f3f3f3')};
  border-radius: 22px;
  padding: 26px;
  padding-bottom: 85px;
  width: 47.4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
        width: 48.4%;
        padding-bottom: 56px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
`;

const ColoredHead = styled.div<{ backgroundColor: string }>`
  position: absolute;
  width: 80%;
  height: 14px;
  top: -14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 24px 24px 0 0;
  background-color: ${(props) => props.backgroundColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 38px;
  text-align: center;
  position: relative;
  padding: 35px;
  padding-top: 30px;
  padding-bottom: 10px;
  border-radius: 14px;
  margin-bottom: 28px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      padding: 30px;
      padding-top: 26px;
      padding-bottom: 10px;
      border-radius: 14px;
      margin-bottom: 22px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      padding: 10px;
      padding-top: 16px;
      padding-bottom: 10px;
      border-radius: 14px;
      margin-bottom: 22px;
      gap: 28px;
  `}
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
`;

const Value = styled.div`
  font-size: 70px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      font-size: 50px;
  `}
`;

const BotLine = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.7);
`;

const InfoDescription = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 43px;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
       padding: 0 20px;
         gap: 33px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 0 10px;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`;

const List = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.45em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    min-height: 50%;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
     gap: 38px;
  `}
`;

const Description = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  text-align: center;
  line-height: 1.45em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Button = styled.button<{ backgroundColor: string; editable: boolean }>`
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 28px 40px;
  border-radius: 46px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  width: 100%;
  margin: 0 auto;
  ${(props) => props.editable && `cursor: text;`}

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => props.editable && `cursor: text !important;`};
    display: inline-block;
  }
`;

export const V2: FC<UpsellVIPFormProps> = (props: UpsellVIPFormProps) => {
  const {
    backgroundColor = COLOR_BLUE_6,
    // title = defaultWebValue,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    button,
    hidden,
    benefits
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const {
    salesPageValues,
    setIsUpsellModalOpen,
    price,
    upsellPrice,
    setUpsellDisplayer,
    setIsUpsellVIP
  } = useSales();

  // const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  // const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const buttonBackground = useMemo(() => {
    if (button?.color) return button.color;
    if (
      salesPageValues &&
      typeof salesPageValues['topbar_1'] === 'object' &&
      salesPageValues['topbar_1'].button?.color
    ) {
      return salesPageValues['topbar_1'].button?.color;
    }
    return backgroundColor;
  }, [backgroundColor, salesPageValues, button]);

  const [shadedBackgroundColor] = useMemo(() => {
    return [shade(buttonBackground, 0.7)];
  }, [buttonBackground]);

  const color = useMemo(() => {
    return tinycolor(buttonBackground).isDark() ? '#FFFFFF' : '#000000';
  }, [buttonBackground]);

  return (
    <Container
      id="upsell_vip_form"
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.1 : 1
      }}>
      <StyledWrapper>
        {/* <TitleContainer
          editable={editable}
          isTitleEmpty={isTitleEmpty}
          isContentEmpty={isContentEmpty}>
          <Title
            id={title?.id}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor),
              display: sanitizeRichText(title.val, editable, true) || editable ? 'block' : 'none'
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(title.val, editable, true)
                ? sanitizeRichText(title.val, editable, false, '<p>Choose the Best Plan</p>')
                : '<p></br><p>'
            }}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="Choose the Best Plan"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
        </TitleContainer> */}
        <RecurInner>
          {benefits?.map((benefit, index) => {
            const isTitleEmpty = checkTitleBenefits(index);
            const isContentEmpty = checkContentBenefits(index);
            const isFirst = index === 0;

            return (
              <Item bgColor={shade(benefit?.button?.color || secondaryColor, 0.7)} key={index}>
                <ColoredHead backgroundColor={benefit?.button?.color || buttonBackground} />
                <Header
                  onClick={() => {
                    setIsUpsellModalOpen(true);
                  }}>
                  <Price>
                    <Value>${isFirst ? price : Number(price) + Number(upsellPrice || 0)}/</Value>
                  </Price>
                  <BotLine />
                </Header>
                <InfoDescription>
                  <TextWrapper>
                    <List
                      id={benefit?.title?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.title?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor('#ffffff', textColor),
                        textAlign: 'center'
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleEmpty}
                    />
                  </TextWrapper>

                  <ButtonInner>
                    <Description
                      id={benefit?.content?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.content?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor(shadedBackgroundColor, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentEmpty}
                    />
                    <Button
                      id={benefit?.button?.id}
                      backgroundColor={
                        benefit.button?.color || (isFirst ? buttonBackground : secondaryColor)
                      }
                      editable={editable}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(
                          benefit?.button?.val || `<p>Monthly Plan - $27</p>`,
                          editable
                          // false,
                        )
                      }}
                      onClick={
                        editable
                          ? updateActiveElement
                          : () => {
                              setUpsellDisplayer('bump');
                              setIsUpsellVIP(index !== 0);
                              setTimeout(() => {
                                const formSection = document.getElementById('form');
                                if (!formSection) return;
                                const offset = -20;
                                const sectionTop =
                                  formSection.getBoundingClientRect().top +
                                  window.pageYOffset +
                                  offset;
                                window.scrollTo({
                                  top: sectionTop,
                                  behavior: 'smooth'
                                });
                              }, 200);
                            }
                      }
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="button"
                      data-pindex={index}
                      data-benefits={true}
                      style={{ color: color }}
                      {...(editable ? { as: 'p' } : {})}
                    />
                  </ButtonInner>
                </InfoDescription>
              </Item>
            );
          })}
        </RecurInner>
      </StyledWrapper>
    </Container>
  );
};
