import type { FC } from 'react';

import styled from 'styled-components';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';

import { FlexBox, NavButton, PageLoader } from 'shared/components/Common';

import useErrorLogger from 'shared/hooks/useErrorLogger';
import useAlert from 'shared/hooks/useAlert';

import { COLOR_ORANGE } from 'shared/helpers/colors';

interface Props {
  amount: string;
  onClose: (open: boolean) => void;
}

const Paragraph = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
`;

const PaymentError: FC<Props> = ({ amount, onClose }) => {
  const elements = useElements();
  const stripe = useStripe();
  const { logError } = useErrorLogger();
  const { addAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!stripe || !elements) return;

    try {
      setIsLoading(true);
      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/dashboard'
        },
        redirect: 'if_required'
      });
      onClose(false);
      addAlert('Payment information updated successfully', 'success', 6000);
    } catch (error) {
      logError(error);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      {isLoading ? <PageLoader text="Loading..." /> : null}
      <FlexBox flexDirection="column" gap={30}>
        <FlexBox flexDirection="column">
          <Paragraph>
            We had a payment glitch and please update your membership to keep your products
          </Paragraph>
          <p>
            <strong>Amount Due:</strong> ${amount}
          </p>
        </FlexBox>
        <PaymentElement />
        <NavButton
          disabled={isLoading}
          style={{ padding: '1rem 7.2rem', backgroundColor: COLOR_ORANGE }}
          content="Update Your Payment Information"
          type="submit"
        />
      </FlexBox>
    </form>
  );
};

export default PaymentError;
