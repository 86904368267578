import * as React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { STATES } from 'shared/helpers/static';
import styled from 'styled-components';

interface Props {
  value: string;
  onChange: (
    event:
      | (Event & {
          target: {
            value: string;
            name: string;
          };
        })
      | React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const Container = styled.div`
  display: flex;
  width: 100%;

  div[role='combobox'] {
    font-size: 24px;
    margin-top: 16px;
  }
`;

const SalesStatesDropdown = ({ value, onChange }: Props) => {
  return (
    <Container>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel id="sales-state" style={{ fontSize: '20px' }}>
            State
          </InputLabel>
          <Select
            labelId="State"
            id="sales-state"
            value={value}
            label="State"
            onChange={onChange}
            style={{
              height: '76px',
              background: 'white',
              fontSize: '14px'
            }}>
            {STATES.map((state) => {
              return (
                <MenuItem
                  value={state}
                  key={state}
                  style={{
                    fontSize: '14px'
                  }}>
                  {state}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Container>
  );
};

export default SalesStatesDropdown;
