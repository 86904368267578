import styled, { css } from 'styled-components';
import { useState, useEffect, useContext, useMemo } from 'react';
import { FormContext, type IFormContext } from '../../context/FormContext';

import Title from '../Title/Title';
import DefInput from '../Inputs/DefInput';
import { FlexBox, SearchWebsiteDropdown } from 'shared/components/Common';
import useSales from 'shared/hooks/useSales';
import { Checkbox } from 'shared/components/Sales/Common';
import { COLOR_BLACK_TEXT } from 'shared/helpers/colors';
import { getCustomWebsiteUrl } from 'shared/helpers/utils';

const MaxLengthError = styled.div`
  margin-top: 10px;
  background: #ff565d;
  color: #fff;
  padding: 5px 10px;

  span {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

const Paragraph = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: ${COLOR_BLACK_TEXT};
  position: absolute;
  left: 30px;
  top: 3px;
  cursor: pointer;
`;

const UrlForm = () => {
  const { setSuccess, setText, setIsRequared, isRequared, maxInputTextLength } = useContext(
    FormContext
  ) as IFormContext;

  const { salesPageCollection } = useSales();

  const [error, setError] = useState('');
  const [inputs, setInputs] = useState('');
  const [websiteSearch, setWebsiteSearch] = useState('');
  const [maxLengthError, setMaxLengthError] = useState('');
  const [lengthSum, setLengthSum] = useState(0);
  const [showMySalesPages, setShowMySalesPages] = useState(false);

  const salesPageOptions = useMemo(() => {
    return salesPageCollection.map((page) => ({
      name: page.sub_domain,
      value: page.sub_domain
    }));
  }, [salesPageCollection]);

  const validateRequared = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
        return false;
      }

      const domain = parsedUrl.hostname;

      const invalidChars = /[^\w.-]/;
      if (invalidChars.test(domain)) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInputs(e.target.value);

    // Max length of the text for the QR code
    if (lengthSum > maxInputTextLength) {
      return;
    }

    // check requare
    if (name === 'urlInput') {
      if (value.trim() === '') {
        setIsRequared(true);
        setError('Url is required field');
      } else if (!validateRequared(value)) {
        setIsRequared(true);
        setError('URL must start with http:// or https://, contain valid characters');
      } else {
        setIsRequared(false);
        setError('');
      }
    }

    setSuccess(false);
  };

  useEffect(() => {
    // Mask of the email for the QR code
    const onFormSum = inputs;

    // Max length of the text for the QR code and validation of main input
    setLengthSum(onFormSum.length);

    if (onFormSum.length < maxInputTextLength) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
      setMaxLengthError(`Max length of the text must be ${maxInputTextLength}`);
    }
  }, [inputs, setText, isRequared, setIsRequared, maxInputTextLength, lengthSum]);

  return (
    <>
      <Title title="Link Address" description="Enter the URL to which the QR code will link" />
      <DefInput
        lableName="Website URL"
        type="text"
        name="urlInput"
        value={inputs}
        onChange={handleInput}
        placeholder="https://example.com/"
        errorstyle={!!error}
        error={error}
        required
      />
      <FlexBox mt="20px" mb="20px" flexDirection="column">
        <FlexBox justifyContent="flex-start" mb="20px" position="relative">
          <Checkbox
            cssContainer={css`
              width: 50px;
            `}
            checked={showMySalesPages}
            onChange={() => setShowMySalesPages(!showMySalesPages)}
          />
          <Paragraph onClick={() => setShowMySalesPages(!showMySalesPages)}>
            Show My Sale Pages
          </Paragraph>
        </FlexBox>
        {showMySalesPages ? (
          <SearchWebsiteDropdown
            placeholder="Sales Pages"
            value={websiteSearch}
            onChange={(v) => {
              setWebsiteSearch(String(v));
              handleInput({
                target: { name: 'urlInput', value: getCustomWebsiteUrl(String(v)) }
              } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
            }}
            options={salesPageOptions}
            cssValueContainer={css`
              width: 350px;
            `}
            cssDropdownItem={css`
              width: 350px;
            `}
          />
        ) : null}
      </FlexBox>
      {lengthSum + 1 > maxInputTextLength ? (
        <MaxLengthError>
          {maxLengthError} / <span>{lengthSum}</span>
        </MaxLengthError>
      ) : null}
    </>
  );
};

export default UrlForm;
