import type { FormEvent, ReactNode } from 'react';

import styled from 'styled-components';
import { useState, useRef } from 'react';

import { isValidUrl, COLOR_ORANGE, COLOR_LIGHT_GRAY } from 'shared/helpers/index';
import { useAlert, useSales } from 'shared/hooks/index';

import { useTranslation } from 'react-i18next';

interface HoverableInputProps {
  children: ReactNode;
  index: number;
  css?: never;
  disabled?: boolean;
  className?: string;
  size?: 'lg' | 'md' | 'sm';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Record<string, any>;
}

const Inner = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: max-content;
  backdrop-filter: blur(34px);
`;

const Container = styled.div<{ css?: never; size: 'lg' | 'md' | 'sm' }>`
  ${(props) => props.css}

  z-index: 20 !important;

  & .hoverable-icons {
    border: unset !important;
    border-radius: unset !important;
  }

  & p {
    ${(props) =>
      props.size === 'lg' &&
      `
      font-size: x-larger;
      text-align: center;
    `}

    ${(props) =>
      props.size === 'md' &&
      `
      font-size: medium;
      text-align: center;
    `}

    ${(props) =>
      props.size === 'sm' &&
      `
      font-size: smaller;
      text-align: center;
    `}
  }
`;

const PopUpInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 28px 34px 38px;
  text-align: left;
  position: relative;
  z-index: 6;

  @media screen and (max-width: 991px) {
    padding: 0px;
  }
`;

const LinkTitle = styled.h3`
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
  font-weight: 700;
  color: #fff;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  position: relative;
  padding: 20px 40px;

  &:hover input {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0));
    transition: filter 0.3s ease;
  }
`;

const ImgYoutube = styled.img`
  position: absolute;
  left: 62px;
  z-index: 4;
  width: 50px;
  top: 46px;
`;

const LinkInput = styled.input`
  width: 100%;
  height: 88px;
  border-radius: 10px;
  border: 1px solid #9a9a9a;
  border-right: none;
  padding: 10px 15% 10px 82px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  transition: filter 0.3s ease;
  font-size: 26px;
  opacity: 1;
`;

const CheckBtn = styled.button`
  width: 15%;
  border-radius: 10px !important;
  border: 1px solid ${COLOR_LIGHT_GRAY};
  background: ${COLOR_ORANGE};
  position: absolute;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  height: 88px;
  font-size: 30px;
  font-family: 'Plus Jakarta Sans', sans-serif;

  img {
    width: 47px;
    height: 42px;
  }

  &:hover {
    background: #c7a727;
    transition: background 0.3s ease;
    cursor: pointer;
  }
`;

const Overlay = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.28);
  width: 100%;
  height: 100%;
`;

export const HoverableInput: React.FC<HoverableInputProps> = ({
  children,
  css,
  disabled = true,
  className,
  size = 'lg',
  meta = {},
  index
}) => {
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [link, setLink] = useState('');
  const { updateVideoUrl } = useSales();
  const { addAlert } = useAlert();

  const { t } = useTranslation();

  const handleLinkUpdate = (event: FormEvent) => {
    event.preventDefault();
    if (!isValidUrl(link) || !meta['accessorKey']) {
      addAlert('Please type a valid URL', 'error');
      return;
    }
    updateVideoUrl(meta['accessorKey'], link, index);
    addAlert('Video URL updated successfully', 'success');
  };

  return (
    <Container
      size={size}
      ref={imageRef}
      className={className}
      onMouseEnter={() => {
        if (!disabled) setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (!disabled) setIsHovered(false);
      }}
      css={css}>
      {children}

      {isHovered ? (
        <Inner>
          <PopUpInner>
            <LinkTitle>
              {t('navigations.youTubeLink', { defaultValue: 'Put Your Link Below:' })}
            </LinkTitle>
            <Form onSubmit={handleLinkUpdate}>
              <ImgYoutube src={'/img/youtubeVideo.svg'} alt="link" />
              <LinkInput
                placeholder="https://www.youtube.com/@ThePageWheel"
                required
                value={link}
                onChange={(e) => setLink(e.target.value)}
                onClick={(e) => e.currentTarget.focus()}
              />
              <CheckBtn type="submit">
                <img src={'/img/color_check.svg'} alt="✓" />
                {/* Add Link */}
              </CheckBtn>
            </Form>
          </PopUpInner>
          <Overlay />
        </Inner>
      ) : null}
    </Container>
  );
};
