import type { FC, FormEvent, ChangeEvent } from 'react';
import type { SalesValues } from 'shared/types/index';
import type { PaymentFormSubscription } from './V1';

import tinycolor from 'tinycolor2';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { COLOR_MEDIUM_GRAY } from 'shared/helpers/colors';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import { FlexBox } from 'shared/components/Common';
import { generateRandomEmail, isDummyDataLocalHost, sanitizeRichText } from 'shared/helpers/utils';
import { Wrapper } from '../Common';
import useUtils from 'shared/hooks/useUtils';
import useSalesForm from 'shared/hooks/useSalesForm';

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  padding: 0 0 94px;
  position: relative;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 62px 0 76px;
  `}
`;

const Form = styled.form`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
`;

const InputLarge = styled.input`
  border-radius: 8px;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  width: 100%;
  height: ${56 + 56 * 0.38}px;
  padding: 0 25px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
`;

const InputInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column;
  `}
`;

const Input = styled.input`
  height: ${56 + 56 * 0.38}px;
  padding: 0 25px;
  width: 49%;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  border-radius: 8px;
  width: 100%;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  `}
`;

const FormInner = styled.div`
  position: relative;
  padding: 46px 88px;
  z-index: 4;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 32px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 52px 35px 72px;
  `}
`;

const FormBg = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const Button = styled.button<{ backgroundColor: string; editable: boolean }>`
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  ${(props) => props.editable && `cursor: text;`}

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => props.editable && `cursor: text !important;`};
    display: inline-block;
  }
`;

const FormWrapper = styled.div`
  position: relative;
  width: 1064px;
  padding: 52px 115px 72px;
  margin: 0 auto;
  word-wrap: break-word;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 100%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 52px 75px 72px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 52px 35px 72px;
  `}
`;

const TitleItemForm = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  line-height: 1.35em;
  margin-bottom: 10px;
  position: relative;
  z-index: 4;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 12px;
  `}
  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #000;
      opacity: 0.9;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const DescriptionTitleForm = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: 20px;
  line-height: 1.35em;
  position: relative;
  z-index: 4;
  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #000;
      opacity: 0.9;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export type Props = {
  button: SalesValues;
  backgroundColor: string;
  callback: (values: PaymentFormSubscription) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
  shadedBackgroundColor: string;
  containerClassname: string;
  recurringModalInterval: 'month' | 'year';
  subDomain: string;
};

export const RecurringFirstForm: FC<Props> = (props) => {
  const {
    backgroundColor,
    callback,
    button,
    meta = {},
    shadedBackgroundColor,
    containerClassname,
    recurringModalInterval,
    subDomain
  } = props;

  const { t } = useTranslation();

  const { salesType, salesPageValues, amazonURL } = useSales();
  const { editable, updateActiveElement, resetActiveElement } = useWebsiteEditor();
  const { setIsPageLoading } = useUtils();
  const { handleFirstRecurring } = useSalesForm();

  const [form, setForm] = useState({
    email: isDummyDataLocalHost() ? generateRandomEmail() : '',
    first_name: isDummyDataLocalHost() ? 'John' : '',
    last_name: isDummyDataLocalHost() ? 'Doe' : ''
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPageLoading(true);
    try {
      const data = await handleFirstRecurring(
        {
          ...form,
          interval: recurringModalInterval
        },
        subDomain
      );
      callback(data);
    } catch (error) {
      console.error(error);
    }
    setIsPageLoading(false);
  };

  const handleInputUpdate = (input: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = input;
    setForm({
      ...form,
      [name]: value
    });
  };

  const buttonBackground = useMemo(() => {
    if (button.color) return button.color;
    if (
      salesPageValues &&
      typeof salesPageValues['topbar_1'] === 'object' &&
      salesPageValues['topbar_1'].button?.color
    ) {
      return salesPageValues['topbar_1'].button?.color;
    }
    return backgroundColor;
  }, [backgroundColor, salesPageValues, button]);

  const color = useMemo(() => {
    return tinycolor(buttonBackground).isDark() ? '#FFFFFF' : '#000000';
  }, [buttonBackground]);

  return (
    <Container
      backgroundColor={shadedBackgroundColor}
      className={containerClassname}
      id="recurring_form">
      <Wrapper>
        <FormWrapper>
          <FormInner>
            <Form onSubmit={handleSubmit}>
              <FlexBox width="100%" flexDirection="column">
                <TitleItemForm
                  dangerouslySetInnerHTML={{
                    __html: sanitizeRichText("It's almost yours!")
                  }}
                />
                <DescriptionTitleForm
                  dangerouslySetInnerHTML={{
                    __html: sanitizeRichText(
                      "We'll email you access soon, but we need your contact info first"
                    )
                  }}
                />
              </FlexBox>
              <InputInner>
                <FlexBox
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  gap={5}>
                  <Input
                    name="first_name"
                    placeholder={t('common.name', { defaultValue: 'Name' })}
                    required
                    value={form.first_name}
                    onChange={handleInputUpdate}
                  />
                </FlexBox>
                <FlexBox
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  gap={5}>
                  <Input
                    name="last_name"
                    placeholder={t('auth.common.lastName', { defaultValue: 'Last Name' })}
                    required
                    value={form.last_name}
                    onChange={handleInputUpdate}
                  />
                </FlexBox>
              </InputInner>
              <InputLarge
                name="email"
                placeholder={t('common.email', { defaultValue: 'Email Address' })}
                required
                value={form.email}
                onChange={handleInputUpdate}
                type="email"
              />
              <Button
                id={button.id}
                backgroundColor={buttonBackground}
                editable={editable}
                dangerouslySetInnerHTML={{
                  __html: sanitizeRichText(
                    button.val,
                    editable,
                    false,
                    salesType === 'sales' && !!amazonURL
                      ? `<p>${t('components.completePayment', { defaultValue: 'COMPLETE PAYMENT' })} >></p>`
                      : `<p>${t('components.signUp', { defaultValue: 'Sign Up to Get Started' })}</p>`
                  )
                }}
                onClick={updateActiveElement}
                onBlur={resetActiveElement}
                data-accessor-parent={meta['accessorKey']}
                data-accessorkey="button"
                style={{ color: color }}
                {...(editable ? { as: 'p' } : {})}
              />
            </Form>
            <FormBg />
          </FormInner>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};
