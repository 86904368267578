import type { CSSProperties, ReactNode, FC, ReactElement, JSXElementConstructor } from 'react';
import type { ImagesAspectRatio, WhoAmI } from 'shared/types/index';

import {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  isValidElement,
  cloneElement,
  lazy
} from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { keyframes } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import { COLOR_LIGHTER_GRAY } from 'shared/helpers/colors';
import { checkImage, convertFileSizeToMB } from 'shared/helpers/image';
import useAlert from 'shared/hooks/useAlert';

import { FlexBox, Image } from 'shared/components/Common';

import QrCodeGenerator from '../Modal/QrCodeGenerator/QrCodeGenerator';
import ImgAIGenerator from '../Modal/ImgAIGenerator/ImgAIGenerator';
import useAuth from 'shared/hooks/useAuth';
import { ImageCropper } from '../Modal';

const ImageEditor = lazy(() => import('../Modal/ImageEditor'));

export interface HoverableDropzoneProps {
  children: ReactNode;
  css: never;
  disabled?: boolean;
  user?: WhoAmI;
  allowedExtensions: Array<string>;
  onUpload: (file: File) => void;
  loading: boolean;
  borderRadius?: string;
  alreadyUploaded?: boolean;
  imageFile?: string;
  mediaVariable?: string;
  className?: string;
  size?: 'lg' | 'md' | 'sm';
  imageAspectRatio?: ImagesAspectRatio;
  // cssText?: FlattenSimpleInterpolation;
  resizeSelector?: boolean;
  containerId?: string;
  style?: CSSProperties;
  meta?: Record<string, string>;
  handleResize?: (value: { width: string | number; height: string | number }) => void;
  moveHandler?: (position: 'center' | 'left' | 'right') => void;
  extraControls?: boolean;
  deleteComponent?: () => void;
  moveComponent?: (direction: 1 | -1) => void;
  setMovebleContainer?: (element: HTMLDivElement | null) => void;
  isSalePage?: boolean;
  initialPosition?: { x: number; y: number; width?: number; height?: number };
  positionEnabled?: boolean;
  handlePosition: (width: number, height: number, x: number, y: number) => void;
  skipMargin?: boolean;
  forceUpdateIngredients?: () => void;
}

const Container = styled.div<{ css: never }>`
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
  ${(props) => props.css}

  z-index: 20 !important;
  position: relative;

  /* Shared styles for all handlers */
  .handler {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border: 2px solid #1cc6cb;
    width: 14px;
    height: 14px;
    gap: 0px;
    border: 2px 0px 0px 0px;
    z-index: 999999;
    cursor: nwse-resize;
    user-select: none;
  }

  /* Top-left corner */
  .handler.top-left {
    top: -5px;
    left: -5px;
    cursor: nwse-resize; /* Diagonal resize */
  }

  /* Top-right corner */
  .handler.top-right {
    top: -5px;
    right: -5px;
    cursor: nesw-resize; /* Diagonal resize */
  }

  /* Bottom-left corner */
  .handler.bottom-left {
    bottom: -5px;
    left: -5px;
    cursor: nesw-resize; /* Diagonal resize */
  }

  /* Bottom-right corner */
  .handler.bottom-right {
    bottom: -5px;
    right: -5px;
    cursor: nwse-resize; /* Diagonal resize */
  }

  /* Middle-left edge */
  .handler.middle-left {
    left: 50%;
    transform: translateY(-50%);
    cursor: ew-resize; /* Horizontal resize */
  }

  /* Middle-right edge */
  .handler.middle-right {
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    cursor: ew-resize; /* Horizontal resize */
  }
  /* Top-center */
  .handler.middle-top {
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize; /* Vertical resize */
  }

  /* Bottom-center */
  .handler.middle-bottom {
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: ns-resize; /* Vertical resize */
  }

  & .hoverable-icons {
    border: unset !important;
    border-radius: unset !important;
  }
`;

const InnerRelative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContainerOptions = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
`;

const OptionsContainer = styled.div<{
  imageWidth?: number;
  imageHeight?: number;
  isSalePage?: boolean;
}>`
  position: absolute;
  padding-left: 26px;
  transition: all 0.5s ease;

  transform: scale(${({ isSalePage }) => (isSalePage ? '1.51' : '1.25')});
  transform-origin: center;
  z-index: 14;
  box-shadow: none !important;

  ${({ isSalePage }) => `
    top: ${isSalePage ? '100px' : '50px'};
    right: ${isSalePage ? '60px' : '40px'};
  `}

  ${({ imageHeight, imageWidth, isSalePage }) =>
    (imageHeight && imageHeight <= (isSalePage ? 600 : 480)) ||
    (imageWidth && imageWidth <= (isSalePage ? 256 : 180))
      ? `
        right: ${isSalePage ? '-108px' : '-96px'} !important;
        top: 50% !important;
         transform: ${isSalePage ? 'translateY(-50%) scale(1.51)' : 'translateY(-50%) scale(1.25)'};
        padding-top: 40px;
        padding-bottom: 40px;
      `
      : ''}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
`;

const RelativeOptionsWrapper = styled.div<{
  imageWidth?: number;
  imageHeight?: number;
  isSalePage?: boolean;
}>`
  position: relative;
  padding: 9px;
  border-radius: 11px;
  background-color: #dfdfdf;
  animation: ${fadeIn} 0.3s ease-in-out;

  ${({ imageHeight, imageWidth, isSalePage }) =>
    (imageHeight && imageHeight <= (isSalePage ? 600 : 480)) ||
    (imageWidth && imageWidth <= (isSalePage ? 256 : 180))
      ? `
        &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: 13px;
        background-color: #dfdfdf;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      `
      : ''}
`;

const OptionsWrapper = styled(FlexBox)<{ isSalePage?: boolean }>`
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border: 1px solid #acacac;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: unset !important;
  position: relative;

  @media screen and (max-width: 1768px) {
    width: ${(props) => (props.isSalePage ? '46px' : '48px')};
    height: ${(props) => (props.isSalePage ? '46px' : '48px')};
  }

  @media screen and (max-width: 1168px) {
    width: ${(props) => (props.isSalePage ? '38px' : '50px')};
    height: ${(props) => (props.isSalePage ? '38px' : '50px')};
  }

  img {
    width: 100% !important;
    height: 100% !important;
    display: block;
    object-fit: contain;
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:hover {
    background: #121212;
    transition: background 0.2s ease;
    cursor: pointer;

    .hoverable-icons {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(103%) contrast(100%);
    }
  }

  // additional list styles
  &:has(.options-wrapper_list:hover) {
    background: #fff;

    .hoverable-icons {
      filter: none;
    }
  }

  .options-wrapper_list:hover .hoverable-icons_list {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(103%) contrast(100%);
  }

  p {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: center;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }
`;

const blurAnim = keyframes`
  from {
    opacity: 0;
  }
`;

const HoverBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -3;
  background: rgba(0, 0, 0, 0.2);
  animation: ${blurAnim} 0.3s ease-in-out;
`;

const fadeInOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const AspectRatioContainer = styled.div<{
  imageWidth: number;
  imageHeight: number;
  isSalePage: boolean;
}>`
  display: flex;
  position: absolute;
  z-index: 12;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  padding: 0 5%;
  animation: ${fadeInOut} 0.3s ease-in-out;

  ${({ imageHeight, imageWidth, isSalePage }) =>
    (imageHeight &&
      imageHeight <= (isSalePage ? 150 : 150) &&
      imageWidth &&
      imageWidth <= (isSalePage ? 330 : 200)) ||
    (imageWidth && imageWidth <= (isSalePage ? 256 : 200)) ||
    (imageHeight && imageHeight <= 70)
      ? `
        visibility: hidden;
      `
      : 'visibility: visible;'}
`;

const RecomendedRatio = styled.p`
  line-height: 3rem;
  text-align: center;
  line-height: 3rem;
  margin: 0 auto;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 12px;
  padding: 4px 12px;
  position: relative;
  letter-spacing: 0px;

  div {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  strong {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  img {
    width: 34px !important;
    height: auto !important;
    flex-shrink: 0 !important;
  }
`;

const RatioBackground = styled.div`
  border-radius: 18px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid #fff;
`;

const fadePrompt = keyframes`
  from {
    transform: translateX(7px);
  }
`;

const ToolPrompt = styled.div`
  position: absolute;
  right: 62px;
  font-size: 14px;
  width: max-content;
  color: #000;
  animation: ${fadePrompt} 0.2s ease-in-out;

  @media screen and (max-width: 1768px) {
    font-size: 15px;
  }

  div {
    position: relative;
    z-index: 4;
    background-color: #ecfeff;
    border: 1px solid #1cc6cb;
    border-radius: 4px;
    padding: 5px 7px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: -8px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #1cc6cb;
  }
`;

const fadePromptBottom = keyframes`
  from {
    transform: translateY(-7px);
  }
`;

const ToolPromptBottom = styled.div`
  position: absolute;
  margin: 0 auto;
  font-size: 14px;
  width: max-content;
  color: #000;
  animation: ${fadePromptBottom} 0.2s ease-in-out;
  bottom: -42px;

  @media screen and (max-width: 1768px) {
    font-size: 15px;
  }

  div {
    position: relative;
    z-index: 4;
    background-color: #ecfeff;
    border: 1px solid #1cc6cb;
    border-radius: 4px;
    padding: 5px 7px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: -6px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: rotate(-90deg);
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #1cc6cb;
  }
`;

const fadeRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
`;

const AdditionalOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 44px;
  width: fit-content;
  top: 0;
  animation: ${fadeRight} 0.3s ease-in-out;

  @media screen and (max-width: 1768px) {
    right: 39px;
  }

  .options-wrapper_list:first-child {
    border-radius: 8px 0 0 8px;
  }

  .options-wrapper_list:last-child {
    border-radius: 0px;
  }

  .options-wrapper_list {
    border-right: none;
    border-bottom: 1px solid #acacac;
  }
`;

const FlexBoxDropFile = styled(FlexBox)`
  align-items: center;
  font-size: 18px;
  gap: 10px;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100px !important;
    height: auto !important;
    display: block;
  }
`;

const FakeContainer = styled.div<{ css: never }>`
  ${(props) => props.css}
`;

export const HoverableDropzone: FC<HoverableDropzoneProps> = (props) => {
  const {
    children,
    css,
    disabled = true,
    allowedExtensions,
    onUpload,
    loading,
    borderRadius,
    user,
    alreadyUploaded = false,
    imageFile,
    mediaVariable,
    className,
    size = 'lg',
    imageAspectRatio,
    resizeSelector,
    handleResize,
    style,
    moveHandler,
    meta = {},
    extraControls,
    deleteComponent,
    moveComponent,
    setMovebleContainer,
    isSalePage = false,
    initialPosition = { x: 0, y: 0, width: 0, height: 0 },
    handlePosition = () => null,
    positionEnabled,
    skipMargin,
    forceUpdateIngredients
  } = props;

  const imageRef = useRef<HTMLDivElement | null>(null);
  const fakeContainer = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [trackDimensions, setTrackDimensions] = useState<{
    width: string | number;
    height: string | number;
  } | null>({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });
  const [isHovered, setIsHovered] = useState(false);
  const { addAlert } = useAlert();
  const { t } = useTranslation();
  const { userSubscription } = useAuth();
  const [isResizeModalOpen, setIsResizeModalOpen] = useState(false);
  const [isNewResizeModalOpen, setIsNewResizeModalOpen] = useState(false);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);
  const [moveControlls, setMoveControlls] = useState(false);
  const [isQrVariable, setIsQrVariable] = useState(false);
  const [isImageAiGenOpen, setImageAiGenOpen] = useState(false);
  const [spacingImage, setSpacingImage] = useState(false);
  const [showPrompt, setShowPrompt] = useState('');
  const [showMoveSectionSalePage, setShowMoveSectionSalePage] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });

  const startResizePosition = useRef<{
    left: number;
    top: number;
    width: number;
    height: number;
  } | null>(null);

  const handler = useRef<string | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[] | FileList) => {
      try {
        const fileItem = acceptedFiles[0];

        const { width, height } = await checkImage(fileItem);
        const sizeMb = convertFileSizeToMB(fileItem);

        if (allowedExtensions && !allowedExtensions.includes(fileItem.type)) {
          addAlert?.(
            t(
              'utils.alerts.uploadImageTypes',
              'Please upload image with .jpg, .jpeg, .png extensions'
            ),
            'error'
          );
          return;
        }
        if ((width >= 2200 && height >= 3200) || (width >= 2200 && height >= 3200)) {
          addAlert?.(
            t(
              'utils.alerts.uploadImageResolution',
              'Please upload image with maximum 2200x3200 resolution'
            ),
            'error'
          );
          return;
        }
        if (Number(sizeMb) > 50) {
          addAlert?.(
            t('utils.alerts.uploadImageSize', 'Please upload image with maximum 50MB size'),
            'error'
          );
          return;
        }

        onUpload(fileItem);
      } catch (_error) {
        addAlert?.(
          t(
            'utils.alerts.uploadImageFailed',
            'We failed to process the image, please try again or format it to .png or .jpeg'
          ),
          'error'
        );
      }
    },
    [addAlert, allowedExtensions, onUpload, t]
  );

  // Initialize the dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled
  });

  // Qr Code Generator
  const uploadQrCode = (file: File) => {
    onUpload(file);
  };

  // Ai Image Generator
  const uploadAiImage = (file: File) => {
    onUpload(file);
  };

  const displayControlls = useMemo(() => {
    return alreadyUploaded || !imageFile?.includes('/img/lead_bg.png');
  }, [alreadyUploaded, imageFile]);

  const handleQrModalOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsQrCodeModalOpen(true);
  };

  const handleQrModalClose = () => {
    setIsQrCodeModalOpen(false);
    setIsHovered(false);
  };

  useEffect(() => {
    if (mediaVariable?.includes('QR')) {
      setIsQrVariable(true);
    }
  }, [mediaVariable]);

  const resizing = useCallback(
    (ev: Event) => {
      if (
        !startResizePosition.current ||
        !handler.current ||
        !imageRef.current ||
        !fakeContainer.current
      )
        return;

      const e = ev as MouseEvent;
      const deltaX: number = e.clientX - startResizePosition.current.left;
      const deltaY: number = e.clientY - startResizePosition.current.top;

      let width = startResizePosition.current.width;
      let height = startResizePosition.current.height;

      switch (handler.current) {
        case 'top-left':
          width -= deltaX;
          height -= deltaY;
          break;
        case 'top-right':
          width += deltaX;
          height -= deltaY;
          break;
        case 'bottom-left':
          width -= deltaX;
          height += deltaY;
          break;
        case 'bottom-right':
          width += deltaX;
          height += deltaY;
          break;
        case 'middle-top':
          height -= deltaY;
          break;
        case 'middle-bottom':
          height += deltaY;
          break;
      }

      width = Math.max(30, width); // Set minimum width
      height = Math.max(30, height); // Set minimum height

      const images = document.querySelectorAll(`[data-image-width="${meta['width']}"]`); // Select all images
      images.forEach((image) => {
        const el = image as HTMLElement;
        if (el === imageRef.current) return;
        el.style.setProperty('width', width + 'px');
        el.style.setProperty('height', height + 'px');
      });
      imageRef.current.style.setProperty('width', width + 'px');
      imageRef.current.style.setProperty('height', height + 'px');
      fakeContainer.current.style.setProperty('width', width + 'px');
      fakeContainer.current.style.setProperty('height', height + 'px');

      setTrackDimensions({
        width,
        height
      });

      if (resizeSelector) {
        handleResize?.({
          width,
          height
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const endResize = useCallback(() => {
    startResizePosition.current = null;
    handler.current = null;

    // Remove event listeners immediately when resize stops
    window.removeEventListener('mousemove', resizing);
    window.removeEventListener('mouseup', endResize);
  }, [resizing]);

  const startResize = useCallback(
    (ev: MouseEvent) => {
      if (!imageRef.current) return;

      const target = ev.target as HTMLElement;

      // Ensure we only handle resizing for the current instance
      if (!target.classList.contains('handler') || ev.which !== 1) return;

      // Store handler type
      handler.current = target.classList[1];

      startResizePosition.current = {
        left: ev.clientX,
        top: ev.clientY,
        width: imageRef.current.clientWidth,
        height: imageRef.current.clientHeight
      };

      // Attach listeners only for the current instance
      window.addEventListener('mousemove', resizing);
      window.addEventListener('mouseup', endResize);
    },
    [resizing, endResize]
  );

  useEffect(() => {
    if (!imageRef.current) return;

    const handlers = imageRef.current.querySelectorAll('.handler');

    const handleMouseDown = (e: Event) => startResize(e as MouseEvent);

    // Attach mousedown listeners only to the current dropzone handlers
    handlers.forEach((element) => {
      element.addEventListener('mousedown', handleMouseDown);
    });

    return () => {
      // Remove mousedown listeners for this instance when it unmounts
      handlers.forEach((element) => {
        element.removeEventListener('mousedown', handleMouseDown);
      });
    };
  }, [startResize]);

  const handleImageAiGenOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setImageAiGenOpen(true);
  };

  const handleImageAiGenClose = () => {
    setImageAiGenOpen(false);
    setIsHovered(false);
  };

  const isAiImgAllowed = useMemo(() => {
    return userSubscription?.plan_name !== 'bizzy_free';
  }, [userSubscription]);

  const handleAdjustPosition = useCallback(
    (width: number, height: number, x: number, y: number) => {
      handlePosition(width, height, x, y);
    },
    [handlePosition]
  );

  const handleStartPositioning = () => {
    // Guard: ensure refs are ready
    if (!imgRef.current || !imageRef.current) return;

    const container = imageRef.current;
    const image = imgRef.current;

    if (initialPosition.width || initialPosition.height) return;
    const resizing = handler.current;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const imageWidth = image.naturalWidth || 1;
    const imageHeight = image.naturalHeight || 1;

    // Scale so the image fits entirely in its container
    const scale = Math.max(containerWidth / imageWidth, containerHeight / imageHeight);

    const scaledWidth = resizing ? imageWidth * scale : initialPosition.width || imageWidth * scale;
    const scaledHeight = resizing
      ? imageHeight * scale
      : initialPosition.height || imageHeight * scale;

    // Keep multiple images in sync if they share the same data attribute
    const images = document.querySelectorAll(
      `[data-image-position-width="${meta['imageWidthAttr']}"]`
    );

    images.forEach((el) => {
      if (el === imgRef.current) return;
      const syncedImage = el as HTMLImageElement;
      syncedImage.style.width = `${scaledWidth}px`;
      syncedImage.style.height = `${scaledHeight}px`;
    });

    // Center the image (or use provided initialPosition)
    const initialX = (containerWidth - scaledWidth) / 2;
    const initialY = (containerHeight - scaledHeight) / 2;
    setPosition({ width: scaledWidth, height: scaledHeight, x: initialX, y: initialY });

    // Also update the position of other synced images
    images.forEach((el) => {
      if (el === imgRef.current) return;
      const syncedImage = el as HTMLImageElement;
      syncedImage.style.left = `${initialX}px`;
      syncedImage.style.top = `${initialY}px`;
    });

    image.style.width = `${scaledWidth}px`;
    image.style.height = `${scaledHeight}px`;

    // If we need to notify the parent (i.e., after a resize)
    handleAdjustPosition(scaledWidth, scaledHeight, initialX, initialY);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    if (disabled || process.env['APP_TYPE'] === 'sales') return;
    setIsDragging(true);
    dragStartPos.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y
    };
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !imgRef.current || !imageRef.current) return;

    const container = imageRef.current;
    const image = imgRef.current;

    // Get container and image dimensions
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const imageWidth = image.clientWidth;
    const imageHeight = image.clientHeight;

    // Calculate new position
    let newX = e.clientX - dragStartPos.current.x;
    let newY = e.clientY - dragStartPos.current.y;

    // Prevent moving past the edges
    newX = Math.min(0, Math.max(newX, containerWidth - imageWidth));
    newY = Math.min(0, Math.max(newY, containerHeight - imageHeight));

    handleAdjustPosition(imageWidth, imageHeight, newX, newY);
    setPosition({ width: imageWidth, height: imageHeight, x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (loading && meta['width'] && meta['height']) {
      if (!imageRef.current || !fakeContainer.current) return;
      const images = document.querySelectorAll(`[data-image-width="${meta['width']}"]`); // Select all images
      images.forEach((image) => {
        const el = image as HTMLElement;
        if (el === imageRef.current) return;
        el.style.setProperty('width', meta['width'] + 'px');
        el.style.setProperty('height', meta['height'] + 'px');
      });
      imageRef.current.style.setProperty('width', meta['width'] + 'px');
      imageRef.current.style.setProperty('height', meta['height'] + 'px');
      fakeContainer.current.style.setProperty('width', meta['width'] + 'px');
      fakeContainer.current.style.setProperty('height', meta['height'] + 'px');

      setTrackDimensions({
        width: meta['width'],
        height: meta['height']
      });

      setPosition({
        x: 0,
        height: Number(meta['height']),
        width: Number(meta['width']),
        y: 0
      });
      setTimeout(() => {
        handleAdjustPosition(Number(meta['width']), Number(meta['height']), 0, 0);
      }, 800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, initialPosition, meta]);

  useEffect(() => {
    // Guard: avoid running in non-browser, disabled, or certain environment
    if (
      typeof window === 'undefined' ||
      typeof document === 'undefined' ||
      process.env['APP_TYPE'] === 'sales' ||
      disabled
    ) {
      return;
    }

    // Guard: ensure refs are ready
    if (!imgRef.current || !imageRef.current) return;

    const container = imageRef.current;
    const image = imgRef.current;

    const updateImageSize = () => {
      if (!initialPosition.x && !initialPosition.y) return;
      const resizing = handler.current;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const imageWidth = image.naturalWidth || 1;
      const imageHeight = image.naturalHeight || 1;

      // Scale so the image fits entirely in its container
      const scale = Math.max(containerWidth / imageWidth, containerHeight / imageHeight);

      const scaledWidth = resizing
        ? imageWidth * scale
        : initialPosition.width || imageWidth * scale;
      const scaledHeight = resizing
        ? imageHeight * scale
        : initialPosition.height || imageHeight * scale;

      // Keep multiple images in sync if they share the same data attribute
      const images = document.querySelectorAll(
        `[data-image-position-width="${meta['imageWidthAttr']}"]`
      );

      images.forEach((el) => {
        if (el === imgRef.current) return;
        const syncedImage = el as HTMLImageElement;
        syncedImage.style.width = `${scaledWidth}px`;
        syncedImage.style.height = `${scaledHeight}px`;
      });

      // Center the image (or use provided initialPosition)
      const initialX = resizing
        ? (containerWidth - scaledWidth) / 2
        : initialPosition.x || initialPosition.x;
      const initialY = resizing
        ? (containerHeight - scaledHeight) / 2
        : initialPosition.y || initialPosition.y;
      setPosition({ width: scaledWidth, height: scaledHeight, x: initialX, y: initialY });

      // Also update the position of other synced images
      images.forEach((el) => {
        if (el === imgRef.current) return;
        const syncedImage = el as HTMLImageElement;
        syncedImage.style.left = `${initialX}px`;
        syncedImage.style.top = `${initialY}px`;
      });

      image.style.width = `${scaledWidth}px`;
      image.style.height = `${scaledHeight}px`;

      // If we need to notify the parent (i.e., after a resize)
      handleAdjustPosition(scaledWidth, scaledHeight, initialX, initialY);
    };

    // Only run this after initial load
    const resizeObserver = new ResizeObserver(() => {
      const resizing = handler.current;
      if (resizing) {
        updateImageSize();
      }
    });

    resizeObserver.observe(container);

    // Cleanup on unmount
    return () => {
      image.onload = null;
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disabled,
    initialPosition.height,
    initialPosition.width,
    initialPosition.x,
    initialPosition.y
  ]);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof document === 'undefined' ||
      process.env['APP_TYPE'] === 'sales' ||
      disabled
    )
      return;
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const styles = useMemo<CSSProperties>(() => {
    return {
      ...(position.y && { top: `${position.y}px` }),
      ...(position.x && { left: `${position.x}px` }),
      position: 'absolute',
      ...((process.env['APP_TYPE'] === 'sales' || !disabled) && {
        cursor: !disabled ? 'move' : '',
        userSelect: 'none'
      }),
      ...(position.width && { width: `${position.width}px` }),
      ...(position.height && { height: `${position.height}px` })
    };
  }, [position.y, position.x, position.width, position.height, disabled]);

  useEffect(() => {
    if (!positionEnabled) return;
    const images = document.querySelectorAll(
      `[data-image-position-width="${meta['imageWidthAttr']}"]`
    );

    images.forEach((el) => {
      if (el === imgRef.current) return;
      const c = el as HTMLImageElement;
      c.style.top = `${position.y}px`;
      c.style.left = `${position.x}px`;
      c.style.width = `${position.width}px`;
      c.style.height = `${position.height}px`;
    });
  }, [meta, position, positionEnabled]);

  const containerStyles = useMemo<Partial<CSSProperties>>(() => {
    return { position: 'relative', overflow: 'hidden' };
  }, []);

  return (
    <Container
      {...getRootProps()}
      ref={(el: HTMLImageElement) => {
        setMovebleContainer?.(el);
        imageRef.current = el;
      }}
      style={{
        overflow: 'visible',
        ...(trackDimensions && trackDimensions.height && { height: trackDimensions.height }),
        ...(trackDimensions && trackDimensions.width && { width: trackDimensions.width }),
        ...style
      }}
      className={className}
      onMouseEnter={() => {
        if (spacingImage) return;
        if (!disabled) setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (spacingImage) return;
        if (!disabled) setIsHovered(false);
      }}
      css={css}>
      <FakeContainer
        ref={fakeContainer}
        className={className}
        css={css}
        style={{
          ...containerStyles,
          ...(trackDimensions && trackDimensions.height && { height: trackDimensions.height }),
          ...(trackDimensions && trackDimensions.width && { width: trackDimensions.width }),
          ...style,
          ...(skipMargin && { margin: 0 })
        }}>
        {isValidElement(children) &&
        !loading &&
        (children.type === 'img' ||
          children.type === Image ||
          (typeof children === 'object' &&
            (children.type as JSXElementConstructor<unknown> & { target: string }).target ===
              'img') ||
          (typeof children === 'object' &&
            (children.type as JSXElementConstructor<unknown> & { target: unknown }).target ===
              Image)) &&
        positionEnabled
          ? cloneElement(children as ReactElement, {
              style: {
                ...styles
              },
              ...(children.props.width && { width: children.props.width }),
              ...(children.props.height && { height: children.props.height }),
              ...(children.props.alt && { alt: children.props.alt }),
              ref: imgRef,

              onMouseDown: handleMouseDown
            })
          : children}
      </FakeContainer>

      {spacingImage && (
        <div
          style={{
            right: 0,
            width: '100%',
            backgroundColor: COLOR_LIGHTER_GRAY,
            opacity: 0.9,
            display: 'flex',
            justifyContent: 'end',
            borderRadius: ' 8px 8px 0 0',
            position: 'absolute',
            zIndex: 12312312312,
            top: 0
          }}>
          <CloseIcon
            onClick={() => {
              setSpacingImage(false);
              forceUpdateIngredients?.();
            }}
            style={{
              cursor: 'pointer',
              fontSize: '52px'
            }}
          />
        </div>
      )}

      {resizeSelector ? (
        <>
          <div className="handler top-left" />
          <div className="handler top-right" />
          <div className="handler bottom-left" />
          <div className="handler bottom-right" />
          <div className="handler middle-top" />
          <div className="handler middle-bottom" />
        </>
      ) : null}

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: COLOR_LIGHTER_GRAY,
            opacity: 0.9,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 5,
            borderRadius: borderRadius || '0%'
          }}>
          <FlexBox
            justifyContent="center"
            alignItems="center"
            width="100%"
            flexDirection="column"
            height="100%">
            <CircularProgress color="inherit" />
            <p style={{ fontSize: 16, marginTop: 15 }}>{t('loading.updating', 'Updating...')}</p>
          </FlexBox>
        </div>
      )}

      {!loading && isDragActive && (
        <div
          {...getRootProps()}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#DCEDBA',
            border: '2px dashed #9ACC3A',
            opacity: 0.9,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 5,
            borderRadius: borderRadius || '0%'
          }}>
          <FlexBox
            justifyContent="center"
            alignItems="center"
            width="100%"
            flexDirection="column"
            height="100%">
            <FlexBoxDropFile
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              height="100%">
              <img src="/img/uploadImageCloud.png" alt="upload" />
              <div>{t('utils.dropFile', 'Drop the files here ...')}</div>
            </FlexBoxDropFile>
          </FlexBox>
        </div>
      )}

      {!loading && isHovered && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5,
            borderRadius: borderRadius || '0%',
            border: '2px solid #1CC6CB'
          }}>
          <InnerRelative>
            <ContainerOptions>
              {displayControlls ? (
                <>
                  <OptionsContainer
                    imageWidth={imageRef.current?.clientWidth || 0}
                    imageHeight={imageRef.current?.clientHeight}
                    isSalePage={isSalePage}>
                    <RelativeOptionsWrapper
                      imageWidth={imageRef.current?.clientWidth}
                      imageHeight={imageRef.current?.clientHeight}
                      isSalePage={isSalePage}>
                      {/*TOOL #0 Move Top and Bottom Image Section for Sale Page */}
                      {extraControls ? (
                        <OptionsWrapper
                          isSalePage={isSalePage}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onMouseEnter={() => setShowMoveSectionSalePage(true)}
                          onMouseLeave={() => setShowMoveSectionSalePage(false)}
                          className="options-wrapper_inner">
                          <img
                            className="hoverable-icons"
                            src="/img/move-section_ic.svg"
                            alt="Move section"
                          />

                          {/* #0_1 additional settings whithin Move Top and Bottom Image Section for Sale Page */}
                          {showMoveSectionSalePage ? (
                            <AdditionalOptionsContainer>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('MoveUp')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveComponent?.(-1);
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/move-section-ic-top.svg"
                                  alt="left align"
                                />
                                {showPrompt === 'MoveUp' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.moveUp', 'Move up')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('MoveDown')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveComponent?.(1);
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/move-section-ic-bottom.svg"
                                  alt="center align"
                                />
                                {showPrompt === 'MoveDown' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.moveDown', 'Move down')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('DeleteSection')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteComponent?.();
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/remove-image.svg"
                                  alt="right align"
                                />
                                {showPrompt === 'DeleteSection' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.delete', 'Delete')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                            </AdditionalOptionsContainer>
                          ) : null}
                        </OptionsWrapper>
                      ) : null}

                      {/*TOOL #1 Replace Image for Ingredients/Sale Page */}
                      <OptionsWrapper
                        isSalePage={isSalePage}
                        onClick={(e) => {
                          e.stopPropagation();
                          inputRef.current?.click();
                        }}
                        onMouseMove={() => setShowPrompt('replace')}
                        onMouseLeave={() => setShowPrompt('')}>
                        <img
                          className="hoverable-icons"
                          src="/img/replace_image.svg"
                          alt="replace image"
                        />
                        {showPrompt === 'replace' && (
                          <ToolPrompt>
                            <div>{t('common.replaceImage', 'Replace Image')}</div>
                          </ToolPrompt>
                        )}
                      </OptionsWrapper>
                      {/*TOOL #2 Space image for Sale Page */}
                      {positionEnabled && (
                        <OptionsWrapper
                          isSalePage={isSalePage}
                          className="options-wrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSpacingImage(true);
                            handleStartPositioning();
                            setIsHovered(false);
                          }}
                          onMouseMove={() => setShowPrompt('adjustSpace')}
                          onMouseLeave={() => setShowPrompt('')}>
                          <img
                            className="hoverable-icons"
                            src="/img/space_image.svg"
                            alt="space image"
                          />
                          {showPrompt === 'adjustSpace' && (
                            <ToolPrompt>
                              <div>{t('common.adjustSpace', 'Adjust Space')}</div>
                            </ToolPrompt>
                          )}
                        </OptionsWrapper>
                      )}

                      {/*TOOL #3 Enhance image for Ingredients/Sale Page */}
                      <OptionsWrapper
                        isSalePage={isSalePage}
                        className="options-wrapper"
                        onMouseMove={() => setShowPrompt('enhance')}
                        onMouseLeave={() => setShowPrompt('')}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsNewResizeModalOpen(true);
                        }}>
                        <img
                          className="hoverable-icons"
                          src="/img/enhance-image.svg"
                          alt="enhance image"
                        />
                        {showPrompt === 'enhance' && (
                          <ToolPrompt>
                            <div>{t('common.enhanceImage', 'Enhance Image')}</div>
                          </ToolPrompt>
                        )}
                      </OptionsWrapper>

                      {/*TOOL #4 Alignment (droplist) for Ingredients */}
                      {resizeSelector && (
                        <OptionsWrapper
                          isSalePage={isSalePage}
                          onClick={(e) => {
                            e.stopPropagation();
                            // if (!imageRef.current) return;
                            // const images = document.querySelectorAll(
                            //   `[data-image-margin="${meta['margin']}"]`
                            // ); // Select all images
                            // images.forEach((image) => {
                            //   const el = image as HTMLElement;
                            //   if (el === imageRef.current) return;
                            //   el.style.removeProperty('margin-left');
                            //   el.style.removeProperty('margin-right');
                            //   el.style.setProperty('margin-right', 'auto');
                            //   el.style.setProperty('margin-left', 'auto');
                            // });
                            // imageRef.current.style.removeProperty('margin-left');
                            // imageRef.current.style.removeProperty('margin-right');
                            // imageRef.current.style.setProperty('margin-right', 'auto');
                            // imageRef.current.style.setProperty('margin-left', 'auto');
                            // moveHandler?.('center');
                          }}
                          onMouseEnter={() => setMoveControlls(true)}
                          onMouseLeave={() => setMoveControlls(false)}
                          className="options-wrapper_inner">
                          <img
                            className="hoverable-icons"
                            src="/img/center-align.svg"
                            alt="align image"
                          />

                          {/* #4_1 additional settings whithin Alignment Droplist */}
                          {resizeSelector && moveControlls && moveHandler ? (
                            <AdditionalOptionsContainer>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('alignLeft')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!imageRef.current) return;
                                  const images = document.querySelectorAll(
                                    `[data-image-margin="${meta['margin']}"]`
                                  ); // Select all images

                                  images.forEach((image) => {
                                    const el = image as HTMLElement;
                                    if (el === imageRef.current) return;
                                    el.style.removeProperty('margin-left');
                                    el.style.removeProperty('margin-right');
                                    el.style.setProperty('margin-right', 'auto');
                                  });
                                  imageRef.current.style.removeProperty('margin-left');
                                  imageRef.current.style.removeProperty('margin-right');
                                  imageRef.current.style.setProperty('margin-right', 'auto');
                                  moveHandler('left');
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/left-align.svg"
                                  alt="left align"
                                />
                                {showPrompt === 'alignLeft' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.leftAlign', 'Left Align')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('alignCenter')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!imageRef.current) return;
                                  const images = document.querySelectorAll(
                                    `[data-image-margin="${meta['margin']}"]`
                                  ); // Select all images
                                  images.forEach((image) => {
                                    const el = image as HTMLElement;
                                    if (el === imageRef.current) return;
                                    el.style.removeProperty('margin-left');
                                    el.style.removeProperty('margin-right');
                                    el.style.setProperty('margin-right', 'auto');
                                    el.style.setProperty('margin-left', 'auto');
                                  });
                                  imageRef.current.style.removeProperty('margin-left');
                                  imageRef.current.style.removeProperty('margin-right');
                                  imageRef.current.style.setProperty('margin-right', 'auto');
                                  imageRef.current.style.setProperty('margin-left', 'auto');
                                  moveHandler('center');
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/center-align.svg"
                                  alt="center align"
                                />
                                {showPrompt === 'alignCenter' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.centerAlign', 'Center Align')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                              <OptionsWrapper
                                isSalePage={isSalePage}
                                className="options-wrapper_list"
                                onMouseMove={() => setShowPrompt('alignRight')}
                                onMouseLeave={() => setShowPrompt('')}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!imageRef.current) return;
                                  const images = document.querySelectorAll(
                                    `[data-image-margin="${meta['margin']}"]`
                                  ); // Select all images
                                  images.forEach((image) => {
                                    const el = image as HTMLElement;
                                    if (el === imageRef.current) return;
                                    el.style.removeProperty('margin-left');
                                    el.style.removeProperty('margin-right');
                                    el.style.setProperty('margin-left', 'auto');
                                  });
                                  imageRef.current.style.removeProperty('margin-left');
                                  imageRef.current.style.removeProperty('margin-right');
                                  imageRef.current.style.setProperty('margin-left', 'auto');
                                  moveHandler('right');
                                }}>
                                <img
                                  className="hoverable-icons_list"
                                  src="/img/right-align.svg"
                                  alt="right align"
                                />
                                {showPrompt === 'alignRight' && (
                                  <ToolPromptBottom>
                                    <div>{t('common.rightAlign', 'Right Align')}</div>
                                  </ToolPromptBottom>
                                )}
                              </OptionsWrapper>
                            </AdditionalOptionsContainer>
                          ) : null}
                        </OptionsWrapper>
                      )}
                      {/*TOOL #5 QR Code Generator for Ingredients */}
                      {isQrVariable && (
                        <OptionsWrapper
                          isSalePage={isSalePage}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleQrModalOpen(e);
                          }}
                          onMouseMove={() => setShowPrompt('qrCode')}
                          onMouseLeave={() => setShowPrompt('')}
                          className="options-wrapper">
                          <img
                            className="hoverable-icons"
                            src="/img/QRCode-icon.svg"
                            alt="rotate image"
                          />
                          {showPrompt === 'qrCode' && (
                            <ToolPrompt>
                              <div>{t('common.qrCodeGenerator', 'QR Code Generator')}</div>
                            </ToolPrompt>
                          )}
                        </OptionsWrapper>
                      )}

                      {/*TOOL #6 AI Image Generator for Ingredients/Sale page */}
                      {isAiImgAllowed ? (
                        <OptionsWrapper
                          isSalePage={isSalePage}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageAiGenOpen(e);
                          }}
                          onMouseMove={() => setShowPrompt('aiImageGen')}
                          onMouseLeave={() => setShowPrompt('')}>
                          <img
                            className="hoverable-icons"
                            src="/img/ai-img-generator-icon.svg"
                            alt="ai image generator"
                          />
                          {showPrompt === 'aiImageGen' && (
                            <ToolPrompt>
                              <div>{t('common.aiImageGenerator', 'AI Image Generator')}</div>
                            </ToolPrompt>
                          )}
                        </OptionsWrapper>
                      ) : null}
                    </RelativeOptionsWrapper>
                  </OptionsContainer>
                  {imageAspectRatio ? (
                    <AspectRatioContainer
                      imageWidth={imageRef.current?.clientWidth || 0}
                      imageHeight={imageRef.current?.clientHeight || 0}
                      isSalePage={isSalePage}>
                      <RecomendedRatio style={{ fontSize: isSalePage ? 1.51 * 15 : 1.25 * 13.5 }}>
                        <img src="/img/ratio_info.svg" alt="i" />
                        <div>
                          {t('common.recommendedRatio', 'Recomended')}:{' '}
                          <strong>
                            {t('common.recommendedRatioRatio', 'Ratio')}: {imageAspectRatio.ratio} |{' '}
                            {t('common.suggestedSize', 'Suggested Size')}: {imageAspectRatio.pixels}{' '}
                            {t('common.pixels', 'pixels')}
                          </strong>
                        </div>

                        <RatioBackground />
                      </RecomendedRatio>
                    </AspectRatioContainer>
                  ) : null}
                </>
              ) : (
                <>
                  <img
                    className="hoverable-icons"
                    src="/img/dropzone-icon.png"
                    alt="upload icon"
                    style={{ cursor: 'pointer' }}
                  />
                  <h4>
                    {t('utils.dragImage')} <a>{t('utils.chooseImage')}</a>
                  </h4>
                  {imageAspectRatio && (
                    <FlexBox
                      mt={size === 'sm' ? '5px' : '10px'}
                      style={{ flexDirection: 'column' }}>
                      <RecomendedRatio>
                        {t('common.recommendedRatio', 'Recomended Ratio')}: {imageAspectRatio.ratio}{' '}
                        / {t('common.suggestedSize', 'Suggested Size')}: {imageAspectRatio.pixels}{' '}
                        {t('common.pixels', 'pixels')}
                      </RecomendedRatio>
                    </FlexBox>
                  )}
                </>
              )}
            </ContainerOptions>
            <HoverBlur />
          </InnerRelative>
        </div>
      )}

      <input {...getInputProps()} ref={inputRef} />

      {typeof window !== 'undefined' && (
        <ImageEditor
          open={isNewResizeModalOpen}
          file={imageFile}
          mediaVariable={mediaVariable}
          onClose={() => setIsNewResizeModalOpen(false)}
          cropText={t('common.crop', 'Crop')}
          closeText={t('common.close', 'Close')}
          handleOnCrop={(value) => {
            onUpload(value);
            setIsNewResizeModalOpen(false);
          }}
        />
      )}

      <ImageCropper
        open={isResizeModalOpen}
        file={imageFile}
        mediaVariable={mediaVariable}
        onClose={() => setIsResizeModalOpen(false)}
        handleOnCrop={(value) => {
          onUpload(value.file);
          setIsResizeModalOpen(false);
        }}
        cropText={t('common.crop', 'Crop')}
        closeText={t('common.close', 'Close')}
      />

      {user && (
        <QrCodeGenerator
          open={isQrCodeModalOpen}
          data={user}
          onClose={handleQrModalClose}
          onUpload={uploadQrCode}
          loading={loading}
        />
      )}

      <ImgAIGenerator
        open={isImageAiGenOpen}
        onClose={handleImageAiGenClose}
        onUpload={uploadAiImage}
        loading={loading}
      />
    </Container>
  );
};
