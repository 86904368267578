import type { RenderSalesComponentProps, SalesComponentTypes } from 'shared/types/Render';

import { memo, useMemo } from 'react';

import { extractComponentKey } from 'shared/helpers/website';

import { CheckSection, type CheckSectionProps } from './CheckSection'; // come back to this
import { Footer, type FooterProps } from './Footer';
import { Download, type DownloadProps } from './Common/Download';
import { OrderProcessing } from './OrderProcessing';

// Multiple versions
import AboutMe, { type AboutMeProps } from './AboutMe';
import CallToAction, { type CallToActionProps } from './CallToAction';
import CheckSectionWithPages, { type CheckSectionWithPagesProps } from './CheckSectionWithPages';
import FAQ, { type FAQProps } from './FAQ';
import HeroBasic, { type HeroBasicProps } from './HeroBasic';
import HeroDetailed, { type HeroDetailedProps } from './HeroDetailed';
import HowToStart, { type HowToStartProps } from './HowToStart';
import HeadlineOnly, { type HeadlineOnlyProps } from './HeadlineOnly';
import Paragraph, { type ParagraphProps } from './Paragraph';
import ThisIsForYou, { type ThisIsForYouProps } from './ThisIsForYou';
import HeadingWithButton, { type HeadingWithButtonProps } from './HeadingWithButton';
import Guarantee, { type GuaranteeProps } from './Guarantee';
import Receipt, { type ReceiptProps } from './Receipt';
import Heading, { type HeadingProps } from './Heading';
import Form, { type FormProps } from './Form';
import RecurringForm from './RecurringForm';
import UpsellVIPForm from './UpsellVIPForm';
import HeroCoach, { type HeroCoachProps } from './HeroCoach';
import MiniHero, { type MiniHeroProps } from './MiniHero';
import Video, { type VideoProps } from './Video';
import Topbar, { type TopBarTypes } from './Topbar';
import Photo, { type PhotoProps } from './Photo';
import Event, { type EventProps } from './Events';
import CustomElement from './CustomElement';

const Heroes = {
  V1: HeroBasic.V1,
  V2: HeroBasic.V2,
  V3: HeroBasic.V3,
  V4: HeroBasic.V4,
  V5: HeroCoach.V1,
  V6: HeroCoach.V2,
  V7: HeroBasic.V2, // temporarely disable HeroDetailed as per request of rachel
  V8: HeroBasic.V3, // temporarely disable HeroDetailed as per request of rachel
  V9: HeroCoach.V1, // temporarely disable HeroDetailed component until it's fixed
  V10: HeroBasic.V5,
  V11: HeroBasic.V6,
  V12: HeroDetailed.V1, // BAD!!! DO NOT CHANGE THIS VERSION! HERO DETAILED V1 IS MAPPED TO HERO V12, Consult Plat for more info
  V13: HeroDetailed.V2, // BAD!!! DO NOT CHANGE THIS VERSION! HERO DETAILED V2 IS MAPPED TO HERO V13, Consult Plat for more info
  V14: HeroDetailed.V3, // BAD!!! DO NOT CHANGE THIS VERSION! HERO DETAILED V3 IS MAPPED TO HERO V14, Consult Plat for more info
  V15: HeroDetailed.V4 // BAD!!! DO NOT CHANGE THIS VERSION! HERO DETAILED V4 IS MAPPED TO HERO V15, Consult Plat for more info
};

const Components: SalesComponentTypes = {
  topbar: (version) => Topbar[version],
  hero_detailed: (version) => HeroDetailed[version],
  hero_coach: (version) => HeroCoach[version],
  hero: (version) => Heroes[version],
  heading: (version) => Heading[version],
  check_section: (version) => CheckSectionWithPages[version],
  for_you: (version) => ThisIsForYou[version],
  about_me: (version) => AboutMe[version],
  call_to_action: (version) => CallToAction[version],
  faq: (version) => FAQ[version],
  guarantee: (version) => Guarantee[version],
  heading_with_button: (version) => HeadingWithButton[version],
  heading_with_button_community: (version) => HeadingWithButton[version],
  heading_with_button_event: (version) => HeadingWithButton[version],
  heading_with_button_calendar: (version) => HeadingWithButton[version],
  headline_only: (version) => HeadlineOnly[version],
  hero_basic: (version) => HeroBasic[version],
  how_to_start: (version) => HowToStart[version],
  paragraph: (version) => Paragraph[version],
  mini_hero: (version) => MiniHero[version],
  video: (version) => Video[version],
  photo: (version) => Photo[version],
  form: (version) => Form[version],
  recurring_form: (version) => RecurringForm[version],
  upsell_vip_form: (version) => UpsellVIPForm[version],
  receipt: () => Receipt.V1,
  event: (version) => Event[version],
  custom_element: () => CustomElement,
  custom_element_tool: () => CustomElement
};

const SalesRenderer = memo<RenderSalesComponentProps>(
  ({
    accessor,
    props,
    cover_thumbnail,
    mockup,
    pageImages,
    textColor,
    backgroundColor,
    secondaryColor,
    navigate,
    stripe,
    type,
    sub_domain,
    deliveryToken,
    book_name,
    book_price,
    currency,
    subscriptionData,
    colorPalette,
    font
  }) => {
    SalesRenderer.displayName = `SalesComponent_ ${accessor}`;

    const componentKey = useMemo(() => {
      return extractComponentKey(accessor as keyof SalesComponentTypes);
    }, [accessor]);

    if (componentKey in Components) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const Comp = Components[componentKey as keyof SalesComponentTypes](props.version);
      if (!Comp) return null;

      return (
        <Comp
          cover_thumbnail={cover_thumbnail}
          mockup={mockup}
          textColor={textColor}
          backgroundColor={backgroundColor}
          secondaryColor={secondaryColor}
          pageImages={pageImages}
          type={type}
          sub_domain={sub_domain}
          deliveryToken={deliveryToken}
          navigate={navigate}
          stripe={stripe}
          book_name={book_name}
          book_price={book_price}
          subscriptionData={subscriptionData}
          currency={currency}
          {...props}
          meta={{
            ...props.meta,
            accessorKey: accessor
          }}
          key={accessor}
          colorPalette={colorPalette}
          font={font}
        />
      );
    }
    return null;
  }
);

export type {
  TopBarTypes,
  CheckSectionProps,
  FooterProps,
  DownloadProps,
  CallToActionProps,
  AboutMeProps,
  SalesComponentTypes,
  CheckSectionWithPagesProps,
  HeroBasicProps,
  HeroDetailedProps,
  HowToStartProps,
  HeadlineOnlyProps,
  ParagraphProps,
  ThisIsForYouProps,
  HeadingWithButtonProps,
  GuaranteeProps,
  FAQProps,
  ReceiptProps,
  HeadingProps,
  FormProps,
  HeroCoachProps,
  MiniHeroProps,
  VideoProps,
  PhotoProps,
  EventProps
};

export {
  Topbar,
  CheckSection,
  Footer,
  Download,
  OrderProcessing,
  // Multiple Versions
  AboutMe,
  CallToAction,
  CheckSectionWithPages,
  HeroBasic,
  HeroDetailed,
  HowToStart,
  HeadlineOnly,
  Paragraph,
  ThisIsForYou,
  HeadingWithButton,
  Guarantee,
  Heading,
  Receipt
};

export default SalesRenderer;
