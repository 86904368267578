import type { FC } from 'react';
import type { FAQInputTypes, ItemType } from './types';

import styled from 'styled-components';
import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

const Title = styled.div<FAQInputTypes>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border: 1px solid;
  transition: background 0.3s ease;
  border-bottom: ${(props) => (props.last ? '1px solid' : 'none')};
  border-radius: ${(props) =>
    props.first
      ? '10px 10px 0 0'
      : props.last && props.isOpen
        ? '0px'
        : props.last
          ? '0px 0px 10px 10px'
          : '0px'};
  border-color: #e5e7eb;
  background-color: ${(props) => (props.isOpen ? '#F3F4F6' : '#fff')};

  &:hover {
    cursor: pointer;
    background-color: #f3f4f6 !important;
    transition: background 0.2s ease;
  }
`;

const Question = styled.h4<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  font-weight: 500;
  width: 90%;
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Image = styled.img<Partial<FAQInputTypes>>`
  width: 16px;
  height: 7px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

const Answer = styled.p<Partial<FAQInputTypes>>`
  border-left: 1px solid;
  border-right: 1px solid;
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  padding: 22px 32px;
  line-height: 1.4em;
  border-radius: ${(props) => (props.last ? '0px 0px 10px 10px' : '0px')};
  border-bottom: ${(props) => (props.last ? '1px solid' : 'none')};
  border-color: #e5e7eb;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const FirstItem: FC<ItemType> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    first,
    last,
    isOpen,
    onClick,
    textColor,
    meta,
    isContentEmpty,
    isTitleEmpty
  } = props;

  const { updateActiveElement, resetActiveElement, editable, websiteAssembler } =
    useWebsiteEditor();

  return (
    <div
      style={{ display: isContentEmpty && isTitleEmpty && !websiteAssembler ? 'none' : 'block' }}>
      <Title first={first} last={last} onClick={onClick} isOpen={isOpen}>
        <Question
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-benefits={true}
          data-placeholder="+"
          isEmpty={isTitleEmpty}
          editable={editable}
        />
        <Image isOpen={isOpen} src="/img/arrow-faq.svg" />
      </Title>
      {isOpen ? (
        <Answer
          id={content.id}
          content={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-benefits={true}
          data-placeholder="+"
          editable={editable}
          isEmpty={isContentEmpty}
        />
      ) : null}
    </div>
  );
};
