import type { FC } from 'react';
import type { FAQInputTypes, ItemType } from './types';

import styled from 'styled-components';
import {
  COLOR_BLACK_6,
  sanitizeRichText,
  defaultWebValue,
  filterBackgroundColor
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

const Container = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #e5e7eb;

  &:not(:last-child) {
    border-bottom: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 2px;
  transition: background 0.3s ease;

  &:hover {
    cursor: pointer;
    transition: background 0.2s ease;
  }
`;

const Question = styled.h4<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  font-weight: 500;
  width: 90%;
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Answer = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  padding: 0px 0 36px 0;
  line-height: 1.4em;
  width: 85%;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Plus = styled.div<Partial<FAQInputTypes>>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${(props) => (props.isOpen ? COLOR_BLACK_6 : '#fff')};
  border: 1px solid ${COLOR_BLACK_6};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemOne = styled.div<Partial<FAQInputTypes>>`
  position: absolute;
  width: 8px;
  height: 1px;
  background: #fff;
  background: ${(props) => (props.isOpen ? '#fff' : COLOR_BLACK_6)};
`;

const ItemTwo = styled.div<Partial<FAQInputTypes>>`
  position: absolute;
  width: 8px;
  height: 1px;
  background: #fff;
  transition: transform 0.4s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  background: ${(props) => (props.isOpen ? '#fff' : COLOR_BLACK_6)};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const SecondItem: FC<ItemType> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    isOpen,
    onClick,
    textColor,
    meta,
    isTitleEmpty,
    isContentEmpty
  } = props;

  const { updateActiveElement, resetActiveElement, editable, websiteAssembler } =
    useWebsiteEditor();

  return (
    <Container
      style={{ display: isContentEmpty && isTitleEmpty && !websiteAssembler ? 'none' : 'block' }}>
      <TitleWrapper onClick={onClick}>
        <Question
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-benefits={true}
          data-placeholder="+"
          isEmpty={isTitleEmpty}
          editable={editable}
        />
        <Plus isOpen={isOpen}>
          <ItemOne isOpen={isOpen} />
          <ItemTwo isOpen={isOpen} />
        </Plus>
      </TitleWrapper>
      {isOpen ? (
        <Answer
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-benefits={true}
          data-placeholder="+"
          isEmpty={isContentEmpty}
          editable={editable}
        />
      ) : null}
    </Container>
  );
};
