import type { PhotoProps } from './types';

import { V1 } from './V1';
import { V2 } from './V2';
import { V3 } from './V3';
import { V4 } from './V4';
import { V5 } from './V5';
import { V6 } from './V6';
import { V7 } from './V7';
import { V8 } from './V8';
import { V9 } from './V9';
import { V10 } from './V10';
import { V11 } from './V11';
import { V12 } from './V12';

export type { PhotoProps };
export default { V1, V2, V3, V4, V5, V6, V7, V8, V9, V10, V11, V12 };
