import type { FC } from 'react';
import type { HowToStartProps } from './types';

import { useMemo } from 'react';
import styled from 'styled-components';

import {
  COLOR_GRAY_13,
  COLOR_BLUE_6,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  COLOR_WHITE,
  COLOR_YELLOW_LIGHT,
  shade,
  COLOR_LIGHT_BLACK
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import { renderSVG } from './helpers';
import tinycolor from 'tinycolor2';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 62px 0 80px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 52px 0 60px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 52px 0;
    `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    `}
`;

const TitleWrapper = styled.div`
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 34px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 90%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin-bottom: 19px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 13px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 10px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  color: ${COLOR_GRAY_13};
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.35em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Benefits = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    `}
`;

const Item = styled.div`
  width: 31.5%;
  background: ${COLOR_YELLOW_LIGHT};
  padding: 28px 30px 42px;
  border-radius: 10px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 22px 20px 26px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 12px 18px;
    width: 100%;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
    `}
`;

const ItemInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: row;
    align-items: center;
  `}
`;

const IconWrapper = styled.div`
  width: 90px;
  height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 17px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 70px;
    height: 70px;
    margin-bottom: 12px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin: 0;
    margin-right: 14px;
    flex-shrink: 0;
  `}
`;

const Icon = styled.svg`
  width: 39px;
  height: 39px;
  object-fit: contain;
  position: relative;
  z-index: 3;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 32px;
    height: 32px;
    `}
`;

const IconBg = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000;
  opacity: 0.24;
  z-index: 1;
`;

const TextWrapper = styled.div`
  text-align: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    text-align: left;
    width: 100%;
  `}
`;

const TitleBenefit = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  font-weight: 500;
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  margin-bottom: 10px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 5px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const DescriptionBenefit = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  line-height: 1.35em;
  color: ${COLOR_GRAY_13};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V2: FC<HowToStartProps> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    benefits = [],
    textColor,
    backgroundColor = COLOR_BLUE_6,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor)
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor)
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </TitleWrapper>
        <Benefits>
          {benefits.map(({ title, content }, index) => {
            const isTitleEmpty = checkTitleBenefits(index);
            const isContentEmpty = checkContentBenefits(index);
            return (
              <Item
                style={{
                  background: backgroundColor,
                  display: isTitleEmpty && isContentEmpty && !websiteAssembler ? 'none' : 'block'
                }}
                key={index}>
                <ItemInner>
                  <IconWrapper>
                    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
                      {renderSVG(
                        index,
                        tinycolor(backgroundColor).isDark() ? COLOR_WHITE : COLOR_LIGHT_BLACK
                      )}
                    </Icon>
                    <IconBg style={{ background: COLOR_WHITE }} />
                  </IconWrapper>
                  <TextWrapper>
                    <TitleBenefit
                      id={title?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                      style={{
                        color: filterBackgroundColor(backgroundColor, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleEmpty}
                    />
                    <DescriptionBenefit
                      id={content?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
                      style={{
                        color: filterBackgroundColor(backgroundColor, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentEmpty}
                    />
                  </TextWrapper>
                </ItemInner>
              </Item>
            );
          })}
        </Benefits>
      </StyledWrapper>
    </Container>
  );
};
