import type { ThisIsForYouProps } from './types';
import type { FC } from 'react';

import styled from 'styled-components';

import {
  COLOR_BLUE_3,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';
import { Wrapper } from '../Common';
import { Item } from './Item';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const ThisIs = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Benefits = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    `}
`;

export const V2: FC<ThisIsForYouProps> = (props) => {
  const {
    backgroundColor = COLOR_BLUE_3,
    title = defaultWebValue,
    benefits = [],
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  return (
    <ThisIs
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <Title
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
        <Benefits>
          {benefits.map(({ content }, index) => {
            const isContentEmpty = checkContentBenefits(index);
            return (
              <Item
                digit={index + 1}
                content={content}
                key={index}
                textColor={textColor}
                backgroundColor={backgroundColor}
                variant="v2"
                meta={meta}
                editable={editable}
                isEmpty={isContentEmpty}
              />
            );
          })}
        </Benefits>
      </Wrapper>
    </ThisIs>
  );
};
