import { useState, useEffect, useContext } from 'react';
import { FormContext, type IFormContext } from '../../context/FormContext';

import Title from '../Title/Title';
import MobInput from '../Inputs/MobInput';

const PhoneNumberForm = () => {
  const { setSuccess, setText, setIsRequared, isRequared } = useContext(
    FormContext
  ) as IFormContext;
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState<string>('');

  const validateRequared = (url: string): boolean => {
    const re = /^\+?[0-9]\d{1,14}$/;
    return re.test(url);
  };

  useEffect(() => {
    if (inputs === '') {
      setIsRequared(true);
      setError('');
    } else if (!validateRequared(inputs)) {
      setIsRequared(true);
      setError('Invalid phone number format');
    } else {
      setIsRequared(false);
      setError('');
    }

    setSuccess(false);
    // Mask of the email for the QR code
    const onFormSum = `tel:${inputs}`;

    if (onFormSum.length < 21) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <>
      <Title
        title="Enter Your Phone Number"
        description="Please provide your phone number in international format"
      />
      <MobInput
        type="text"
        name="phoneInput"
        value={inputs}
        onChange={(value: string) => setInputs(value)}
        placeholder="+123456789"
        errorstyle={!!error}
        required
        error={error}
      />
    </>
  );
};

export default PhoneNumberForm;
