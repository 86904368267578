import { useCallback } from 'react';

import { isLocalhost } from 'shared/helpers/utils';
import { ERROR_MESSAGES_MAPPER } from 'shared/helpers/static';

import useAlert from 'shared/hooks/useAlert';

/**
 * @function useErrorLogger
 *
 * @description This method is used to log errors in the application.
 * It uses the loglevel library to log errors in the production and console for
 * localhost. It also uses the useAlert hook to show the error message to the user.
 */
const useErrorLogger = () => {
  const { addAlert } = useAlert();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stripeErrorHandler = (error: any) => {
    let errorMessage = '';
    if (error.code === 'card_declined') {
      if (error.decline_code === 'generic_decline') {
        errorMessage =
          'Your card was declined. Please try again or contact your bank for more information.';
      }
      if (error.decline_code === 'insufficient_funds') {
        errorMessage =
          'Your card has insufficient funds. Please try again or contact your bank for more information.';
      }
      if (error.decline_code === 'lost_card') {
        errorMessage =
          'Your card was declined because it has been reported lost. Please try again or contact your bank for more information.';
      }
      if (error.decline_code === 'stolen_card') {
        errorMessage =
          'Your card was declined because it has been reported stolen. Please try again or contact your bank for more information.';
      }
      if (error.decline_code === 'card_velocity_exceeded') {
        errorMessage =
          'Your card was declined because velocity limit has been exceeded. Please try again or contact your bank for more information.';
      }
    }

    if (error.code === 'incomplete_number') {
      errorMessage = 'Your card number is incomplete.';
    }
    if (error.code === 'processing_error') {
      errorMessage = 'An error occurred while processing your card. Please try again.';
    }
    if (error.code === 'expired_card') {
      errorMessage = 'Your card number is expired.';
    }
    if (error.code === 'incorrect_cvc') {
      errorMessage = 'Your card’s security code (CVC) is incorrect.';
    }
    if (!errorMessage && error.message) {
      errorMessage = error.message;
    }

    if (!errorMessage) {
      errorMessage = 'Something went wrong. Please try again';
    }

    if (isLocalhost()) {
      console.error(errorMessage);
    }
    addAlert(errorMessage, 'error', 5000);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logError = useCallback((error: any, skipAlert = false) => {
    let errorMessage;
    if (error.message === 'Network Error') {
      errorMessage = error;
      addAlert('Connectivity error. Please try again', 'error');
    } else if (error?.response?.data?.message) {
      errorMessage = error?.response?.data?.message;
      if (!skipAlert) addAlert(errorMessage, 'error');
    } else if (error.response?.data?.field_errors?.[0]?.message) {
      errorMessage = error.response?.data?.field_errors;
      const firstError = error.response?.data?.field_errors?.[0];
      let alertMessage = error.response?.data?.field_errors?.[0]?.message;
      if (
        (ERROR_MESSAGES_MAPPER as Record<string, Record<string, string>>)?.[firstError?.field]?.[
          firstError?.code
        ]
      ) {
        alertMessage = (ERROR_MESSAGES_MAPPER as Record<string, Record<string, string>>)?.[
          firstError?.field
        ]?.[firstError?.code];
      }
      if (!skipAlert) addAlert(alertMessage, 'error');
    } else if (error.response?.data?.errors?.[0]?.message) {
      errorMessage = error.response?.data?.errors;
      if (!skipAlert) addAlert(error.response?.data?.errors?.[0]?.message, 'error');
    } else {
      errorMessage = error;
      addAlert('Something went wrong. Please try again', 'error');
    }
    if (isLocalhost()) {
      console.error(errorMessage);
    } else {
      if (errorMessage instanceof Error) {
        errorMessage = errorMessage.toString();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { logError, stripeErrorHandler };
};

export default useErrorLogger;
