import { useMemo, type FC } from 'react';
import type { GuaranteeProps } from './types';

import styled from 'styled-components';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const WrapperStyled = styled(Wrapper)`
  position: relative;
  z-index: 4;
`;

const IconInner = styled.div`
  width: 186px;
  height: 186px;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 4px 16.6px rgba(0, 0, 0, 0.07));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
`;
type SvgIconProps = {
  backgroundColor: string;
};

const SvgIcon = styled.svg<SvgIconProps>`
  width: 107px;
  height: 130px;

  .guarantyThree-cls-1 {
    opacity: 0.13;
  }

  .guarantyThree-cls-1,
  .guarantyThree-cls-2,
  .guarantyThree-cls-3,
  .guarantyThree-cls-4 {
    stroke-width: 0px;
  }

  .guarantyThree-cls-1,
  .guarantyThree-cls-3,
  .guarantyThree-cls-4 {
    fill: #fff;
  }

  .guarantyThree-cls-2 {
    fill: ${({ backgroundColor }) => backgroundColor};
  }

  .guarantyThree-cls-4 {
    opacity: 0.59;
  }
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  text-align: center;
  line-height: 1.35em;
  margin-bottom: 22px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 26px;
    `}
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subtitle = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  text-align: center;
  position: relative;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V3: FC<GuaranteeProps> = (props) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <WrapperStyled>
        <IconInner>
          <SvgIcon
            backgroundColor={shadedBackgroundColor}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 107 128">
            <path
              className="guarantyThree-cls-2"
              d="m104.36,18.3c-17.8-.9-33.38-6.16-48.54-17.48-1-.74-2.6-1.35-4.54-.11-11.78,8.29-25.63,15.31-39.61,16.84-4.56.41-10.77-1.25-11.61,3.6-1.14,40.68,12.98,92.53,53.45,106.85,38.92-14.6,53.03-60.42,53.37-100.52-.45-2.97,1.59-8.48-2.52-9.18Z"
            />
            <path
              className="guarantyThree-cls-4"
              d="m53.48,122.48C16.69,106.02,5.32,61.7,5.49,23.69c16.08-.72,31.98-6.74,45.49-16.22.82-.54,1.63-1.07,2.44-1.6,11.16,8.11,23,13.51,35.2,16.06,4.31.91,8.53,1.16,12.82,1.63.44,38.79-11.5,82.38-47.96,98.92Z"
            />
            <path
              className="guarantyThree-cls-3"
              d="m76,52.93c-.24,1.13-.95,1.93-1.73,2.72-9.03,9.13-18.05,18.28-27.06,27.43-.71.72-1.52,1.12-2.52.81-.41-.13-.82-.37-1.12-.67-4.49-4.52-8.97-9.06-13.44-13.61-1.53-1.55-1.5-3.75.02-5.31.5-.52,1.01-1.03,1.52-1.55,1.54-1.54,3.71-1.55,5.24,0,2.68,2.7,5.35,5.41,8.01,8.12.12.12.18.28.35.55.22-.3.31-.46.44-.59,7.4-7.5,14.79-15,22.19-22.5,1.75-1.77,3.83-1.78,5.57-.02.25.25.49.51.75.75.83.76,1.53,1.6,1.77,2.75v1.12h0Z"
            />
            <path
              className="guarantyThree-cls-1"
              d="m101.44,23.56c-4.29-.47-8.51-.72-12.82-1.63-12.17-2.54-23.98-7.93-35.12-16v116.54c36.44-16.55,48.38-60.13,47.94-98.91Z"
            />
            <path
              className="guarantyThree-cls-4"
              d="m24,33l2.03,5.47,5.47,2.03-5.47,2.03-2.03,5.47-2.03-5.47-5.47-2.03,5.47-2.03,2.03-5.47Z"
            />
            <path
              className="guarantyThree-cls-4"
              d="m33.35,26l1.49,4.01,4.01,1.49-4.01,1.49-1.49,4.01-1.49-4.01-4.01-1.49,4.01-1.49,1.49-4.01Z"
            />
          </SvgIcon>
        </IconInner>
        <Title
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
        <Subtitle
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-placeholder="+"
          editable={editable}
          isEmpty={isContentEmpty}
        />
      </WrapperStyled>
    </Container>
  );
};
