import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const LANGUAGE_KEY = 'userLanguage';
// const isBrowser = typeof window !== 'undefined';

// eslint-disable-next-line import/no-named-as-default-member
void i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      // load in i18n if url is set, if not default to loading from public directory
      loadPath: process.env['I18N_URL']
        ? `${process.env['I18N_URL']}/translations/{{lng}}`
        : '/translations/{{lng}}.json'
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: LANGUAGE_KEY,
      lookupQuerystring: 'lang'
    },
    cache: ['localStorage'],
    interpolation: {
      escapeValue: false
    },
    fallbackLng: 'en'
  });

export default i18next;
