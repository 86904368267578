import { useMemo, useState, type FC } from 'react';
import type { HeroBasicProps } from './types';

import styled, { css } from 'styled-components';

import {
  ApiService,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper, Button, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  padding: 55px 0 60px;
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  max-width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column-reverse;
    gap: 10px;
    `}
`;

const TitleWrapper = styled.div`
  width: 655px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 492px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 20px;
  `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  margin-bottom: 26px;
  line-height: 1.25em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 28px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 58px;
  line-height: 1.35em;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 50px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 45px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 40px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  width: auto;
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 395px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  right: -5px;
  width: 530px;
  height: auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 480px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 360px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      width: 80%;
    `}

  ${({ mockup_id, theme: { $width } }) =>
    mockup_id === 1
      ? `
        right: -5px;
        width: 530px;
        height: auto;

        ${
          $width <= 1254 &&
          `
            width: 480px;
          `
        }

        ${
          $width <= 991 &&
          `
            width: 360px;
            left: -20px;
          `
        }

        ${
          $width <= 575 &&
          `
            width: 80%;
            left: -20px;
          `
        }
      `
      : mockup_id === 2
        ? `
        width: 500px;
        height: auto;

        ${
          $width <= 1254 &&
          `
            width: 448px;
            height: auto;
          `
        }

        ${
          $width <= 991 &&
          `
            width: 368px;
            height: auto;
          `
        }

        ${
          $width <= 575 &&
          `
            width: 80%;
            left: -10px;
          `
        }
      `
        : mockup_id === 3
          ? `
        right: -5px;
        width: 440px;
        height: auto;

        ${
          $width <= 1254 &&
          `
            width: 402px;
            height: auto;
          `
        }

        ${
          $width <= 991 &&
          `
            width: 318px;
            height: auto;
          `
        }

        ${
          $width <= 575 &&
          `
            width: 70%;
            height: auto;
          `
        }
      `
          : ``}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V3: FC<HeroBasicProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { pages, isCoverDisabled, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, purchaseElementId, updateImageMeta } = useSales();

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, cover_thumbnail, pagesThumbnails]);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <Hero
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
        {bookMockup ? (
          <HoverableDropzone
            isSalePage
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={isLoading}
            alreadyUploaded={Boolean(bookMockup)}
            imageFile={bookMockup}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            style={{
              ...(resize.height && { height: resize.height }),
              ...(resize.width && { width: resize.width })
            }}
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '3x4',
              pixels: '600x800'
            }}>
            <BookMediaImg
              isImageUploaded={isImageUploaded}
              src={bookMockup}
              width={resize.width || 500}
              height={resize.height || 500}
              alt="Book Mockup"
            />
          </HoverableDropzone>
        ) : (
          <StyledMockup
            mockup={mockup?.file}
            mockup_id={mockup?.id}
            cover_thumbnail={coverThumbnail}
          />
        )}
      </StyledWrapper>
    </Hero>
  );
};
