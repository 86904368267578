import type { FC } from 'react';
import type { BookBaseColor, SalesValues } from 'shared/types/index';

import { useMemo } from 'react';
import styled from 'styled-components';

import { COLOR_RED_3 } from 'shared/helpers/colors';
import { sanitizeRichText } from 'shared/helpers/utils';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import { filterBackgroundColor } from 'shared/helpers/website';

const Container = styled.div<{ editable: boolean }>`
  all: unset;
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  display: block;

  ${(props) =>
    props.editable
      ? 'cursor: text;'
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
    transition:
      transform 0.2s ease 0s,
      box-shadow 0.2s ease 0s;
  }
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    &:hover {
      transform: translateY(0);
      box-shadow: none;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}
`;

const StyledButton = styled.a`
  all: unset;
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  text-align: center;

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  }
`;

export interface DownloadProps {
  button: SalesValues;
  textColor: BookBaseColor;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Record<string, any>;
}

export const Download: FC<DownloadProps> = (props) => {
  const { button, meta, textColor } = props;

  const { downloadUrl, communityUrl, type, eventDetails } = useSales();
  const { updateActiveElement, resetActiveElement, editable, showLink } = useWebsiteEditor();

  const href = useMemo(() => {
    if (meta['download']) return downloadUrl;
    if (meta['community']) return communityUrl;
    if (meta['url']) return meta['url'];
    if (meta['event']) return eventDetails?.calendarUrl;
    if (type === 'delivery') return downloadUrl;

    return '#';
  }, [communityUrl, downloadUrl, eventDetails?.calendarUrl, meta, type]);

  return (
    <Container style={{ backgroundColor: button?.color || COLOR_RED_3 }} editable={editable}>
      <StyledButton
        id={button.id}
        dangerouslySetInnerHTML={{
          __html: sanitizeRichText(button?.val)
        }}
        style={{ color: filterBackgroundColor(button?.color || COLOR_RED_3, textColor) }}
        data-accessor-parent={meta['accessorKey']}
        data-accessorkey="button"
        onClick={updateActiveElement}
        onBlur={resetActiveElement}
        {...(editable || showLink ? { as: 'p' } : { href, target: '_blank' })}
        {...(meta['community'] ? { 'data-community': true } : {})}
        {...(meta['download'] ? { 'data-anchor-disabled': true } : {})}
      />
    </Container>
  );
};
