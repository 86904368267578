import type { FC, PropsWithChildren } from 'react';
import type { ChildAccountSetting, PlanName, UserDetails, UserPrivateMedia } from '../types';

import { createContext, useMemo, useState } from 'react';
import { COLOR_COPYSHOP, COLOR_LIGHT_ORANGE, COLOR_ORANGE_1, shutDownIntercom } from '../helpers';

interface Login {
  access: string;
  refresh: string;
}

interface ChildLogin {
  access: string;
  refresh: string;
  user: string;
  entity_id: string;
}

interface UserSubscription {
  plan_name: PlanName;
  status: string;
  period_end: string;
  cancel_at: string;
  stripe_customer: string;
  email: string;
  tags: string[];
  language: string;
  interval?: 'month' | 'year';
  promo_code: string;
  pending_payment?: boolean;
  stripe_data?: Record<string, number | string>;
}

interface EnterpriseBranding {
  color1: string;
  color2: string;
  color1Bright: string;
  logo: string;
}

interface PaymentMethod {
  id: string;
  brand: string;
  last4: string;
  exp_year: number;
  exp_month: number;
  funding: string;
  country: string;
  is_default: boolean;
}

type AuthContextData = {
  entityId?: number;
  setEntityId: (value: number) => void;
  user?: UserDetails;
  setUser: (value: UserDetails) => void;
  setSalesUser: (fullName?: string, buiness_name?: string) => void;
  userPrivateMedia?: UserPrivateMedia[];
  setUserPrivateMedia: (value: UserPrivateMedia[]) => void;
  userAvatar?: UserPrivateMedia;
  userLogo?: UserPrivateMedia;
  userSubscription?: UserSubscription;
  setUserSubscription: (value: UserSubscription) => void;
  paymentMethods?: PaymentMethod[];
  setPaymentMethods: (value: PaymentMethod[]) => void;
  loginChildUser: (data: ChildLogin) => void;
  logoutChildUser: () => void;
  childUsers: ChildAccountSetting[];
  setChildUsers: (v: ChildAccountSetting[]) => void;
  activeChild?: ChildAccountSetting;
  setActiveChild: (v?: ChildAccountSetting) => void;
  firstBookId: number;
  setFirstBookId: (value: number) => void;
  isEditingEnabled: boolean;
  login: (data: Login) => void;
  logout: () => void;
  logoutImp: () => void;
  enterpriseBranding?: EnterpriseBranding;
  setEnterpriseBranding: (value: EnterpriseBranding) => void;
  whiteLabelBranding: EnterpriseBranding;
  hasProductAccess: boolean;
  hasWebsiteAccess: boolean;
  hasCopyPackAccess: boolean;
  isChildAccount: boolean;
  isChildImpersonating: boolean;
  isStaff: boolean;
  updateIsStaff: (value: boolean) => void;
  userLanguage: string;
  updateUserLanguage: (lng: string) => void;
};

const AuthContext = createContext<AuthContextData>({
  entityId: undefined,
  setEntityId: () => null,
  user: undefined,
  setUser: () => null,
  setSalesUser: () => null,
  userPrivateMedia: [],
  setUserPrivateMedia: () => null,
  userAvatar: undefined,
  userLogo: undefined,
  userSubscription: undefined,
  setUserSubscription: () => null,
  paymentMethods: [],
  setPaymentMethods: () => null,
  loginChildUser: () => null,
  logoutChildUser: () => null,
  childUsers: [],
  setChildUsers: () => null,
  activeChild: undefined,
  setActiveChild: () => null,
  firstBookId: 0,
  setFirstBookId: () => null,
  isEditingEnabled: true,
  login: () => null,
  logout: () => null,
  logoutImp: () => null,
  enterpriseBranding: undefined,
  setEnterpriseBranding: () => null,
  whiteLabelBranding: {
    color1: COLOR_LIGHT_ORANGE,
    color2: COLOR_COPYSHOP,
    color1Bright: COLOR_ORANGE_1,
    logo: ''
  },
  hasProductAccess: true,
  hasWebsiteAccess: true,
  hasCopyPackAccess: true,
  isChildAccount: false,
  isChildImpersonating: false,
  isStaff: false,
  updateIsStaff: () => null,
  userLanguage: 'en',
  updateUserLanguage: () => null
});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [entityId, _setEntityId] = useState<number>();
  const [user, _setUser] = useState<UserDetails>();
  const [userPrivateMedia, setUserPrivateMedia] = useState<UserPrivateMedia[]>();
  const [userSubscription, setUserSubscription] = useState<UserSubscription>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [childUsers, setChildUsers] = useState<ChildAccountSetting[]>([]);
  const [activeChild, setActiveChild] = useState<ChildAccountSetting>();
  const [firstBookId, setFirstBookId] = useState(0);
  const [isStaff, setIsStaff] = useState(false);
  const [enterpriseBranding, _setEnterpriseBranding] = useState<EnterpriseBranding>();
  const [userLanguage, setUserLanguage] = useState('en');

  const updateIsStaff = (value: boolean) => {
    setIsStaff(value);
  };

  const updateUserLanguage = (lang: string) => {
    setUserLanguage(lang);
  };

  const setUser = (value: UserDetails) => {
    _setUser(value);
    _setEntityId(value.entity?.id);
    if (value.validation_disable_key) {
      localStorage.setItem('validation_disable_key', value.validation_disable_key);
    }
    localStorage.setItem('impEntityId', String(value.entity?.id));
    localStorage.setItem('entityId', String(value.entity?.id));
  };

  const setEntityId = (value: number) => {
    localStorage.setItem('entityId', String(value));
    _setEntityId(value);
  };

  const setEnterpriseBranding = (value: EnterpriseBranding) => {
    _setEnterpriseBranding({
      ...value,
      color1Bright: value.color1 || COLOR_ORANGE_1
    });
  };

  const whiteLabelBranding = useMemo(() => {
    if (enterpriseBranding !== undefined && user?.enterprise_settings !== undefined) {
      return enterpriseBranding;
    }
    if (enterpriseBranding && activeChild) return enterpriseBranding;
    return {
      color1: COLOR_LIGHT_ORANGE,
      color2: COLOR_COPYSHOP,
      color1Bright: COLOR_ORANGE_1,
      logo: ''
    };
  }, [activeChild, enterpriseBranding, user?.enterprise_settings]);

  const setSalesUser = (fullName?: string, businessName?: string) => {
    setUser({
      ...user,
      full_name: fullName,
      business_name: businessName
    });
  };

  const userAvatar = useMemo(() => {
    return userPrivateMedia?.find((media) => media.description === 'user_avatar');
  }, [userPrivateMedia]);

  const userLogo = useMemo(() => {
    return userPrivateMedia?.find((media) => media.description === 'enterprise_logo');
  }, [userPrivateMedia]);

  const login = (data: Login) => {
    localStorage.setItem('token', data.access);
    localStorage.setItem('refresh', data.refresh);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('pk');
    localStorage.removeItem('entityId');
    shutDownIntercom();
  };

  const logoutImp = () => {
    localStorage.removeItem('imp-token');
    localStorage.removeItem('imp-refresh');
    localStorage.removeItem('pk');
    localStorage.removeItem('entityId');
    localStorage.removeItem('impEntityId');
  };

  const loginChildUser = (data: ChildLogin) => {
    localStorage.setItem('child-imp-active', '1');
    localStorage.setItem('child-imp-token', data.access);
    localStorage.setItem('child-imp-refresh', data.refresh);
    localStorage.setItem('child-impEntityId', String(data.entity_id));
    localStorage.setItem('child-email', data.user);
  };

  const logoutChildUser = () => {
    localStorage.removeItem('child-imp-active');
    localStorage.removeItem('child-imp-token');
    localStorage.removeItem('child-imp-refresh');
    localStorage.removeItem('child-email');
  };

  const isEditingEnabled = useMemo(() => {
    if (user?.enterprise_settings) {
      return user.enterprise_settings.edit_access;
    }
    return true;
  }, [user?.enterprise_settings]);

  const hasProductAccess = useMemo(() => {
    if (user?.enterprise_settings) {
      return user.enterprise_settings.product_access;
    }
    return true;
  }, [user?.enterprise_settings]);

  const hasWebsiteAccess = useMemo(() => {
    if (user?.enterprise_settings) {
      return user.enterprise_settings.website_access;
    }
    return true;
  }, [user?.enterprise_settings]);

  const hasCopyPackAccess = useMemo(() => {
    if (user?.enterprise_settings) {
      return user.enterprise_settings.copypacks_access;
    }
    return true;
  }, [user?.enterprise_settings]);

  const isChildAccount = useMemo(() => {
    if (
      userSubscription?.plan_name === 'pw_enterprise_child' &&
      !localStorage.getItem('child-imp-active') &&
      !!user?.enterprise_settings
    )
      return true;
    return false;
  }, [user?.enterprise_settings, userSubscription?.plan_name]);

  const isChildImpersonating = useMemo(() => {
    if (
      userSubscription?.plan_name === 'pw_enterprise_child' &&
      localStorage.getItem('child-imp-active') &&
      !!user?.enterprise_settings
    )
      return true;
    return false;
  }, [user?.enterprise_settings, userSubscription?.plan_name]);

  return (
    <AuthContext.Provider
      value={{
        entityId,
        setEntityId,
        user,
        setUser,
        setSalesUser,
        userPrivateMedia,
        setUserPrivateMedia,
        userAvatar,
        userLogo,
        userSubscription,
        setUserSubscription,
        paymentMethods,
        setPaymentMethods,
        loginChildUser,
        logoutChildUser,
        childUsers,
        setChildUsers,
        activeChild,
        setActiveChild,
        firstBookId,
        setFirstBookId,
        isEditingEnabled,
        login,
        logout,
        logoutImp,
        enterpriseBranding,
        setEnterpriseBranding,
        whiteLabelBranding,
        hasProductAccess,
        hasWebsiteAccess,
        hasCopyPackAccess,
        isChildAccount,
        isChildImpersonating,
        isStaff,
        updateIsStaff,
        userLanguage,
        updateUserLanguage
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export type { AuthContextData };

export { AuthContext, AuthProvider };
