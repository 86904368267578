import type { AppContext, AppProps } from 'next/app';
import type { SalesResponse } from 'shared/types';

import App from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';

import i18n from '../i18n';

import { axiosClientForSales, updateWebsiteKeysToColor } from 'shared/helpers';
import { BookProvider, SalesProvider, UtilsProvider } from 'shared/context';

import './globals.css';
import { useEffect } from 'react';
import { UtilsWrapper } from 'shared/pages';

export interface CustomApp extends AppProps {
  data: SalesResponse;
}

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps, data }: CustomApp) {
  useEffect(() => {
    if (data?.salesData?.extra_head?.['language']) {
      const language = data.salesData.extra_head['language'] || 'en';
      i18n
        .changeLanguage(language)
        .catch((err) => console.error('Failed to change language:', err));
    }
  }, [data]);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <UtilsProvider>
          <BookProvider>
            <SalesProvider>
              <Component {...pageProps} data={data} />
              <UtilsWrapper />
            </SalesProvider>
          </BookProvider>
        </UtilsProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);
  const { slug, deliveryToken, isThankYou } = context.ctx.query;

  let searchParams = '';
  if (isThankYou) {
    searchParams = new URLSearchParams({
      thankYou: 'true',
      deliveryToken: String(deliveryToken)
    }).toString();
  }

  // Give 404 page not found
  if (!slug) return { notFound: true, data: {} };
  try {
    const { data: homeResponse } = await axiosClientForSales.get(
      `/sales/page/${slug}/home/?${searchParams}`
    );
    if (homeResponse.status === 404) {
      return { notFound: true, data: {} };
    }

    const { data: wordingResponse } = await axiosClientForSales.get(`sales/page/${slug}/wording/`);
    const home = homeResponse ?? {};
    const wording = wordingResponse ?? {};

    let stripe = {};
    if (home?.type === 'sales' || home?.is_subscription) {
      const { data: stripeResponse } = await axiosClientForSales.get(
        `/sales/page/${slug}/stripe-config/`
      );
      stripe = stripeResponse;
    }
    const { data: fontsResponse } = await axiosClientForSales.get(`/bizzy/fonts/${home?.font}/`);
    const fonts = fontsResponse ?? {};

    const colorPalette = home.color_palette;
    const updatedWebsiteData = updateWebsiteKeysToColor(home.page_data, colorPalette);
    const updatedDeliveryData = updateWebsiteKeysToColor(home.delivery_page_data, colorPalette);

    const salesData = {
      ...home,
      page_data: updatedWebsiteData,
      delivery_page_data: updatedDeliveryData,
      wording
    };

    return { ...ctx, data: { salesData, wording, fonts, stripe } };
  } catch (error) {
    console.error(error);
    return { notFound: true, data: {} };
  }
};
