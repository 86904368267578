import { useContext } from 'react';
import { WebsiteEditorContext } from '../context/WebsiteEditorContext';

const useWebsiteEditor = () => {
  const context = useContext(WebsiteEditorContext);

  return context;
};

export default useWebsiteEditor;
