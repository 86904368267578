import styled from 'styled-components';

const WrapperInput = styled.div`
  position: relative;
  margin-top: 30px;

  span {
    color: #ff5d63;
    font-size: 14px;
  }

  label {
    position: absolute;
    background: linear-gradient(180deg, #fff 70%, rgba(255, 255, 255, 0) 50%);
    top: -10px;
    left: 10px;
    padding: 0 24px 0 10px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 4;

    span {
      color: #ff5d63;
      padding-left: 5px;
    }
  }
`;

interface InputProps {
  errorstyle?: boolean;
}

const Input = styled.input<InputProps>`
  display: flex;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  padding: 0 20px;
  background: #fcfcfc;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 17px;

  border: ${(props) => (props.errorstyle ? '2px solid #FF5D63' : '1px solid #c8c8c8')};
`;

interface DefInputProps {
  type: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: string;
  errorstyle?: boolean;
  required?: boolean;
  lableName: string;
}

const DefInput = (props: DefInputProps) => {
  const { type, name, value, onChange, placeholder, errorstyle, error, required, lableName } =
    props;

  return (
    <WrapperInput>
      <label>
        {lableName} {required && <span> *</span>}
      </label>
      <Input
        type={type || 'text'}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        errorstyle={errorstyle}
      />
      {error && <span>{error}</span>}
    </WrapperInput>
  );
};

export default DefInput;
