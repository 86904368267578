import { useUtils } from '../hooks';
import { FlexBox, PageLoader } from '../components';

const UtilsWrapper = () => {
  const { isPageLoading } = useUtils();

  return <FlexBox>{isPageLoading ? <PageLoader text="Applying Magic..." /> : null}</FlexBox>;
};

export default UtilsWrapper;
