import type { FC } from 'react';
import type { CallToActionProps } from './types';

import styled from 'styled-components';
import {
  filterBackgroundColor,
  sanitizeRichText,
  COLOR_BLUE_3,
  defaultWebValue
} from 'shared/helpers/index';
import { useSales, useWebsiteEditor } from 'shared/hooks/index';
import { Wrapper, Button, Download } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div<{ backgroundColor: string }>`
  padding: 110px 0;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 80px 0;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  width: 100%;
  line-height: 1.35em;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 48px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 38px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 26px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 36px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Benefits = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 64px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 100%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 34px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column;
    align-items: center;
    `}
`;

const Item = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  display: flex;
  align-items: center;
  width: 32%;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: auto;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
    `}
`;

const Check = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  margin-right: 17px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-right: 12px;
    `}
`;

const Icon = styled.svg`
  width: 14px;
  height: 11px;
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  width: 93%;
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V2: FC<CallToActionProps> = (props) => {
  const {
    button = defaultWebValue,
    title = defaultWebValue,
    backgroundColor = COLOR_BLUE_3,
    textColor,
    benefits = [],
    meta,
    hidden
  } = props;
  const { updateActiveElement, resetActiveElement, containerClassname, editable } =
    useWebsiteEditor();

  const { type, purchaseElementId } = useSales();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { checkBenefits } = useIsEmpty(meta, 'content', true);

  return (
    <Container
      backgroundColor={backgroundColor}
      className={containerClassname}
      style={{
        display: hidden && !editable ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <Benefits>
          {benefits.map(({ content }, index) => {
            const isContentEmpty = checkBenefits(index);
            return (
              <Item key={`calltoaction-${index}`}>
                <Check>
                  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 9" fill="none">
                    <path
                      d="M3.86159 6.74161L1.58705 4.46707L0.8125 5.23616L3.86159 8.28525L10.407 1.73979L9.63795 0.970703L3.86159 6.74161Z"
                      fill="black"
                    />
                  </Icon>
                </Check>
                <Text
                  id={content?.id}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeRichText(content?.val, editable)
                  }}
                  style={{
                    color: filterBackgroundColor(backgroundColor, textColor)
                  }}
                  onClick={updateActiveElement}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="content"
                  data-placeholder="+"
                  editable={editable}
                  isEmpty={isContentEmpty}
                />
              </Item>
            );
          })}
        </Benefits>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
            style={{
              color: filterBackgroundColor(backgroundColor, textColor)
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-benefits={true}
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          {type === 'delivery' ? (
            <Download textColor={textColor} button={button} meta={meta} />
          ) : (
            <Button to={purchaseElementId} button={button} meta={meta} />
          )}
        </TitleWrapper>
      </Wrapper>
    </Container>
  );
};

V2.displayName = 'CallToActionVersion2';
