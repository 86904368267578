import type { UpsellVIPFormProps } from './V1';

import { V1 } from './V1';
import { V2 } from './V2';
import { V3 } from './V3';
import { V4 } from './V4';
import { V5 } from './V5';

export type { UpsellVIPFormProps };

export default {
  V1,
  V2,
  V3,
  V4,
  V5
};
