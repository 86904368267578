/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import type { SupportedLocales, SupportedLocalesWithLabel, Translations } from 'shared/types/User';

import axios from 'axios';

import axiosRetry from 'axios-retry';
import { isPermanentProduction } from './utils';

export const axiosClient = axios.create({
  baseURL: process.env['API_URL']
});

export const i18nAxiosClient = axios.create({
  baseURL: process.env['I18N_URL']
});

export const axiosClientBase = axios.create({
  baseURL: process.env['API_URL']
    ?.replace('fun/api', 'fun')
    ?.replace('com/api', 'com')
    ?.replace('8000/api', '8000')
});

export const axiosClientForSales = axios.create({
  baseURL: process.env['API_URL'],
  headers: {
    'X-Pw-Skip-Throttle': process.env['APP_THROTTLE_SKIP']
  }
});

export const axiosClientBaseAuth = axios.create({
  baseURL: process.env['API_URL']
    ?.replace('fun/api', 'fun')
    ?.replace('com/api', 'com')
    ?.replace('8000/api', '8000'),
  headers:
    typeof window !== 'undefined' && localStorage.getItem('token')
      ? {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      : {}
});

axiosRetry(axiosClient, {
  retries: 0,
  retryCondition: () => true
});

interface ExtraConfig {
  forceToken?: string;
  forceParentUser?: boolean;
}

axiosClient.interceptors.request.use((config: InternalAxiosRequestConfig & ExtraConfig) => {
  let imp: string | null = null;
  let accessToken: string | null = null;
  const forceToken = config?.forceToken;

  const adminImpersonation = localStorage.getItem('imp-active');
  if (typeof window !== 'undefined') {
    // safe to use window.localStorage
    imp = localStorage.getItem('imp-active');
    accessToken = imp ? localStorage.getItem('imp-token') : localStorage.getItem('token');
    const impEntityId = localStorage.getItem('impEntityId');
    if (
      imp &&
      impEntityId &&
      config.data &&
      Object.prototype.hasOwnProperty.call(config?.data, 'owner_entity')
    ) {
      config.data.owner_entity = impEntityId;
    }
  }

  const forceParent = config?.params?.forceParentUser;
  // Child Impersonation
  if (typeof window !== 'undefined' && !forceParent && !adminImpersonation) {
    // safe to use window.localStorage
    imp = localStorage.getItem('child-imp-active');
    accessToken = imp ? localStorage.getItem('child-imp-token') : localStorage.getItem('token');
    const impEntityId = localStorage.getItem('child-impEntityId');
    if (
      imp &&
      impEntityId &&
      config.data &&
      Object.prototype.hasOwnProperty.call(config?.data, 'owner_entity')
    ) {
      config.data.owner_entity = impEntityId;
    }
  }

  if (config.headers && typeof window !== 'undefined') {
    config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : false;
  }

  if (config.headers && forceToken) {
    config.headers['Authorization'] = `Bearer ${forceToken}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (typeof document === 'undefined' || typeof localStorage === 'undefined') return;
    const originalRequest = error.config;
    const alreadyRefreshed = originalRequest._alreadyRefreshed;
    const refreshToken = localStorage.getItem('refresh');
    if (error.response?.status === 401 && !alreadyRefreshed && refreshToken) {
      originalRequest._alreadyRefreshed = true;
      const imp = localStorage.getItem('imp-active');
      if (imp) {
        localStorage.removeItem('imp-active');
        window.location.reload();
        return Promise.reject(error);
      }

      try {
        const response = await axiosClientBase.post('/api/token/refresh/', {
          refresh: refreshToken
        });
        const { access: newAccessToken, session_id } = response.data;
        // Store the new access token
        localStorage.setItem('token', newAccessToken);
        // Update the authorization header
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

        const domain = isPermanentProduction() ? '.pagewheel.com' : '.pagewheel.fun';

        // update the cookie base on domain
        document.cookie = `sessionid=${session_id};domain=${domain};path=/`;

        return axiosClient(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        window.location.href = '/';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export const ApiService = {
  get: async <T = any>(
    url: string,
    config?: AxiosRequestConfig & ExtraConfig
  ): Promise<AxiosResponse<T>> => {
    const resp = await axiosClient.get<T>(url, config);
    return resp;
  },
  post: async <T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig & ExtraConfig
  ): Promise<AxiosResponse<T>> => {
    const resp = await axiosClient.post<T>(url, data, config);
    return resp;
  },
  put: async <T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig & ExtraConfig
  ): Promise<AxiosResponse<T>> => {
    const resp = await axiosClient.put<T>(url, data, config);
    return resp;
  },
  patch: async <T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig & ExtraConfig
  ): Promise<AxiosResponse<T>> => {
    const resp = await axiosClient.patch<T>(url, data, config);
    return resp;
  },
  delete: async <T = any>(
    url: string,
    config?: AxiosRequestConfig & ExtraConfig
  ): Promise<AxiosResponse<T>> => {
    const resp = await axiosClient.delete<T>(url, config);
    return resp;
  }
};

type SupportedResponse = {
  supported: SupportedLocalesWithLabel[];
  supportedLocales: SupportedLocales;
  fallback: string;
};

type VersionResponse = {
  version: string;
};

export const i18nService = {
  getSupported: async (): Promise<SupportedResponse> => {
    const { data } = await i18nAxiosClient.get(`/supported`);
    return data;
  },
  getTranslations: async (locale: string): Promise<Translations> => {
    const { data } = await i18nAxiosClient.get(`/${locale}`);
    return data;
  },
  getVersion: async (locale: string): Promise<VersionResponse> => {
    const { data } = await i18nAxiosClient.get(`/version/${locale}`);
    return data;
  }
};

export const baseMediaEndpoint = process.env['API_URL']?.includes('localhost')
  ? `${process.env['API_URL']}`.replace('/api', '')
  : '';
