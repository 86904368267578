import type { PendingPaymentData } from 'shared/types/index';

import styled, { css } from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { FlexBox } from 'shared/components/Common';

import PaymentError from './PaymentError';

import { Modal } from './Modal';

const Container = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow: auto;
  max-height: 80rem;
`;

interface Props {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  data: PendingPaymentData;
}

function PaymentErrorModal({ isOpen, onClose, data }: Props) {
  const publicKey = localStorage.getItem('pk');
  const stripePromise = loadStripe(`${publicKey}`);

  return (
    <Modal
      cssContent={css`
        height: fit-content !important;
        width: 100% !important;
      `}
      open={isOpen}
      onOpenChange={(open) => {
        onClose(open);
      }}>
      <Container>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: data.client_secret
          }}
          key={data.payment_intent}>
          <PaymentError onClose={onClose} amount={data.amount_due} />
        </Elements>
      </Container>
    </Modal>
  );
}

export default PaymentErrorModal;
