import type { ReactNode } from 'react';

import { Card, CardContent, Typography } from '@mui/material';

interface Props {
  header?: string | number | ReactNode;
  title?: string | number | ReactNode;
  description?: string | number | ReactNode;
}

export default function BasicCard(props: Props) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
          {props.header}
        </Typography>
        <Typography variant="h5" component="div" fontSize="16px">
          {props.title}
        </Typography>
        <Typography variant="body2" fontSize="12px">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
