import type { ColorPalette } from '../types';
import type { StructuredPaletteType, ColorToKeyType } from '../types/ColorPaletteTypes';

import { DEFAULT_PALETTE } from './static';

export const convertColorsToPallete = (colors?: ColorPalette): StructuredPaletteType => ({
  id: colors?.palette_id || colors?.id,
  name: colors?.name,
  mainColors: [
    {
      id: 0,
      value: colors?.color1 || DEFAULT_PALETTE.color1,
      name: 'Color 1'
    },
    {
      id: 1,
      value: colors?.color2 || DEFAULT_PALETTE.color2,
      name: 'Color 2'
    },
    {
      id: 2,
      value: colors?.color3 || DEFAULT_PALETTE.color3,
      name: 'Color 3'
    },
    {
      id: 3,
      value: colors?.color4 || DEFAULT_PALETTE.color4,
      name: 'Color 4'
    },
    {
      id: 4,
      value: colors?.color5 || DEFAULT_PALETTE.color5,
      name: 'Color 5'
    }
  ],
  backgroundColors: [
    {
      id: 0,
      value: colors?.background_color1 || DEFAULT_PALETTE.background_color1,
      name: 'Background Color 1'
    },
    {
      id: 1,
      value: colors?.background_color2 || DEFAULT_PALETTE.background_color2,
      name: 'Background Color 2'
    },
    {
      id: 2,
      value: colors?.background_color3 || DEFAULT_PALETTE.background_color3,
      name: 'Background Color 3'
    },
    {
      id: 3,
      value: colors?.background_color4 || DEFAULT_PALETTE.background_color4,
      name: 'Background Color 4'
    }
  ],
  baseColors: [
    {
      id: 0,
      value: colors?.base_color1 || DEFAULT_PALETTE.base_color1,
      dark: DEFAULT_PALETTE.base_color_dark,
      name: 'Base Color 1'
    },
    {
      id: 1,
      value: colors?.base_color2 || DEFAULT_PALETTE.base_color2,
      dark: DEFAULT_PALETTE.base_color_dark,
      name: 'Base Color 2'
    },
    {
      id: 2,
      value: colors?.base_color3 || DEFAULT_PALETTE.base_color3,
      dark: DEFAULT_PALETTE.base_color_dark,
      name: 'Base Color 3'
    },
    {
      id: 3,
      value: colors?.base_color4 || DEFAULT_PALETTE.base_color4,
      dark: DEFAULT_PALETTE.base_color_dark,
      name: 'Base Color 4'
    }
  ]
});

export const colorToKeyMapping = (key: string, palette?: ColorPalette) => {
  if (!palette) {
    return 'background_color4';
  }
  const mapping: ColorToKeyType = Object.fromEntries(
    Object.entries(palette).map((a) => a.reverse())
  );
  const loweredMapping: ColorToKeyType = Object.keys(mapping).reduce((obj: ColorToKeyType, k) => {
    obj[k.toLowerCase()] = mapping[k];
    return obj;
  }, {});
  return loweredMapping[key?.toLowerCase()] || 'background_color4';
};

const convertToLowerCase = (value?: string | number | boolean): string => {
  if (typeof value === 'string') {
    return value.toLowerCase();
  }
  return '';
};

export const keyToColorMapping = (key: string, palette: ColorPalette) => {
  if (key in palette) {
    return convertToLowerCase(palette[key]) || '#ffffff';
  }
  return '#ffffff';
};

export const keyToColorMappingWebsite = (palette: ColorPalette, key?: string) => {
  if (key && key in palette) {
    return convertToLowerCase(palette[key]) || '#000';
  }
  return '#000';
};
