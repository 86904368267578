import { lazy, useMemo, type FC } from 'react';
import type { Stripe, Sales as SalesType, SalesItem } from 'shared/types/index';

import { ThemeProvider } from 'styled-components';
import { MagicLoader } from 'shared/components/Common';
import { useSales, useWebsiteCreation, useBranding } from 'shared/hooks/index';
import { Footer } from 'shared/components/Sales/Footer';
import { calculateResponsiveDesign } from '../helpers';
import Alert from './Alert';

const SalesRenderer = lazy(() => import('shared/components/Sales/SalesRenderer'));

export type SalesPageTypes = {
  data: SalesType;
  stripe?: Stripe;
  navigate: (to: string) => void;
};

const Sales: FC<SalesPageTypes> = (props) => {
  const {
    data: { page_data, ...data },
    stripe,
    navigate
  } = props;

  const {
    parentWidth,
    parentManualWidth = 0,
    renderSales = page_data,
    salesType,
    subDomain,
    deliveryToken,
    price,
    selectedCurrency,
    metaTags,
    isSubscription,
    upsellDisplayer,
    upsellDetails
  } = useSales();

  const { websiteBaseColor } = useBranding();

  const { isLoading: isDataLoading } = useWebsiteCreation();

  const theme = useMemo(() => {
    const width = parentManualWidth || parentWidth;
    // if (width < 1450) width = (parentManualWidth || parentWidth) - 0; // adjust for zoom when screen hits the responsive range
    return {
      $width: width,
      $largeHeadlineFont: calculateResponsiveDesign(width, 'LARGE_HEADLINE'),
      $headlineFont: calculateResponsiveDesign(width, 'HEADLINE'),
      $paragraphFont: calculateResponsiveDesign(width, 'PARAGRAPH'),
      $subheadlineFont: calculateResponsiveDesign(width, 'SUBHEADLINE'),
      $tinyTextFont: calculateResponsiveDesign(width, 'TINY_TEXT')
    };
  }, [parentManualWidth, parentWidth]);

  const formattedSalesValues = useMemo(() => {
    if (!renderSales) return [];
    return Object.keys(renderSales)
      .map((key) => {
        const value = renderSales[key];
        if (value && typeof value === 'object') return { key, ...value };
        return null;
      })
      .filter(Boolean)
      .filter(
        (x) =>
          !(x?.key?.includes('guarantee') && salesType !== 'sales' && !metaTags?.['amazon_url'])
      )
      .filter((x) => {
        if (
          x?.key?.startsWith('upsell_vip_form') &&
          upsellDetails?.product_name &&
          upsellDisplayer === 'bump'
        ) {
          return false;
        }
        if (
          x?.key?.startsWith('form') &&
          upsellDetails?.product_name &&
          upsellDisplayer === 'vip'
        ) {
          return false;
        }
        if (x?.key?.startsWith('form') && isSubscription) return false;
        if (x?.key?.startsWith('recurring_form') && !isSubscription) return false;
        return true;
      })
      .sort((a, b) => {
        if (a && b) return a.order - b.order;
        return 0;
      });
  }, [
    renderSales,
    salesType,
    metaTags,
    upsellDetails?.product_name,
    upsellDisplayer,
    isSubscription
  ]);

  if (!parentWidth && !parentManualWidth) return null; // return null first to avoid flickering until parent width is set

  if (!renderSales || isDataLoading) return <MagicLoader />;

  return (
    <ThemeProvider theme={theme}>
      {formattedSalesValues.map((props) => {
        return (
          <SalesRenderer
            key={props?.key}
            accessor={props!.key}
            props={props as SalesItem}
            cover_thumbnail={data.cover_thumbnail}
            mockup={data.mockup}
            textColor={websiteBaseColor}
            backgroundColor={renderSales.website_color}
            secondaryColor={renderSales.secondary_color}
            pageImages={data.pages_thumbnails}
            stripe={stripe}
            navigate={navigate}
            type={salesType}
            sub_domain={subDomain}
            deliveryToken={deliveryToken}
            book_name={data.book_name}
            book_price={price}
            currency={selectedCurrency}
            colorPalette={data.color_palette}
            font={data.font}
          />
        );
      })}
      <Alert />
      <Footer textColor={websiteBaseColor} />
    </ThemeProvider>
  );
};

export default Sales;
