import type { CallToActionProps } from './types';
import { V1 } from './V1';
import { V2 } from './V2';

export type { CallToActionProps };

export default {
  V1,
  V2
};
