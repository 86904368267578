import type { FC } from 'react';
import type { RecurringFormProps } from './V1';
import tinycolor from 'tinycolor2';

import { useMemo } from 'react';
import styled from 'styled-components';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import 'react-phone-number-input/style.css';
import { COLOR_BLUE_6, COLOR_YELLOW } from 'shared/helpers/colors';
import { shade, sanitizeRichText } from 'shared/helpers/utils';
import { filterBackgroundColor } from 'shared/helpers/website';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Wrapper } from '../Common';
import useSalesForm from 'shared/hooks/useSalesForm';
import RecurringSecondForm from './RecurringSecondForm';
import { RecurringFirstForm } from './RecurringFirstForm';

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  padding: 80px 0 144px;
  position: relative;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 62px 0 116px;
  `}
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 4;
  gap: 80px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    justify-content: center;
  `}
`;

// const TitleContainer = styled.div<{
//   isTitleEmpty?: boolean;
//   isContentEmpty?: boolean;
//   editable?: boolean;
// }>`
//   display: flex;
//   flex-direction: column;
//   ${({ isTitleEmpty, editable }) => (isTitleEmpty && editable ? `gap: 50px;` : '')}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     width: 100%;
//     margin-bottom: 22px;
//   `}
// `;

// const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
//   font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
//   margin-bottom: 115px;
//   line-height: 1.3em;
//   position: relative;
//   text-align: center;

//   ${({ theme: { $width } }) =>
//     $width <= 1254 &&
//     `
//     font-size: 50px;
//       margin-bottom: 95px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 991 &&
//     `
//     font-size: 36px;
//   `}

//   ${({ theme: { $width } }) =>
//     $width <= 768 &&
//     `
//     font-size: 30px;
//      margin-bottom: 60px;
//   `}

//   ${({ isEmpty, editable }) =>
//     isEmpty && editable
//       ? `
//     &:not(:focus):before {
//       content: attr(data-placeholder);
//       cursor: text;
//       color: #aaa;
//       position: absolute;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   `
//       : `
//     &:before {
//       content: none;
//     }
//   `}
// `;

const RecurInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    gap: 126px;
  `}
`;

const Item = styled.div`
  background-color: #fff;
  border-radius: 0px;
  padding: 26px;
  padding-bottom: 160px;
  width: 47.4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
        width: 48.4%;
        padding-bottom: 96px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      padding-bottom: 76px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding: 85px 15px 0;
  border-radius: 14px;
  margin-bottom: 56px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      padding: 45px 15px;
      padding-bottom: 20px;
        margin-bottom: 30px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
        margin-bottom: 20px;
  `}
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const Value = styled.div`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
`;

const Name = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
`;

const IconItem = styled.div<{ bgColor: string }>`
  width: 136px;
  height: 136px;
  border-radius: 50%;
  position: absolute;
  top: -78px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f3f3f3')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px;
  left: 0;
  right: 0;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 106px;
      height: 106px;
      padding: 25px;
        top: -53px;
  `}

  svg {
    width: 100%;
    height: 100%;
    margin-top: 3px;
  }
`;

const InfoDescription = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 40px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
       padding: 0 20px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    gap: 30px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 0 10px;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`;

const List = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.45em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    min-height: 50%;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
     gap: 38px;
  `}
`;

const Description = styled.div<{ editable?: boolean; isEmpty?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  text-align: center;
  line-height: 1.45em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Button = styled.button<{ backgroundColor: string; editable: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 28px 40px;
  border-radius: 46px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  width: 86%;
  margin: 0 auto;
  position: absolute;
  bottom: -47px;
  left: 0;
  right: 0;
  min-height: 94px;
  display: flex;
  justify-content: center;
  ${(props) => props.editable && `cursor: text;`}

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

    ${({ theme: { $width } }) =>
    $width <= 1275 &&
    `
    min-height: 82px;
    bottom: -40px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
    min-height: auto;
    bottom: -30px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => props.editable && `cursor: text !important;`};
    display: inline-block;
  }
`;

export const V3: FC<RecurringFormProps> = (props: RecurringFormProps) => {
  const {
    backgroundColor = COLOR_BLUE_6,
    // title = defaultWebValue,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    button,
    hidden,
    benefits,
    stripe
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const {
    recurringModalInterval,
    setRecurringModalInterval,
    paymentFormSubscription,
    setPaymentFormSubscription,
    stripePromise
  } = useSalesForm(stripe);
  const { salesPageValues, setIsRecurringSubscriptionModalOpen, subDomain } = useSales();

  // const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  // const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');
  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const buttonBackground = useMemo(() => {
    if (button?.color) return button.color;
    if (
      salesPageValues &&
      typeof salesPageValues['topbar_1'] === 'object' &&
      salesPageValues['topbar_1'].button?.color
    ) {
      return salesPageValues['topbar_1'].button?.color;
    }
    return backgroundColor;
  }, [backgroundColor, salesPageValues, button]);

  const [shadedBackgroundColor] = useMemo(() => {
    return [shade(backgroundColor, 0.7)];
  }, [backgroundColor]);

  const color = useMemo(() => {
    return tinycolor(buttonBackground).isDark() ? '#FFFFFF' : '#000000';
  }, [buttonBackground]);

  if (recurringModalInterval && !paymentFormSubscription?.client_secret) {
    const button = benefits?.[recurringModalInterval === 'month' ? 0 : 1]?.button;

    return (
      <RecurringFirstForm
        backgroundColor={backgroundColor}
        callback={(data) => setPaymentFormSubscription(data)}
        button={
          button || {
            color: buttonBackground,
            val: '<p>Purchase</p>'
          }
        }
        meta={meta}
        containerClassname={containerClassname}
        shadedBackgroundColor={shadedBackgroundColor}
        subDomain={subDomain}
        recurringModalInterval={recurringModalInterval}
      />
    );
  }

  if (paymentFormSubscription?.client_secret) {
    return (
      <RecurringSecondForm
        paymentFormSubscription={paymentFormSubscription}
        containerClassname={containerClassname}
        shadedBackgroundColor={shadedBackgroundColor}
        stripePromise={stripePromise}
        backgroundColor={backgroundColor}
        textColor={textColor}
      />
    );
  }

  return (
    <Container
      id="recurring_form"
      backgroundColor={shadedBackgroundColor}
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.1 : 1
      }}>
      <StyledWrapper>
        {/* <TitleContainer
          editable={editable}
          isTitleEmpty={isTitleEmpty}
          isContentEmpty={isContentEmpty}>
          <Title
            id={title?.id}
            style={{
              color: filterBackgroundColor(shadedBackgroundColor, textColor),
              display: sanitizeRichText(title.val, editable, true) || editable ? 'block' : 'none'
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(title.val, editable, true)
                ? sanitizeRichText(title.val, editable, false, '<p>Choose the Best Plan</p>')
                : '<p></br><p>'
            }}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="Choose the Best Plan"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
        </TitleContainer> */}
        <RecurInner>
          {benefits?.map((benefit, index) => {
            const isTitleEmpty = checkTitleBenefits(index);
            const isContentEmpty = checkContentBenefits(index);
            const isFirst = index === 0;
            return (
              <Item key={index}>
                <IconItem bgColor={shade(benefit?.button?.color || backgroundColor, 0.7)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.01 39.85">
                    <path
                      d="M0,31.31c0,2.27.9,4.44,2.51,6.04,1.6,1.6,3.77,2.5,6.04,2.51h23.92c2.26,0,4.44-.9,6.04-2.51,1.6-1.6,2.5-3.77,2.51-6.04v-15.38H0v15.38Z"
                      fill={benefit?.button?.color || buttonBackground}
                    />
                    <path
                      d="M17.49,22.89v-2.98h5.19v13.68h-3.34v-10.7h-1.86Z"
                      fill={color}
                      opacity={0.7}
                    />
                    <path
                      d="M32.47,3.42h-1.71v-1.71c0-.45-.18-.89-.5-1.21-.32-.32-.75-.5-1.21-.5s-.89.18-1.21.5c-.32.32-.5.76-.5,1.21v1.71h-13.67v-1.71c0-.45-.18-.89-.5-1.21-.32-.32-.76-.5-1.21-.5s-.89.18-1.21.5c-.32.32-.5.76-.5,1.21v1.71h-1.71c-2.27,0-4.44.9-6.04,2.51C.9,7.52,0,9.7,0,11.96v1.71h41.01v-1.71c0-2.27-.9-4.44-2.51-6.04-1.6-1.6-3.77-2.5-6.04-2.51Z"
                      fill={benefit?.button?.color || buttonBackground}
                    />
                  </svg>
                </IconItem>
                <Header
                  onClick={() => {
                    setIsRecurringSubscriptionModalOpen(true);
                  }}>
                  <Price>
                    <Value>
                      ${isFirst ? meta['monthly_price'] : meta['yearly_price']}/
                      {isFirst ? 'm' : 'y'}
                    </Value>
                    <Name>{isFirst ? 'Monthly' : 'Yearly'} Membership</Name>
                  </Price>
                </Header>
                <InfoDescription>
                  <TextWrapper>
                    <List
                      id={benefit?.title?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.title?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor('#ffffff', textColor),
                        textAlign: 'center'
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleEmpty}
                    />
                  </TextWrapper>

                  <ButtonInner>
                    <Description
                      id={benefit?.content?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefit?.content?.val, editable)
                      }}
                      style={{
                        color: filterBackgroundColor(shadedBackgroundColor, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentEmpty}
                    />
                    <Button
                      id={benefit?.button?.id}
                      backgroundColor={
                        benefit.button?.color || (isFirst ? buttonBackground : secondaryColor)
                      }
                      editable={editable}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(
                          benefit?.button?.val || `<p>Monthly Plan - $27</p>`,
                          editable
                          // false,
                        )
                      }}
                      onClick={
                        editable
                          ? updateActiveElement
                          : () => setRecurringModalInterval(isFirst ? 'month' : 'year')
                      }
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="button"
                      data-pindex={index}
                      data-benefits={true}
                      style={{ color: color }}
                      {...(editable ? { as: 'p' } : {})}
                    />
                  </ButtonInner>
                </InfoDescription>
              </Item>
            );
          })}
        </RecurInner>
      </StyledWrapper>
    </Container>
  );
};
