const loremIpsumText = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sollicitudin, massa ut blandit vehicula, urna massa elementum nunc, eget ultricies sem enim id sem. Curabitur non mauris ullamcorper, dignissim augue in, semper tellus. Aenean tincidunt interdum ligula at cursus. Morbi bibendum vestibulum est, eu blandit felis interdum ac. Nunc sed egestas arcu. Ut posuere felis in sem tempor, aliquam dignissim sapien feugiat. Quisque ac mi sapien. Aliquam erat volutpat. Curabitur quis mi eu quam commodo feugiat. Mauris non interdum massa. Etiam vitae tincidunt tellus.
`;

export type SalesItemBase = {
  title?: string;
  content?: string;
  button?: string;
};

export const generateRandomText = (wordNumber: number) => {
  const words = loremIpsumText.split(' ');
  return words.slice(0, wordNumber).join(' ');
};

export const generateComponentWithFaker = () => {
  const data = {} as Record<string, string | SalesItemBase[]>;
  data['title'] = generateRandomText(4);
  data['content'] = generateRandomText(6);
  data['button'] = generateRandomText(2);

  const benefits = [] as SalesItemBase[];
  Array.from({ length: 6 }, (_, i) => i + 1).forEach(() => {
    const benefitData = {} as Record<string, string>;
    benefitData['title'] = generateRandomText(4);
    benefitData['content'] = generateRandomText(6);
    benefitData['button'] = generateRandomText(2);
    benefits.push(benefitData);
  });
  data['benefits'] = benefits;
  return data;
};
