import type { FC } from 'react';
import type { Stripe, Sales as SalesType, SalesItem } from 'shared/types/index';

import { lazy, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { MagicLoader } from 'shared/components/Common';

import { useSales, useWebsiteCreation, useBranding } from 'shared/hooks/index';
import { calculateResponsiveDesign } from 'shared/helpers/index';

import { Footer } from 'shared/components/Sales/Footer';

const SalesRenderer = lazy(() => import('shared/components/Sales/SalesRenderer'));

export type ThankyouProps = {
  data: SalesType;
  stripe?: Stripe;
};

const Thankyou: FC<ThankyouProps> = (props) => {
  const {
    data: { delivery_page_data, ...data }
  } = props;

  const {
    parentWidth,
    parentManualWidth = 0,
    renderDelivery = delivery_page_data,
    salesType,
    subDomain,
    price,
    selectedCurrency,
    isSubscription,
    subscriptionData
  } = useSales();

  const { websiteBaseColor } = useBranding();

  const { isLoading: isDataLoading } = useWebsiteCreation();

  const theme = useMemo(() => {
    const width = parentManualWidth || parentWidth;
    return {
      $width: width,
      $largeHeadlineFont: calculateResponsiveDesign(width, 'LARGE_HEADLINE'),
      $headlineFont: calculateResponsiveDesign(width, 'HEADLINE'),
      $paragraphFont: calculateResponsiveDesign(width, 'PARAGRAPH'),
      $subheadlineFont: calculateResponsiveDesign(width, 'SUBHEADLINE'),
      $tinyTextFont: calculateResponsiveDesign(width, 'TINY_TEXT')
    };
  }, [parentManualWidth, parentWidth]);

  const formattedDeliveryValues = useMemo(() => {
    if (!renderDelivery) return [];
    return Object.keys(renderDelivery)
      .map((key) => {
        const value = renderDelivery[key];
        if (value && typeof value === 'object') return { key, ...value };
        return null;
      })
      .filter(Boolean)
      .filter((x) => {
        if (isSubscription && x?.key.includes('receipt')) return true;
        return !(x?.key?.includes('receipt') && salesType !== 'sales');
      })
      .sort((a, b) => {
        if (a && b) return a.order - b.order;
        return 0;
      });
  }, [isSubscription, renderDelivery, salesType]);

  if (!parentWidth && !parentManualWidth) return null; // return null first to avoid flickering until parent width is set

  if (!renderDelivery || isDataLoading) return <MagicLoader />;

  return (
    <ThemeProvider theme={theme}>
      {formattedDeliveryValues.map((props) => {
        return (
          <SalesRenderer
            key={props?.key}
            accessor={props!.key}
            props={props as SalesItem}
            cover_thumbnail={data.cover_thumbnail}
            mockup={data.mockup}
            textColor={websiteBaseColor}
            backgroundColor={renderDelivery.website_color}
            secondaryColor={renderDelivery.secondary_color}
            pageImages={data.pages_thumbnails}
            sub_domain={subDomain}
            type={salesType}
            book_name={data.book_name}
            book_price={price}
            subscriptionData={subscriptionData}
            currency={data?.price_currency ? data.price_currency : selectedCurrency}
            colorPalette={data.color_palette}
          />
        );
      })}
      <Footer textColor={websiteBaseColor} />
    </ThemeProvider>
  );
};

export default Thankyou;
