import type { FC, PropsWithChildren } from 'react';
import { createContext, useState } from 'react';

type WebsiteCreationContextData = {
  isLoading: boolean;
  updateLoading: (value: boolean) => void;
};

const WebsiteCreationContext = createContext<WebsiteCreationContextData>({
  isLoading: false,
  updateLoading: () => null
});

const WebsiteCreationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateLoading = (value: boolean) => {
    setIsLoading(value);
  };
  return (
    <WebsiteCreationContext.Provider
      value={{
        isLoading,
        updateLoading
      }}>
      {children}
    </WebsiteCreationContext.Provider>
  );
};

export type { WebsiteCreationContextData };

export { WebsiteCreationContext, WebsiteCreationProvider };
