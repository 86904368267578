import type { FC } from 'react';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { COLOR_BLACK_3, COLOR_GRAY_13 } from 'shared/helpers/colors';
import { sanitizeRichText } from 'shared/helpers/utils';

export type Web = {
  web?: boolean;
};

const Container = styled.div<Web>`
  display: flex;
  align-items: flex-start;

  img {
    width: 40px;
  }
`;

const Body = styled.div<Web>`
  margin: 0 2rem;

  h3 {
    font-weight: 400;
    line-height: 150%;
    font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
    ${(props) => (props.web ? '' : `color: ${COLOR_BLACK_3}`)};
  }

  p {
    margin-top: 1rem;
    font-weight: 400;
    line-height: 150%;
    color: ${COLOR_GRAY_13};
    font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  }
`;

export type CheckSectionProps = {
  title?: string;
  content?: string;
  id: string;
  web: boolean;
  color: string;
};

export const CheckSection: FC<CheckSectionProps> = (props) => {
  const { title = '', content = '', id, web, color } = props;

  return (
    <Container web={web}>
      <FontAwesomeIcon icon={faCheck} style={{ width: 100, color: color }} />
      <Body id={id} web={web}>
        <h3
          style={{ color: color }}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(`<strong>${title}</strong>`) }}
        />
        <p
          style={{ color: color }}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content) }}
        />
      </Body>
    </Container>
  );
};
