import type { HeroDetailedProps } from './types';
import { V1 } from './V1';
import { V2 } from './V2';
import { V3 } from './V3';
import { V4 } from './V4';

export type { HeroDetailedProps };
export default {
  V1,
  V2,
  V3,
  V4
};
