/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ForwardRefRenderFunction, ImgHTMLAttributes } from 'react';
import { forwardRef } from 'react';

// Conditional import (won't break in non-Next.js projects)
let NextImage: any = null;
try {
  NextImage = require('next/image').default;
} catch (_error) {
  // Ignore if next/image is not available
}

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width?: number;
  height?: number;
}

const Component: ForwardRefRenderFunction<HTMLImageElement, ImageProps> = ({ ...props }, ref) => {
  if (NextImage) {
    const { src, alt, width, height, ...rest } = props;
    return (
      <NextImage
        ref={ref as any} // Cast ref as any since NextImage might not support direct ref
        src={src}
        alt={alt}
        width={width}
        height={height}
        data-debug="next"
        {...rest}
      />
    );
  }

  // Fallback for non-Next.js projects
  return <img ref={ref} {...props} />;
};

// Use forwardRef to support refs
export const Image = forwardRef(Component);

Image.displayName = 'React/NextJS Hybrid Image';
