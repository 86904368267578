import styled from 'styled-components';

const TitleForm = styled.h5`
  font-size: 17px;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-bottom: 4px;
`;

const DescriptionForm = styled.p`
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #444;
`;

interface TitleProps {
  title: string;
  description: string;
}

const Title = (props: TitleProps) => {
  const { title, description } = props;
  return (
    <>
      <TitleForm>{title}</TitleForm>
      <DescriptionForm>{description}</DescriptionForm>
    </>
  );
};

export default Title;
