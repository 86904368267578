import { COLOR_GRAY_13 } from 'shared/helpers/colors';
import styled from 'styled-components';
import type { EventProps } from './types';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import useSales from 'shared/hooks/useSales';
import { useMemo } from 'react';

const Container = styled.div`
  position: relative;
  background: #fff;
  padding: 70px 0 0px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const SaleContainer = styled.div`
  width: 1254px;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SectionTitle = styled.h2`
  font-size: 46px;
  text-align: center;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    font-size: 40px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    font-size: 36px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 32px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    font-size: 28px;
  `}
`;

const SaleContainerStyled = styled(SaleContainer)`
  position: relative;
  width: 1064px;
  margin: 0 auto;
  z-index: 2;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SectionTitleStyled = styled(SectionTitle)`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 26px;
  width: 100%;
`;

const Description = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  color: ${COLOR_GRAY_13};
  width: 80%;
  text-align: center;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    font-size: 17px;
  `}
`;

const EventInner = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0px;
  position: relative;
  margin-top: 50px;
  justify-content: center;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-top: 36px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    margin-top: 0px;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  position: relative;
  z-index: 3;
  margin-left: 100px;
  max-width: 55%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-left: 50px;
    max-width: 58%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    max-width: 100%;
    width: 100%;
    padding-right: 0px;
    padding-bottom: 10px;
    margin-left: 0px;
  `}
`;

const Icon = styled.div`
  width: 178px;
  height: 178px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 16.6px rgba(0, 0, 0, 0.07));
  background: #fff;
  padding: 40px;
  position: relative;
  z-index: 3;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 148px;
    height: 148px;
    margin-bottom: 25px;
  `}
`;

const WrapperInfo = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  `}
`;

const InfoTitle = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  min-width: 90px;
  word-break: break-all;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 17px;
    text-align: center;
    width: 100%;
    margin-bottom: 6px;
  `}
`;

const ItemInfo = styled.div`
  background: #fff;
  border-radius: 20px;
  display: flex;
  padding: 8px 20px;
  border-bottom: 1px solid #dedede;
  margin-left: 10px;
  word-break: break-all;
  max-width: 86%;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    max-width: 79%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    max-width: 84%;
    margin-left: 0px;
  `}

  svg {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 16px;
  }
`;

const InfoText = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  width: 90%;
`;

const BgEventInfo = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.75;
  border-radius: 10px;
  z-index: 2;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    top: auto;
    bottom: 0;
    height: 80%;
  `}
`;

export const V2 = (props: EventProps) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { setIsEventFormModalOpen } = useSales();

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <SaleContainerStyled>
        <SectionTitleStyled
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={() => {
            if (editable) setIsEventFormModalOpen(true);
          }}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
        />
        <Description
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
        />
        <EventInner>
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.68 95.63">
              <path
                d="m95.63,16.11c-.5-4.7-3.93-8.54-8.54-9.08-2.78-.31-5.61-.14-8.4-.18v9.86c0,2.24-1.46,3.74-3.47,3.74s-3.38-1.55-3.38-3.74V6.85h6.71s.09,0,.14,0v-.05l-.09.03v-3.41c-.05-1.92-1.51-3.42-3.38-3.42-1.92-.05-3.47,1.46-3.47,3.42v3.38H23.96v9.91c0,2.24-1.46,3.74-3.47,3.74s-3.38-1.55-3.38-3.74V6.85h6.8v-3.42C23.87,1.51,22.41,0,20.54,0s-3.38,1.46-3.42,3.42v3.38h-6.62c-6.03.05-10.5,4.47-10.5,10.45v9.08c0,.32.05.64.05.91h95.58v-9.77c0-.46.05-.91,0-1.37Z"
                fill={shadedBackgroundColor || '#2EC5CE'}
                strokeWidth={0}
              />
              <path
                d="m0,34.24v50.8c0,6.16,4.47,10.59,10.59,10.59h74.54c1,0,2.01-.09,2.97-.37,4.56-1.23,7.58-5.16,7.58-10v-51.03H0Zm70.28,25.7l-10.52,7.64c-.7.51-.99,1.41-.73,2.24l4.02,12.37c.6,1.84-1.51,3.37-3.08,2.24l-10.52-7.64c-.7-.51-1.65-.51-2.35,0l-10.52,7.64c-1.57,1.14-3.68-.39-3.08-2.24l4.02-12.37c.27-.82-.03-1.73-.73-2.24l-10.52-7.64c-1.57-1.14-.76-3.62,1.18-3.62h13c.87,0,1.63-.56,1.9-1.38l4.02-12.37c.6-1.84,3.21-1.84,3.8,0l4.02,12.37c.27.82,1.04,1.38,1.9,1.38h13c1.94,0,2.74,2.48,1.18,3.62Z"
                fill={shadedBackgroundColor || '#2EC5CE'}
                strokeWidth={0}
              />
              <path
                d="m0,34.24v50.8c0,6.16,4.47,10.59,10.59,10.59h74.54c1,0,2.01-.09,2.97-.37,4.56-1.23,7.58-5.16,7.58-10v-51.03H0Zm70.28,25.7l-10.52,7.64c-.7.51-.99,1.41-.73,2.24l4.02,12.37c.6,1.84-1.51,3.37-3.08,2.24l-10.52-7.64c-.7-.51-1.65-.51-2.35,0l-10.52,7.64c-1.57,1.14-3.68-.39-3.08-2.24l4.02-12.37c.27-.82-.03-1.73-.73-2.24l-10.52-7.64c-1.57-1.14-.76-3.62,1.18-3.62h13c.87,0,1.63-.56,1.9-1.38l4.02-12.37c.6-1.84,3.21-1.84,3.8,0l4.02,12.37c.27.82,1.04,1.38,1.9,1.38h13c1.94,0,2.74,2.48,1.18,3.62Z"
                opacity={0.54}
                fill={'#fff'}
                strokeWidth={0}
              />
            </svg>
          </Icon>
          <Info>
            {benefits?.map(({ title: benefitTitle, content: benefitContent }, index) => (
              <WrapperInfo key={index}>
                <InfoTitle
                  id={benefitTitle?.id}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeRichText(benefitTitle?.val)
                  }}
                  style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                  onClick={() => {
                    if (editable) setIsEventFormModalOpen(true);
                  }}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="title"
                  data-benefits={true}
                />
                <ItemInfo>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.99">
                    <path
                      d="m0,6.44h18v9.6c0,.91-.57,1.65-1.43,1.88-.18.05-.37.07-.56.07H1.99c-1.15,0-1.99-.83-1.99-1.99V6.43h0Z"
                      fill={shadedBackgroundColor || '#2EC5CE'}
                      strokeWidth={0}
                    />
                    <path
                      d="m16.39,1.32c-.52-.06-1.06-.03-1.58-.03v1.85c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.26,0s.02,0,.03,0h0s-.02,0-.02,0v-.64C14.78.28,14.5,0,14.15,0c-.36,0-.65.27-.65.64v.64H4.51v1.86c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.28v-.64C4.49.28,4.22,0,3.86,0s-.64.27-.64.64v.64h-1.24c-1.13,0-1.98.84-1.98,1.97v1.71c0,.06,0,.12,0,.17h17.98v-1.84c0-.09,0-.17,0-.26-.09-.88-.74-1.61-1.61-1.71Z"
                      fill={shadedBackgroundColor || '#2EC5CE'}
                      strokeWidth={0}
                    />
                  </svg>
                  <InfoText
                    id={benefitContent?.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeRichText(benefitContent?.val, editable)
                    }}
                    onClick={() => {
                      if (editable) setIsEventFormModalOpen(true);
                    }}
                    style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="content"
                    data-benefits={true}
                  />
                </ItemInfo>
              </WrapperInfo>
            ))}
          </Info>
          <BgEventInfo />
        </EventInner>
      </SaleContainerStyled>
    </Container>
  );
};
