import type { FC, ComponentPropsWithoutRef } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';

import styled from 'styled-components';

import { COLOR_BUTTON_OUTLINE, COLOR_YELLOW } from 'shared/helpers/colors';
import { sanitizeRichText } from 'shared/helpers/utils';

/**
 * Styled button component with customizable styles.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {FlattenSimpleInterpolation} [props.css] - Additional CSS styles.
 * @param {boolean} [props.disableHover] - Disables hover effects if true.
 * @param {string} [props.background] - Background color of the button.
 */
type ButtonProps = {
  css?: FlattenSimpleInterpolation;
  disableHover?: boolean;
  background?: string;
};
const Button = styled.button<ButtonProps>`
  cursor: pointer;
  padding: 1.4rem 7rem;
  border: 1px solid ${COLOR_BUTTON_OUTLINE};
  border-radius: 1rem;
  font-size: 2rem;
  transition: all 0.2s;

  ${(props) => props.background && `background: ${props.background};`}

  ${(props) =>
    props.disableHover
      ? ''
      : `&:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }`}

  &:disabled {
    background-color: lighten(${COLOR_YELLOW}, 30%) !important;
    cursor: not-allowed;
  }

  ${(props) => props.css}
`;

export interface NavButtonProps extends ComponentPropsWithoutRef<'button'> {
  css?: FlattenSimpleInterpolation;
  content: string;
  backgroundColor?: string;
  disableHover?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

/**
 * NavButton component that renders a styled button with optional hover effects and sanitized HTML content.
 *
 * @component
 * @param {FlattenSimpleInterpolation} props.css - Additional CSS styles.
 * @param {string} props.content - The HTML content to be displayed inside the button.
 * @param {string} props.backgroundColor - Background color of the button.
 * @param {boolean} props.disableHover - Disables hover effects if true.
 * @param {boolean} props.disabled - Disables Button if true.
 * @returns {JSX.Element} The rendered button component.
 * @example
 * return (
 *   <NavButton
 *     backgroundColor="blue"
 *     content="<strong>Click Me</strong>"
 *     disableHover={false}
 *     css={css`
 *       margin: 10px;
 *     `}
 *   />
 * )
 */
export const NavButton: FC<NavButtonProps> = (props) => {
  const { backgroundColor, content, disableHover, css, type = 'button', disabled, ...rest } = props;

  return (
    <Button
      type={type}
      background={backgroundColor}
      style={{ backgroundColor: backgroundColor }}
      dangerouslySetInnerHTML={{ __html: sanitizeRichText(content) }}
      css={css}
      disabled={disabled}
      disableHover={disableHover}
      {...rest}
    />
  );
};
