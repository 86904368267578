import { useState } from 'react';

export type ListNode<T> = {
  value: T;
  next: ListNode<T> | null;
};

export const createNode = <T>(value: T, next: ListNode<T> | null = null): ListNode<T> => ({
  value,
  next
});

const useHistory = <T>() => {
  const [head, setHead] = useState<ListNode<T> | null>(null);
  const [history, setHistory] = useState<(ListNode<T> | null)[]>([]);

  const prepend = (value: T) => {
    setHistory((prevHistory) => [head, ...prevHistory]);
    setHead(createNode(value, head));
  };

  const restore = () => {
    if (history.length > 0) {
      setHead(history[0]);
      setHistory((currentHistory) => currentHistory.slice(1));
      return history[0];
    }
    return;
  };

  const reset = () => {
    setHead(null);
    setHistory([]);
  };

  return { head, prepend, restore, reset };
};

export default useHistory;
