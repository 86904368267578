import type { FlattenSimpleInterpolation } from 'styled-components';

import styled from 'styled-components';

import { COLOR_BLACK, COLOR_GRAY, COLOR_WHITE, COLOR_YELLOW } from 'shared/helpers/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Toggler = styled.label`
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 1.4rem;
`;

const Label = styled.span<{ isToggled: boolean; isLeft?: boolean }>`
  cursor: pointer;
  ${(props) => (props.isLeft ? 'margin-right: 2rem;' : 'margin-left: 2rem;')}
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 15px;
  font-weight: ${(props) => (props.isToggled ? '700' : '500')};
  color: ${(props) => (props.isToggled ? COLOR_BLACK : COLOR_GRAY)};
`;

const TogglerSlider = styled.span<{ css?: FlattenSimpleInterpolation }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3.4rem;
  background-color: ${COLOR_YELLOW};

  &::before {
    position: absolute;
    content: '';
    height: 1.6rem;
    width: 1.6rem;
    bottom: -1px;
    border-radius: 50%;
    background-color: ${COLOR_WHITE};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
    transition: all 0.3s;
  }

  ${(props) => props.css}
`;

const TogglerInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span::before {
    transform: translateX(2.6rem);
  }
`;

interface Props {
  first: string;
  second: string;
  isToggled: boolean;
  onToggle: () => void;
  className?: string;
  sliderClass?: string;
  isMove?: boolean;
  cssContainer?: FlattenSimpleInterpolation;
  cssSlider?: FlattenSimpleInterpolation;
}

const QrToggle = (props: Props) => {
  const { first, second, isToggled, onToggle, className, sliderClass, isMove, cssSlider } = props;

  const toggleHandler = () => {
    onToggle();
  };

  return (
    <Container className={className}>
      <Label isToggled={!isToggled} isLeft onClick={toggleHandler}>
        {first}
      </Label>
      <Toggler>
        <TogglerInput type="checkbox" checked={isToggled} onChange={toggleHandler} />
        <TogglerSlider
          css={cssSlider}
          onClick={isMove ? toggleHandler : undefined}
          className={`${sliderClass}`}
        />
      </Toggler>
      <Label isToggled={isToggled} onClick={toggleHandler}>
        {second}
      </Label>
    </Container>
  );
};

export default QrToggle;
