import { useContext } from 'react';
import { BrandingContext } from '../context/BrandingContext';

function useBranding() {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error('useBrandingContext must be used within a BrandingProvider');
  }
  return context;
}

export default useBranding;
