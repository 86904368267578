import { COLOR_GRAY_13 } from 'shared/helpers/colors';
import styled from 'styled-components';
import type { EventProps } from './types';
import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import useSales from 'shared/hooks/useSales';
import { useMemo } from 'react';

const Container = styled.div`
  position: relative;
  background: #fff;
  padding: 70px 0 40px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 40px;
  `}
`;

const SectionTitle = styled.h2`
  font-size: 46px;
  text-align: center;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SaleContainer = styled.div`
  width: 1254px;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SaleContainerStyled = styled(SaleContainer)`
  position: relative;
  width: 1064px;
  margin: 0 auto;
  z-index: 2;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SectionTitleStyled = styled(SectionTitle)`
  text-align: left;
  margin-bottom: 10px;
  width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  `}
`;

const Description = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  color: ${COLOR_GRAY_13};
  text-align: left;
  margin-top: 20px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    font-size: 17px;
    margin-top: 30px;
  `}
`;

const EventInner = styled.div`
  display: flex;
  position: relative;
  margin-top: 50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-top: 36px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    margin-top: 0px;
  `}
`;

const InfoWrapper = styled.div`
  width: 83.5%;
  padding-left: 54px;
  padding-top: 20px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding-left: 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding-left: 0px;
    width: 100%;
  `}
`;

const Info = styled.div`
  display: flex;
  gap: 15px;
  position: relative;
  z-index: 3;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    align-items: center;
  `}
`;

const Icon = styled.div`
  width: 178px;
  height: 178px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 16.6px rgba(0, 0, 0, 0.07));
  background: #fff;
  padding: 40px;
  position: relative;
  z-index: 3;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 128px;
    height: 128px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin: 0 auto;
    margin-bottom: 10px;
  `}
`;

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    width: fit-content;
  `}
`;

const InfoTitle = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  min-width: 90px;
  width: 72px;
  margin-left: 18px;
  margin-bottom: 3px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 17px;
    text-align: center;
    width: 100%;
    margin-bottom: 6px;
    margin-left: 0px;
  `}
`;

const ItemInfo = styled.div`
  align-items: center;
  width: max-content;
  background: #fff;
  border-radius: 20px;
  display: flex;
  padding: 8px 20px;
  border-bottom: 1px solid #dedede;
  word-break: break-all;

  svg {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 10px;
  }
`;

const InfoText = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
`;

export const V3 = (props: EventProps) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { setIsEventFormModalOpen } = useSales();

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <SaleContainerStyled>
        <SectionTitleStyled
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={() => {
            if (editable) setIsEventFormModalOpen(true);
          }}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
        />
        <EventInner>
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.68 95.63">
              <path
                d="m95.63,16.11c-.5-4.7-3.93-8.54-8.54-9.08-2.78-.31-5.61-.14-8.4-.18v9.86c0,2.24-1.46,3.74-3.47,3.74s-3.38-1.55-3.38-3.74V6.85h6.71s.09,0,.14,0v-.05l-.09.03v-3.41c-.05-1.92-1.51-3.42-3.38-3.42-1.92-.05-3.47,1.46-3.47,3.42v3.38H23.96v9.91c0,2.24-1.46,3.74-3.47,3.74s-3.38-1.55-3.38-3.74V6.85h6.8v-3.42C23.87,1.51,22.41,0,20.54,0s-3.38,1.46-3.42,3.42v3.38h-6.62c-6.03.05-10.5,4.47-10.5,10.45v9.08c0,.32.05.64.05.91h95.58v-9.77c0-.46.05-.91,0-1.37Z"
                fill={shadedBackgroundColor || '#2EC5CE'}
                strokeWidth={0}
              />
              <path
                d="m0,34.24v50.8c0,6.16,4.47,10.59,10.59,10.59h74.54c1,0,2.01-.09,2.97-.37,4.56-1.23,7.58-5.16,7.58-10v-51.03H0Zm70.28,25.7l-10.52,7.64c-.7.51-.99,1.41-.73,2.24l4.02,12.37c.6,1.84-1.51,3.37-3.08,2.24l-10.52-7.64c-.7-.51-1.65-.51-2.35,0l-10.52,7.64c-1.57,1.14-3.68-.39-3.08-2.24l4.02-12.37c.27-.82-.03-1.73-.73-2.24l-10.52-7.64c-1.57-1.14-.76-3.62,1.18-3.62h13c.87,0,1.63-.56,1.9-1.38l4.02-12.37c.6-1.84,3.21-1.84,3.8,0l4.02,12.37c.27.82,1.04,1.38,1.9,1.38h13c1.94,0,2.74,2.48,1.18,3.62Z"
                fill={shadedBackgroundColor || '#2EC5CE'}
                strokeWidth={0}
              />
              <path
                d="m0,34.24v50.8c0,6.16,4.47,10.59,10.59,10.59h74.54c1,0,2.01-.09,2.97-.37,4.56-1.23,7.58-5.16,7.58-10v-51.03H0Zm70.28,25.7l-10.52,7.64c-.7.51-.99,1.41-.73,2.24l4.02,12.37c.6,1.84-1.51,3.37-3.08,2.24l-10.52-7.64c-.7-.51-1.65-.51-2.35,0l-10.52,7.64c-1.57,1.14-3.68-.39-3.08-2.24l4.02-12.37c.27-.82-.03-1.73-.73-2.24l-10.52-7.64c-1.57-1.14-.76-3.62,1.18-3.62h13c.87,0,1.63-.56,1.9-1.38l4.02-12.37c.6-1.84,3.21-1.84,3.8,0l4.02,12.37c.27.82,1.04,1.38,1.9,1.38h13c1.94,0,2.74,2.48,1.18,3.62Z"
                opacity={0.54}
                fill={shadedBackgroundColor || '#2EC5CE'}
                strokeWidth={0}
              />
            </svg>
          </Icon>
          <InfoWrapper>
            <Info>
              {benefits?.map(({ title: benefitTitle, content: benefitContent }, index) => (
                <WrapperInfo key={index}>
                  <InfoTitle
                    id={benefitTitle?.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeRichText(benefitTitle?.val)
                    }}
                    style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                    onClick={() => {
                      if (editable) setIsEventFormModalOpen(true);
                    }}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="title"
                    data-benefits={true}
                  />
                  <ItemInfo>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.99">
                      <path
                        d="m0,6.44h18v9.6c0,.91-.57,1.65-1.43,1.88-.18.05-.37.07-.56.07H1.99c-1.15,0-1.99-.83-1.99-1.99V6.43h0Z"
                        fill={shadedBackgroundColor || '#2EC5CE'}
                        strokeWidth={0}
                      />
                      <path
                        d="m16.39,1.32c-.52-.06-1.06-.03-1.58-.03v1.85c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.26,0s.02,0,.03,0h0s-.02,0-.02,0v-.64C14.78.28,14.5,0,14.15,0c-.36,0-.65.27-.65.64v.64H4.51v1.86c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.28v-.64C4.49.28,4.22,0,3.86,0s-.64.27-.64.64v.64h-1.24c-1.13,0-1.98.84-1.98,1.97v1.71c0,.06,0,.12,0,.17h17.98v-1.84c0-.09,0-.17,0-.26-.09-.88-.74-1.61-1.61-1.71Z"
                        fill={shadedBackgroundColor || '#2EC5CE'}
                        strokeWidth={0}
                      />
                    </svg>
                    <InfoText
                      id={benefitContent?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefitContent?.val, editable)
                      }}
                      style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                      onClick={() => {
                        if (editable) setIsEventFormModalOpen(true);
                      }}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-benefits={true}
                    />
                  </ItemInfo>
                </WrapperInfo>
              ))}
            </Info>
            <Description
              id={content.id}
              dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
              style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
              onClick={updateActiveElement}
              onBlur={resetActiveElement}
              data-accessor-parent={meta['accessorKey']}
              data-accessorkey="content"
            />
          </InfoWrapper>
        </EventInner>
      </SaleContainerStyled>
    </Container>
  );
};
