import type { FC } from 'react';

import { useEffect, useState } from 'react';
import styled from 'styled-components';

/**
 * Styled container for the animated loader.
 */
const AnimatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
`;

/**
 * Styled text for the loading message.
 */
const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 20px;
  color: #333;
  font-family: 'Plus Jakarta Sans', sans-serif;
`;

/**
 * MagicLoader component that displays an animated loading text.
 *
 * This component shows a loading message with animated dots that cycle every 2 seconds.
 *
 * @component
 * @returns {JSX.Element} The rendered loader component.
 * @example
 * return (
 *   <MagicLoader />
 * )
 */
export const MagicLoader: FC = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? '.' : prevDots + '.'));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AnimatedContainer>
      <LoadingText>Applying Magic{dots}</LoadingText>
    </AnimatedContainer>
  );
};
