import type { FC } from 'react';
import type { BookBaseColor } from 'shared/types/Branding';

import { useMemo, useState } from 'react';
import { AddressElement, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

import { filterBackgroundColor } from 'shared/helpers/website';
import useErrorLogger from 'shared/hooks/useErrorLogger';
import tinycolor from 'tinycolor2';
import { COLOR_BLACK, COLOR_WHITE } from 'shared/helpers/colors';
import { ZOOM_SIZE } from 'shared/helpers/static';

export type FirstInputType = {
  phone?: string;
  email: string;
  first_name: string;
  last_name: string;
  subscribe: boolean;
  city: string;
  street: string;
  apartment: string;
  zipcode: string;
  state: string;
};

export type SecondInputTypes = {
  backgroundColor: string;
  callback: (args: unknown) => void;
  textColor: BookBaseColor;
  orderData: FirstInputType;
};

const Button = styled.button<{ backgroundColor: string; textColor: string; editable: boolean }>`
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => `color: ${props.textColor}`}
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
`;

const Form = styled.form`
  width: 100%;
`;

const LoadingWrapper = styled.div`
  margin-top: 25px;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: end;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    justify-content: center;
  `}
`;

const ZoomWrapper = styled.div`
  width: 100%;
  zoom: ${1 + (1 - ZOOM_SIZE + 0.1)};
  margin-bottom: 20px;
`;

export const Second: FC<SecondInputTypes> = (props) => {
  const { backgroundColor, callback, textColor, orderData } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { stripeErrorHandler, logError } = useErrorLogger();

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/dashboard'
        },
        redirect: 'if_required'
      });

      if (response.error) {
        stripeErrorHandler(response.error);
      } else {
        callback({});
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  const color = useMemo(() => {
    return tinycolor(backgroundColor).isDark() ? COLOR_WHITE : COLOR_BLACK;
  }, [backgroundColor]);

  return (
    <Container>
      <h3 style={{ color: filterBackgroundColor('#FFF', textColor) }}>It’s almost yours!</h3>
      <Form onSubmit={submitForm}>
        <ZoomWrapper>
          <AddressElement
            options={{
              mode: 'billing',
              display: {
                name: 'full'
              },
              defaultValues: {
                name: `${orderData.first_name || ''} ${orderData.last_name || ''}`,
                address: {
                  line1: orderData.street || '',
                  line2: orderData.apartment || '',
                  city: orderData.city || '',
                  state: orderData.state || '',
                  postal_code: orderData.zipcode || '',
                  country: 'US'
                }
              }
            }}
          />
        </ZoomWrapper>
        <ZoomWrapper>
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  name: `${orderData.first_name || ''} ${orderData.last_name || ''}`,
                  address: {
                    line1: orderData.street || '',
                    line2: orderData.apartment || '',
                    city: orderData.city || '',
                    state: orderData.state || '',
                    postal_code: orderData.zipcode || '',
                    country: 'US'
                  }
                }
              }
            }}
          />
        </ZoomWrapper>
        {loading ? (
          <LoadingWrapper>
            <LinearProgress color="inherit" />
          </LoadingWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              editable={false}
              textColor={color}
              type="submit"
              backgroundColor={backgroundColor}
              dangerouslySetInnerHTML={{
                __html: '<p>COMPLETE PAYMENT >></p>'
              }}
            />
          </ButtonWrapper>
        )}
      </Form>
    </Container>
  );
};
