import type { Dayjs } from 'dayjs';
import type { EventDetails, PageData } from 'shared/types/Sales';

import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { COLOR_BLACK, COLOR_MEDIUM_GRAY, COLOR_ORANGE } from 'shared/helpers/colors';
import { removeAllTags, truncateUrlToTLD, validateUrl } from 'shared/helpers/utils';
import useAlert from 'shared/hooks/useAlert';

import { FlexBox, NavButton } from '../Common';

const MainInner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .MuiInputBase-formControl {
    font-size: 14px !important;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    overflow: auto;
  }
`;

const Container = styled.div`
  color: ${COLOR_BLACK};
  padding: 10px 56px 66px;
  position: relative;
  z-index: 2;
  background: #fff;
  width: 860px;
  border-radius: 10px;

  @media screen and (max-width: 991px) {
    width: 768px;
    padding: 10px 56px 56px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 10px 5% 36px;
  }
`;

const Title = styled.h3`
  font-size: 28px;
  font-weight: 700;
  margin: 26px 0 8px;
  color: ${COLOR_BLACK};

  @media screen and (max-width: 991px) {
    margin: 26px 0 8px;
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 38px;
  font-size: 17px;

  @media screen and (max-width: 991px) {
    margin-bottom: 36px;
  }
`;

const CloseBtn = styled.div`
  width: 26px;
  height: 26px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

const WrapperInput = styled.div`
  position: relative;
  width: 100%;

  span {
    color: #ff5d63;
    font-size: 14px;
  }

  label {
    position: absolute;
    background: linear-gradient(180deg, #fff 70%, rgba(255, 255, 255, 0) 50%);
    top: -10px;
    left: 10px;
    padding: 0 24px 0 10px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 4;

    span {
      color: #ff5d63;
      padding-left: 5px;
    }
  }

  .MuiStack-root {
    padding-top: 0;

    input {
      font-size: 17px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
`;

const Input = styled.input`
  display: flex;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  padding: 0 20px;
  background: #fcfcfc;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 17px;
  color: ${COLOR_BLACK};
  border: 1px solid #c8c8c8;

  &::placeholder {
    color: #a8a8a8;
  }

  @media screen and (max-width: 1200px) {
    height: 46px;
  }
`;

const FlexBoxStyled = styled(FlexBox)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Select = styled.select`
  width: 100%;
  height: 52px;
  border-radius: 10px;
  padding: 0 20px;
  background: #fcfcfc;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 17px;
  border: 1px solid #c8c8c8;
  appearance: none;
  color: ${COLOR_BLACK};
`;

const Option = styled.option`
  padding: 8px;
`;

const Arrow = styled.div`
  position: absolute;
  top: 40%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  width: 19px;
  height: 9px;
  z-index: 2;
`;

const TypeEvent = styled.div`
  display: flex;
  gap: 20px;
  margin-top: -10px;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const TypeWrapper = styled.div`
  display: flex;
  gap: 24px;

  @media screen and (max-width: 380px) {
    flex-direction: column;
    gap: 7px;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: ${COLOR_BLACK};
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  cursor: pointer;

  &:checked + span {
    background-color: ${COLOR_ORANGE};
    border-color: ${COLOR_ORANGE};
  }

  &:checked + span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    background: url('/img/check_form.svg') no-repeat center center;
    background-size: contain;
    transform: translate(-50%, -50%);
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
`;

const CheckboxCustom = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
  cursor: pointer;

  &:checked + span {
    background-color: ${COLOR_ORANGE};
    border-color: ${COLOR_ORANGE};
  }
`;

const RadioCustom = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  background-color: ${COLOR_MEDIUM_GRAY};
  transition: all 0.3s ease;
`;

const NavButtonStyled = styled(NavButton)`
  margin-left: auto;
  display: block;
  padding: 1.8rem 7rem;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 700;
  font-size: 17px;

  @media screen and (max-width: 991px) {
    padding: 2.5rem 7rem;
    width: 100%;
    font-size: 16px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 20%;
  z-index: 1;
  position: absolute;
  top: 0;
`;

interface EventFormModalProps {
  open: boolean;
  onClose: () => void;
  salesPageValues: PageData | null;
  deliveryPageValues: PageData | null;
  updateInitialWebsite: (type: 'sales' | 'delivery', data: PageData) => void;
  setIsEventFormModalOpen: (isOpen: boolean) => void;
  eventDetails: EventDetails;
}

const EventFormModal = (props: EventFormModalProps) => {
  const {
    open,
    onClose,
    salesPageValues,
    deliveryPageValues,
    updateInitialWebsite,
    setIsEventFormModalOpen,
    eventDetails
  } = props;
  const { addAlert } = useAlert();
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    eventName: removeAllTags(eventDetails?.eventName) || 'Birthday Party!',
    eventUrl: eventDetails?.eventUrl || '',
    calendarUrl: eventDetails?.calendarUrl || '',
    zoomPassword: eventDetails?.zoomPassword || '',
    peopleLimit: eventDetails?.peopleLimit || 0
  });

  const [date, setDate] = useState<Dayjs | null>(
    eventDetails?.date ? dayjs(eventDetails.date, 'MM/DD/YYYY') : dayjs(new Date())
  );
  const [time, setTime] = useState<Dayjs | null>(
    eventDetails?.time
      ? dayjs(`${dayjs().format('MM/DD/YYYY')} ${eventDetails.time}`, 'MM/DD/YYYY hh:mm A')
      : dayjs(new Date())
  );

  //select
  const [timezone, setTimezone] = useState<string>(eventDetails?.timezone || 'CDT');

  //checkbox and type popUp
  const [isChecked, setIsChecked] = useState<boolean>(eventDetails?.isRecurring || false);
  const [isNoTimeChecked, setIsNoTimeChecked] = useState<boolean>(eventDetails?.noDates || false);
  const [selectedTypeValue, setSelectedTypeValue] = useState(
    eventDetails?.recurringType || 'Weekly'
  );

  const fillEventValues = (type: 'sales' | 'delivery') => {
    const salesData = type === 'sales' ? salesPageValues : deliveryPageValues;
    if (!salesData || typeof salesData['event_1'] !== 'object') return;

    let navigationBenefit = salesData['event_1']?.benefits?.[2]?.content?.val || '';
    if (type === 'delivery') {
      const urlText = truncateUrlToTLD(inputs.eventUrl);
      const zoomPassword = inputs.zoomPassword ? `Password: ${inputs.zoomPassword}` : '';
      navigationBenefit = `<span><a href="${inputs.eventUrl}" rel="noopener noreferrer" target="_blank">${urlText}...</a> </br> ${zoomPassword}</span>`;
    }

    const event = {
      ...salesData['event_1'],
      title: {
        ...salesData['event_1'].title,
        val: inputs.eventName
      },
      benefits: [
        {
          ...salesData['event_1'].benefits?.[0],
          title: {
            ...salesData['event_1'].benefits?.[0].title,
            val: 'Date:'
          },
          content: {
            ...salesData['event_1'].benefits?.[0].content,
            val: isNoTimeChecked ? 'Instant Access' : dayjs(date).format('MM/DD/YYYY')
          }
        },
        {
          ...salesData['event_1'].benefits?.[1],
          title: {
            ...salesData['event_1'].benefits?.[1].title,
            val: 'Time:'
          },
          content: {
            ...salesData['event_1'].benefits?.[1].content,
            val: isNoTimeChecked
              ? 'Instant Access'
              : `${dayjs(time).format('hh:mm A')}   ${timezone}`
          }
        },
        {
          ...salesData['event_1'].benefits?.[2],
          title: {
            ...salesData['event_1'].benefits?.[2].title,
            val: 'Place:'
          },
          content: {
            ...salesData['event_1'].benefits?.[2].content,
            val: navigationBenefit
          }
        }
      ],
      meta: {
        ...salesData['event_1'].meta,
        ...(inputs.eventUrl && {
          eventUrl: inputs.eventUrl
        }),
        ...(inputs.calendarUrl && {
          calendarUrl: inputs.calendarUrl
        }),
        ...(inputs.zoomPassword && {
          zoomPassword: inputs.zoomPassword
        }),
        ...(inputs.peopleLimit && {
          peopleLimit: inputs.peopleLimit
        }),
        date: dayjs(date).format('MM/DD/YYYY'),
        time: dayjs(time).format('hh:mm A'),
        timezone: timezone,
        isRecurring: isChecked,
        recurringType: selectedTypeValue,
        noDates: isNoTimeChecked
      }
    };
    return event;
  };

  const handleUpdateEvent = () => {
    if (!validateUrl(inputs.eventUrl)) {
      addAlert(t('utils.alerts.validEventURL'), 'error');
      return;
    }
    if (inputs.calendarUrl && !validateUrl(inputs.calendarUrl)) {
      addAlert(t('utils.alerts.validCalendarURL'), 'error');
      return;
    }
    const salesEvent = fillEventValues('sales');
    const deliveryEvent = fillEventValues('delivery');
    updateInitialWebsite('sales', {
      ...(salesPageValues as PageData),
      event_1: salesEvent
    });
    updateInitialWebsite('delivery', {
      ...(deliveryPageValues as PageData),
      event_1: deliveryEvent
    });
    setIsEventFormModalOpen(false);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value
    }));
  };

  const handleRadioType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTypeValue(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <MainInner>
        <RelativeWrapper>
          <Container>
            <Title>{t('events.title')}</Title>
            <Subtitle>{t('events.description')}</Subtitle>
            <CloseBtn onClick={onClose}>
              <img src={'/img/close_link.svg'} alt="X" />
            </CloseBtn>
            <FormInner>
              <WrapperInput>
                <label>{t('events.eventName')}</label>
                <Input
                  name="eventName"
                  value={inputs.eventName}
                  onChange={handleInput}
                  type="text"
                  placeholder={t('events.yourEvent')}
                />
              </WrapperInput>
              <FlexBoxStyled gap={15}>
                <WrapperInput>
                  <label>{t('events.eventDate')}</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker value={date} onChange={setDate} />
                    </DemoContainer>
                  </LocalizationProvider>
                </WrapperInput>
                <WrapperInput>
                  <label>{t('events.eventTime')}</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker value={time} onChange={setTime} />
                    </DemoContainer>
                  </LocalizationProvider>
                </WrapperInput>
                <WrapperInput
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end'
                  }}>
                  <label>{t('events.timezone')}</label>
                  <Select value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                    <Option value="CDT">{t('events.timezones.cdt')}</Option>
                    <Option value="EDT">{t('events.timezones.edt')}</Option>
                    <Option value="MDT">{t('events.timezones.mdt')}</Option>
                    <Option value="PDT">{t('events.timezones.pdt')}</Option>
                  </Select>
                  <Arrow>
                    <img src={'/img/arrowDownEvent.svg'} alt=">" />
                  </Arrow>
                </WrapperInput>
              </FlexBoxStyled>
              <TypeEvent>
                <CheckboxLabel>
                  <CheckboxInput
                    checked={isNoTimeChecked}
                    onChange={(e) => setIsNoTimeChecked(e.target.checked)}
                  />
                  <CheckboxCustom />
                  {t('events.noDateOnDemand')}
                </CheckboxLabel>
                {!isNoTimeChecked ? (
                  <CheckboxLabel>
                    <CheckboxInput
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <CheckboxCustom />
                    {t('events.recurringEvent')}
                  </CheckboxLabel>
                ) : null}
                {!isNoTimeChecked && (
                  <TypeWrapper>
                    <RadioLabel>
                      <RadioInput
                        name="typeEvent"
                        value="Weekly"
                        checked={selectedTypeValue === 'Weekly'}
                        onChange={handleRadioType}
                      />
                      <RadioCustom />
                      {t('common.weekly')}
                    </RadioLabel>
                    <RadioLabel>
                      <RadioInput
                        name="typeEvent"
                        value="Monthly"
                        checked={selectedTypeValue === 'Monthly'}
                        onChange={handleRadioType}
                      />
                      <RadioCustom />
                      {t('common.yearly')}
                    </RadioLabel>
                  </TypeWrapper>
                )}
              </TypeEvent>
              <WrapperInput>
                <label>{t('events.eventURL')}</label>
                <Input
                  name="eventUrl"
                  value={inputs.eventUrl}
                  onChange={handleInput}
                  type="text"
                  placeholder={t('events.eventDescription')}
                />
              </WrapperInput>
              <WrapperInput>
                <label>{t('events.calendarURL')}</label>
                <Input
                  name="calendarUrl"
                  value={inputs.calendarUrl}
                  onChange={handleInput}
                  type="text"
                  placeholder={t('events.calendarDescription')}
                />
              </WrapperInput>
            </FormInner>
            <FlexBox justifyContent="center" alignItems="center" mt="34px" gap={20}>
              <WrapperInput style={{ width: '70%' }}>
                <label>
                  {t('events.zoomPassword')} ({t('common.optional')})
                </label>
                <Input
                  name="zoomPassword"
                  value={inputs.zoomPassword}
                  onChange={handleInput}
                  type="text"
                  placeholder={t('events.typeZoomPassword')}
                />
              </WrapperInput>
              <WrapperInput style={{ width: '50%' }}>
                <label> ({t('common.optional')})</label>
                <Input
                  name="peopleLimit"
                  value={inputs.peopleLimit === 0 ? '' : inputs.peopleLimit}
                  onChange={handleInput}
                  type="text"
                  placeholder={t('common.limit')}
                />
              </WrapperInput>
              <NavButtonStyled
                onClick={handleUpdateEvent}
                backgroundColor={COLOR_ORANGE}
                color={COLOR_BLACK}
                style={{
                  width: '300px',
                  padding: '1rem 10px'
                }}
                content={t('events.saveDetails')}
              />
            </FlexBox>
          </Container>
          <Overlay onClick={onClose} />
        </RelativeWrapper>
      </MainInner>
    </Modal>
  );
};

export default EventFormModal;
