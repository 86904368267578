import type { FC } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';

import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { FlexBox } from './FlexBox';

const LoaderBlock = styled(FlexBox)<{ css?: FlattenSimpleInterpolation }>`
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  align-items: center;
  flex-direction: column;
  top: 0;
  width: 100%;
  z-index: 999999;
  gap: 2rem;

  ${(props) => props.css}
`;

const Text = styled.h3`
  font-family: 'Plus Jakarta Sans', sans-serif;
`;

export interface PageLoaderProps {
  text?: string;
  css?: FlattenSimpleInterpolation;
}

const PageLoader: FC<PageLoaderProps> = ({ text, css }) => {
  return (
    <LoaderBlock css={css}>
      <CircularProgress color="inherit" />
      {text ? <Text>{text}</Text> : null}
    </LoaderBlock>
  );
};

export default PageLoader;
