import type { FC } from 'react';
import type { ComponentType, ItemProps } from './types';

import styled from 'styled-components';

import {
  COLOR_YELLOW_LIGHT,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

export const Container = styled.div<ComponentType>`
  width: ${(props) => (props.variant === 'v1' ? '31%' : '30%')};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { $width }, variant }) =>
    $width <= 991 &&
    `
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ${variant === 'v1' ? '40px' : '36px'};
    }
  `}
`;

export const DigitWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const Digit = styled.div<ComponentType>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  flex-shrink: 0;

  width: ${(props) => (props.variant === 'v1' ? '70px' : '35px')};
  height: ${(props) => (props.variant === 'v1' ? '70px' : '35px')};
  font-size: ${({ variant, theme: { $headlineFont, $paragraphFont } }) =>
    variant === 'v1' ? $headlineFont : $paragraphFont};
  margin-bottom: ${(props) => (props.variant === 'v1' ? '16px' : '0')};
  margin-right: ${(props) => (props.variant === 'v1' ? '0' : '24px')};

  ${({ theme: { $width }, variant }) =>
    $width <= 1254 &&
    variant === 'v1' &&
    `
    width: 60px;
    height: 60px;
  `}

  ${({ theme: { $width }, variant }) =>
    $width <= 991 &&
    variant === 'v2' &&
    `
    margin-right: 15px;
  `}
`;

export const Line = styled.div`
  width: 70%;
  height: 1px;
  border-bottom: 2px dashed;
  opacity: 0.4;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 80%;
  `}
`;

export const Text = styled.p<ComponentType>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  font-weight: 500;
  line-height: 1.4em;

  width: ${(props) => (props.variant === 'v1' ? '90%' : 'auto')};
  text-align: ${(props) => (props.variant === 'v1' ? 'center' : 'left')};
  margin-left: ${(props) => (props.variant === 'v2' ? '58px' : '0')};
  position: relative;

  ${({ theme: { $width }, variant }) =>
    $width <= 991 &&
    `
    width: ${variant === 'v1' ? '100%' : '75%'};
    margin-left: ${variant === 'v2' ? '48px' : '0'};
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    font-weight: 400;
  `}

  ${({ theme: { $width }, variant }) =>
    $width <= 768 &&
    variant === 'v2' &&
    `
    width: 100%;
    margin-left: 0;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
     content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const Item: FC<ItemProps> = (props) => {
  const {
    content = defaultWebValue,
    digit = 1,
    backgroundColor = COLOR_YELLOW_LIGHT,
    variant,
    textColor,
    meta,
    editable,
    isEmpty
  } = props;
  const { updateActiveElement, resetActiveElement, websiteAssembler } = useWebsiteEditor();

  if (variant === 'v2') {
    return (
      <Container
        variant={variant}
        style={{ display: !websiteAssembler && isEmpty ? 'none' : 'flex' }}>
        <DigitWrapper>
          <Digit
            style={{
              background: backgroundColor,
              color: filterBackgroundColor(backgroundColor, textColor)
            }}
            variant={variant}>
            {digit}
          </Digit>
          <Line style={{ borderColor: backgroundColor }} />
        </DigitWrapper>
        <Text
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          variant={variant}
          style={{ color: filterBackgroundColor('#fff', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-benefits={true}
          data-placeholder="+"
          editable={editable}
          isEmpty={isEmpty}
        />
      </Container>
    );
  }

  return (
    <Container
      variant={variant}
      style={{ display: !websiteAssembler && isEmpty ? 'none' : 'flex' }}>
      <Digit
        style={{
          background: backgroundColor,
          color: filterBackgroundColor(backgroundColor, textColor)
        }}
        variant={variant}>
        {digit}
      </Digit>
      <Text
        id={content.id}
        dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
        variant={variant}
        style={{ color: filterBackgroundColor('#fff', textColor) }}
        onClick={updateActiveElement}
        onBlur={resetActiveElement}
        data-accessor-parent={meta['accessorKey']}
        data-accessorkey="content"
        data-benefits={true}
        data-placeholder="+"
        editable={editable}
        isEmpty={isEmpty}
      />
    </Container>
  );
};
