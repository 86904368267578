import { useCallback, useMemo } from 'react';
import { sanitizeRichText } from '../helpers';
import useSales from './useSales';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useIsEmpty = (meta: Record<string, any>, field: 'title' | 'content', benefit = false) => {
  const { deliveryPageValues, salesPageValues, type } = useSales();

  const getIsEmpty = useCallback(
    (index?: number) => {
      if (!salesPageValues || !deliveryPageValues) return false;
      const typeValue = type === 'sales' ? salesPageValues : deliveryPageValues;
      const key = meta['accessorKey'];
      if (key in typeValue) {
        const value = typeValue[key];
        if (typeof value === 'object') {
          if (benefit && (index === 0 || index)) {
            const benefits = value.benefits?.[index];
            if (benefits) return !sanitizeRichText(benefits[field]?.val, true, true);
            return false;
          }
          return !sanitizeRichText(value[field]?.val, true, true);
        }
      }
      return false;
    },
    [benefit, deliveryPageValues, field, meta, salesPageValues, type]
  );

  const isEmpty = useMemo(() => getIsEmpty(), [getIsEmpty]);

  const checkBenefits = useCallback((index: number) => getIsEmpty(index), [getIsEmpty]);

  return { checkBenefits, isEmpty };
};

export default useIsEmpty;
