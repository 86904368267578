import type { FC } from 'react';
import type { ImageProps } from './types';

import styled from 'styled-components';

const ImageInner = styled.div`
  width: 537px;
  height: 474px;
  position: relative;
  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 47%;
    height: 400px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    height: 380px;
    margin-bottom: 20px;
    `}

    ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    `}


  & div:nth-child(1) {
    width: 344px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: 94px;
    z-index: 8;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
    width: 284px;
    height: 330px;
    `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
    left: 0;
    right: 0;
    margin: 0 auto;
    right: -14%;
    `}


    ${({ theme: { $width } }) =>
      $width <= 375 &&
      `
    width: 257px;
    height: 300px;
    `}




    & div {
      position: relative;
      width: 100%;
      height: 100%;

      & img:nth-child(1) {
        position: absolute;
        height: 86%;
        z-index: 3;
        object-fit: cover;
        object-position: center center;
        top: 4%;
        left: 5.3%;
      }

      & img:nth-child(2) {
        position: absolute;
        height: 100%;
        z-index: 1;
        object-fit: contain;
      }
    }
  }

  & div:nth-child(2) {
    & div {
      width: 328px;
      height: 385px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      top: -4%;
      z-index: 7;

      ${({ theme: { $width } }) =>
        $width <= 1254 &&
        `
      width: 256px;
      height: 305px;
    `}

      ${({ theme: { $width } }) =>
        $width <= 375 &&
        `
      width: 226px;
      height: 278px;
      right: 2%;
    `}


    & img:nth-child(1) {
        position: absolute;
        width: 80%;
        height: 89%;
        z-index: 3;
        object-fit: cover;
        object-position: center center;
        top: 5%;
        left: 10%;
        transform: rotate(9.6deg);
        object-position: center top;
      }

      & img:nth-child(2) {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: contain;
      }
      & div {
        position: relative;
        width: 100%;
        height: 100%;

        & img:nth-child(1) {
          position: absolute;
          width: 80%;
          height: 89%;
          z-index: 3;
          object-fit: cover;
          object-position: center center;
          top: 5%;
          left: 10%;
          transform: rotate(9.6deg);
          object-position: center top;
        }

        & img:nth-child(2) {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          object-fit: contain;
        }
      }
    }
  }

  & div:nth-child(3) {
    width: 328px;
    height: 385px;
    position: absolute;
    bottom: 0px;
    left: 5%;
    top: -60px;
    z-index: 6;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
      width: 278px;
      height: 324px;
    `}

    ${({ theme: { $width } }) =>
      $width <= 375 &&
      `
      width: 240px;
      height: 278px;
    `}

    & div {
      position: relative;
      width: 100%;
      height: 100%;

      & img:nth-child(1) {
        position: absolute;
        width: 76%;
        z-index: 3;
        object-fit: cover;
        object-position: center center;
        top: 7%;
        left: 12%;
        transform: rotate(346deg);
        object-position: center top;
      }

      & img:nth-child(2) {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: contain;
      }
    }
  }

  & div:nth-child(4) {
    width: 270px;
    height: 324px;
    position: absolute;
    bottom: 0px;
    left: 30%;
    top: -70px;
    z-index: 5;
    transform: rotate(8deg);

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
      width: 226px;
      height: 272px;
    `}

    ${({ theme: { $width } }) =>
      $width <= 768 &&
      `
      left: auto;
    right: 7%;
    top: -30px;
    `}

    ${({ theme: { $width } }) =>
      $width <= 375 &&
      `
      width: 233px;
      height: 278px;
    `}

    & div {
      position: relative;
      width: 100%;
      height: 100%;

      & img:nth-child(1) {
        position: absolute;
        width: 88%;
        height: 95.5%;
        z-index: 3;
        object-fit: cover;
        object-position: center center;
        top: 1.4%;
        left: 6%;
        transform: rotate(2deg);
        object-position: center top;
      }

      & img:nth-child(2) {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: contain;
      }
    }
  }
`;

const StyledImage = styled.img`
  border: 1px solid #cacaca;
`;

export const Image: FC<ImageProps> = (props) => {
  const { images, className } = props;

  return (
    <ImageInner className={className}>
      {images.filter(Boolean).map((image, index) => (
        <div key={index}>
          <div>
            <StyledImage src={image} width="auto" />
          </div>
        </div>
      ))}
    </ImageInner>
  );
};
