import type { FC } from 'react';
import type { VideoProps } from './types';

import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import { HoverableInput } from '../HoverableInput';

const Container = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const playerStyles = css`
  width: 100% !important;
  height: 720px !important;
  border: none;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    height: 580px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 380px !important;
  `}

  & div, & .exampleImg {
    width: 100% !important;
    width: 1280px !important;
    height: 720px !important;
    border: none;
    position: relative;

    ${({ theme: { $width } }) =>
      $width <= 1300 &&
      `
      width: 100% !important;
  `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
      height: 100% !important;
  `}
  }
`;

export const V5: FC<VideoProps> = (props) => {
  const { meta = {}, hidden } = props;
  const {
    editable,
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    websiteAssembler
  } = useWebsiteEditor();

  return (
    <Container
      id="no-editor-element"
      className={containerClassname}
      data-accessor-parent={meta['accessorKey']}
      onClick={updateActiveElement}
      onBlur={resetActiveElement}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <HoverableInput disabled={!editable} css={playerStyles as never} meta={meta} index={1}>
          {meta['url_1'] ? (
            <ReactPlayer url={meta['url_1']} controls={true} />
          ) : (
            <img className="exampleImg" src="/img/example_video.png" />
          )}
        </HoverableInput>
      </Wrapper>
    </Container>
  );
};
