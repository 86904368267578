import type { BookIngredients, BookPages, ColorPalette } from '../types';

import { useCallback, useMemo } from 'react';

import { convertColorsToPallete, ApiService } from 'shared/helpers/index';
import useBranding from 'shared/hooks/useBranding';

/**
 * @function useRenderImage
 *
 * @description This hook is used to render images based on it's ingredient data and
 * branding colors and fonts. It has 3 retries by default in case of failure.
 */
const useRenderImage = () => {
  const { colorPalette, font, fontStyle, bookBaseColor, bookBaseColorCollection } = useBranding();

  const categorizedColorPalette = useMemo(() => {
    return convertColorsToPallete(colorPalette);
  }, [colorPalette]);

  const globalVariables = () => {
    return {
      color_palette: {
        background_color1: categorizedColorPalette.backgroundColors?.[0]?.value,
        background_color2: categorizedColorPalette.backgroundColors?.[1]?.value,
        background_color3: categorizedColorPalette.backgroundColors?.[2]?.value,
        background_color4: categorizedColorPalette.backgroundColors?.[3]?.value,
        base_color1: bookBaseColorCollection?.[0]?.value,
        base_color2: bookBaseColorCollection?.[1]?.value,
        base_color3: bookBaseColorCollection?.[2]?.value,
        base_color4: bookBaseColorCollection?.[3]?.value,
        color1: categorizedColorPalette.mainColors?.[0]?.value,
        color2: categorizedColorPalette.mainColors?.[1]?.value,
        color3: categorizedColorPalette.mainColors?.[2]?.value,
        color4: categorizedColorPalette.mainColors?.[3]?.value,
        color5: categorizedColorPalette.mainColors?.[4]?.value
      },
      base_color: bookBaseColor.value,
      headline_style: fontStyle.name,
      font: font.id
    };
  };

  const convertColorToKeys = (color: string) => {
    const mapping = Object.entries(colorPalette).reduce(
      (acc: Partial<{ [key: string]: unknown }>, [key, value]) => {
        acc[String(value).toLowerCase()] = key;
        return acc;
      },
      {}
    );
    const colorsMapping = new Map(Object.entries(mapping));

    if (colorsMapping.has(color.toLowerCase())) {
      return colorsMapping.get(color.toLowerCase());
    }
    return 'base_color3';
  };

  const convertPageShapeForRender = (page: BookPages) => {
    const updatedIngredients = page.ingredients.map((ingredient) => ({
      ...ingredient,
      page_ingredient: ingredient.page_ingredient || ingredient.id
    }));

    const removeRedundantColors = updatedIngredients.map((ingredient) => {
      if (ingredient.ingredient_data) {
        const filteredingredientData = Object.keys(ingredient.ingredient_data)
          .filter((key) => !key.startsWith('color__') || key.startsWith('color_key'))
          .reduce((result: Partial<{ [key: string]: unknown }>, key) => {
            if (key.includes('color_key') && ingredient.ingredient_data[key].includes('#')) {
              result[key] = convertColorToKeys(ingredient.ingredient_data[key]);
            } else {
              result[key] = ingredient.ingredient_data[key];
            }
            return result;
          }, {});

        const baseColorKey = Object.keys(colorPalette ?? {}).find(
          (key) => bookBaseColor.value === colorPalette[key]
        );
        const resultIngredientData = { ...filteredingredientData };
        Object.keys(filteredingredientData).forEach((key) => {
          if (key.includes('color_key') && filteredingredientData[key] === 'base_color') {
            resultIngredientData[key] = baseColorKey;
          }
        });
        return {
          ...ingredient,
          ingredient_data: resultIngredientData
        };
      }
      return ingredient;
    });

    return {
      ...page,
      ingredients: removeRedundantColors
    };
  };

  const convertPagesShapeForRender = (pages: BookPages[]) => {
    const updatedPages = pages.map((page) => {
      if (page.bookpage_type === 'pdf') {
        return page;
      }
      return convertPageShapeForRender(page);
    });

    return updatedPages;
  };

  const renderImage = async (
    pageId: number | string | undefined,
    ingerdientData: unknown,
    includeGlobalVars = true,
    extendGlobalVars = {}
  ) => {
    const color_palette = {} as Record<string, string>;

    if (
      categorizedColorPalette?.backgroundColors &&
      categorizedColorPalette?.backgroundColors?.length
    ) {
      categorizedColorPalette.backgroundColors?.map((bg, i) => {
        color_palette[`background_color${i + 1}`] = bg?.value;
        return null;
      });
    }

    if (categorizedColorPalette?.mainColors && categorizedColorPalette?.mainColors?.length) {
      categorizedColorPalette.mainColors?.map((c, i) => {
        color_palette[`color${i + 1}`] = c?.value;
        return null;
      });
    }

    if (bookBaseColorCollection && bookBaseColorCollection?.length) {
      bookBaseColorCollection?.map((b, i) => {
        color_palette[`base_color${i + 1}`] = b?.value;
        return null;
      });
    }

    const globalVars = {
      color_palette,
      base_color: bookBaseColor?.value,
      headline_style: fontStyle?.name,
      font: font?.id
    };
    const data = {
      ...(includeGlobalVars && {
        global_vars: { ...globalVars, ...extendGlobalVars }
      }),
      page_ingredients: ingerdientData
    };
    return await ApiService.post(`/book-generator/pages/${pageId}/render-image/`, data, {
      'axios-retry': {
        retries: 3
      }
    });
  };

  const renderHTML = async (
    bookId: number | string | undefined,
    pageId: number | undefined,
    ingerdientData: BookIngredients[] | undefined = [],
    bookPageId: number | undefined
  ) => {
    const color_palette: Partial<ColorPalette> = {};

    if (
      categorizedColorPalette?.backgroundColors &&
      categorizedColorPalette?.backgroundColors?.length
    ) {
      categorizedColorPalette.backgroundColors?.map((bg, i) => {
        color_palette[`background_color${i + 1}`] = bg?.value;
        return null;
      });
    }

    if (categorizedColorPalette?.mainColors && categorizedColorPalette?.mainColors?.length) {
      categorizedColorPalette.mainColors?.map((c, i) => {
        color_palette[`color${i + 1}`] = c?.value;
        return null;
      });
    }

    if (bookBaseColorCollection && bookBaseColorCollection?.length) {
      bookBaseColorCollection?.map((b, i) => {
        color_palette[`base_color${i + 1}`] = b?.value;
        return null;
      });
    }

    ingerdientData.forEach((item) => {
      const keys = Object.keys(item.ingredient_data || {});
      keys.forEach((key) => {
        if (key.includes('media__') && !key.includes('_xx_')) {
          delete item.ingredient_data[key];
        }
      });
    });

    const globalVars = {
      color_palette,
      base_color: bookBaseColor?.value,
      headline_style: fontStyle?.name,
      font: font?.id
    };

    const data = {
      id: bookId,
      ...(!bookPageId && { page: pageId }),
      ...(bookPageId && { book_page: bookPageId }),
      page_ingredients: ingerdientData,
      global_vars: globalVars,
      set_order: true
    };

    return await ApiService.post(`/book-generator/books/${bookId}/render-preview-html/`, data, {
      'axios-retry': {
        retries: 3
      }
    });
  };

  const renderPreviewImageCallback = useCallback(
    async (
      bookId: string | number | undefined,
      pageId: number | undefined,
      ingredientData: BookIngredients[],
      bookPageId: number | undefined
    ) => {
      const color_palette: Partial<ColorPalette> = {};

      if (
        categorizedColorPalette.backgroundColors &&
        categorizedColorPalette.backgroundColors.length
      ) {
        categorizedColorPalette.backgroundColors.map((bg, i) => {
          color_palette[`background_color${i + 1}`] = bg?.value;
          return null;
        });
      }

      if (categorizedColorPalette.mainColors && categorizedColorPalette.mainColors.length) {
        categorizedColorPalette.mainColors.map((c, i) => {
          color_palette[`color${i + 1}`] = c?.value;
          return null;
        });
      }

      if (bookBaseColorCollection && bookBaseColorCollection.length) {
        bookBaseColorCollection.map((b, i) => {
          color_palette[`base_color${i + 1}`] = b?.value;
          return null;
        });
      }

      // Remove All media__ variables from ingredient_data
      ingredientData.forEach((item) => {
        const keys = Object.keys(item.ingredient_data || {});
        keys.forEach((key) => {
          if (key.includes('media__') && !key.includes('_xx_')) {
            delete item.ingredient_data[key];
          }
        });
      });

      const globalVars = {
        color_palette,
        base_color: bookBaseColor?.value,
        headline_style: fontStyle?.name,
        font: font?.id
      };

      const data = {
        id: bookId,
        ...(!bookPageId && { page: pageId }),
        ...(bookPageId && { book_page: bookPageId }),
        page_ingredients: ingredientData,
        global_vars: globalVars,
        set_order: true
      };

      return await ApiService.post(`/book-generator/books/${bookId}/render-preview/`, data, {
        'axios-retry': {
          retries: 2
        }
      });
    },
    [categorizedColorPalette, bookBaseColorCollection, bookBaseColor, fontStyle, font]
  );

  return {
    renderImage,
    renderPreviewImageCallback,
    renderHTML,
    convertPageShapeForRender,
    convertPagesShapeForRender,
    globalVariables
  };
};

export default useRenderImage;
