import type { FlattenSimpleInterpolation } from 'styled-components';
import type { DialogProps } from '@radix-ui/react-dialog';

import { forwardRef } from 'react';
import styled from 'styled-components';

import { Overlay, Root, Portal, Content, Close } from '@radix-ui/react-dialog';
import { Cross1Icon } from '@radix-ui/react-icons';

const StyledRoot = styled(Root)`
  button,
  fieldset,
  input {
    all: unset;
  }
`;

const StyledOverlay = styled(Overlay)`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 99997;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0.5;
`;

const StyledContent = styled(Content)<{ css?: FlattenSimpleInterpolation }>`
  z-index: 99999;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  height: 54%;
  width: 55%;

  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1500px) {
    width: 60%;
  }

  @media only screen and (max-width: 1300px) {
    width: 75%;
  }

  @media only screen and (max-width: 1000px) {
    width: 90%;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 10px;
  }

  @media only screen and (max-height: 1100px) {
    height: 80%;
  }

  &:focus {
    outline: none;
  }

  ${(props) => props.css}
`;

const StyledClose = styled(Close)`
  align-self: end;
  cursor: pointer;
`;

interface Props extends DialogProps {
  cssContent?: FlattenSimpleInterpolation;
}

export const Modal = forwardRef<HTMLDivElement, Props>(({ children, ...props }, forwardedRef) => {
  const { cssContent } = props;

  return (
    <StyledRoot {...props}>
      <Portal>
        <StyledOverlay />
        <StyledContent css={cssContent} ref={forwardedRef}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StyledClose aria-label="Close" asChild>
              <Cross1Icon />
            </StyledClose>
          </div>
          {children}
        </StyledContent>
      </Portal>
    </StyledRoot>
  );
});

Modal.displayName = 'Modal';
