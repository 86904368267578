import type { FC } from 'react';
import type { Crop } from 'react-image-crop';
import type { CropResult } from 'shared/types/index';

import { useState, useRef, useMemo } from 'react';
import { Component as ReactCrop } from 'react-image-crop';
import styled from 'styled-components';

import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import 'react-image-crop/dist/ReactCrop.css';

import { COLOR_ORANGE } from 'shared/helpers/colors';
import { convertBase64ToFile } from 'shared/helpers/image';

import { FlexBox, NavButton } from '../Common';

const Container = styled.div`
  background-color: white;
  width: 60%;
  border-radius: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  gap: 4rem;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const ReactCropStyled = styled(ReactCrop)`
  max-height: 70rem;
`;

export interface ImageCropperProps {
  open: boolean;
  file?: string;
  closeText: string;
  cropText: string;
  onClose: () => void;
  mediaVariable?: string;
  handleOnCrop?: (value: CropResult, mediaVariable?: string) => void;
}

export const ImageCropper: FC<ImageCropperProps> = ({
  open,
  file,
  onClose,
  handleOnCrop,
  mediaVariable,
  cropText,
  closeText
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState('');

  const handleCrop = async () => {
    const fileInstance = await convertBase64ToFile(croppedImageUrl, 'croppedImage.png');
    const payload = {
      file: fileInstance,
      base64: croppedImageUrl
    };
    if (mediaVariable) {
      handleOnCrop?.(payload, mediaVariable);
    } else {
      handleOnCrop?.(payload);
    }
  };

  const fileItem = useMemo(() => {
    let endpoint = file;
    if (file?.includes('base64')) return file;
    if (process.env['API_URL']?.includes('localhost')) {
      endpoint = `http://localhost:8000/${endpoint}`;
    }
    return endpoint;
  }, [file]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCroppedImg = (image: any, c: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;

    canvas.width = c.width;
    canvas.height = c.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      c.x * scaleX,
      c.y * scaleY,
      c.width * scaleX,
      c.height * scaleY,
      0,
      0,
      c.width,
      c.height
    );

    return canvas.toDataURL('image/png');
  };

  const onCropComplete = (c: Crop) => {
    if (imgRef.current && c.width && c.height) {
      const url = getCroppedImg(imgRef.current, c);
      setCroppedImageUrl(url);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <FlexBox justifyContent="flex-end" position="absolute" left="97%" top="1%" zIndex={5}>
          <CloseIcon style={{ fontSize: '1.5em', cursor: 'pointer' }} onClick={onClose} />
        </FlexBox>
        <FlexBox flexDirection="column" gap={32}>
          <ReactCropStyled
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={onCropComplete}
            // aspect={16 / 9}
            maxWidth={5000}
            maxHeight={5000}
            minWidth={200}
            minHeight={200}>
            <img ref={imgRef} alt="Crop me" src={fileItem} crossOrigin="anonymous" />
          </ReactCropStyled>
          <FlexBox justifyContent="space-evenly" gap={25}>
            <NavButton
              id="pw-cancel-crop"
              style={{
                padding: '1.5rem',
                fontSize: '1.8rem',
                fontWeight: 600,
                flex: '0.8'
              }}
              content={closeText}
              onClick={onClose}
            />
            <NavButton
              id="pw-confirm-crop"
              style={{
                padding: '1.5rem',
                fontSize: '1.8rem',
                fontWeight: 600,
                flex: '0.8'
              }}
              backgroundColor={COLOR_ORANGE}
              content={cropText}
              onClick={handleCrop}
            />
          </FlexBox>
        </FlexBox>
      </Container>
    </Modal>
  );
};
