import type { FC, Ref } from 'react';
import type { AlertProps } from '@mui/material';

import { forwardRef } from 'react';
import { Alert as MuiAlert, Snackbar } from '@mui/material';

interface MessageSnackbarProps {
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  open: boolean;
}

const Alert = forwardRef(function Alert(props: AlertProps, ref: Ref<HTMLDivElement>) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage: FC<MessageSnackbarProps> = ({ message, severity, open }) => {
  const backgroundMapping = {
    success: '#1caeb2',
    error: '#ff5d63',
    warning: '#ffc107'
  };
  return (
    <Snackbar
      open={open}
      style={{
        zIndex: 9999999
      }}>
      <Alert
        severity={severity}
        sx={{
          width: '100%',
          fontSize: '1.6rem !important',
          backgroundColor: Object.keys(backgroundMapping).includes(
            severity as keyof typeof backgroundMapping
          )
            ? backgroundMapping[severity as keyof typeof backgroundMapping]
            : ''
        }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
