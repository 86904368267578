/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useLayoutEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isHotkey from 'is-hotkey';
// import { formatExec } from '../helpers';

// moved this function here because the formatExec is no longer required
// it used to convert <em> tags to <strong> tags
export const formatExec = (element: Element) => {
  const selection = window.getSelection();
  if (!selection) return; // Ensure there's a selection
  if (selection?.rangeCount === 0) return; // Ensure there's a selection

  // Save selection positions
  const range = selection.getRangeAt(0);
  const startContainer = range.startContainer,
    startOffset = range.startOffset;
  const endContainer = range.endContainer,
    endOffset = range.endOffset;

  // Perform content transformation
  const bElements = element.querySelectorAll('b');
  bElements.forEach((bElement: Node) => {
    const strongElement = document.createElement('strong');
    while (bElement.firstChild) {
      strongElement.appendChild(bElement.firstChild);
    }
    if (bElement.parentNode) bElement.parentNode.replaceChild(strongElement, bElement);
  });

  // Restore the selection
  const newRange = document.createRange();
  newRange.setStart(startContainer, startOffset);
  newRange.setEnd(endContainer, endOffset);
  selection.removeAllRanges();
  selection.addRange(newRange);
};

export interface Offset {
  start: number;
  end: number;
}
export interface Position {
  position: number;
  end: number;
  extent: number;
  content: string;
  line: number;
  range: Range;
  offset: Offset;
  extend: number;
  ignoreReset?: boolean;
}

type History = [Position, string];

const observerSettings = {
  characterData: true,
  characterDataOldValue: true,
  childList: true,
  subtree: true
};

const getCurrentRange = () => window.getSelection()!.getRangeAt(0);

const setCurrentRange = (range: Range) => {
  const selection = window.getSelection()!;
  selection.empty();
  selection.addRange(range);
};

const isUndoRedoKey = (event: KeyboardEvent): boolean =>
  (event.metaKey || event.ctrlKey) && !event.altKey && event.code === 'KeyZ';

const setStart = (range: Range, node: Node, offset: number) => {
  if (offset < node.textContent!.length) {
    range.setStart(node, offset);
  } else {
    range.setStartAfter(node);
  }
};

const setEnd = (range: Range, node: Node, offset: number) => {
  if (offset < node.textContent!.length) {
    range.setEnd(node, offset);
  } else {
    range.setEndAfter(node);
  }
};

// use for debugging
export const getParentFunctionName = () => {
  // Create a new Error object to access the stack trace
  const err = new Error();
  const stack = err.stack || ''; // Get the stack trace as a string

  // Split the stack trace into individual lines
  const stackLines = stack.split('\n');
  // The first line is the error message itself, not part of the stack trace
  // The second line is this function (getParentFunctionName)
  // The third line should be the direct caller of getParentFunctionName
  // The fourth line should be the parent of the function you're interested in
  const callerLine = stackLines[3] || ''; // Adjust the index based on your needs

  // Extract the function name from the line
  // This regex is simplistic and might need adjustment to accurately parse all possible stack trace formats
  const match = callerLine.match(/at (\S+)/);
  const functionName = match ? match[1] : 'Unknown';

  console.info(`Called by ${functionName}`);
};

const findMarkedNode = (element: Element, first?: boolean) => {
  if (element.nodeType === Node.ELEMENT_NODE) {
    const searchFor = first
      ? element?.getAttribute('data-editing-start') === 'true'
      : element?.getAttribute('data-editing-end') === 'true';
    if (searchFor) {
      return element;
    }
  }

  let foundNode: Node | null = null;
  element.childNodes.forEach((child) => {
    if (foundNode === null) {
      foundNode = findMarkedNode(child as Element, false);
    }
  });
  return foundNode;
};

const goThroughNodesToFindMatches = (
  range: Range,
  queue: Node[],
  offset: Offset,
  foundFirst: boolean,
  sameNodes?: boolean
) => {
  let markNode = foundFirst;
  let node: Node;
  while ((node = queue[queue.length - 1])) {
    if (node.nodeType === Node.ELEMENT_NODE && node.firstChild?.nodeName === 'BR') {
      const length = node.textContent!.length || 0;
      if (length === 0 && offset.start === 0 && offset.end === 0) {
        range.setStart(node, 0);
        range.setEnd(node, 0);
        break;
      }
    }
    if (node.nodeType === Node.TEXT_NODE) {
      if (sameNodes) {
        const textLength = node.textContent?.length || 0;
        if (offset.start > textLength) {
          range.setStart(node, 0);
          range.setEnd(node, 0);
          break;
        } else {
          range.setStart(node, offset.start);
          if (offset.end > textLength) {
            range.setEnd(node, offset.start);
          } else {
            range.setEnd(node, offset.end);
          }
          break;
        }
      } else {
        const textLength = node.textContent?.length || 0;
        if (!foundFirst) {
          if (offset.start > textLength) {
            range.setStart(node, 0);
            markNode = true;
            break;
          } else {
            range.setStart(node, offset.start);
            markNode = true;
            break;
          }
        } else {
          if (offset.end > textLength) {
            range.setEnd(node, 0);
            break;
          } else {
            range.setEnd(node, offset.end);
            break;
          }
        }
      }
      // const length = node.textContent!.length || 0;
      // // If we've found the starting position but not set it yet
      // if (!startSet && current + length >= offset.start) {
      //   range.setStart(node, offset.start - current);
      //   startSet = true;
      // }
      // // Check if we can also set the end position within the same node
      // if (startSet && current + length >= offset.end) {
      //   range.setEnd(node, offset.end - current);
      //   break; // End has been set, exit the loop
      // }
      // current += node.textContent!.length;
    }

    queue.pop();
    if (node.nextSibling) queue.push(node.nextSibling);
    if (node.firstChild) queue.push(node.firstChild);
  }
  return markNode;
};

const containsFormattingAndTextNode = (parentElement: Node) => {
  const formattingTags = ['STRONG', 'B', 'EM', 'I', 'U', 'STRIKE', 'S', 'DEL', 'SPAN'];
  let foundTextNode = false;
  let foundStrongTag = false;

  for (const node of parentElement.childNodes) {
    if (node.nodeType === Node.TEXT_NODE && node.textContent!.trim().length > 0) {
      // Check for non-empty text node
      foundTextNode = true;
    } else if (node instanceof Element && formattingTags.includes(node.tagName)) {
      // Check for formatting elements
      foundStrongTag = true;
    }

    // If both have been found, no need to continue checking
    if (foundTextNode && foundStrongTag) {
      return true;
    }
  }

  return false;
};

const restoreSelectionToMarkedNode = (element: Element, offset: Offset, textOffset: Offset) => {
  const firstMark = findMarkedNode(element, true);
  const lastMark = findMarkedNode(element);
  let range = document.createRange();
  let foundFirst = false;

  if (!firstMark || !lastMark) {
    range.setStart(element, 0);
    range.setEnd(element, 0);
    return range;
  }

  const placeAtStart = firstMark === lastMark && offset.start === 0 && offset.end === 0;

  if (firstMark === lastMark) {
    const queue: Node[] = [firstMark];
    if (placeAtStart) {
      range.setStart(firstMark, 0);
      range.setEnd(firstMark, 0);
    } else if (containsFormattingAndTextNode(firstMark)) {
      const textRange = makeRange(element as HTMLElement, textOffset.start, textOffset.end);
      range = textRange;
    } else goThroughNodesToFindMatches(range, queue, offset, false, true);
  } else {
    const firstQueue: Node[] = [firstMark];
    const secondQueue: Node[] = [lastMark];
    if (containsFormattingAndTextNode(firstMark)) {
      foundFirst = true; // manually set that we have found the first node, since we have the exact character we can locate it
      makeRange(element as HTMLElement, textOffset.start, textOffset.end);
    } else foundFirst = goThroughNodesToFindMatches(range, firstQueue, offset, foundFirst, false);

    if (foundFirst) {
      if (
        (containsFormattingAndTextNode(lastMark) && lastMark.textContent?.length) ||
        0 !== offset.end
      )
        makeRange(element as HTMLElement, textOffset.start, textOffset.end);
      else goThroughNodesToFindMatches(range, secondQueue, offset, foundFirst, false);
    } else {
      range.setStart(element, 0);
      range.setEnd(element, 0);
    }
  }

  // Cleanup: remove the marked attribute
  setTimeout(() => {
    cleanUpNodes(element);
  }, 30);

  return range;
};

const cleanUpNodes = (element: Element) => {
  const queue: Node[] = [element];

  let node: Node;
  while ((node = queue[queue.length - 1])) {
    if (node instanceof Element) {
      node.removeAttribute('data-editing-start');
      node.removeAttribute('data-editing-end');
    }

    queue.pop();
    if (node.nextSibling) queue.push(node.nextSibling);
    if (node.firstChild) queue.push(node.firstChild);
  }
};

const markEditingNode = (element: Element, range: Range) => {
  const first = range.startContainer,
    last = range.endContainer;
  const start = range.startOffset,
    end = range.endOffset;
  let startSet = false;
  let endSet = false;

  function scanNode(node: Node) {
    if (first === node || last === node) {
      if (first === last) {
        if (node instanceof Element) {
          node.setAttribute('data-editing-start', 'true');
          node.setAttribute('data-editing-end', 'true');
          startSet = true;
          endSet = true;
        } else if (node.nodeType === Node.TEXT_NODE && node.parentNode instanceof Element) {
          node.parentNode.setAttribute('data-editing-start', 'true');
          node.parentNode.setAttribute('data-editing-end', 'true');
          startSet = true;
          endSet = true;
        }
      } else {
        if (node instanceof Element) {
          if (node === first && !startSet) {
            node.setAttribute('data-editing-start', 'true');
            startSet = true;
          }

          if (node === last && startSet) {
            node.setAttribute('data-editing-end', 'true');
            endSet = true;
          }
        } else if (node.nodeType === Node.TEXT_NODE && node.parentNode instanceof Element) {
          if (node === first && !startSet) {
            node.parentNode.setAttribute('data-editing-start', 'true');
            startSet = true;
          }

          if (node === last && startSet) {
            node.parentNode.setAttribute('data-editing-end', 'true');
            endSet = true;
          }
        }
      }

      return startSet && endSet; // Found the start container and end container, no need to search further
    }

    // Recursively process child nodes
    let child = node.firstChild;
    while (child) {
      if (scanNode(child)) return true; // Propagate the completion upwards
      child = child.nextSibling;
    }

    return false; // Continue scanning if neither start nor end container has been found yet
  }

  scanNode(element);
  return { start, end };
};

const toString = (element: HTMLElement, range?: Range, text?: boolean): string => {
  let content = '';
  if (element) {
    if (text) content += element.textContent!;
    else content += element.innerHTML;
  }

  if (range) markEditingNode(element, range);
  return content;
};

const getPosition = (element: HTMLElement, ignoreReset?: boolean, insert?: string): Position => {
  // Firefox Quirk: Since plaintext-only is unsupported the position
  // of the text here is retrieved via a range, rather than traversal
  // as seen in makeRange()
  let extend = 0;
  const range = getCurrentRange();

  let offset = markEditingNode(element, range);
  const extent = !range.collapsed ? range.toString().length : 0;
  const untilRange = document.createRange();
  const endRange = document.createRange();

  endRange.setStart(element, 0);
  endRange.setEnd(range.endContainer, range.endOffset);
  untilRange.setStart(element, 0);
  untilRange.setEnd(range.startContainer, range.startOffset);
  let content = untilRange.toString();
  // for now ignore the /n line ( Sebastian )
  const endContent = endRange.toString();
  if (insert) {
    const oldLength = range.toString().length;
    range.deleteContents();
    const copy = document.createTextNode(insert);
    range.insertNode(copy);
    copy?.parentNode?.normalize();
    extend = extend + insert.length - oldLength;
    if (extend === 0) {
      offset = { start: offset.end, end: offset.end };
    }
  }

  const position = content.length;
  const end = endContent.length;
  const lines = content.split('\n');
  const line = lines.length - 1;
  content = lines[line];
  return { position, end, extent, content, line, range, offset, ignoreReset, extend };
};

const makeRange = (element: HTMLElement, start: number, end?: number): Range => {
  if (start <= 0) start = 0;
  if (!end || end < 0) end = start;

  const range = document.createRange();
  const queue: Node[] = [element];
  let current = 0;

  let node: Node;
  let position = start;
  while ((node = queue[queue.length - 1])) {
    if (node.nodeType === Node.TEXT_NODE) {
      const length = node.textContent!.length;
      if (current + length >= position) {
        const offset = position - current;
        if (position === start) {
          setStart(range, node, offset);
          if (end !== start) {
            position = end;
            continue;
          } else {
            break;
          }
        } else {
          if (position === end) {
            const offset = end - current;
            setEnd(range, node, offset);
            break;
          }
        }
      }

      current += node.textContent!.length;
    }

    queue.pop();
    if (node.nextSibling) queue.push(node.nextSibling);
    if (node.firstChild) queue.push(node.firstChild);
  }

  return range;
};

interface State {
  observer: MutationObserver;
  disconnected: boolean;
  onChange(text: string, position?: Position): void;
  queue: MutationRecord[];
  history: History[];
  historyAt: number;
  track: boolean;
  position: Position | null;
  controlKeyPressed: boolean;
  unblockedKeys: Array<string>;
}

export interface Options {
  disabled?: boolean;
  indentation?: number;
  text?: boolean;
  domain?: boolean;
  limit?: number;
}

export interface Edit {
  /** Replaces the entire content of the editable while adjusting the caret position. */
  update(content: string): void;
  /** Inserts new text at the caret position while deleting text in range of the offset (which accepts negative offsets). */
  insert(append: string, offset?: number): void;
  /** Positions the caret where specified */
  move(pos: number | { row: number; column: number }): void;
  /** Returns the current editor state, as usually received in onChange */
  getState(): { text: string; position: Position };
  triggerUpdate(): void;
}

/**
 * @deprecated useEditable is now deprecated, please use useQuill hook for future editing.
 */
const useEditable = (
  elementRef: { current: HTMLElement | undefined | null },
  onChange: (text: string, position?: Position) => void,
  opts?: Options
): Edit => {
  if (!opts) opts = {};

  const unblock = useState([])[1];
  const state: State = useState(() => {
    const state: State = {
      observer: null as any,
      disconnected: false,
      onChange,
      queue: [],
      history: [],
      historyAt: -1,
      track: true,
      position: null,
      controlKeyPressed: false,
      unblockedKeys: ['up', 'down', 'left', 'right', 'backspace', 'mod+a', 'mod+c', 'mod+v']
    };

    if (typeof MutationObserver !== 'undefined') {
      state.observer = new MutationObserver((batch) => {
        for (const mutation of batch) {
          if (mutation.type === 'childList' || mutation.type === 'attributes') {
            mutation.addedNodes.forEach((addedNode) => {
              if (addedNode.nodeType === Node.ELEMENT_NODE) {
                // Ensure we're dealing with an element
                formatExec(addedNode as Element);
              }
            });

            // In case the direct parent node has been changed, not added
            if (mutation.target.nodeType === Node.ELEMENT_NODE) {
              formatExec(mutation.target as Element);
            }
          }
        }
        state.queue.push(...batch);
      });
    }

    return state;
  })[0];

  const edit = useMemo<Edit>(
    () => ({
      update(content: string) {
        const { current: element } = elementRef;
        if (element) {
          const position = getPosition(element);
          const prevContent = toString(element);
          position.position += content.length - prevContent.length;
          state.position = position;
          state.onChange(content, position);
        }
      },
      insert(append: string) {
        const { current: element } = elementRef;
        if (element) {
          const range = getCurrentRange();
          range.deleteContents();
          range.collapse();
          getPosition(element);
          range.deleteContents();
          if (append) range.insertNode(document.createTextNode(append));
        }
      },
      move(pos: number | { row: number; column: number }) {
        const { current: element } = elementRef;
        if (element) {
          element.focus();
          let position = 0;
          if (typeof pos === 'number') {
            position = pos;
          } else {
            const lines = toString(element, undefined, true).split('\n').slice(0, pos.row);
            if (pos.row) position += lines.join('\n').length + 1;
            position += pos.column;
          }

          setCurrentRange(makeRange(element, position));
        }
      },
      getState() {
        const { current: element } = elementRef;
        const text = toString(element!, undefined, true);
        const position = getPosition(element!);
        return { text, position };
      },
      triggerUpdate() {
        const { current: element } = elementRef;
        if (!element || !element.innerHTML) return;
        const position = getPosition(element);
        state.onChange(element.innerHTML, position);
        state.position = position;
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementRef]
  );

  useLayoutEffect(() => {
    state.onChange = onChange;

    if (!elementRef.current || opts.disabled) return;

    state.disconnected = false;
    state.observer.observe(elementRef.current, observerSettings);
    if (state.position) {
      const { position, extent, offset, ignoreReset, extend, end } = state.position;
      if (opts?.text) setCurrentRange(makeRange(elementRef.current, position, position + extent));
      else {
        const range = !ignoreReset
          ? restoreSelectionToMarkedNode(
              elementRef.current,
              extend ? { end: offset.end + extend, start: offset.end + extend } : offset,
              { start: position + extend, end: end + extend }
            )
          : null;

        if (range) setCurrentRange(range);
        else setCurrentRange(makeRange(elementRef.current, position, position + extent));
      }
    }

    return () => {
      state.observer.disconnect();
    };
  });

  useLayoutEffect(() => {
    if (!elementRef.current || opts.disabled) {
      state.history.length = 0;
      state.historyAt = -1;
      return;
    }

    const element = elementRef.current;
    if (state.position) {
      element.focus();

      const { position, extent, offset, ignoreReset, extend, end } = state.position;
      if (opts?.text) setCurrentRange(makeRange(elementRef.current, position, position + extent));
      else {
        const range = !ignoreReset
          ? restoreSelectionToMarkedNode(
              elementRef.current,
              extend ? { end: offset.end + extend, start: offset.start + extend } : offset,
              { start: position, end: end + extend }
            )
          : null;
        if (range) setCurrentRange(range);
        else setCurrentRange(makeRange(elementRef.current, position, position + extent));
      }
    }

    const prevWhiteSpace = element.style.whiteSpace;
    const prevLineBreak = element.style.lineBreak;
    const prevContentEditable = element.contentEditable;
    element.contentEditable = 'true';
    // force to focus to avoid clicking twice on the element
    if (!opts?.text && !opts?.domain) element.focus();

    if (opts.indentation) {
      element.style.tabSize = (element.style as any).MozTabSize = '' + opts.indentation;
    }

    let _trackStateTimestamp: number;
    const trackState = (position: Position, content: string, ignoreTimestamp?: boolean) => {
      if (!elementRef.current || !position) return;

      const timestamp = new Date().valueOf();

      // Prevent recording new state in list if last one has been new enough
      const lastEntry = state.history[state.historyAt];
      if (
        (!ignoreTimestamp && timestamp - _trackStateTimestamp < 500) ||
        (lastEntry && lastEntry[1] === content)
      ) {
        _trackStateTimestamp = timestamp;
        return;
      }

      const at = ++state.historyAt;
      state.history[at] = [position, content];
      state.history.splice(at + 1);
      if (at > 500) {
        state.historyAt--;
        state.history.shift();
      }
    };

    const disconnect = () => {
      state.observer.disconnect();
      state.disconnected = true;
    };

    const flushChanges = debounce(() => {
      state.queue.push(...state.observer.takeRecords());
      const position = getPosition(element);
      if (state.queue.length) {
        disconnect();
        const content = toString(element, position.range);
        state.position = position;
        state.onChange(content, position);
        trackState(position, content);
      }
    }, 900);

    const stripHtml = (html: string) => {
      const tmp = document.createElement('div');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    };

    const blockEvent = (event: HTMLElementEventMap['keydown']) => {
      event.preventDefault();
      return false;
    };

    const onKeyDown = (event: HTMLElementEventMap['keydown']) => {
      const el = event.target as HTMLElement;
      const unblocked = state.unblockedKeys.some((key) => isHotkey(key, event));
      if (opts?.limit && stripHtml(el.innerHTML).length >= opts.limit) {
        if (!unblocked) return blockEvent(event);
      } else {
        if (opts.domain) {
          const regex = /^[a-zA-Z0-9-]+$/;
          if (!regex.test(event.key)) return blockEvent(event);
        }
      }

      if (event.defaultPrevented || event.target !== element) {
        return;
      } else if (state.disconnected) {
        // React Quirk: It's expected that we may lose events while disconnected, which is why
        // we'd like to block some inputs if they're unusually fast. However, this always
        // coincides with React not executing the update immediately and then getting stuck,
        // which can be prevented by issuing a dummy state change.
        event.preventDefault();
        return unblock([]);
      }

      if (isUndoRedoKey(event)) {
        event.preventDefault();

        let history: History;
        if (!event.shiftKey) {
          const at = --state.historyAt;
          history = state.history[at];
          if (!history) state.historyAt = 0;
        } else {
          const at = ++state.historyAt;
          history = state.history[at];
          if (!history) state.historyAt = state.history.length - 1;
        }

        if (history) {
          disconnect();
          state.position = history[0];
          state.onChange(history[1], history[0]);
        }
        return;
      }

      // do no flush changes on these keys, only if used in combos
      if (event.metaKey || event.altKey) return;
      // keep track of changes
      flushChanges();
    };

    const onKeyUp = (event: Event) => {
      event.preventDefault();
      // Chrome Quirk: The contenteditable may lose focus after the first edit or so
      setTimeout(() => {
        element.focus();
      }, 0);
    };

    const onSelect = () => {
      // reset the position when clicking with mouse, event tracking continues as soon as a keyboard event is hit
      state.position = null;
    };

    const onPaste = (event: HTMLElementEventMap['paste']) => {
      let data = event.clipboardData?.getData('text/plain');
      if (!data) return;
      event.preventDefault();
      if (opts.domain) {
        const regex = /^[a-zA-Z0-9-]+$/gi;
        data = data.replaceAll(regex, '').replaceAll(' ', ''); // only keep domain formatting
        if (opts.limit && data.length > opts.limit) {
          data = data.slice(0, opts.limit);
        }
      }
      const position = getPosition(element, false, data);
      const content = toString(element, position.range);
      state.position = position;
      state.onChange(content, position);
      trackState(position, content);
    };

    const onClick = (event: Event) => {
      let el = event.target as Element;
      let checkDepth = 5;
      const elements = [];
      while (checkDepth > 0) {
        elements.push(el);
        if (el.parentElement) el = el.parentElement;
        checkDepth--;
      }
      if (elements.includes(element)) {
        const position = getPosition(element);
        // track the initial version of the data and then cleanup the nodes
        trackState(position, element.innerHTML, true);
      }
      cleanUpNodes(element);
    };

    const cleanUpAfterUnselect = () => {
      flushChanges.cancel();
      state.onChange(element.innerHTML);
      cleanUpNodes(element);
    };

    document.addEventListener('click', onClick);
    document.addEventListener('selectstart', onSelect);
    element.addEventListener('keydown', onKeyDown);
    element.addEventListener('paste', onPaste);
    element.addEventListener('keyup', onKeyUp);
    element.addEventListener('blur', cleanUpAfterUnselect);

    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('selectstart', onSelect);
      element.removeEventListener('keydown', onKeyDown);
      element.removeEventListener('paste', onPaste);
      element.removeEventListener('keyup', onKeyUp);
      element.removeEventListener('blur', cleanUpAfterUnselect);
      element.style.whiteSpace = prevWhiteSpace;
      element.contentEditable = prevContentEditable;
      element.style.lineBreak = prevLineBreak;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current!, opts.disabled, opts.indentation, opts.text, opts.domain]);

  return edit;
};

export default useEditable;
