import styled from 'styled-components';
import { lazy } from 'react';

const PhoneInput = lazy(() => import('react-phone-number-input'));

const WrapperInput = styled.div`
  position: relative;
  margin-top: 30px;

  span {
    color: #ff5d63;
    font-size: 14px;
  }

  label {
    position: absolute;
    background: linear-gradient(180deg, #fff 70%, rgba(255, 255, 255, 0) 50%);
    top: -10px;
    left: 10px;
    padding: 0 24px 0 10px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 4;

    span {
      color: #ff5d63;
      padding-left: 5px;
    }
  }
`;

interface InputProps {
  errorstyle?: boolean;
}

const PhoneInputStyled = styled(PhoneInput)<InputProps>`
  display: flex;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  padding: 0 20px;
  background: #fcfcfc;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 17px;
  position: relative;

  .PhoneInputCountry {
    z-index: 6;
  }

  input {
    height: 100%;
    width: 100%;
    background: transparent;
    padding-left: 70px;
    position: absolute;
    z-index: 0;
    border: none;
    left: 0;
    border-radius: 10px;
    border: ${(props) => (props.errorstyle ? '2px solid #FF5D63' : '1px solid #c8c8c8')};
  }
`;

interface MobInputProps {
  type: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  error: string;
  errorstyle: boolean;
  required?: boolean;
}

const MobInput = (props: MobInputProps) => {
  const { type, name, value, onChange, placeholder, errorstyle, error, required } = props;

  return (
    <WrapperInput>
      <label>Phone Number {required && <span> *</span>}</label>
      <PhoneInputStyled
        type={type}
        name={name}
        defaultCountry="US"
        international
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        errorstyle={errorstyle}
      />
      {error && <span>{error}</span>}
    </WrapperInput>
  );
};

export default MobInput;
