import type { LinkProps } from 'react-scroll';
import type { SalesValues } from 'shared/types/index';
import type { FC } from 'react';

import { useMemo } from 'react';
import tinycolor from 'tinycolor2';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import { sanitizeRichText } from 'shared/helpers/utils';
import { defaultWebValue } from 'shared/helpers/website';

import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

// Fix me later
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StyledButton = styled(Link)<{ editable: string }>`
  all: unset;
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  display: block;

  ${(props) =>
    props.editable === 'true'
      ? 'cursor: text;'
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
    transition:
      transform 0.2s ease 0s,
      box-shadow 0.2s ease 0s;
  }
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      padding: 17px 28px;
  `}


${({ theme: { $width } }) =>
    $width <= 768 &&
    `
     &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
    display: inline-block; /* or 'block' depending on your needs */
  }
`;

export interface ButtonProps extends LinkProps {
  button: SalesValues;
  backgroundColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: any;
}

export const Button: FC<ButtonProps> = (props) => {
  const { button = defaultWebValue, meta, ...rest } = props;

  const textColor = useMemo(() => {
    return tinycolor(button.color).isDark() ? '#FFFFFF' : '#000000';
  }, [button.color]);

  const { updateActiveElement, resetActiveElement, editable } = useWebsiteEditor();

  return (
    <StyledButton
      spy
      smooth
      duration={500}
      hidden={button.hidden}
      style={{ backgroundColor: button.color, color: textColor }}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        updateActiveElement(e);
      }}
      onBlur={resetActiveElement}
      dangerouslySetInnerHTML={{
        __html: sanitizeRichText(button.val)
      }}
      id={button.id}
      {...rest}
      {...(editable ? { as: 'p', to: '#' } : { to: rest.to })}
      data-accessor-parent={meta?.['accessorKey']}
      data-accessorkey="button"
      editable={`${editable}`}
    />
  );
};

Button.displayName = 'SalesButton';
