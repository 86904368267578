import type { FC } from 'react';
import type { FAQProps } from './types';

import { useState } from 'react';
import styled from 'styled-components';
import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';
import { Wrapper } from '../Common';
import { FirstItem } from './FirstItem';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
  `}
`;

const TitleWrapper = styled.div`
  width: 40%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 32%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 32px;
    text-align: center;
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin-bottom: 18px;
  line-height: 1.35em;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 12px;
  `}
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subtitle = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.35em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const FAQWrapper = styled.div`
  width: 56%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 62%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
`;

export const V1: FC<FAQProps> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    benefits = [],
    textColor,
    meta,
    hidden
  } = props;
  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const [current, setCurrent] = useState<number | undefined>(0);

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <TextContainer>
          <TitleWrapper>
            <Title
              id={title.id}
              dangerouslySetInnerHTML={{
                __html: sanitizeRichText(title.val, editable)
              }}
              style={{
                color: filterBackgroundColor('#fff', textColor)
              }}
              onClick={updateActiveElement}
              onBlur={resetActiveElement}
              data-accessor-parent={meta['accessorKey']}
              data-accessorkey="title"
              data-placeholder="+"
              editable={editable}
              isEmpty={isTitleEmpty}
            />
            <Subtitle
              id={content.id}
              dangerouslySetInnerHTML={{
                __html: sanitizeRichText(content.val, editable)
              }}
              style={{
                color: filterBackgroundColor('#fff', textColor)
              }}
              onClick={updateActiveElement}
              onBlur={resetActiveElement}
              data-accessor-parent={meta['accessorKey']}
              data-accessorkey="content"
              data-placeholder="+"
              editable={editable}
              isEmpty={isContentEmpty}
            />
          </TitleWrapper>
          <FAQWrapper>
            {benefits.map((faq, index) => {
              const isTitleEmpty = checkTitleBenefits(index);
              const isContentEmpty = checkContentBenefits(index);
              return (
                <FirstItem
                  {...faq}
                  meta={meta}
                  textColor={textColor}
                  key={index}
                  first={index === 0}
                  last={index === benefits.length - 1}
                  isOpen={current === index}
                  onClick={() => setCurrent(index)}
                  isTitleEmpty={isTitleEmpty}
                  isContentEmpty={isContentEmpty}
                />
              );
            })}
          </FAQWrapper>
        </TextContainer>
      </Wrapper>
    </Container>
  );
};
