import type { FC, FormEvent, ChangeEvent } from 'react';
import type { BookBaseColor, SalesValues } from 'shared/types/index';

import tinycolor from 'tinycolor2';
import { useMemo, useState, lazy } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

import { COLOR_BLACK_TEXT, COLOR_MEDIUM_GRAY } from 'shared/helpers/colors';
import { filterBackgroundColor } from 'shared/helpers/website';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import { FlexBox } from 'shared/components/Common';
import { sanitizeRichText } from 'shared/helpers/utils';
import SalesStatesDropdown from '../Common/SalesStatesDropdown';
import { TOP_USED_CURRENCIES } from 'shared/helpers/static';

const PhoneInput = lazy(() => import('react-phone-number-input'));

const Form = styled.form`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
`;

const InputLarge = styled.input`
  border-radius: 8px;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  width: 100%;
  height: ${56 + 56 * 0.38}px;
  padding: 0 25px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
`;

const InputInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  gap: 10px;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column;
  `}
`;

const Paragraph = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: ${COLOR_BLACK_TEXT};

  @media only screen and (max-width: 37.5em) {
    font-size: 1.2rem;
  }
`;

const Input = styled.input`
  height: ${56 + 56 * 0.38}px;
  padding: 0 25px;
  width: 49%;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  border-radius: 8px;
  width: 100%;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  `}
`;

const PhoneInputStyled = styled(PhoneInput)`
  border-radius: 8px;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  width: 100%;
  height: ${56 + 56 * 0.38}px;
  padding: 0 25px;
  background: #fff;
  margin-top: 20px;

  div {
    margin-right: 8px;
  }

  input {
    border: none;
    font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  }

  input:focus-visible {
    outline: none;
  }
`;

const Button = styled.button<{ backgroundColor: string; editable: boolean }>`
  min-height: 10px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  ${(props) => props.editable && `cursor: text;`}

  ${(props) =>
    props.editable
      ? ''
      : `
  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
  }`}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
  `}

  & p {
    ${(props) => props.editable && `cursor: text !important;`};
    display: inline-block;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 15px;
  margin-bottom: 15px;
  & label {
    display: block;
  }
  & span {
    font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
    font-family: inherit !important;
  }
  & span:first {
    font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  }

  & input {
    width: ${({ theme: { $headlineFont } }) => $headlineFont};
    height: ${({ theme: { $headlineFont } }) => $headlineFont};
  }

  & svg {
    width: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
    height: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  }
`;

export type FirstInputType = {
  phone?: string;
  email: string;
  first_name: string;
  last_name: string;
  subscribe: boolean;
  city: string;
  street: string;
  apartment: string;
  zipcode: string;
  state: string;
  is_vip?: boolean;
};

export type FirstProps = {
  button: SalesValues;
  backgroundColor: string;
  callback: (values: FirstInputType) => void;
  loadStripe?: boolean;
  textColor: BookBaseColor;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
};

export const First: FC<FirstProps> = (props) => {
  const { backgroundColor, callback, textColor, button, meta = {} } = props;
  const {
    salesType,
    togglePhoneInputField,
    salesPageValues,
    amazonURL,
    isShippingForm,
    isSubscription,
    upsellDetails,
    upsellPrice
  } = useSales();
  const { editable, updateActiveElement, resetActiveElement } = useWebsiteEditor();
  const { t } = useTranslation();

  const [data, setData] = useState({
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    subscribe: isSubscription,
    city: '',
    street: '',
    apartment: '',
    zipcode: '',
    state: '',
    is_vip: false
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      email,
      first_name,
      last_name,
      phone,
      subscribe,
      apartment,
      city,
      street,
      zipcode,
      state
    } = data;
    callback({
      email,
      first_name,
      last_name,
      apartment,
      city,
      street,
      zipcode,
      state,
      ...(data?.is_vip ? { is_vip: data.is_vip } : {}),
      ...(phone && { phone }),
      subscribe
    });
  };

  const handleInputUpdate = (input: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = input;
    setData({
      ...data,
      [name]: value
    });
  };

  const checkboxColor = useMemo(() => {
    if (tinycolor(backgroundColor).toHex() === 'ffffff') {
      return 'gray';
    }
    return backgroundColor;
  }, [backgroundColor]);

  const buttonBackground = useMemo(() => {
    if (button.color) return button.color;
    if (
      salesPageValues &&
      typeof salesPageValues['topbar_1'] === 'object' &&
      salesPageValues['topbar_1'].button?.color
    ) {
      return salesPageValues['topbar_1'].button?.color;
    }
    return backgroundColor;
  }, [backgroundColor, salesPageValues, button]);

  const color = useMemo(() => {
    return tinycolor(buttonBackground).isDark() ? '#FFFFFF' : '#000000';
  }, [buttonBackground]);

  return (
    <Form onSubmit={handleSubmit}>
      <InputInner>
        {!meta?.['hideFirstname'] || editable ? (
          <FlexBox
            position="relative"
            alignItems="center"
            justifyContent="center"
            width="100%"
            gap={5}>
            <img
              src={meta?.['hideFirstname'] ? '/img/eye-hide.svg' : '/img/eye-show.svg'}
              alt="eye icon"
              style={{
                width: 32,
                marginRight: 10,
                cursor: 'pointer',
                display: editable ? 'block' : 'none'
              }}
              onClick={() => {
                if (meta['accessorKey'])
                  togglePhoneInputField(meta['accessorKey'], 'hideFirstname');
              }}
            />
            <Input
              name="first_name"
              placeholder={t('common.name', { defaultValue: 'Name' })}
              required
              value={data.first_name}
              style={{ opacity: meta['hideFirstname'] ? 0.5 : 1 }}
              onChange={handleInputUpdate}
            />
          </FlexBox>
        ) : null}
        {!meta['hideLastname'] || editable ? (
          <FlexBox
            position="relative"
            alignItems="center"
            justifyContent="center"
            width="100%"
            gap={5}>
            <img
              src={meta?.['hideLastname'] ? '/img/eye-hide.svg' : '/img/eye-show.svg'}
              alt="eye icon"
              style={{
                width: 32,
                marginRight: 10,
                cursor: 'pointer',
                display: editable ? 'block' : 'none'
              }}
              onClick={() => {
                if (meta['accessorKey']) togglePhoneInputField(meta['accessorKey'], 'hideLastname');
              }}
            />
            <Input
              name="last_name"
              placeholder={t('auth.common.lastName', { defaultValue: 'Last Name' })}
              required
              value={data.last_name}
              style={{ opacity: meta['hideLastname'] ? 0.5 : 1 }}
              onChange={handleInputUpdate}
            />
          </FlexBox>
        ) : null}
      </InputInner>
      <FlexBox position="relative" alignItems="center" justifyContent="center" width="100%">
        <InputLarge
          name="email"
          placeholder={t('common.email', { defaultValue: 'Email Address' })}
          required
          value={data.email}
          onChange={handleInputUpdate}
          type="email"
        />
      </FlexBox>
      {!meta['hidePhoneInput'] || editable ? (
        <FlexBox position="relative" alignItems="center" justifyContent="center" width="100%">
          <img
            src={meta['hidePhoneInput'] ? '/img/eye-hide.svg' : '/img/eye-show.svg'}
            alt="eye icon"
            style={{
              width: 32,
              paddingTop: 25,
              marginRight: 17,
              cursor: 'pointer',
              display: editable ? 'block' : 'none'
            }}
            onClick={() => {
              if (meta['accessorKey']) togglePhoneInputField(meta['accessorKey'], 'hidePhoneInput');
            }}
          />
          <PhoneInputStyled
            defaultCountry="US"
            placeholder={t('auth.common.phoneNumber', {
              defaultValue: 'Phone Number (000)000-0000'
            })}
            value={data.phone}
            onChange={(phone: string) => setData({ ...data, phone })}
            style={{ opacity: meta['hidePhoneInput'] ? 0.5 : 1 }}
          />
        </FlexBox>
      ) : null}
      {isShippingForm ? (
        <FlexBox flexDirection="column" gap={15} mt={15} width="100%">
          <InputLarge
            name="street"
            placeholder={'Mailing Street Address'}
            required
            value={data.street}
            onChange={handleInputUpdate}
          />
          <InputLarge
            name="apartment"
            placeholder={'Apartments, suites, etc. (optional)'}
            value={data.apartment}
            onChange={handleInputUpdate}
          />
          <FlexBox gap={10}>
            <Input
              name="city"
              placeholder={'City'}
              required
              value={data.city}
              onChange={handleInputUpdate}
            />
            <FlexBox>
              <SalesStatesDropdown
                value={data.state}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    state: e.target.value
                  }))
                }
              />
            </FlexBox>
            <Input
              name="zipcode"
              placeholder={'Zip Code'}
              required
              value={data.zipcode}
              onChange={handleInputUpdate}
            />
          </FlexBox>
        </FlexBox>
      ) : null}
      {upsellDetails?.product_name && upsellDetails?.upsell_type === 'bump' ? (
        <FlexBox
          border="1px dashed black"
          padding="3rem 1rem 3rem 3rem"
          width="100%"
          backgroundColor="#d5fafc"
          marginTop="20px">
          <FlexBox
            width="40px"
            height="40px"
            position="relative"
            onClick={() =>
              setData({
                ...data,
                is_vip: !data.is_vip
              })
            }
            borderRadius="8px"
            cursor="pointer"
            backgroundColor="white"
            marginRight="15px"
            justifyContent="center"
            alignItems="center">
            {data?.is_vip ? (
              <CheckIcon
                sx={{
                  fontSize: '30px'
                }}
              />
            ) : null}
          </FlexBox>
          <Paragraph>
            BUT WAIT!! Add our {upsellDetails.product_name} for just{' '}
            {TOP_USED_CURRENCIES?.[upsellDetails?.currency as keyof typeof TOP_USED_CURRENCIES] ||
              upsellDetails?.currency}
            {upsellPrice}
          </Paragraph>
        </FlexBox>
      ) : null}

      <FlexBox width="98%" pt={3}>
        <StyledFormControlLabel
          label={t('components.checkboxSubscribe', {
            defaultValue:
              'I would like to subscribe to the newsletter and receive informational content from you.'
          })}
          control={
            <StyledCheckbox
              checked={data.subscribe}
              onChange={() =>
                setData((prev) => ({
                  ...prev,
                  subscribe: !prev.subscribe
                }))
              }
              aria-required={false}
              style={{
                color: filterBackgroundColor(backgroundColor, textColor),
                backgroundColor: checkboxColor,
                marginRight: 10
              }}
            />
          }
        />
      </FlexBox>

      <Button
        id={button.id}
        backgroundColor={buttonBackground}
        editable={editable}
        dangerouslySetInnerHTML={{
          __html: sanitizeRichText(
            button.val,
            editable,
            false,
            salesType === 'sales' && !!amazonURL
              ? `<p>${t('components.completePayment', { defaultValue: 'COMPLETE PAYMENT' })} >></p>`
              : `<p>${t('components.signUp', { defaultValue: 'Sign Up to Get Started' })}</p>`
          )
        }}
        onClick={updateActiveElement}
        onBlur={resetActiveElement}
        data-accessor-parent={meta['accessorKey']}
        data-accessorkey="button"
        style={{ color: color }}
        {...(editable ? { as: 'p' } : {})}
      />
    </Form>
  );
};
