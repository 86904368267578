import { useMemo, useState, type FC } from 'react';
import type { HeroDetailedProps } from './types';

import styled, { css } from 'styled-components';
import {
  ApiService,
  COLOR_ORANGE,
  COLOR_YELLOW,
  defaultWebValue,
  filterBackgroundColor,
  filterBackgroundColorMultiple,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Button, Wrapper, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const HeroDetailed = styled.div`
  position: relative;
  overflow-x: hidden;
  min-height: 693px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  flex-direction: column;

  `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  display: flex;
  justify-content: space-between;
  padding: 70px 0 125px 0px;
  max-width: 100%;
  gap: 50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
      padding: 70px 0 165px 0px;
        gap: 20px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
      padding: 70px 0 65px 0px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      padding: 70px 0 65px 0px;
    `}
`;

const TitleWrapper = styled.div`
  width: 655px;
  position: relative;
  z-index: 7;
  margin-top: -19px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 472px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 0 15px;
    `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  line-height: 1.25em;
  color: #fff;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const LineTitle = styled.div`
  width: 70px;
  height: 7px;
  margin: 20px 0 24px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin: 20px 0 24px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin: 10px 0 24px;
    `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 38px;
  line-height: 1.35em;
  color: #fff;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 59px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 40px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    max-width: 360px;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    max-width: 300px;

  `}
`;

const RelativeWrapper = styled.div`
  position: relative;
  z-index: 6;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      display: flex;
      justify-content: center;
    `}
`;

const SvgBg = styled.svg`
  opacity: 0.22;
  width: 1340px;
  height: 679px;
  position: absolute;
  top: -70px;
  left: -110%;
  z-index: 1;

  .cls-1 {
    fill: url(#_svg_Gradient);
    stroke-width: 0px;
  }

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 1230px;
    height: 609px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      top: -110px;
      left: -240px;
      width: 1160px;
      height: 659px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
      top: -110px;
      left: -310px;
      width: 1080px;
      height: 609px;
    `}

      ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    left: -35%;
    width: 800px;
    height: 419px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 485 &&
    `
    left: -55%;
    width: 800px;
    height: 419px;
    `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  width: 506px;
  height: auto;
  object-fit: fill;
  z-index: 2;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 438px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 340px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 370 &&
    `
    width: 270px;
  `}
`;

const BenefitWrapper = styled.div`
  position: relative;
`;

const BenefitBg = styled.div`
  position: absolute;
  bottom: 0px;
  height: 130px;
  width: 100%;
  background: #fff;
  z-index: 2;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    display: none;
    `}
`;

const Benefits = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 62px;
  position: relative;
  z-index: 24;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
    bottom: auto;
    padding-bottom: 80px;
    padding-top: 20px;
    `}
`;

const BenefitItem = styled.div`
  width: 31.5%;
  padding: 18px 22px 22px;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 32.5%;
    padding: 18px 18px 20px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: row;
    width: 100%;
    padding: 18px 15px;
    border-radius: 12px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    `}
`;

const Svg = styled.svg`
  width: 62px;
  height: 62px;
  margin-right: 18px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 52px;
      height: 52px;
      margin-bottom: 8px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 42px;
    height: 42px;
    margin-bottom: 8px;
  `}

    ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 52px;
    height: 52px;
    margin-bottom: 8px;
  `}
`;

const BenefitTxtInner = styled.div`
  width: 78%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 100%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      width: 80%;
    `}
`;

const TitleBenefit = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  margin-bottom: 4px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  line-height: 1.35em;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 4px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const TextBenefit = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  line-height: 1.3em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const ColoredBg = styled.div`
  position: absolute;
  width: 100%;
  height: 95%;
  top: 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 100%;
    `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V3: FC<HeroDetailedProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    secondaryColor = COLOR_YELLOW,
    backgroundColor = COLOR_ORANGE,
    textColor,
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { isCoverDisabled, pages, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, updateImageMeta, purchaseElementId } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, pagesThumbnails, cover_thumbnail]);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <HeroDetailed
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <RelativeWrapper>
          <div>
            {bookMockup ? (
              <HoverableDropzone
                isSalePage
                css={imageStyles as never}
                disabled={!editable}
                resizeSelector={editable}
                handleResize={({ width, height }) => {
                  setResize({ width, height });
                  updateImageMeta(meta['accessorKey'], width, 'width');
                  updateImageMeta(meta['accessorKey'], height, 'height');
                }}
                allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
                onUpload={handleUpload}
                loading={isLoading}
                alreadyUploaded={Boolean(bookMockup)}
                imageFile={bookMockup}
                initialPosition={{
                  x: meta['x_1'] || 0,
                  y: meta['y_1'] || 0,
                  width: meta['d_width_1'],
                  height: meta['d_height_1']
                }}
                meta={meta}
                positionEnabled
                handlePosition={(width: number, height: number, x: number, y: number) => {
                  updateImageMeta(meta['accessorKey'], x, 'x_1');
                  updateImageMeta(meta['accessorKey'], y, 'y_1');
                  updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                  updateImageMeta(meta['accessorKey'], height, 'd_height_1');
                }}
                imageAspectRatio={{
                  ratio: '3x4',
                  pixels: '600x800'
                }}>
                <BookMediaImg
                  isImageUploaded={isImageUploaded}
                  src={bookMockup}
                  alt="Book Mockup"
                  width={resize.width || 500}
                  height={resize.height || 500}
                />
              </HoverableDropzone>
            ) : (
              <StyledMockup
                mockup={mockup?.file}
                mockup_id={mockup?.id}
                cover_thumbnail={coverThumbnail}
              />
            )}
          </div>
          <SvgBg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1199.97 609.55">
            <defs>
              <linearGradient
                id="_svg_Gradient"
                data-name="svg_Gradient"
                x1="617.14"
                y1="448.92"
                x2="648.16"
                y2="150.38"
                gradientTransform="translate(0 614) scale(1 -1)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#fff" stopOpacity=".07" />
                <stop offset="1" stopColor="#fff" stopOpacity=".6" />
              </linearGradient>
            </defs>
            <path
              className="cls-1"
              d="m879.7,0l-207.12,312.68L370.97,0H0l524.1,543.34c41.16,42.67,96.96,66.21,155.52,66.21,6.64,0,13.32-.3,20.01-.92,65.73-6,124.34-41.01,160.79-96.04L1199.97,0h-320.27Z"
            />
          </SvgBg>
        </RelativeWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{
              color: filterBackgroundColorMultiple([backgroundColor, secondaryColor], textColor)
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <LineTitle style={{ backgroundColor: props.button?.color || COLOR_ORANGE }} />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{
              color: filterBackgroundColorMultiple([backgroundColor, secondaryColor], textColor)
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
      </StyledWrapper>
      <BenefitWrapper>
        <Wrapper>
          <Benefits>
            {benefits.map(({ content, icon, title }, index) => {
              const isTitleBenEmpty = checkTitleBenefits(index);
              const isContentBenEmpty = checkContentBenefits(index);
              return (
                <BenefitItem
                  // style={{
                  //   display: isTitleBenEmpty && isContentBenEmpty && !editable ? 'flex' : 'none'
                  // }}
                  // TODO: Replace with dynamic values later
                  key={`${icon?.toString()}-${index}`}>
                  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                    <circle
                      cx="31"
                      cy="31"
                      r="31"
                      fill={props.button?.color || COLOR_ORANGE}
                      strokeWidth={0}
                    />
                    <path
                      d="m44.85,23.17c-.13.64-.53,1.1-.97,1.55-5.07,5.21-10.13,10.43-15.19,15.65-.4.41-.85.64-1.41.46-.23-.07-.46-.21-.63-.38-2.52-2.58-5.03-5.17-7.54-7.76-.86-.89-.84-2.14.01-3.03.28-.3.57-.59.86-.88.87-.88,2.08-.88,2.94,0,1.5,1.54,3,3.09,4.5,4.63.07.07.1.16.2.31.12-.17.17-.26.25-.34,4.15-4.28,8.3-8.56,12.46-12.84.98-1.01,2.15-1.01,3.13,0,.14.14.27.29.42.43.46.43.86.91.99,1.57v.64h0Z"
                      fill="#fff"
                      strokeWidth={0}
                    />
                  </Svg>
                  <BenefitTxtInner>
                    <TitleBenefit
                      id={title?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleBenEmpty}
                    />
                    <TextBenefit
                      id={content?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentBenEmpty}
                    />
                  </BenefitTxtInner>
                </BenefitItem>
              );
            })}
          </Benefits>
        </Wrapper>

        <BenefitBg />
      </BenefitWrapper>
      <ColoredBg style={{ background: backgroundColor }} />
    </HeroDetailed>
  );
};
