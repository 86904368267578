// Colors for use inline css OR component props

// Black and White Colors
export const COLOR_BLACK = '#000000';
export const COLOR_LIGHT_BLACK = '#4F4F4F';
export const COLOR_BLACK_3 = '#18191f';
export const COLOR_BLACK_4 = '#1B1D21';
export const COLOR_BLACK_5 = '#191D21';
export const COLOR_BLACK_6 = '#000103';
export const COLOR_BLACK_7 = '#05060A';
export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_2 = '#dbe5eb';

// Gray Color Variants
export const COLOR_GRAY = '#414141';
export const COLOR_NORMAL_GRAY = '#9A9A9A';
export const COLOR_LIGHTER_GRAY = '#F9FAFB';
export const COLOR_DARK_GRAY = '#667085';
export const COLOR_BOLD_GRAY = '#757575';
export const COLOR_MEDIUM_GRAY = '#D9D9D9';
export const COLOR_LIGHT_GRAY = '#dfdfdf';
export const COLOR_GRAY_7 = '#949494';
export const COLOR_GRAY_8 = '#C4C4C4';
export const COLOR_GRAY_9 = '#606060';
export const COLOR_GRAY_10 = '#979797';
export const COLOR_GRAY_11 = '#969bab';
export const COLOR_GRAY_12 = '#dddddd';
export const COLOR_GRAY_13 = '#6d7280';
export const COLOR_GRAY_14 = '#111827';
export const COLOR_GRAY_15 = '#1A1A1A';
export const COLOR_GRAY_16 = '#D2D5DA';
export const COLOR_GRAY_17 = '#4B5563';
export const COLOR_GRAY_18 = '#5D616F';
export const COLOR_GRAY_19 = '#333333';
export const COLOR_GRAY_20 = '#737373';
export const COLOR_GRAY_21 = '#bdbdbd';
export const COLOR_GRAY_22 = '#9f9f9f';

// Orange Color Variants
export const COLOR_ORANGE = '#ffc800';
export const COLOR_DARK_ORANGE = '#F6C546';
export const COLOR_LIGHT_ORANGE = '#ffdf60';
export const COLOR_ORANGE_1 = '#FFC107';
export const COLOR_INPUTABLE_ORANGE = '#FFC107';

// Yellow Color Variants
export const COLOR_YELLOW = '#f8b417';
export const COLOR_YELLOW_LIGHTER = '#FBDE4B';
export const COLOR_LIGHT_YELLOW = '#ffea79';
export const COLOR_YELLOW_LIGHT = '#fff8e3';

// Blue Color Variants
export const COLOR_BLUE = '#377dff';
export const COLOR_DARK_BLUE = '#072125';
export const COLOR_LIGHT_BLUE = '#D5FAFC';
export const COLOR_STRIPE = '#0A2540';
export const COLOR_BLUE_3 = '#170f49';
export const COLOR_BLUE_4 = '#2ec5ce';
export const COLOR_BLUE_6 = '#d5fafc';
export const COLOR_BLUE_8 = '#1CAEB2';

// Red Color Variants
export const COLOR_RED_1 = '#EF1004';
export const COLOR_RED_2 = '#F1434C';
export const COLOR_RED_3 = '#FE6E06';

// ColorInputs Color Variants
export const COLOR_FROZE_1 = '#56CCF2';
export const COLOR_FROZE_2 = '#F05A88';
export const COLOR_FROZE_3 = '#4A1A74';
export const COLOR_FROZE_4 = '#EE7701';
export const COLOR_FROZE_5 = '#EBB163';

export const backgBar = [
  { id: 0, value: '#DEF9FA', name: 'Color 1' },
  { id: 1, value: '#FFEED3', name: 'Color 2' },
  { id: 2, value: '#EBF5D8', name: 'Color 3' },
  { id: 3, value: '#FFFFFF', name: 'Color 4' }
];

export const COLOR_SUCCESS_LIGHT = '#81c784';
export const COLOR_SUCCESS_MAIN = '#66bb6a';
export const COLOR_SUCCESS_DARK = '#388e3c';

export const COLOR_ERROR_LIGHT = '#e57373';
export const COLOR_ERROR_MAIN = '#f44336';
export const COLOR_ERROR_DARK = '#d32f2f';

export const COLOR_INPUTABLE_YELLOW = '#FFF4CB';

export const COLOR_COPYSHOP = '#5ce1e6';
export const COLOR_SECONDARY_LIGHT = '#E8FEFF';
export const COLOR_SECONDARY_LIGHTER = '#E8FEFF80';
export const COLOR_SECONDARY_LIGHTER_2 = '#E9F8F9';
export const COLOR_SECONDARY_TEXT = '#1CAEB2';

export const COLOR_TERTIARY_TEXT = '#FF8700';

export const COLOR_BLACK_TEXT = '#303030';

export const COLOR_BUTTON_OUTLINE = '#cecece';

export const DELETE_COLOR = '#ff5d63';
