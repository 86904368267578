import { useMemo, type FC } from 'react';
import type { ReceiptProps } from './type';

import styled from 'styled-components';

import {
  COLOR_BLUE_6,
  COLOR_LIGHT_GRAY,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';

import { Wrapper } from '../Common';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

const Container = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 40px 15px;
    `}
`;

const StyledWrapper = styled(Wrapper)<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  border: 1px solid ${COLOR_LIGHT_GRAY};
  padding: 34px 30px;
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
`;

const Order = styled.p`
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLOR_LIGHT_GRAY};
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid ${COLOR_LIGHT_GRAY};
`;

const Title = styled.p`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
`;

const PriceItem = styled.p`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};

  span {
    font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
    margin-right: 5px;
  }
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  align-items: center;
`;

const TitleTotal = styled.p`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
`;

const PriceTotal = styled.div`
  display: flex;
  align-items: center;
`;

const Currency = styled.p`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  margin-right: 14px;
  padding-top: 5px;
`;

const PriceItemTotal = styled.p`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
`;

export const V1: FC<ReceiptProps> = (props) => {
  const {
    book_name,
    book_price,
    backgroundColor = COLOR_BLUE_6,
    currency,
    textColor,
    subscriptionData,
    sub_domain
  } = props;
  const recurringInterval = localStorage.getItem(
    `PAGEWHEEL_RECURRING_ORDER_INTERVAL_${sub_domain}`
  );

  const { editable } = useWebsiteEditor();

  const dispayPrice = useMemo(() => {
    if (editable && subscriptionData) {
      return `${subscriptionData.monthly_price}/m | ${subscriptionData.yearly_price}/y`;
    }
    if (subscriptionData) {
      return recurringInterval === 'month'
        ? `${subscriptionData.monthly_price}/m`
        : `${subscriptionData.yearly_price}/y`;
    }
    return `<p>${currency !== 'USD' ? `<span>${currency}</span>` : '$'} ${book_price}</p>`;
  }, [book_price, currency, editable, recurringInterval, subscriptionData]);

  const displayTotalPrice = useMemo(() => {
    if (editable && subscriptionData) {
      return `${subscriptionData.monthly_price}/m | ${subscriptionData.yearly_price}/y`;
    }
    if (subscriptionData) {
      return recurringInterval === 'year'
        ? `${subscriptionData.monthly_price}/m`
        : `${subscriptionData.yearly_price}/y`;
    }
    return book_price;
  }, [book_price, editable, recurringInterval, subscriptionData]);

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container>
      <StyledWrapper backgroundColor={shadedBackgroundColor}>
        <Order
          dangerouslySetInnerHTML={{
            __html: sanitizeRichText('<p>Order Receipt</p>')
          }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
        />
        <Price>
          <Title
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(`<p>${book_name}</p>`) }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          />
          <PriceItem
            dangerouslySetInnerHTML={{
              __html: sanitizeRichText(dispayPrice)
            }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          />
        </Price>
        <Total>
          <TitleTotal
            dangerouslySetInnerHTML={{ __html: sanitizeRichText('<p>Total</p>') }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          />
          <PriceTotal>
            <Currency
              dangerouslySetInnerHTML={{
                __html: sanitizeRichText(currency)
              }}
              style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            />
            <PriceItemTotal
              dangerouslySetInnerHTML={{
                __html: sanitizeRichText(`${displayTotalPrice}`)
              }}
              style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            />
          </PriceTotal>
        </Total>
      </StyledWrapper>
    </Container>
  );
};
