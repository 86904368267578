import type { SalesContextData } from './SalesContext';
import type { BookContextData } from './BookContext';
import type { BrandingContextData } from './BrandingContext';
import type { WebsiteEditorContextData, Dimensions } from './WebsiteEditorContext';
import type { WebsiteCreationContextData } from './WebsiteCreationContext';

import { SalesContext, SalesProvider } from './SalesContext';
import { BookContext, BookProvider } from './BookContext';
import { AuthContext, AuthProvider } from './AuthContext';
import { UtilsContext, UtilsProvider } from './Utils';
import { BrandingContext, BrandingProvider } from './BrandingContext';
import WebsiteEditorProvider, { WebsiteEditorContext } from './WebsiteEditorContext';
import { WebsiteCreationContext, WebsiteCreationProvider } from './WebsiteCreationContext';
import { defaultBookBaseColor, defaultBookBaseColorCollection } from './BrandingDefaultValues';

export type {
  SalesContextData,
  BookContextData,
  BrandingContextData,
  WebsiteEditorContextData,
  WebsiteCreationContextData,
  Dimensions
};

export {
  SalesContext,
  SalesProvider,
  BookContext,
  BookProvider,
  AuthContext,
  AuthProvider,
  BrandingContext,
  BrandingProvider,
  WebsiteEditorContext,
  WebsiteEditorProvider,
  WebsiteCreationContext,
  WebsiteCreationProvider,
  UtilsContext,
  UtilsProvider,
  defaultBookBaseColor,
  defaultBookBaseColorCollection
};
