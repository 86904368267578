import { getFileFromUrl } from './utils';

export const convertBase64ToFile = async (dataurl: string, filename: string) => {
  return fetch(dataurl)
    .then((res) => {
      return res.arrayBuffer();
    })
    .then((buf) => {
      return new File([buf], filename, { type: 'png' });
    });
};

export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const convertUrlToBase64 = async (url: string) => {
  const file = await getFileFromUrl(url);
  const base64 = await convertFileToBase64(file as File);
  return base64;
};

export const convertFileSizeToMB = (file: File) => {
  const fileSizeInBytes = file.size;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return fileSizeInMB.toFixed(2);
};

export const checkImage = (
  file: File
): Promise<{ width: number; height: number; size: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();

      // Set up the Image onload event
      image.onload = () => {
        const { width } = image;
        const { height } = image;
        const { size } = file;
        resolve({ width, height, size });
      };

      image.onerror = () => {
        reject(new Error('Failed to load the image.'));
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image.src = event.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export const placeImageLayout = (position: number, length: number) => {
  let center = 3;
  if (length === 5) center = 4;
  if (length === 4) center = 6;
  if (length === 3) center = 10;
  if (length === 2) center = 18;
  if (length === 1) center = 40;
  if (length) center *= length;
  return `
    left: ${position * 7 + center}%;
  `;
};
