import styled from 'styled-components';
import { COLOR_BLACK, COLOR_GRAY_13 } from 'shared/helpers/colors';
import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { shade } from 'shared/helpers/utils';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import type { EventProps } from './types';
import useSales from 'shared/hooks/useSales';
import { useMemo } from 'react';

const Container = styled.div`
  position: relative;
  background: #fff;
  padding: 60px 0 50px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const SaleContainer = styled.div`
  width: 1254px;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SectionTitle = styled.h2`
  font-size: 46px;
  text-align: center;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    font-size: 40px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    font-size: 36px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 32px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    font-size: 28px;
  `}
`;

const SaleContainerStyled = styled(SaleContainer)`
  position: relative;
  width: 1064px;
  margin: 0 auto;
  z-index: 2;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
  `}
`;

const SectionTitleStyled = styled(SectionTitle)`
  text-align: left;
  margin-bottom: 57px;
  width: 100%;
  color: ${COLOR_BLACK};

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 30px;
    text-align: center;
  `}
`;

const EventInner = styled.div`
  display: flex;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
  `}
`;

const Description = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  color: ${COLOR_GRAY_13};
  padding-left: 38px;
  width: 49%;
  border-left: 1px solid #000;
  display: flex;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    border-left: 0px;
    border-top: 1px solid #000;
    padding-left: 0;
    padding-top: 30px;
    font-size: 17px;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 49%;
  justify-content: center;
  padding-right: 38px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding-right: 0px;
    padding-bottom: 30px;
  `}
`;

const WrapperInfo = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
  `}
`;

const InfoTitle = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  min-width: 90px;
  word-break: break-all;
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    font-size: 17px;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
  `}
`;

const ItemInfo = styled.div`
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid #dedede;
  margin-left: 10px;
  word-break: break-all;
  max-width: 82%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    max-width: 75%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-left: 0px;
    max-width: 100%;
  `}

  svg {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 10px;
  }
`;

const InfoText = styled.div`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  width: 90%;
  color: black;
`;

export const V1 = (props: EventProps) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { setIsEventFormModalOpen } = useSales();

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <SaleContainerStyled>
        <SectionTitleStyled
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={() => {
            if (editable) setIsEventFormModalOpen(true);
          }}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
        />
        <EventInner>
          <Info>
            {benefits?.map(({ title: benefitTitle, content: benefitContent }, index) => (
              <WrapperInfo key={index}>
                <InfoTitle
                  id={benefitTitle?.id}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeRichText(benefitTitle?.val)
                  }}
                  style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                  onClick={() => {
                    if (editable) setIsEventFormModalOpen(true);
                  }}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="title"
                  data-benefits={true}
                />
                <ItemInfo>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.99">
                    <path
                      d="m0,6.44h18v9.6c0,.91-.57,1.65-1.43,1.88-.18.05-.37.07-.56.07H1.99c-1.15,0-1.99-.83-1.99-1.99V6.43h0Z"
                      fill={backgroundColor || '#2EC5CE'}
                      strokeWidth={0}
                    />
                    <path
                      d="m16.39,1.32c-.52-.06-1.06-.03-1.58-.03v1.85c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.26,0s.02,0,.03,0h0s-.02,0-.02,0v-.64C14.78.28,14.5,0,14.15,0c-.36,0-.65.27-.65.64v.64H4.51v1.86c0,.42-.27.7-.65.7s-.64-.29-.64-.7v-1.85h1.28v-.64C4.49.28,4.22,0,3.86,0s-.64.27-.64.64v.64h-1.24c-1.13,0-1.98.84-1.98,1.97v1.71c0,.06,0,.12,0,.17h17.98v-1.84c0-.09,0-.17,0-.26-.09-.88-.74-1.61-1.61-1.71Z"
                      fill={backgroundColor || '#2EC5CE'}
                      strokeWidth={0}
                    />
                  </svg>
                  <InfoText
                    id={benefitContent?.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeRichText(benefitContent?.val, editable)
                    }}
                    onClick={() => {
                      if (editable) setIsEventFormModalOpen(true);
                    }}
                    style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="content"
                    data-benefits={true}
                  />
                </ItemInfo>
              </WrapperInfo>
            ))}
          </Info>
          <Description>
            <div
              style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
              id={content.id}
              dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
              onClick={updateActiveElement}
              onBlur={resetActiveElement}
              data-accessor-parent={meta['accessorKey']}
              data-accessorkey="content"
            />
          </Description>
        </EventInner>
      </SaleContainerStyled>
    </Container>
  );
};
