import { useEffect, useMemo, useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Modal, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { b64toBlob } from 'shared/helpers/utils';

import {
  COLOR_MEDIUM_GRAY,
  COLOR_LIGHT_ORANGE,
  COLOR_YELLOW_LIGHT,
  COLOR_BLUE_4
} from 'shared/helpers/colors';

import DefaultImage from './DefaultImage/DefaultImage';
import ZoomedImg from './ZoomedImg/ZoomedImg';
import useOpenAi from 'shared/hooks/useOpenAi';
import useErrorLogger from 'shared/hooks/useErrorLogger';
import { useQuery } from 'react-query';
import { ApiService } from 'shared/helpers/api';
import useAuth from 'shared/hooks/useAuth';
import type { PlanName } from 'shared/types/User';
import useAlert from 'shared/hooks/useAlert';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const MainInner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    height: 100%;
    font-size: 15px;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
    font-weight: inherit;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  button {
    background: none;
    border: none;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    overflow: auto;
    align-items: flex-start;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(90px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PopUp = styled.div`
  position: relative;
  z-index: 3;
  width: 1186px;
  background: #fff;
  box-shadow: 0px 10px 17.9px 0px rgba(0, 0, 0, 0.32);
  animation: ${fadeIn} 0.4s ease-in-out;
  border-radius: 10px;

  @media screen and (max-width: 1250px) {
    width: 95%;
  }

  @media screen and (max-width: 991px) {
    width: 768px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
    height: auto;
    border-radius: 0;
  }
`;

const PopUpTitle = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 19px 12px 42px;
  border-radius: 10px 10px 0 0;

  @media screen and (max-width: 991px) {
    padding: 15px 19px 12px 25px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 24px;
    border-radius: 0;
    position: fixed;
    z-index: 22;
    border-bottom: 1px solid ${COLOR_MEDIUM_GRAY};
  }
`;

const TitleMain = styled.h4`
  font-size: 22px;
  font-weight: 700 !important;
  font-family: 'Plus Jakarta Sans', sans-serif;

  @media screen and (max-width: 991px) {
    font-size: 19px;
  }
`;

const CloseBtn = styled.button`
  width: 26px;
  height: 26px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -9px;

  &:hover {
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const PopUpInner = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 50px;
  }
`;

const Form = styled.div`
  width: 58%;
  border-radius: 0 0 0 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StepLine = styled.div`
  width: 100%;
  padding: 11px 42px;
  background: #2ec5ce;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    left: -7px;
    background: #2ec5ce;
    width: 13px;
    height: 13px;
    display: block;
    transform: rotate(45deg);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 11px 24px;
  }
`;

const StepLineDigit = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #ffffff;
  color: #2ec5ce;
  border-radius: 50%;
  font-weight: 500;
`;

const StepLineText = styled.div`
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 13px;

  @media screen and (max-width: 1200px) {
    font-size: 11px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

// const Size = styled.div`
//   padding: 42px 42px 72px;
//   border-right: 1px solid ${COLOR_MEDIUM_GRAY};

//   @media screen and (max-width: 1250px) {
//     padding: 24px 24px 35px;
//   }

//   @media screen and (max-width: 991px) {
//     padding: 24px 24px 30px;
//   }
// `;

const ItemTitle = styled.h5`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  margin-bottom: 6px;

  @media screen and (max-width: 991px) {
    font-size: 17px;
  }
`;

const ItemSubtitle = styled.p`
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #444;
  font-size: 18px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
`;

const LimitText = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  img {
    width: 24px;
    height: 24px;
    margin-top: -2px;
  }
`;

// const SizeInner = styled.div`
//   display: flex;
//   gap: 18px;
//   margin-top: 27px;

//   @media screen and (max-width: 991px) {
//     margin-top: 17px;
//     gap: 9px;
//   }

//   @media screen and (max-width: 420px) {
//     flex-wrap: wrap;
//     gap: 10px;
//   }
// `;

// const SizeItem = styled.div<{ isActive: boolean }>`
//   width: 160px;
//   border-radius: 6px;
//   background: #fff;
//   display: flex;
//   align-items: center;
//   border: ${(props) => (props.isActive ? `3px solid ${COLOR_LIGHT_ORANGE}` : '1px solid #c8c8c8')};
//   padding: ${(props) => (props.isActive ? '8px 11px' : '8px 13px')};
//   box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
//   transition: box-shadow 0.3s ease;

//   &:hover {
//     cursor: pointer;
//     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
//     box-shadow: ${(props) =>
//       props.isActive ? '0 0px 0px rgba(0, 0, 0, 0.1)' : '0 5px 10px rgba(0, 0, 0, 0.12)'};
//     transition: box-shadow 0.3s ease;
//   }

//   @media screen and (max-width: 991px) {
//     padding: ${(props) => (props.isActive ? '4px 8px' : '4px 10px')};
//   }
// `;

// const SizeIcon = styled.img`
//   width: 28px;
//   height: 28px;
//   margin-right: 10px;
//   flex-shrink: 0;

//   @media screen and (max-width: 991px) {
//     margin-right: 7px;
//     width: 24px;
//     height: 24px;
//   }
// `;

// const SizeTitle = styled.div`
//   font-size: 14px;
//   font-family: 'Plus Jakarta Sans', sans-serif;
//   font-weight: 700;

//   @media screen and (max-width: 991px) {
//     font-size: 13px;
//   }
// `;

// const SizeSubtitle = styled.p`
//   font-family: 'Plus Jakarta Sans', sans-serif;
//   letter-spacing: 0.1em;
//   font-size: 13px;
//   color: #545454;
// `;

const PromptInner = styled.div`
  background-color: ${COLOR_YELLOW_LIGHT};
  padding: 48px 42px 56px;
  height: 78%;

  @media screen and (max-width: 1250px) {
    padding: 24px 24px 35px;
  }
`;

const ItemPrompt = styled.div`
  position: relative;
  margin-top: 30px;

  @media screen and (max-width: 991px) {
    margin-top: 17px;
  }

  textarea {
    width: 100%;
    min-height: 222px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 15px 170px 20px 24px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 17px;
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      transition: box-shadow 0.3s ease;
    }

    @media screen and (max-width: 991px) {
      padding: 15px 130px 20px 14px;
      font-size: 16px;
      min-height: 190px;
    }

    @media screen and (max-width: 575px) {
      padding: 14px 20px;
      min-height: 116px;
    }
  }
`;

const ButtonGenerate = styled.button<{ isLoading: boolean }>`
  background: ${COLOR_LIGHT_ORANGE} !important;
  display: flex;
  width: 152px;
  height: 46px;
  border-radius: 7px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 700 !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  bottom: 16px;
  right: 14px;

  &:hover {
    cursor: pointer;
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};
  }

  img {
    width: 25px;
    height: 26px;
  }

  @media screen and (max-width: 991px) {
    width: 122px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 30px;
  }
`;

const Limitation = styled.div`
  padding: 30px 42px 10px;
  border-top: 1px solid ${COLOR_MEDIUM_GRAY};
  border-radius: 0 0 10px 0;
  color: #5c5c5c;

  @media screen and (max-width: 1250px) {
    padding: 20px 24px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px 24px;
  }
`;

const Result = styled.div`
  width: 42%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ResultInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9fafb;
  padding: 40px 20px 46px;
  height: 92.5%;
  border-radius: 0 0 10px 0;
  border-left: 1px solid ${COLOR_MEDIUM_GRAY};

  @media screen and (max-width: 1250px) {
    height: 91.5%;
    padding: 40px 20px 40px;
  }

  @media screen and (max-width: 1250px) {
    height: 91.1%;
    padding: 30px 20px 40px;
  }
`;

const ImageInner = styled.div`
  width: 398px;
  height: 348px;
  background: #f3f3f3;
  border-radius: 5px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid #d9d9d9;

  @media screen and (max-width: 1250px) {
    width: 93%;
    height: 332px;
    overflow: hidden;
  }

  @media screen and (max-width: 991px) {
    width: 93%;
    height: 262px;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    width: 96%;
    height: 332px;
    overflow: hidden;
    margin-bottom: 30px;
  }
`;

const HoverZoomImg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
`;

const AiGeneratedImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const ButtonAddImg = styled.button<{ isActive: boolean; success: boolean; loading: boolean }>`
  width: 398px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 15px;
  color: ${(props) => (props.isActive ? `#000000` : '#525252')};
  background: ${(props) =>
    props.isActive ? `${COLOR_LIGHT_ORANGE} !important` : '#ddd !important'};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transform: translateY(0px);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  background: ${(props) =>
    props.loading
      ? `${COLOR_LIGHT_ORANGE} !important `
      : props.success
        ? '#9ACC3A !important'
        : props.isActive
          ? `${COLOR_LIGHT_ORANGE} !important `
          : '#cecece !important'};

  &:hover {
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};
    box-shadow: ${(props) =>
      props.isActive ? '0 5px 10px rgba(0, 0, 0, 0.05)' : '0px 0px 0px 0px rgba(0, 0, 0, 0)'};
    transform: translateY(-4px);
  }

  span {
    display: flex;
    gap: 10px;
    color: #fff;

    img {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 1250px) {
    width: 93%;
  }

  @media screen and (max-width: 991px) {
    font-size: 15px !important;
  }

  @media screen and (max-width: 768px) {
    width: 96%;
  }
`;

const ButtonDownload = styled.a<{ isAllowed: boolean }>`
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: ${(props) => (props.isAllowed ? `${COLOR_BLUE_4}` : '#cecece')};

  &:hover {
    text-decoration: none;
    text-decoration: ${(props) => (props.isAllowed ? 'none' : 'underline')};
    cursor: ${(props) => (props.isAllowed ? 'pointer' : 'not-allowed')};
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 400;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(5px);
`;

interface ImgAIGeneratorProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
  loading?: boolean;
}

const ImgAIGenerator = (props: ImgAIGeneratorProps) => {
  const { open, onClose, onUpload, loading = false } = props;
  const { logError } = useErrorLogger();
  const { generateAiImage } = useOpenAi();
  const { t } = useTranslation();
  const { userSubscription, user } = useAuth();
  const { addAlert } = useAlert();

  const [dallEPrompt, setDallEPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImg, setGeneratedImg] = useState('');
  const [chosenSize, setChosenSize] = useState('1024x1024');
  const [success, setSuccess] = useState(false);

  const [zoomedImg, setZoomedImg] = useState(false);

  useEffect(() => {
    if (open) {
      setDallEPrompt('');
      setGeneratedImg('');
      setIsLoading(false);
      setChosenSize('1024x1024');
      setSuccess(false);
    }
  }, [open]);

  // const sizeList = [
  //   {
  //     id: 0,
  //     img: '/img/sizeGenImg1.svg',
  //     size: '1024x1024',
  //     subtitle: '(1:1)'
  //   },
  //   {
  //     id: 1,
  //     img: '/img/sizeGenImg3.svg',
  //     size: '1792x1024',
  //     subtitle: '(16:9)'
  //   },
  //   {
  //     id: 2,
  //     img: '/img/sizeGenImg2.svg',
  //     size: '1024x1792',
  //     subtitle: '(9:16)'
  //   }
  // ];

  const { data: rateLimitData, refetch: refetchRateLimit } = useQuery(
    '/bizzy/rate-limit/',
    () => ApiService.get('/bizzy/rate-limit/'),
    {
      select: (response) => response.data?.results?.[0],
      enabled: process.env['APP_TYPE'] !== 'sales' && !!user?.user_id,
      onSuccess: async (data) => {
        if (data === undefined) {
          await ApiService.post('/bizzy/rate-limit/initial-rate-limit/');
          await refetchRateLimit();
        }
      }
    }
  );

  const generateImage = async () => {
    if (!dallEPrompt) {
      return;
    }
    setIsLoading(true);
    try {
      const b64Image = await generateAiImage(dallEPrompt, chosenSize);
      await refetchRateLimit();
      setIsLoading(false);
      setSuccess(false);
      if (b64Image) {
        setGeneratedImg(b64Image);
      }
    } catch (error) {
      setIsLoading(false);
      addAlert?.(t('errors.somethingWentWrong', 'Something went wrong'), 'error');
      logError(error);
    }
  };

  const uploadImage = () => {
    if (generatedImg && generatedImg !== '') {
      const dallEBlob = b64toBlob(generatedImg, 'image/jpeg');
      const dalleImg = new File([dallEBlob], 'dallE.jpeg', { type: 'image/jpeg' });

      onUpload(dalleImg);
      setSuccess(true);
    }
  };

  const downloadImage = () => {
    if (generatedImg && generatedImg !== '') {
      const dallEBlob = b64toBlob(generatedImg, 'image/jpeg');
      const dalleImg = new File([dallEBlob], 'pagewheel-image.jpeg', { type: 'image/jpeg' });

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(dalleImg);
      downloadLink.download = 'pagewheel-image.jpeg';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const leftoverAIRuns = useMemo(() => {
    const AI_IMAGE_COUNTER_MAPPING = {
      bizzy_starter: 5,
      bizzy_business: 20,
      bizzy_founder: 20,
      pw_enterprise_child: 20,
      pw_enterprise_beta: 100,
      pw_enterprise: 100
    } as Record<PlanName, number>;

    if (!userSubscription?.plan_name) return 0;

    return AI_IMAGE_COUNTER_MAPPING[userSubscription?.plan_name] - rateLimitData?.ai_image_counter;
  }, [rateLimitData?.ai_image_counter, userSubscription?.plan_name]);

  return (
    <Modal open={open} onClose={onClose}>
      <MainInner>
        {zoomedImg && <ZoomedImg imgSrc={generatedImg} onClose={() => setZoomedImg(false)} />}

        <RelativeWrapper>
          <PopUp>
            <PopUpTitle>
              <TitleMain>{t('ai.imageGenerator', 'AI Image Generator')}</TitleMain>
              <CloseBtn onClick={onClose}>
                <img src={'/img/close_link.svg'} alt="X" />
              </CloseBtn>
            </PopUpTitle>
            <PopUpInner>
              <Form>
                <StepLine>
                  <StepLineDigit>1</StepLineDigit>
                  <StepLineText>{t('ai.describeImage', 'Describe your image')}:</StepLineText>
                </StepLine>
                {/* <Size>
                  <ItemTitle>Choose the resolution of image</ItemTitle>
                  <ItemSubtitle>
                    We have already chosen the resolution for your image, but you can change it
                  </ItemSubtitle>
                  <SizeInner>
                    {sizeList.map((item) => (
                      <SizeItem
                        key={item.id}
                        onClick={() => setChosenSize(item.size)}
                        isActive={chosenSize === item.size}>
                        <SizeIcon src={item.img} />
                        <div>
                          <SizeTitle>{item.size}</SizeTitle>
                          <SizeSubtitle>{item.subtitle}</SizeSubtitle>
                        </div>
                      </SizeItem>
                    ))}
                  </SizeInner>
                </Size> */}
                <PromptInner>
                  <ItemTitle>{t('ai.addPrompt', 'Add your prompt')}</ItemTitle>
                  <ItemSubtitle>
                    {t(
                      'ai.describeRequest',
                      'Please describe your request to the field and click generate'
                    )}
                  </ItemSubtitle>
                  <ItemPrompt>
                    <textarea
                      placeholder={t('ai.placeholderImage')}
                      onChange={(e) => setDallEPrompt(e.target.value)}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          await generateImage();
                        }
                      }}
                    />
                    <ButtonGenerate
                      onClick={generateImage}
                      isLoading={isLoading}
                      disabled={isLoading}>
                      {isLoading ? (
                        <CircularProgress size={20} color="success" />
                      ) : (
                        <>
                          <img src="/img/refresh_ai.svg" /> {t('common.generate', 'Generate')}
                        </>
                      )}
                    </ButtonGenerate>
                  </ItemPrompt>
                </PromptInner>
                <Limitation>
                  {rateLimitData?.ai_image_counter !== undefined && userSubscription?.plan_name ? (
                    <LimitText>
                      <img src="/img/limitImgGen.svg" alt="AI Counter" />
                      {t('utils.leftOverAIRuns', {
                        replace: {
                          leftoverAIRuns
                        },
                        defaultValue: `You have ${leftoverAIRuns} image generations left`
                      })}
                    </LimitText>
                  ) : (
                    t('loading.default', 'Loading...')
                  )}
                </Limitation>
              </Form>
              <Result>
                <StepLine style={{ background: '#37D2DB' }}>
                  <StepLineDigit>2</StepLineDigit>
                  <StepLineText>
                    {t('ai.getResult', 'Get result and add to the page')}:
                  </StepLineText>
                  <span />
                </StepLine>
                <ResultInner>
                  <ImageInner>
                    {generatedImg && !isLoading && (
                      <HoverZoomImg onClick={() => setZoomedImg(true)}>
                        <img src="/img/zoom-in-hover.svg" alt="Generated image" />
                      </HoverZoomImg>
                    )}

                    {!isLoading && generatedImg ? (
                      <AiGeneratedImg
                        src={generatedImg}
                        alt="Generated image"
                        style={{ position: 'relative', zIndex: '2' }}
                      />
                    ) : (
                      <DefaultImage isLoading={isLoading} />
                    )}
                  </ImageInner>
                  <ButtonAddImg
                    onClick={uploadImage}
                    isActive={generatedImg !== '' && !isLoading}
                    disabled={generatedImg == '' || success || loading || isLoading}
                    loading={loading}
                    success={success}>
                    {loading ? (
                      <>
                        <CircularProgress
                          style={{ marginRight: '10px' }}
                          size={20}
                          color="inherit"
                        />
                        {t('loading.uploading', 'Uploading...')}
                      </>
                    ) : success ? (
                      <span>
                        <img src="/img/uloadedCheck.svg" alt="✓" />{' '}
                        {t('common.uploaded', 'Uploaded')}
                      </span>
                    ) : (
                      t('common.looksGreatAdd', 'Looks GREAT!! Add')
                    )}
                  </ButtonAddImg>
                  <ButtonDownload
                    onClick={downloadImage}
                    isAllowed={generatedImg !== '' && !isLoading}>
                    {t('common.downloadNow', 'Download Now')}
                  </ButtonDownload>
                </ResultInner>
              </Result>
            </PopUpInner>
          </PopUp>
          <Overlay onClick={onClose} />
        </RelativeWrapper>
        <GlobalStyle />
      </MainInner>
    </Modal>
  );
};

export default ImgAIGenerator;
