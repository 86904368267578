import { useMemo, type FC } from 'react';
import type { HeadlineOnlyProps } from './types';

import styled from 'styled-components';

import {
  COLOR_BLUE_6,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const HeadlineOnly = styled.div`
  padding: 90px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const Headline = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  text-align: center;
  line-height: 1.3em;
  ${({ isEmpty, editable }) =>
    isEmpty && editable
      ? `
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V2: FC<HeadlineOnlyProps> = (props) => {
  const {
    backgroundColor = COLOR_BLUE_6,
    title = defaultWebValue,
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <HeadlineOnly
      style={{
        background: shadedBackgroundColor,
        display:
          (hidden && !websiteAssembler) || (!websiteAssembler && isTitleEmpty) ? 'none' : 'block',
        opacity: hidden ? 0.1 : 1
      }}
      className={containerClassname}>
      <Wrapper>
        <Headline
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
      </Wrapper>
    </HeadlineOnly>
  );
};
