import useUtils from './useUtils';

type Severity = 'error' | 'success' | 'info' | 'warning';

const useAlert = () => {
  const { setAlert } = useUtils();

  const addAlert = (message: string, severity: Severity = 'error', timeout = 5000) => {
    setAlert({
      message,
      severity,
      isOpen: true
    });

    setTimeout(() => {
      setAlert({
        message: '',
        severity,
        isOpen: false
      });
    }, timeout);
  };

  return { addAlert };
};

export default useAlert;
