import type { Mockup as MockupTypes } from 'shared/types/index';
import type { FC, HTMLAttributes } from 'react';

import { useMemo } from 'react';
import styled from 'styled-components';
import { Mockup } from './Mockup';

import useSales from 'shared/hooks/useSales';
import { Image as ImageComp } from 'shared/components/Common';
import useBook from 'shared/hooks/useBook';
import { COLOR_LIGHT_GRAY } from 'shared/helpers/colors';

type ComponentTypes = {
  variant: 'semi' | 'full';
  pageCount: number;
};

const Wrapper = styled.div<ComponentTypes>`
  position: relative;
  width: 300px;
  height: ${(props) => (props.variant === 'semi' ? '33em' : '39em')};
  margin-bottom: 5rem;

  ${({ variant, theme: { $width } }) =>
    $width <= 370 &&
    `
     height: ${variant === 'semi' ? '23em' : '30em'};
     width: 180px;
  `}
`;

const StackPageCard = styled.div<ComponentTypes>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;

  height: 100%;
  ${({ variant, pageCount, theme: { $width } }) =>
    $width <= 1254 &&
    `
    ${variant === 'semi' ? 'margin-top: -3rem;' : ''}
    ${pageCount < 3 ? 'margin-top: -15rem' : ''}
  `}

  img {
    position: absolute;
    border: 1px solid ${COLOR_LIGHT_GRAY};
    width: 78%;
  }

  ${(props) =>
    props.variant === 'full'
      ? `
    img:nth-child(1) {
    transform: rotate(7deg);
    top: 0;
    left: 100px;
  }

  img:nth-child(2) {
    transform: rotate(-7deg);
    top: 50px;
    left: 0px;
  }

  img:nth-child(3) {
    transform: rotate(4deg);
    top: 60px;
    left: 100px;
  }

  img:nth-child(4) {
    transform: rotate(-3deg);
    top: 120px;
    left: 5px;
  }

  img:nth-child(5) {
    transform: rotate(10deg);
    top: 150px;
    left: 100px;
  }

  img:nth-child(6) {
    transform: rotate(-4deg);
    top: 220px;
    left: 10px;
  }

  img:nth-child(7) {
    transform: rotate(7deg);
    top: 280px;
    left: 90px;
  }`
      : `
      img:nth-child(1) {
        transform: rotate(7deg);
        top: 190px;
        left: 100px;
      }
    
      img:nth-child(2) {
        transform: rotate(3.35deg);
        top: 170px;
        left: 0;
      }
    
      img:nth-child(3) {
        transform: rotate(4.05deg);
        top: 80px;
        left: 90px;
      }
    
      img:nth-child(4) {
        transform: rotate(-3.96deg);
        top: 50px;
        left: 0;
      }
      `};
`;

const MockupWrapper = styled.div`
  position: absolute;
  bottom: -11%;
`;

const MockUpStyled = styled((props) => <Mockup {...props} />)`
  width: 100%;
  height: auto;
`;

export interface StackedImagesProps extends HTMLAttributes<HTMLDivElement> {
  pageImages: Array<string>;
  mockup: MockupTypes;
  cover_thumbnail: string;
}

export const StackedImages: FC<StackedImagesProps> = (props) => {
  const { pageImages = [], mockup, cover_thumbnail, ...rest } = props;
  const { isCoverDisabled, pages: bookPages } = useBook();
  const { pagesThumbnails } = useSales();

  const pages = useMemo(() => {
    const filteredPageImages = pageImages.filter(Boolean);
    if (filteredPageImages.length < 7) return filteredPageImages.slice(0, 4);
    return filteredPageImages.slice(0, 7);
  }, [pageImages]);

  const variant = useMemo(() => {
    if (pages.length < 7) {
      return 'semi';
    } else {
      return 'full';
    }
  }, [pages]);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || bookPages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages.length, cover_thumbnail, pagesThumbnails, bookPages]);

  return (
    <Wrapper variant={variant} pageCount={pages.length} {...rest}>
      <StackPageCard variant={variant} pageCount={pages.length}>
        {pages.map((x, index) => (
          <ImageComp key={index} src={x} width={500} height={500} alt="page" />
        ))}
      </StackPageCard>
      <MockupWrapper>
        <MockUpStyled
          mockup={mockup?.file}
          mockup_id={mockup?.id}
          cover_thumbnail={coverThumbnail}
        />
      </MockupWrapper>
    </Wrapper>
  );
};
