import type { WhoAmI } from 'shared/types/User';

import { useEffect, useState, createElement } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Modal } from '@mui/material';
import { b64toBlob } from 'shared/helpers/utils';

import QRCode from 'qrcode.react';
import { FormContext } from './context/FormContext';
import { COLOR_LIGHT_ORANGE, COLOR_BLUE_4, COLOR_MEDIUM_GRAY } from 'shared/helpers/colors';

import EmailForm from './components/Forms/EmailForm';
import UrlForm from './components/Forms/UrlForm';
import PhoneNumberForm from './components/Forms/PhoneNumberForm';
import SmsForm from './components/Forms/SmsForm';
import VCard from './components/Forms/VCardForm';
import FaceTimeCallForm from './components/Forms/FaceTimeCallForm';

import { useTranslation } from 'react-i18next';

const tabForms = [
  { name: 'Url', component: UrlForm },
  { name: 'Email', component: EmailForm },
  { name: 'Phone Number', component: PhoneNumberForm },
  { name: 'SMS', component: SmsForm },
  { name: 'VCard', component: VCard },
  { name: 'FaceTime Call', component: FaceTimeCallForm }
];

const imgArr = [
  '/img/qrIcon1.svg',
  '/img/qrIcon2.png',
  '/img/qrIcon3.png',
  '/img/qrIcon4.png',
  '/img/qrIcon5.png',
  '/img/qrIcon6.png',
  '/img/qrIcon7.png',
  '/img/qrIcon8.png',
  '/img/qrIcon9.png',
  '/img/qrIcon10.png',
  '/img/qrIcon11.png',
  '/img/qrIcon12.png',
  '/img/qrIcon13.png',
  '/img/qrIcon14.png',
  '/img/qrIcon15.png',
  '/img/qrIcon16.png'
];

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const MainInner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    height: 100%;
    font-size: 15px;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
    font-weight: inherit;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  button {
    background: none;
    border: none;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    overflow: auto;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const PopUp = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 300;
  height: 100%;
  width: 88%;
  display: flex;
  flex-wrap: wrap;
  animation: ${fadeIn} 0.3s ease-in-out;

  @media screen and (max-width: 991px) {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
    height: auto;
  }
`;

const PopUpTitle = styled.div`
  height: 6vh;
  background: #fff;
  border-bottom: 1px solid ${COLOR_MEDIUM_GRAY};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 19px 0 32px;

  @media screen and (max-width: 768px) {
    padding-left: 24px;
  }
`;

const TitleMain = styled.h4`
  font-size: 17px;
  font-weight: 700 !important;
  font-family: 'Plus Jakarta Sans', sans-serif;
`;

const CloseBtn = styled.button`
  width: 26px;
  height: 26px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const List = styled.div`
  background: #fff;
  height: 94vh;
  width: 22%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: background 0.5s ease;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    transition: background 0.5s ease;
  }

  @media screen and (max-width: 991px) {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  @media screen and (max-width: 991px) {
    width: 21%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const StepLine = styled.div`
  width: 100%;
  padding: 11px 32px;
  background: #2ec5ce;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    left: -7px;
    background: #2ec5ce;
    width: 13px;
    height: 13px;
    display: block;
    transform: rotate(45deg);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 11px 24px;
    min-height: 50px;
  }
`;

const StepLineDigit = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #ffffff;
  color: #2ec5ce;
  border-radius: 50%;
  font-weight: 500;
`;

const StepLineText = styled.div`
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 13px;

  @media screen and (max-width: 1200px) {
    font-size: 11px;
  }

  @media screen and (max-width: 991px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const ItemNav = styled.button`
  display: flex;
  padding: 16px 32px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    transform: translate(0px, 0px);
    transition: transform 0.2s ease;
    position: relative;
    z-index: 4;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  &:hover p {
    transform: translate(5px, 0px);
    transition: transform 0.2s ease;

    @media screen and (max-width: 991px) {
      transform: translate(0px, 0px);
    }
  }

  @media screen and (max-width: 768px) {
    &:not(:last-child) {
      border-bottom: 1px solid ${COLOR_MEDIUM_GRAY};
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px 24px;
  }
`;

const Active = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 93, 99, 0.18) 0.38%, rgba(255, 93, 99, 0) 99.61%);
  left: 0;
  top: 0;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;

const ActiveColor = styled.div`
  width: 6px;
  height: 100%;
  background: #ff5d63;
`;

const Settings = styled.div`
  background: #fff;
  width: 50%;
  height: 95vh;
  overflow-y: hidden;

  @media screen and (max-width: 991px) {
    width: 51%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const SettingsScroll = styled.div`
  overflow-y: auto;
  height: 100%;
  border-right: 1px solid ${COLOR_MEDIUM_GRAY};
  border-left: 1px solid ${COLOR_MEDIUM_GRAY};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: background 0.5s ease;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    transition: background 0.5s ease;
  }

  @media screen and (max-width: 991px) {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  @media screen and (max-width: 768px) {
    overflow-y: none;
  }
`;

const Form = styled.div`
  padding: 22px 32px 46px;
  border-bottom: 1px solid ${COLOR_MEDIUM_GRAY};

  @media screen and (max-width: 991px) {
    padding: 22px 24px 36px;
  }
`;

const ImagesInner = styled.div`
  padding: 28px 32px 114px;

  @media screen and (max-width: 991px) {
    padding: 22px 24px 56px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 42px;
  }
`;

const TitleImage = styled.h5`
  font-size: 17px;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-bottom: 4px;
  width: 100%;
`;

const DescriptionImage = styled.p`
  font-size: 15px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #444;
  width: 100%;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 1.1%;
  margin-top: 20px;
`;

const ImageItem = styled.div<{ selectedImg: boolean }>`
  width: 11.4%;
  height: 68px;
  border: ${(props) => (props.selectedImg ? `3px solid ${COLOR_LIGHT_ORANGE}` : '1px solid #EEE')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s ease;
  }

  img {
    width: 34px;
    height: auto;

    @media screen and (max-width: 991px) {
      width: 22px;
    }

    @media screen and (max-width: 768px) {
      width: 28px;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 51px;
  }

  @media screen and (max-width: 991px) {
    height: 44px;
    width: 16%;
  }

  @media screen and (max-width: 768px) {
    height: 48px;
    width: 17%;
  }
`;

const Result = styled.div`
  background: #f9fafb;
  width: 28%;
  height: 95vh;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ResultInner = styled.div`
  padding: 28px 30px;

  @media screen and (max-width: 991px) {
    padding: 28px 24px;
  }
`;

const FrameQr = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  border-radius: 6px;
  margin-bottom: 36px;
  padding: 26px;

  canvas {
    width: 100% !important;
    height: auto !important;
    display: block;
    margin: 0 auto;
  }
`;

const WrapperQr = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
`;

const ScanAnimation = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 300;
  border-radius: 5px;
`;

const RelativeAnimation = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
`;

const moveDown = keyframes`
  from {
    top: -17%;
  }
  to {
    top: 340%;
  }
`;

const ScanLine = styled.img`
  width: 100%;
  height: auto;
  animation: ${moveDown} 5.5s infinite;
  position: absolute;
  animation-timing-function: linear;
`;

const ImgDef = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  margin: auto;
  border-radius: 5px;
`;

const ButtonAddQr = styled.button<{ isAllowed: boolean; success: boolean; loading: boolean }>`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  border-radius: 10px;
  margin-bottom: 20px;
  background: ${(props) =>
    props.loading
      ? `${COLOR_LIGHT_ORANGE} !important `
      : props.success
        ? '#9ACC3A !important'
        : props.isAllowed
          ? `${COLOR_LIGHT_ORANGE} !important `
          : '#cecece !important'};
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    ${(props) =>
      props.success
        ? `
        cursor: not-allowed !important;`
        : props.isAllowed
          ? `
            cursor: pointer;
            transform: translateY(-4px);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
          `
          : `
            cursor: not-allowed;
            transform: translateY(0px);
            box-shadow: none;
          `}
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
  }

  span {
    display: flex;
    gap: 10px;
    color: #fff;

    img {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 15px !important;
  }
`;

const ButtonDownload = styled.a<{ isAllowed: boolean }>`
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: ${(props) => (props.isAllowed ? `${COLOR_BLUE_4}` : '#cecece')};

  &:hover {
    text-decoration: none;
    text-decoration: ${(props) => (props.isAllowed ? 'none' : 'underline')};
    cursor: ${(props) => (props.isAllowed ? 'pointer' : 'not-allowed')};
  }
`;

const overLay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: .2;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 20%;
  z-index: 400;
  animation: ${overLay} 0.5s ease;
`;

interface QrCodeGeneraterProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
  loading: boolean;
  size?: number;
  data: WhoAmI;
}

const QrCodeGenerator = (props: QrCodeGeneraterProps) => {
  const maxInputTextLength = 1616;
  const { open, onClose, onUpload, loading, size = 1024 } = props;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [currImg, setCurrImg] = useState<string>(`${imgArr[0]}`);
  const [text, setText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isRequared, setIsRequared] = useState<boolean>(true);
  const [scanImg, setScanImg] = useState('');
  const { t } = useTranslation();

  const handleTab = (i: number) => {
    setActiveTab(i);
    setIsRequared(true);
    setSuccess(false);
  };

  const handleImg = (img: string) => {
    setCurrImg(img);
    setSuccess(false);
  };

  useEffect(() => {
    if (!open) {
      setText('');
      setSuccess(false);
      setIsRequared(true);
    }
  }, [open]);

  // generate qr code
  const generateQrCode = () => {
    if (loading || success) return;

    const canvas = document.getElementById('qrCode') as HTMLCanvasElement;

    if (canvas) {
      const qrCodeDataUrl = canvas.toDataURL('image/png');
      const qrCodeBase64 = qrCodeDataUrl.split(',')[1];
      const qrCodeBlob = b64toBlob(qrCodeBase64, 'image/png');
      const qrCodeFile = new File([qrCodeBlob], 'qr-code.png', { type: 'image/png' });

      onUpload(qrCodeFile);
      setSuccess(true);
    }
  };

  // download qr code
  const downloadQrCode = () => {
    const canvas = document.getElementById('qrCode') as HTMLCanvasElement;

    if (canvas) {
      const qrCodeDataUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = qrCodeDataUrl;
      downloadLink.download = 'qr-code.png';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = '/img/scanQR2.png';
    img.onload = () => setScanImg(img.src);
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <MainInner>
        <RelativeWrapper>
          <PopUp>
            <PopUpTitle>
              <TitleMain>{t('utils.qrCode.title', 'QR Code Generator')}</TitleMain>
              <CloseBtn onClick={onClose}>
                <img src={'/img/close_link.svg'} alt="X" />
              </CloseBtn>
            </PopUpTitle>
            <List>
              <StepLine>
                <StepLineDigit>1</StepLineDigit>
                <StepLineText>{t('common.selectType', 'Select Type')}:</StepLineText>
              </StepLine>
              {tabForms.map((tab, index) => (
                <ItemNav key={index} onClick={() => handleTab(index)}>
                  <p>{tab.name}</p>
                  <Active isActive={index === activeTab}>
                    <ActiveColor />
                  </Active>
                </ItemNav>
              ))}
            </List>
            <Settings>
              <StepLine style={{ background: '#37D2DB' }}>
                <StepLineDigit>2</StepLineDigit>
                <StepLineText>
                  {t('common.addContentAndSettings', 'Add Content & Settings')}:
                </StepLineText>
                <span />
              </StepLine>
              <SettingsScroll>
                <FormContext.Provider
                  value={{
                    setText,
                    setSuccess,
                    setIsRequared,
                    isRequared,
                    maxInputTextLength,
                    data: props.data
                  }}>
                  <Form>{createElement(tabForms[activeTab].component)}</Form>
                </FormContext.Provider>
                <ImagesInner>
                  <TitleImage>{t('utils.qrCode.chooseIcon', 'Choose Icon')}:</TitleImage>
                  <DescriptionImage>
                    {t(
                      'utils.qrCode.describeImage',
                      'Select an icon to be displayed in the center of the QR code.'
                    )}
                  </DescriptionImage>
                  <IconWrapper>
                    {imgArr.map((img, index) => (
                      <ImageItem
                        onClick={() => handleImg(img)}
                        selectedImg={img === currImg}
                        key={index}>
                        <img src={img} alt="icon1" />
                      </ImageItem>
                    ))}
                  </IconWrapper>
                </ImagesInner>
              </SettingsScroll>
            </Settings>
            <Result>
              <StepLine style={{ background: '#41E0E9' }}>
                <StepLineDigit>3</StepLineDigit>
                <StepLineText>{t('common.generateAndPreview', 'Generate & Preview')}:</StepLineText>
                <span style={{ background: '#37D2DB' }} />
              </StepLine>
              <ResultInner>
                <FrameQr>
                  {text && !isRequared ? (
                    <WrapperQr>
                      {loading ? (
                        <ScanAnimation>
                          <RelativeAnimation>
                            <ScanLine src={scanImg} alt="Scan Line" />
                          </RelativeAnimation>
                        </ScanAnimation>
                      ) : null}

                      <QRCode
                        id="qrCode"
                        value={text}
                        size={size} // size of QR Code
                        bgColor="#ffffff" // color of background
                        fgColor="#000000" // color of QR Code
                        level="Q" // Issues level ('L', 'M', 'Q', 'H')
                        includeMargin={false} // frame around the QR Code
                        renderAs="canvas"
                        // icon in the center of the QR Code
                        imageSettings={{
                          src: currImg === `${imgArr[0]}` ? '' : currImg,
                          x: undefined,
                          y: undefined,
                          // height: 190,
                          // width: 190,
                          height: size / 4.96,
                          width: size / 4.96,
                          excavate: true
                        }}
                      />
                    </WrapperQr>
                  ) : (
                    <ImgDef src="/img/defQrCode.png" alt="Your Qr Code will Appear Here" />
                  )}
                </FrameQr>

                <ButtonAddQr
                  onClick={generateQrCode}
                  isAllowed={!isRequared}
                  loading={loading}
                  success={success}>
                  {loading ? (
                    t('loading.uploading', 'Uploading...')
                  ) : success ? (
                    <span>
                      <img src="/img/uloadedCheck.svg" alt="✓" /> {t('common.uploaded', 'Uploaded')}
                    </span>
                  ) : (
                    'Upload QR Code'
                  )}
                </ButtonAddQr>
                <ButtonDownload onClick={downloadQrCode} isAllowed={!isRequared}>
                  {t('common.downloadNow', 'Download Now')}
                </ButtonDownload>
              </ResultInner>
            </Result>
          </PopUp>
          <Overlay onClick={onClose} />
        </RelativeWrapper>
        <GlobalStyle />
      </MainInner>
    </Modal>
  );
};

export default QrCodeGenerator;
