import { useMemo, useState, type FC } from 'react';
import type { BookBaseColor } from 'shared/types/Branding';

import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './Common/Wrapper';

import {
  COLOR_GRAY_14,
  COLOR_GRAY_16,
  COLOR_LIGHT_GRAY,
  COLOR_ORANGE
} from 'shared/helpers/colors';
import { filterBackgroundColor } from 'shared/helpers/website';

import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import useSales from 'shared/hooks/useSales';
import { FlexBox } from '../Common';
import useAuth from 'shared/hooks/useAuth';

const Container = styled((props) => <Wrapper {...props} />)`
  padding: 6rem 2rem;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  img {
    cursor: pointer;
  }
`;

const fadeInTwo = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px) scale(0.75);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const PopUpLink = styled.div`
  width: 525px;
  position: absolute;
  bottom: 52px;
  z-index: 55;
  background: #fff8e3;
  border-radius: 7px;
  left: 15%;
  border: 1px solid #acacac;
  cursor: default;
  animation: ${fadeInTwo} 0.2s ease-in-out;
  zoom: 1.8;

  @media screen and (max-width: 991px) {
    width: 475px;
  }

  @media screen and (max-width: 768px) {
    width: 375px;
  }

  @media screen and (max-width: 465px) {
    width: 300px;
  }

  @media screen and (max-width: 340px) {
    width: 270px;
    left: -99px;
  }
`;

const ClosePopUpLink = styled.img`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 9px;
  height: 9px;
  cursor: pointer;
`;

const InnerRelativeLink = styled.div`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 7px 0px;
  border-radius: 7px;
`;

const PopUpInner = styled.div`
  padding: 28px 34px 38px;
  text-align: left;

  @media screen and (max-width: 991px) {
    padding: 20px 24px 28px;
  }
`;

const LinkTitle = styled.h3`
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
  font-weight: 700;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  position: relative;

  &:hover input {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0));
    transition: filter 0.3s ease;
  }
`;

const LinkInput = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${COLOR_LIGHT_GRAY};
  border-right: none;
  padding: 10px 15% 10px 14px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  transition: filter 0.3s ease;
`;

const StyledLink = styled.span`
  text-decoration: underline;
  color: #377dff;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  text-align: center;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    li {
      font-weight: 600;
      padding: 1rem 0;
      cursor: pointer;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    li:hover {
      color: ${COLOR_GRAY_14};
    }
  }

  p {
    font-weight: 600;
    line-height: 130%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${COLOR_GRAY_16};
  flex-wrap: wrap;
`;

const CheckBtn = styled.button`
  height: 48px;
  width: 15%;
  border-radius: 0px 10px 10px 0px !important;
  border: 1px solid ${COLOR_LIGHT_GRAY};
  background: ${COLOR_ORANGE};
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;

  img {
    width: 17px;
    height: 12px;
  }

  &:hover {
    background: #c7a727;
    transition: background 0.3s ease;
    cursor: pointer;
  }
`;

export type FooterProps = {
  textColor: BookBaseColor;
};

export const Footer: FC<FooterProps> = (props) => {
  const { textColor } = props;

  const { salesExtraBody, syncFooterValue, updateSalesExtraBody, hidePagewheel } = useSales();
  const { userSubscription } = useAuth();
  const { t } = useTranslation();
  const { updateActiveElement, resetActiveElement, editable, updateFooterActive } =
    useWebsiteEditor();
  const [link, setLink] = useState('');
  const [linkType, setLinkType] = useState<'terms' | 'privacy'>();

  const updateTermsAndPrivacy = (
    e: React.MouseEvent<HTMLAnchorElement>,
    type: 'terms' | 'privacy'
  ) => {
    if (editable) {
      e.preventDefault();
      setLinkType(type);
    }
  };

  const handleCloseLink = () => {
    setLinkType(undefined);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!linkType) return;
    updateSalesExtraBody({
      ...salesExtraBody,
      [linkType]: link
    });
    setLink('');
    setLinkType(undefined);
  };

  const privacy = useMemo(() => {
    return salesExtraBody['privacy'] || 'https://learn.pagewheel.com/user-privacy-data-use-policy/';
  }, [salesExtraBody]);

  const terms = useMemo(() => {
    return salesExtraBody['terms'] || 'https://learn.pagewheel.com/user-privacy-data-use-policy/';
  }, [salesExtraBody]);

  const displayPagewheel = useMemo(() => {
    const restrictedPlans = ['bizzy_free', 'bizzy_starter'];

    if (editable && restrictedPlans.includes(userSubscription?.plan_name || '')) {
      return true;
    }

    if (editable) return false;

    return !hidePagewheel;
  }, [editable, hidePagewheel, userSubscription?.plan_name]);

  return (
    <Container>
      {linkType ? (
        <FlexBox position="relative">
          <PopUpLink>
            <InnerRelativeLink>
              <ClosePopUpLink onClick={handleCloseLink} src="/img/close_link.svg" alt="X" />
              <PopUpInner>
                <LinkTitle>Put your {linkType} link below:</LinkTitle>
                <Form onSubmit={handleSubmit}>
                  <LinkInput
                    placeholder="https://app.pagewheel.com"
                    required
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    onClick={(e) => e.currentTarget.focus()}
                  />
                  <CheckBtn type="submit">
                    <img src={'/img/color_check.svg'} alt="✓" />
                  </CheckBtn>
                </Form>
              </PopUpInner>
            </InnerRelativeLink>
          </PopUpLink>
        </FlexBox>
      ) : null}
      <InnerContainer>
        <MenuContainer>
          <ul>
            <li>
              <a
                onClick={(e) => updateTermsAndPrivacy(e, 'terms')}
                style={{ color: filterBackgroundColor('#fff', textColor) }}
                rel="noreferrer"
                href={terms}
                target="_blank">
                {t('terms.terms', { defaultValue: 'Terms' })}
              </a>
            </li>
            <li>
              <a
                onClick={(e) => updateTermsAndPrivacy(e, 'privacy')}
                style={{ color: filterBackgroundColor('#fff', textColor) }}
                rel="noreferrer"
                href={privacy}
                target="_blank">
                {t('terms.privacy', { defaultValue: 'Privacy' })}
              </a>
            </li>
          </ul>
          <p style={{ color: filterBackgroundColor('#fff', textColor) }}>
            {displayPagewheel ? (
              <>
                {t('terms.createdBy', { defaultValue: 'Created by' })}{' '}
                <StyledLink
                  onClick={() => {
                    window.open('https://pagewheel.com/', '_blank');
                  }}>
                  Pagewheel.com
                </StyledLink>
              </>
            ) : (
              <>
                {editable || salesExtraBody['footer'] ? `${t('common.by', 'by')} ` : ''}
                <span
                  style={{ display: 'inline-flex' }}
                  onClick={(e) => {
                    updateActiveElement(e, false);
                    updateFooterActive(true);
                  }}
                  onBlur={() => {
                    resetActiveElement();
                    syncFooterValue();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: salesExtraBody['footer'] || `<p>Your name here</p>`
                  }}
                />
              </>
            )}{' '}
            © {new Date().getFullYear()}{' '}
            {t('terms.allRightsReserved', { defaultValue: 'All Rights Reserved' })}.
          </p>
        </MenuContainer>
      </InnerContainer>
    </Container>
  );
};
