import styled from 'styled-components';
const MainInner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    height: 100%;
    font-size: 15px;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  @media screen and (max-width: 768px) {
    overflow: auto;
  }
`;

const Img = styled.img`
  max-width: auto;
  max-height: 80vh;
  object-fit: contain;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 17.9px 0px rgba(0, 0, 0, 0.13);

  @media screen and (max-width: 768px) {
    max-height: auto;
    max-width: 96%;
  }
`;

const CloseBtn = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
    height: 70%;
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 20%;
  z-index: 400;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

interface ZoomedImgProps {
  imgSrc: string;
  onClose: () => void;
}

const ZoomedImg = (props: ZoomedImgProps) => {
  const { imgSrc, onClose } = props;

  return (
    <MainInner>
      <RelativeWrapper>
        <CloseBtn onClick={onClose}>
          <img src="/img/close_link-white.svg" alt="X" />
        </CloseBtn>
        <Img src={imgSrc} alt="Generated Image" />
        <Overlay onClick={onClose} />
      </RelativeWrapper>
    </MainInner>
  );
};

export default ZoomedImg;
