import type { EventProps } from './types';
import { V1 } from './V1';
import { V2 } from './V2';
import { V3 } from './V3';

export type { EventProps };

export default {
  V1,
  V2,
  V3
};
