import type { FC } from 'react';
import type { PhotoProps } from './types';

import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Wrapper } from '../Common';

import { ApiService, baseMediaEndpoint } from 'shared/helpers/index';
import { useAlert, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { HoverableDropzone } from '../HoverableDropzone';
import { Image as ImageComp } from 'shared/components/Common';
import { useMutation } from 'react-query';

const Photo = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    flex-direction: column-reverse;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 60px 15px;
    flex-direction: column-reverse;
  `}
`;

const Container = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const imageStyles = css`
  width: 560px;
  height: 560px;
  border: none;
  position: relative;
  overflow: hidden;

  & img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const V8: FC<PhotoProps> = (props) => {
  const { meta, hidden, id } = props;
  const {
    editable,
    containerClassname,
    websiteAssembler,
    updateActiveElement,
    resetActiveElement
  } = useWebsiteEditor();

  const { addAlert } = useAlert();
  const { salesPageId, updateImageMeta, handleRemoveComponent, moveComponent, type } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const { mutateAsync: uploadeImage, isLoading } = useMutation({
    mutationFn: (payload: FormData) =>
      ApiService.post(`/sales/sales-page/${salesPageId}/media/`, payload),
    onError: () => {
      addAlert('Please upload png, jpg and jpeg image only', 'warning');
    }
  });

  const aboutMeImage = useMemo(() => {
    if (meta['image'] || meta['image_1']) {
      if (baseMediaEndpoint?.includes('localhost'))
        return `${baseMediaEndpoint}${meta['image'] || meta['image_1']}`;
      return meta['image'];
    }
    return '/img/lead_bg.png';
  }, [meta]);

  const handleUpload = async (file: File) => {
    if (!meta['accessorKey'] || !editable) return;

    try {
      const formData = new FormData();
      formData.append('type', meta['accessorKey']);
      formData.append('file', file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setResize({ width, height });
          updateImageMeta(meta['accessorKey'], width, 'width');
          updateImageMeta(meta['accessorKey'], height, 'height');
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);

      const { data } = await uploadeImage(formData);
      updateImageMeta(meta['accessorKey'], data.file, 'image');
    } catch (_error) {
      addAlert('Something went wrong', 'error');
    }
  };

  return (
    <Photo
      onClick={() => {
        if (id) {
          const selected = document.querySelector(`.${id}`);
          updateActiveElement({ currentTarget: selected } as never);
        }
      }}
      onBlur={resetActiveElement}
      id="no-editor-element"
      className={`${containerClassname} ${id}`}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'flex',
        opacity: hidden ? 0.3 : 1
      }}
      data-accessor-parent={meta['accessorKey']}
      data-accessorkey="title">
      <Container>
        <HoverableDropzone
          isSalePage
          css={imageStyles as never}
          disabled={!editable}
          allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
          onUpload={handleUpload}
          loading={isLoading}
          alreadyUploaded={Boolean(aboutMeImage)}
          imageFile={aboutMeImage}
          resizeSelector={editable}
          handleResize={({ width, height }) => {
            setResize({ width, height });
            updateImageMeta(meta['accessorKey'], width, 'width');
            updateImageMeta(meta['accessorKey'], height, 'height');
          }}
          imageAspectRatio={{
            ratio: '1x1',
            pixels: '560x560'
          }}
          initialPosition={{
            x: meta['x_1'] || 0,
            y: meta['y_1'] || 0,
            width: meta['d_width_1'],
            height: meta['d_height_1']
          }}
          meta={meta}
          positionEnabled
          handlePosition={(width: number, height: number, x: number, y: number) => {
            updateImageMeta(meta['accessorKey'], x, 'x_1');
            updateImageMeta(meta['accessorKey'], y, 'y_1');
            updateImageMeta(meta['accessorKey'], width, 'd_width_1');
            updateImageMeta(meta['accessorKey'], height, 'd_height_1');
          }}
          extraControls
          deleteComponent={() => handleRemoveComponent(meta['accessorKey'], type)}
          moveComponent={(direction: 1 | -1) =>
            moveComponent(direction, type, meta['accessorKey'])
          }>
          <ImageComp
            src={aboutMeImage}
            width={resize.width || 500}
            height={resize.height || 500}
            alt="Photo"
          />
        </HoverableDropzone>
      </Container>
    </Photo>
  );
};
