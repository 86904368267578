import type { FC, ButtonHTMLAttributes } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';
import type { BookBaseColor } from 'shared/types/Branding';

import styled from 'styled-components';

import { COLOR_WHITE, COLOR_RED_3, COLOR_BUTTON_OUTLINE } from 'shared/helpers/colors';

/**
 * Styled button component with various styling props and responsive design.
 * @param {string} [props.backgroundColor=COLOR_RED_3] - Background color for the button.
 * @param {FlattenSimpleInterpolation} [props.css] - Optional additional CSS styling.
 */
type StyleButtonProps = { backgroundColor: string; css?: FlattenSimpleInterpolation };
const StyledButton = styled.button<StyleButtonProps>`
  min-height: 10px;
  padding: 24px 40px;
  border-radius: 10px;
  text-align: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: 1px solid ${COLOR_BUTTON_OUTLINE};

  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
    transition:
      transform 0.2s ease 0s,
      box-shadow 0.2s ease 0s;
  }

  @media screen and (max-width: 1254px) {
    font-size: 18px;
  }

  @media screen and (max-width: 991px) {
    padding: 17px 28px;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 375px) {
    padding: 18px 10px;
    font-size: 15px;
  }

  ${(props) => props.css}
`;

/**
 * Styled paragraph element used inside the button to display the text.
 */
const Paragraph = styled.span<{ color?: string }>`
  color: ${(props) => props.color || COLOR_WHITE};
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  textColor?: BookBaseColor;
  backgroundColor?: string;
  css?: FlattenSimpleInterpolation;
  buttonTextColor?: string;
}

/**
 * Button component for rendering a styled button with customizable text, colors, and additional styles.
 *
 * @param {ButtonProps} props - The props for the button component.
 * @param {string} props.text - The text to be displayed inside the button.
 * @param {BookBaseColor} [props.textColor] - Optional color for the text.
 * @param {string} [props.backgroundColor=COLOR_RED_3] - Optional background color for the button.
 * @param {FlattenSimpleInterpolation} [props.css] - Optional additional CSS styling.
 * @returns {JSX.Element} The rendered button component.
 * @example
 * return (
 *   <Button
 *     text="Click Me"
 *     backgroundColor="#ff0000"
 *     css={customCss}
 *     onClick={() => alert('Button clicked!')}
 *   />
 * )
 */
export const Button: FC<ButtonProps> = (props) => {
  const { text, backgroundColor = COLOR_RED_3, css, buttonTextColor, ...rest } = props;
  return (
    <StyledButton backgroundColor={backgroundColor} css={css} {...rest}>
      <Paragraph color={buttonTextColor}>{text}</Paragraph>
    </StyledButton>
  );
};
