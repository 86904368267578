import styled from 'styled-components';

const Paragraph = styled.h1<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '28px'};
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
`;

const Letter = styled.span`
  color: ${(props) => props.color};
`;

interface PageWheelProps {
  className?: string;
  fontSize?: string;
}

const PageWheel: React.FC<PageWheelProps> = (props) => {
  const { className, fontSize } = props;
  return (
    <Paragraph className={className} fontSize={fontSize}>
      <Letter color="#1CAEB2">p</Letter>
      <Letter color="#9ACC3A">a</Letter>
      <Letter color="#FF8700">g</Letter>
      <Letter color="#FF5D63">e</Letter>
      <Letter color="#1CAEB2">w</Letter>
      <Letter color="#6ACC3C">h</Letter>
      <Letter color="#FF8700">e</Letter>
      <Letter color="#FF5D63">e</Letter>
      <Letter color="#1CAEB2">l</Letter>
    </Paragraph>
  );
};

export default PageWheel;
