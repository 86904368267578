import type { FormProps } from './V1';

import { V1 } from './V1';
import { V2 } from './V2';

export type { FormProps };

export default {
  V1,
  V2
};
