import type { ComponentPropsWithoutRef, FC } from 'react';
import styled from 'styled-components';
import type { FlattenSimpleInterpolation } from 'styled-components';

import { COLOR_ORANGE, COLOR_MEDIUM_GRAY } from 'shared/helpers/colors';

const CheckboxLabel = styled.label<{ css?: FlattenSimpleInterpolation }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  line-height: 1.35em;
  ${(props) => props.css}
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
`;

const CustomCheckbox = styled.div<{ checked: boolean; backgroundColor?: string }>`
  width: 22px;
  height: 22px;
  border: 1px solid ${COLOR_MEDIUM_GRAY};
  border-radius: 6px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 22px;
  background: #fff;

  &:before {
    content: ' ';
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.checked ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
    background: ${(props) =>
      props.checked
        ? `url(${'/img/check_form.svg'}) ${props.backgroundColor ?? COLOR_ORANGE}`
        : 'none'};
    background-size: ${(props) => (props.checked ? '60%' : 'auto')};
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
  }
`;

const TextLabel = styled.span`
  width: 93%;
  ${({ theme: { $tinyTextFont } }) => `font-size: ${$tinyTextFont}`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 84%;
  `}
`;

export interface CheckBoxProps extends ComponentPropsWithoutRef<'input'> {
  checked: boolean;
  label?: string;
  backgroundColor?: string;
  cssContainer?: FlattenSimpleInterpolation;
}

export const Checkbox: FC<CheckBoxProps> = (props) => {
  const { backgroundColor, checked, onChange, label, className, cssContainer } = props;

  return (
    <CheckboxLabel className={className} css={cssContainer}>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <CustomCheckbox
        checked={checked}
        backgroundColor={backgroundColor}
        className="checkBoxCustom"
      />
      {label ? <TextLabel>{label}</TextLabel> : null}
    </CheckboxLabel>
  );
};
