import type { BookBaseColor } from 'shared/types/Branding';
import type { PaymentFormSubscription } from './V1';
import type { Stripe } from '@stripe/stripe-js';

import styled from 'styled-components';
import { Wrapper } from '../Common';
import { Elements } from '@stripe/react-stripe-js';
import { Second } from '../Form/Second';
import useSalesForm from 'shared/hooks/useSalesForm';
import { isDummyDataLocalHost } from 'shared/helpers/utils';

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  padding: 80px 0 94px;
  position: relative;
  background: ${(props) => props.backgroundColor};

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 62px 0 76px;
  `}
`;

const FormWrapper = styled.div`
  position: relative;
  width: 1064px;
  padding: 52px 115px 72px;
  margin: 0 auto;
  word-wrap: break-word;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 100%;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 52px 75px 72px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 52px 35px 72px;
  `}
`;

const FormBg = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

interface Props {
  paymentFormSubscription: PaymentFormSubscription;
  stripePromise: Promise<Stripe | null> | null;
  shadedBackgroundColor: string;
  containerClassname: string;
  backgroundColor: string;
  textColor: BookBaseColor;
}

const RecurringSecondForm = ({
  paymentFormSubscription,
  containerClassname,
  shadedBackgroundColor,
  stripePromise,
  backgroundColor,
  textColor
}: Props) => {
  const { handleSecondRecurrent } = useSalesForm();

  const handleSecond = () => {
    if (!paymentFormSubscription) return;
    handleSecondRecurrent(
      paymentFormSubscription.book_order_sub.email,
      paymentFormSubscription.book_order_sub.uuid
    );
  };

  return (
    <Container
      backgroundColor={shadedBackgroundColor}
      className={containerClassname}
      id="recurring_form">
      <Wrapper>
        <FormWrapper>
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: paymentFormSubscription.client_secret }}>
            <Second
              orderData={{
                ...paymentFormSubscription.book_order_sub,
                city: isDummyDataLocalHost() ? 'New York' : '',
                state: isDummyDataLocalHost() ? 'NY' : '',
                street: isDummyDataLocalHost() ? '123 Main St' : '',
                apartment: isDummyDataLocalHost() ? 'Apt 1' : '',
                zipcode: isDummyDataLocalHost() ? '10001' : ''
              }}
              backgroundColor={backgroundColor}
              callback={handleSecond}
              textColor={textColor}
            />
            <FormBg />
          </Elements>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default RecurringSecondForm;
