import type { FC } from 'react';

import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

import { COLOR_BLUE_6, COLOR_GRAY_13, COLOR_YELLOW } from 'shared/helpers/colors';

import { Wrapper } from './Common';

const Container = styled.div`
  background: ${COLOR_BLUE_6};
  padding: 80px 0;

  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  display: block !important;
  margin: 0 auto;
  width: 64px !important;
  height: 64px !important;
`;

const Title = styled.h2`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  text-align: center;
  line-height: 1.35em;
  margin-bottom: 18px;
  margin-top: 50px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 26px;
  `}
`;

const Subtitle = styled.p`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.45em;
  color: ${COLOR_GRAY_13};
  text-align: center;
`;

const Button = styled.button`
  display: block;
  color: #1879b9;
  margin: 0 auto;
  cursor: pointer;
`;

export const OrderProcessing: FC = () => {
  return (
    <Container>
      <Wrapper>
        <StyledCircularProgress style={{ color: `${COLOR_YELLOW}`, margin: '0 auto' }} />
        <Title>Your order’s being processed.</Title>
        <Subtitle>
          If you’re not automatically redirected,
          <Button type="button">refresh this page</Button>
        </Subtitle>
      </Wrapper>
    </Container>
  );
};
