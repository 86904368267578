import styled from 'styled-components';

const WrapperInput = styled.div`
  position: relative;
  margin-top: 30px;

  span {
    color: #ff5d63;
    font-size: 14px;
  }

  label {
    position: absolute;
    background: linear-gradient(180deg, #fff 70%, rgba(255, 255, 255, 0) 50%);
    top: -10px;
    left: 10px;
    padding: 0 24px 0 10px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em;
    z-index: 4;

    span {
      color: #ff5d63;
      padding-left: 5px;
    }
  }
`;

const InputTextArea = styled.textarea`
  display: flex;
  width: 100%;
  height: 108px;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  padding: 0 20px;
  background: #fcfcfc;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 17px;
  padding-top: 15px;
`;
interface DefInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  lableName: string;
}

const DefInput = (props: DefInputProps) => {
  const { name, value, onChange, lableName } = props;

  return (
    <WrapperInput>
      <label>{lableName}</label>
      <InputTextArea rows={4} name={name} value={value} onChange={onChange} />
    </WrapperInput>
  );
};

export default DefInput;
