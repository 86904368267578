import type {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  BoxShadowProps,
  GridTemplateColumnsProps,
  GridColumnGapProps,
  GridRowGapProps,
  GridColumnProps,
  TextAlignProps,
  FontFamilyProps
} from 'styled-system';

import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  textAlign,
  boxShadow,
  gridTemplateColumns,
  gridColumnGap,
  gridRowGap,
  gridColumn,
  fontFamily
} from 'styled-system';

type BlockBoxProps = LayoutProps &
  FlexboxProps &
  SpaceProps &
  BoxShadowProps &
  PositionProps &
  TextAlignProps &
  GridTemplateColumnsProps &
  GridColumnGapProps &
  GridColumnProps &
  GridRowGapProps &
  BorderProps &
  FontFamilyProps &
  ColorProps & { cursor?: string };
/**
 * A versatile styled-component Box that supports various styled-system props.
 *
 * This component can handle layout, flexbox, space, boxShadow, position,
 * text alignment, grid layout, borders, colors, and custom cursor styling.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.cursor] - Optional cursor style.
 * @example
 * return (
 *   <BlockBox
 *     display="flex"
 *     justifyContent="center"
 *     alignItems="center"
 *     p={3}
 *     bg="lightgray"
 *     border="1px solid gray"
 *     cursor="pointer"
 *   >
 *     Content
 *   </BlockBox>
 * )
 */
export const BlockBox = styled.div<BlockBoxProps>`
  ${layout}
  ${flexbox}
  ${space}
  ${gridTemplateColumns}
  ${gridColumnGap}
  ${boxShadow}
  ${gridRowGap}
  ${gridColumn}
  ${textAlign}
  ${position}
  ${color}
  ${border}
  ${fontFamily}
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${`${props.cursor}`};
    `}
`;
