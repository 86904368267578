import { useTranslation } from 'react-i18next';
import { ApiService } from 'shared/helpers/api';
import {
  capitalizeFirstLetter,
  languageMapping,
  toBase64,
  wrapTextWithHtmlTag
} from 'shared/helpers/index';
import type { KeyValueString, Prompt, SalesPrompt } from 'shared/types/index';

interface ParsedOutput {
  [key: string]: string;
}

interface Section {
  [key: string]: KeyValueString;
}

/**
 * @function useOpenAi
 *
 * @description This hook is used to generate text using the OpenAI API.
 * It also supports parsing raw output from the API. But in newer versions, it is
 * used to generate text using the prompt and input. All the parsing is happening on the backend.
 */
const useOpenAi = () => {
  const { i18n } = useTranslation();

  const parsed: KeyValueString = {};

  const parseOutput = (output: string): ParsedOutput => {
    const lines = output.split('\n');
    const result: ParsedOutput = {};
    let currentField = '';

    lines.forEach((line) => {
      const trimmed: string = line.trim();

      if (trimmed.includes(':')) {
        currentField = trimmed.substring(0, trimmed.indexOf(':'));
        result[currentField.toLowerCase()] = `${trimmed
          .substring(trimmed.indexOf(':') + 1)
          .trim()}\r\n`;
      } else if (currentField !== '') {
        result[currentField.toLowerCase()] += `${trimmed}\r\n`;
      }
    });

    return result;
  };

  const parseList = (listString: string): string[] => {
    const listArray: string[] = [];
    const listLines = listString?.split('\n');

    listLines?.forEach((line) => {
      const trimmedLine = line?.trim();
      const match = trimmedLine.match(/^- (.*)/);

      if (match) {
        listArray.push(match[1].trim());
      } else {
        const numberedMatch =
          trimmedLine.match(/^(\d+\.)\s*(.*)/) || trimmedLine.match(/^(\d+\))\s*(.*)/);

        if (numberedMatch) {
          listArray.push(numberedMatch[2].trim());
        } else if (trimmedLine !== '') {
          listArray.push(trimmedLine);
        }
      }
    });

    return listArray;
  };

  const parseSection = (output: string): Section => {
    const array: Section = {
      headline: {},
      body: {}
    };
    const outputArray = output.split('\n');
    let key = '';
    let value = '';

    outputArray.forEach((line) => {
      if (line.includes('Section')) {
        if (key !== '') {
          array['body'][key] = value.trim();
          value = '';
        }

        const [parsedKey, headline] = line.split(':');
        key = parsedKey.trim().toLocaleLowerCase();
        const sanitizedHeadline = headline.replace('//END//', '');

        array['headline'][key] = sanitizedHeadline.trim();
      } else if (line.includes('//END//')) {
        array['body'][key] = value.trim();
        key = '';
        value = '';
      } else if (key !== '') {
        const sanitizedLine = line.replace('//END//', '');
        value += `${sanitizedLine}\n`;
        array['body'][key] += sanitizedLine;
      }
    });

    if (key !== '') {
      array['body'][key] = value.trim();
    }

    return array;
  };

  const parsePrompt = (text: string, promptItem: Prompt | SalesPrompt) => {
    const section = parseSection(text);
    const field = parseOutput(text);

    Object.keys(promptItem.mapping).forEach((key) => {
      const mappedType = promptItem.types?.[key];
      const mapping = promptItem.mapping[key];

      if (mapping === 'alltext') {
        parsed[key] = text;
      } else if (mappedType === 'section') {
        const [sectionKey, type]: [string, string] = promptItem.mapping[key].split('.') as [
          string,
          string
        ];

        if (section[type] && section[type]?.[sectionKey]) {
          parsed[key] = section[type][sectionKey];
        }
      } else if (mappedType === 'field') {
        const map = promptItem.mapping[key];
        parsed[key] = field[map];
      } else if (mappedType === 'list') {
        const [keyword, id] = promptItem.mapping[key].split('.');

        const listItem = parseList(field[keyword])[Math.max(0, Number(id) - 1)];
        parsed[key] = listItem;
      }
    });

    const prompts = text.split('\n').filter(Boolean);

    const mapping: KeyValueString = Object.entries(promptItem.mapping).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {} as KeyValueString
    );

    const promptsWithVariables: KeyValueString = {};

    Object.keys(mapping).forEach((key) => {
      const prompt = prompts.find((p) => p.includes(key));
      const variableKey = mapping[key];
      if (prompt) {
        promptsWithVariables[variableKey] = prompt.replace(`${key}:`, '').trim();
      }
    });

    return parsed;
  };

  const runAi = async (prompt: string, input: string) => {
    const response = await ApiService.post(
      `/book-generator/pages/generate-inputs/`,
      {
        prompt,
        input
      },
      {
        timeout: 45000
      }
    );

    return response.data;
  };

  const generateText = async (
    prompt: Prompt | SalesPrompt,
    input: string,
    input2?: string,
    type?: 'diy' | 'sales'
  ) => {
    const updatedPrompt = input2
      ? prompt.prompt.replace('{$2INPUT}', `"${input2}"`)
      : prompt.prompt;

    const response = await ApiService.post(
      `/book-generator/pages/generate-inputs/`,
      {
        prompt: updatedPrompt,
        input
      },
      {
        timeout: 45000
      }
    );

    const parsedPrompt = parsePrompt(response.data, prompt);
    Object.keys(parsedPrompt).forEach((key) => {
      if (!parsedPrompt[key]) return;
      parsedPrompt[key] = capitalizeFirstLetter(parsedPrompt[key]);
      parsedPrompt[key] = parsedPrompt[key].replaceAll('"', '');
      if (parsedPrompt[key].endsWith('\r\n\r\n')) {
        parsedPrompt[key] = parsedPrompt[key].slice(0, -4);
      }
      if (parsedPrompt[key].startsWith('\r\n\r\n')) {
        parsedPrompt[key] = parsedPrompt[key].slice(4);
      }
      if (parsedPrompt[key].startsWith('\r\n')) {
        parsedPrompt[key] = parsedPrompt[key].slice(2);
      }
      if (parsedPrompt[key].endsWith('\r\n')) {
        parsedPrompt[key] = parsedPrompt[key].slice(0, -2);
      }
      if (type === 'diy') {
        parsedPrompt[key] = parsedPrompt[key].replaceAll('\r\n\r\n', '<br>');
        parsedPrompt[key] = parsedPrompt[key].replaceAll('\r\n', '<br>');

        if (prompt?.is_breakline_enabled) {
          parsedPrompt[key] = parsedPrompt[key]?.replaceAll('. ', '<br>');
        }
        parsedPrompt[key] = wrapTextWithHtmlTag(parsedPrompt[key], 'p');
      } else if (prompt?.is_breakline_enabled) {
        parsedPrompt[key] = parsedPrompt[key]?.replaceAll('. ', '<p><br></p>');
      }
    });

    if (i18n.language !== 'en' && i18n.language && languageMapping[i18n.language]) {
      const selectedLanguage = languageMapping[i18n.language];
      for (const key in parsedPrompt) {
        const value = parsedPrompt[key];
        const translated = await runAi(
          `Translate This to ${selectedLanguage}: ${value}`,
          'Translation'
        );
        parsedPrompt[key] = translated;
      }
    }

    return {
      raw: response.data,
      prompt: parsedPrompt
    };
  };

  const generateAiImage = async (prompt: string, size: string) => {
    if (localStorage.getItem('isAiMocked') === 'true') {
      return '/img/ai-faker-image.png';
    }
    const response = await ApiService.post(
      `/book-generator/pages/generate-image/`,
      {
        prompt,
        size
      },
      {
        timeout: 90000
      }
    );
    return toBase64(response.data);
  };

  return { generateText, runAi, generateAiImage };
};

export default useOpenAi;
