import { useMemo, type FC } from 'react';
import type { GuaranteeProps } from './types';

import styled from 'styled-components';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 4;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column-reverse;
    `}
`;

const TitleWrapper = styled.div`
  width: 65%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-top: 30px;
    width: 100%;
    `}
`;

const IconInner = styled.div`
  width: 339px;
  height: 339px;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 4px 16.6px rgba(0, 0, 0, 0.07));
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 209px;
    height: 209px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin: 0 auto;
    margin-bottom: 38px;
    `}
`;
type SvgIconProps = {
  backgroundColor: string;
};

const SvgIcon = styled.svg<SvgIconProps>`
  width: 170px;
  height: 170px;
  margin-top: -10px;

  .guarantyTwo-cls-1 {
    fill: rgba(255, 255, 255, 0.13);
  }

  .guarantyTwo-cls-1,
  .guarantyTwo-cls-2,
  .guarantyTwo-cls-3,
  .guarantyTwo-cls-4,
  .guarantyTwo-cls-5 {
    stroke-width: 0px;
  }

  .guarantyTwo-cls-6 {
    fill: none;
    stroke: ${({ backgroundColor }) => backgroundColor};
    stroke-width: 6px;
  }

  .guarantyTwo-cls-2 {
    fill: ${({ backgroundColor }) => backgroundColor};
  }

  .guarantyTwo-cls-3 {
    fill: ${({ backgroundColor }) => backgroundColor};
  }

  .guarantyTwo-cls-4,
  .guarantyTwo-cls-5 {
    fill: #fff;
  }

  .guarantyTwo-cls-5 {
    opacity: 0.59;
  }

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 120px;
    height: 120px;
    `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  line-height: 1.35em;
  margin-bottom: 25px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    margin-bottom: 26px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    text-align: center;
    margin-bottom: 20px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subtitle = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    text-align: center;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V2: FC<GuaranteeProps> = (props) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Subtitle
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </TitleWrapper>
        <IconInner>
          <SvgIcon
            backgroundColor={shadedBackgroundColor}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 158.22 159.7">
            <g>
              <path
                className="guarantyTwo-cls-3"
                d="m3.02,60.14h152.17c0,27.12,0,54.24,0,81.36.59,9.41-7.41,16.09-16.36,15.11-1.04,0-2.09,0-3.13,0-3.93,0-7.86,0-11.79,0-4.57,0-9.14,0-13.71,0-10.35,0-20.69,0-31.04,0-10.35,0-20.7,0-31.05,0-4.57,0-9.15,0-13.73,0-3.85,0-7.71,0-11.56,0-1.12,0-2.25,0-3.37,0-9.28.84-16.9-5.63-16.44-15.33,0-27.05,0-54.09,0-81.14Z"
              />
              <path
                className="guarantyTwo-cls-5"
                d="m3.02,60.14h152.17c0,27.12,0,54.24,0,81.36.59,9.41-7.41,16.09-16.36,15.11-1.04,0-2.09,0-3.13,0-3.93,0-7.86,0-11.79,0-4.57,0-9.14,0-13.71,0-10.35,0-20.69,0-31.04,0-10.35,0-20.7,0-31.05,0-4.57,0-9.15,0-13.73,0-3.85,0-7.71,0-11.56,0-1.12,0-2.25,0-3.37,0-9.28.84-16.9-5.63-16.44-15.33,0-27.05,0-54.09,0-81.14Z"
              />
              <path
                className="guarantyTwo-cls-6"
                d="m3.02,60.14h152.17c0,27.12,0,54.24,0,81.36.59,9.41-7.41,16.09-16.36,15.11-1.04,0-2.09,0-3.13,0-3.93,0-7.86,0-11.79,0-4.57,0-9.14,0-13.71,0-10.35,0-20.69,0-31.04,0-10.35,0-20.7,0-31.05,0-4.57,0-9.15,0-13.73,0-3.85,0-7.71,0-11.56,0-1.12,0-2.25,0-3.37,0-9.28.84-16.9-5.63-16.44-15.33,0-27.05,0-54.09,0-81.14Z"
              />
              <path
                className="guarantyTwo-cls-3"
                d="m129.89,11.43c4.68.08,9.43-.23,14.11.3,7.62.91,13.28,7.31,14.11,15.16.08.76,0,1.52,0,2.29v16.3H.1c0-.46-.08-.99-.08-1.52v-15.16C.02,18.82,7.42,11.43,17.38,11.35h10.94v16.53c0,3.66,2.34,6.25,5.58,6.25s5.74-2.51,5.74-6.25V11.35h79.16v16.53c0,3.66,2.34,6.25,5.58,6.25s5.73-2.51,5.73-6.25V11.35l-.23.08Z"
              />
              <path
                className="guarantyTwo-cls-3"
                d="m28.32,11.43v-5.71C28.4,2.44,30.89,0,33.98,0s5.51,2.51,5.58,5.71v5.71h-11.32.08Z"
              />
              <path
                className="guarantyTwo-cls-3"
                d="m118.65,11.43v-5.71C118.65,2.44,121.22-.07,124.39,0c3.09,0,5.51,2.51,5.59,5.71v5.71h-11.32Z"
              />
              <rect className="guarantyTwo-cls-1" x="24.88" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="46.72" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="68.57" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="90.41" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="112.25" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="134.1" y="63.17" width=".75" height="90.49" />
              <rect className="guarantyTwo-cls-1" x="6.05" y="79.84" width="146.12" height=".75" />
              <rect className="guarantyTwo-cls-1" x="6.05" y="97.92" width="146.12" height=".75" />
              <rect className="guarantyTwo-cls-1" x="6.05" y="115.99" width="146.12" height=".75" />
              <rect className="guarantyTwo-cls-1" x="6.05" y="134.07" width="146.12" height=".75" />
            </g>
            <rect
              className="guarantyTwo-cls-4"
              x="37.68"
              y="77.58"
              width="84.36"
              height="54.98"
              rx="9.04"
              ry="9.04"
            />
            <path
              className="guarantyTwo-cls-2"
              d="m48.65,94.47c.26-3.42,1.58-6.09,3.96-8.03,2.38-1.93,5.46-2.9,9.25-2.9,2.53,0,4.7.46,6.52,1.39,1.86.89,3.25,2.12,4.18,3.68.97,1.56,1.45,3.33,1.45,5.29,0,2.3-.67,4.29-2.01,5.96-1.3,1.67-3.01,2.75-5.13,3.23v.28c2.42.6,4.33,1.77,5.74,3.51,1.41,1.75,2.12,4.03,2.12,6.86,0,2.12-.48,4.03-1.45,5.74-.97,1.67-2.42,2.99-4.35,3.96-1.93.97-4.25,1.45-6.97,1.45-3.94,0-7.17-1.02-9.7-3.07-2.53-2.08-3.94-5.02-4.24-8.81h4.9c.26,2.23,1.17,4.05,2.73,5.46,1.56,1.41,3.64,2.12,6.24,2.12s4.57-.67,5.91-2.01c1.37-1.38,2.06-3.14,2.06-5.29,0-2.79-.93-4.79-2.79-6.02-1.86-1.23-4.66-1.84-8.42-1.84h-1.28v-4.24h1.34c3.42-.04,6-.6,7.75-1.67,1.75-1.11,2.62-2.82,2.62-5.13,0-1.97-.65-3.55-1.95-4.74-1.26-1.19-3.08-1.78-5.46-1.78s-4.16.59-5.57,1.78c-1.41,1.19-2.25,2.79-2.51,4.79h-4.96Zm32.93,9.59c0-6.39,1.04-11.37,3.12-14.94,2.08-3.6,5.72-5.41,10.92-5.41s8.79,1.8,10.87,5.41c2.08,3.57,3.12,8.55,3.12,14.94s-1.04,11.56-3.12,15.16c-2.08,3.6-5.7,5.41-10.87,5.41s-8.84-1.8-10.92-5.41c-2.08-3.6-3.12-8.66-3.12-15.16Zm23.02,0c0-3.23-.22-5.96-.67-8.19-.41-2.27-1.28-4.09-2.62-5.46-1.3-1.37-3.2-2.06-5.68-2.06s-4.46.69-5.8,2.06c-1.3,1.37-2.17,3.2-2.62,5.46-.41,2.23-.61,4.96-.61,8.19s.2,6.15.61,8.42c.45,2.27,1.32,4.09,2.62,5.46,1.34,1.38,3.27,2.06,5.8,2.06s4.38-.69,5.68-2.06c1.34-1.38,2.21-3.2,2.62-5.46.45-2.27.67-5.07.67-8.42Z"
            />
          </SvgIcon>
        </IconInner>
      </StyledWrapper>
    </Container>
  );
};
