import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { FormContext, type IFormContext } from '../../context/FormContext';

import Title from '../Title/Title';
import DefInput from '../Inputs/DefInput';
import MobInput from '../Inputs/MobInput';

const MaxLengthError = styled.div`
  margin-top: 10px;
  background: #ff565d;
  color: #fff;
  padding: 5px 10px;

  span {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

const VCard = () => {
  const { setText, setIsRequared, isRequared, maxInputTextLength, data } = useContext(
    FormContext
  ) as IFormContext;
  const [lengthSum, setLengthSum] = useState(0);
  const [maxLengthError, setMaxLengthError] = useState('');
  const [error, setError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [inputs, setInputs] = useState({
    firstNameInput: '',
    phoneInput: '',
    emailInput: '',
    urlInput: '',
    companyInput: '',
    roleInput: ''
  });

  useEffect(() => {
    if (data && data.entity) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        firstNameInput: data.entity?.name || '',
        emailInput: data.email || ''
      }));
      setIsRequared(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateRequaredPhone = (num: string): boolean => {
    const re = /^\+?[0-9]\d{1,14}$/;
    return re.test(num);
  };

  const validateRequaredEmail = (value: string): boolean => {
    if (value.trim() === '') {
      return true;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(value);
  };

  const validateRequaredUrl = (url: string): boolean => {
    if (url.trim() === '') {
      return true;
    }

    try {
      const parsedUrl = new URL(url);
      if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
        return false;
      }

      const domain = parsedUrl.hostname;
      const invalidChars = /[^\w.-]/;
      if (invalidChars.test(domain)) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value
    }));

    if (name === 'firstNameInput') {
      if (value.trim() === '') {
        setIsRequared(true);
        setError('Name is required field');
      } else {
        setIsRequared(false);
        setError('');
      }
    } else {
      if (inputs.firstNameInput.trim() === '') {
        setIsRequared(true);
        setError('Name is required field');
      } else {
        setIsRequared(false);
        setError('');
      }
    }

    if (name === 'emailInput') {
      if (!validateRequaredEmail(value)) {
        setEmailError('Invalid email');
      } else {
        setEmailError('');
      }
    }

    if (name === 'urlInput') {
      if (!validateRequaredUrl(value)) {
        setUrlError('URL must start with http:// or https://, contain valid characters');
      } else {
        setUrlError('');
      }
    }
  };

  const handlePhoneInputChange = (value: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      phoneInput: value
    }));

    if (inputs.firstNameInput === '') {
      setIsRequared(true);
      setError('Name is required field');
    } else {
      setIsRequared(false);
      setError('');
    }

    if (!validateRequaredPhone(value) && value !== undefined && inputs.firstNameInput !== '') {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  useEffect(() => {
    // Mask of the email for the QR code
    const onFormSum = [
      'BEGIN:VCARD',
      'VERSION:3.0',
      `N:${inputs.firstNameInput}`,
      `TEL:${inputs.phoneInput}`,
      `EMAIL:${inputs.emailInput}`,
      `URL:${inputs.urlInput}`,
      `ORG:${inputs.companyInput}`,
      `TITLE:${inputs.roleInput}`,
      'END:VCARD'
    ].join('\n');

    // Max length of the text for the QR code and validation of main input
    setLengthSum(onFormSum.length);

    if (onFormSum.length < maxInputTextLength) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
      setMaxLengthError(`Max length of the text must be ${maxInputTextLength}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <>
      <Title
        title="vCard Contact"
        description="Please provide the contact details to generate the QR code"
      />
      <DefInput
        lableName="Enter Name"
        type="text"
        name="firstNameInput"
        value={inputs.firstNameInput}
        onChange={handleInputChange}
        placeholder="John"
        required
        errorstyle={!!error}
        error={error}
      />
      <MobInput
        type="text"
        name="phoneInput"
        value={inputs.phoneInput}
        onChange={handlePhoneInputChange}
        placeholder="+123456789"
        errorstyle={!!phoneError}
        error={phoneError}
      />
      <DefInput
        lableName="Email"
        type="text"
        name="emailInput"
        value={inputs.emailInput}
        onChange={handleInputChange}
        placeholder="user@gmail.com"
        errorstyle={!!emailError}
        error={emailError}
      />
      <DefInput
        lableName="Website URL"
        type="text"
        name="urlInput"
        value={inputs.urlInput}
        onChange={handleInputChange}
        placeholder="https://example.com/"
        errorstyle={!!urlError}
        error={urlError}
      />
      <DefInput
        lableName="Company"
        type="text"
        name="companyInput"
        value={inputs.companyInput}
        onChange={handleInputChange}
        placeholder="Company LLC"
      />
      <DefInput
        lableName="Title"
        type="text"
        name="roleInput"
        value={inputs.roleInput}
        onChange={handleInputChange}
        placeholder="Your Profession or position"
      />
      {lengthSum + 1 > maxInputTextLength ? (
        <MaxLengthError>
          {maxLengthError} / <span>{lengthSum}</span>
        </MaxLengthError>
      ) : null}
    </>
  );
};

export default VCard;
