import type { TopBarTypes } from './types';
import type { FC } from 'react';

import { useEffect, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import { Wrapper, Button, Download } from '../Common';
import { HoverableDropzone } from '../HoverableDropzone';

import { useSales, useWebsiteEditor } from 'shared/hooks/index';
import {
  ApiService,
  COLOR_BLUE_3,
  baseMediaEndpoint,
  defaultWebValue,
  sanitizeRichText
} from 'shared/helpers/index';
import { FlexBox, Image as ImageComp } from '../../Common';

const Container = styled.div<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  padding: 18px 0;
  color: #fff;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) => $width <= 768 && 'flex-direction: column;'}
`;

const StyledButton = styled((props) => <Button {...props} />)`
  position: relative;
  z-index: 99;
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 8px;
  `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 80px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 30px;
  `}

  & img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const WrapLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  ${({ theme: { $width } }) =>
    $width <= 1252 &&
    `
    flex-direction: column;
    margin-right: 36px;
    align-items: flex-start;
    justify-content: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-right: 0px;
    align-items: center;
    margin-bottom: 10px;
  `}
`;

const Link = styled.div`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};

  &:not(:last-child) {
    @media screen and (max-width: 1252px) {
      margin-right: 0px;
      margin-bottom: 5px;
      font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
    }
  }
`;

export const V3: FC<TopBarTypes> = (props) => {
  const {
    button = defaultWebValue,
    hidden,
    secondaryColor = COLOR_BLUE_3,
    meta = {},
    title = defaultWebValue,
    benefits = [],
    textColor
  } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    salesPageId,
    updateImageMeta,
    type,
    salesPageValues,
    deliveryPageValues,
    purchaseElementId
  } = useSales();
  const {
    containerClassname,
    editable,
    updateActiveElement,
    resetActiveElement,
    websiteAssembler
  } = useWebsiteEditor();

  const [loading, setLoading] = useState(false);
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const logoImage = useMemo(() => {
    if (meta['image']) {
      if (baseMediaEndpoint?.includes('localhost')) return `${baseMediaEndpoint}${meta['image']}`;
      return meta['image'];
    }
    return '/img/components/topbar/logoDefUpl.svg';
  }, [meta]);

  const handleUpload = async (file: File) => {
    setLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setLoading(false);
  };

  useEffect(() => {
    const updateSticky = () => {
      const checkValues = type === 'sales' ? salesPageValues : deliveryPageValues;
      if (!ref.current || !ref.current.parentElement || websiteAssembler || !checkValues) return;
      const values = checkValues[meta['accessorKey']];

      const minOrder = Math.min(
        ...Object.values(checkValues)
          .filter((item) => typeof item === 'object')
          .map((item) => item['order'])
          .filter((order) => typeof order === 'number')
      );

      if (typeof values === 'object' && values.order !== minOrder) return;

      const top = ref.current.parentElement.getBoundingClientRect().top;

      if (top < 0) {
        ref.current.classList.add('pw-sticky-topbar');
      } else if (top >= 0) {
        ref.current.classList.remove('pw-sticky-topbar');
      }
    };

    window.addEventListener('scroll', updateSticky);
    window.addEventListener('resize', updateSticky);

    return () => {
      window.removeEventListener('scroll', updateSticky);
      window.removeEventListener('resize', updateSticky);
    };
  }, [deliveryPageValues, websiteAssembler, meta, salesPageValues, type]);

  const href = useMemo(() => {
    if (meta['url']) return meta['url'];
    return purchaseElementId;
  }, [meta, purchaseElementId]);

  return (
    <Container
      ref={ref}
      id="sales_topbar_component"
      backgroundColor={secondaryColor}
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <ImageContainer>
          <HoverableDropzone
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={loading}
            alreadyUploaded={Boolean(logoImage)}
            imageFile={logoImage}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            style={{
              ...(resize.height && { height: resize.height }),
              ...(resize.width && { width: resize.width })
            }}
            size="sm"
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '32x9',
              pixels: '160x45'
            }}>
            <ImageComp
              width={resize.width || 500}
              height={resize.height || 500}
              src={logoImage}
              alt="Photo"
            />
          </HoverableDropzone>
          <FlexBox gap={50}>
            <WrapLinks>
              {benefits.map(({ title: benefitTitle }, index) => (
                <Link
                  key={index}
                  id={benefitTitle?.id}
                  onClick={updateActiveElement}
                  onBlur={resetActiveElement}
                  data-accessor-parent={meta['accessorKey']}
                  data-accessorkey="title"
                  data-benefits={true}
                  dangerouslySetInnerHTML={{ __html: sanitizeRichText(benefitTitle?.val) }}
                />
              ))}
            </WrapLinks>
            {type === 'delivery' ||
            !['form', 'recurring_form', 'upsell_vip_form'].includes(href) ? (
              <Download textColor={textColor} button={button} meta={meta} />
            ) : (
              <StyledButton to={purchaseElementId} button={button} meta={meta} id={title.id} />
            )}
          </FlexBox>
        </ImageContainer>
      </Wrapper>
    </Container>
  );
};
