import type { FC } from 'react';
import type { FlattenSimpleInterpolation } from 'styled-components';

import { useState } from 'react';
import styled from 'styled-components';

import { ClickAwayListener } from '@mui/material';

import { FlexBox } from 'shared/components/Common';
import { COLOR_BLACK, COLOR_BOLD_GRAY, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'shared/helpers/colors';

const ValueContainer = styled(FlexBox)<{
  isDropdownOpen: boolean;
  css?: FlattenSimpleInterpolation;
}>`
  height: 38px;
  align-items: center;
  background-color: white;
  border: 1px solid ${COLOR_LIGHT_GRAY};
  border-radius: 10px;
  width: 220px;
  justify-content: space-between;
  cursor: pointer;

  ${({ isDropdownOpen }) =>
    isDropdownOpen
      ? `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    `
      : null}

  ${(props) => props.css}
`;

const ValueSpan = styled.span<{ css?: FlattenSimpleInterpolation }>`
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 20px;

  ${(props) => props.css}
`;

const ImgDropdown = styled.img`
  border-left: 1px solid ${COLOR_LIGHT_GRAY};
`;

const DropdownItem = styled(FlexBox)<{ isLast?: boolean; css?: FlattenSimpleInterpolation }>`
  height: 38px;
  align-items: center;
  background-color: white;
  border: 1px solid ${COLOR_LIGHT_GRAY};
  width: 220px;
  justify-content: space-between;
  cursor: pointer;
  display: flex;

  ${({ isLast }) =>
    isLast
      ? `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    `
      : null}

  &:hover {
    background-color: #f5f5f5;
  }

  ${(props) => props.css}
`;

const StyledPlaceholder = styled.span`
  color: ${COLOR_BOLD_GRAY};
  pointer-events: none;
  position: absolute;
  top: 25%;
  left: 2rem;
  background-color: ${COLOR_WHITE};
  border-left: 1rem solid ${COLOR_WHITE};
  border-right: 1rem solid ${COLOR_WHITE};
  transition: all 0.2s ease-in-out;
  top: -8px;
  color: ${COLOR_BLACK};
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 4;

  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

const BlockSpan = styled.span`
  width: 30px;
  height: 30px;
  border-left: 1px solid ${COLOR_LIGHT_GRAY};
`;

const OptionsParagraph = styled.p`
  margin-left: 20px;
`;

const DropdownContainer = styled(FlexBox)<{ css?: FlattenSimpleInterpolation }>`
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;

  ${({ css }) => css}
`;

interface Option {
  name: string;
  value: string;
}

interface Props {
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
  cssValueContainer?: FlattenSimpleInterpolation;
  cssDropdownItem?: FlattenSimpleInterpolation;
  placeholder?: string;
  cssValueSpan?: FlattenSimpleInterpolation;
  cssDropdownContainer?: FlattenSimpleInterpolation;
}

/**
 * This component is for Sales Pages Standard Dropdown.
 *
 * @param {Props} props - The props of the component
 * @param {Option[]} props.options - The options of the dropdown.
 * @param {string | number} props.value - The value of the dropdown.
 * @param {Function} props.onChange - The function that will be called when the value of the dropdown changes.
 * @param {FlattenSimpleInterpolation} [props.cssValueContainer] - The css of the value container, wrapper css for custom styles.
 * @param {FlattenSimpleInterpolation} [props.cssDropdownItem] - The css of the dropdown item, wrapper css for custom styles.
 *
 * @returns {JSX.Element} Rendered Website Dropdown Component
 *
 * @example return (
 *  <WebsiteDropdown
 *   options={[
 *     { name: 'Option 1', value: 'option1' },
 *     { name: 'Option 3', value: 'option3' },
 *   ]}
 *   value="option1"
 *   onChange={(value) => handleChange(value)}
 *   cssDropdownItem={css` width: 100%; height: 100%; `}
 *   cssValueContainer={css` width: 100%; height: 100%; `}
 *  />
 * );
 */
const WebsiteDropdown: FC<Props> = ({
  options,
  value,
  onChange,
  cssValueContainer,
  cssDropdownItem,
  placeholder,
  cssValueSpan,
  cssDropdownContainer
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (value: string) => {
    onChange(value);
    setIsDropdownOpen(false);
  };

  return (
    <FlexBox position="relative" height="fit-content">
      <ValueContainer
        css={cssValueContainer}
        isDropdownOpen={isDropdownOpen}
        onClick={() => {
          if (!isDropdownOpen) {
            setIsDropdownOpen((prev) => !prev);
          }
        }}>
        {placeholder ? <StyledPlaceholder>{placeholder}</StyledPlaceholder> : null}
        <ValueSpan css={cssValueSpan}>
          {options.find((x) => x.value === value)?.name || value}
        </ValueSpan>
        <ImgDropdown
          src={'/img/stack_icon.svg'}
          alt="dropdown"
          onClick={() => {
            console.log('setIsDropdownOpen((prev) => !prev)', isDropdownOpen);
          }}
        />
      </ValueContainer>
      {isDropdownOpen ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsDropdownOpen(false);
          }}>
          <DropdownContainer css={cssDropdownContainer}>
            {options?.map((x, index) => (
              <DropdownItem
                css={cssDropdownItem}
                key={index}
                onClick={() => handleChange(x.value)}
                isLast={options.length - 1 === index}>
                <OptionsParagraph>{x.name}</OptionsParagraph>
                <BlockSpan></BlockSpan>
              </DropdownItem>
            ))}
          </DropdownContainer>
        </ClickAwayListener>
      ) : null}
    </FlexBox>
  );
};

export default WebsiteDropdown;
