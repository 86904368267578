import { type FC } from 'react';
import ReactPlayer from 'react-player';

import styled, { css } from 'styled-components';
import type { HeroBasicProps } from './types';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  COLOR_BLUE_3,
  COLOR_YELLOW
} from 'shared/helpers/index';
import { useSales, useWebsiteEditor } from 'shared/hooks/index';
import { HoverableInput } from '../HoverableInput';

import { Wrapper, Button } from '../Common';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  padding: 70px 0 94px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 70px 0;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 50px 0 70px;
  `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const playerStyles = css`
  width: 100% !important;
  height: 720px !important;
  border: none;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    height: 560px !important;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  height: 430px !important;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 310px !important;
  `}

  & div, & .exampleImg {
    width: 100% !important;
    height: 720px !important;
    border: none;

    ${({ theme: { $width } }) =>
      $width <= 1254 &&
      `
    height: 560px !important;
  `}

    ${({ theme: { $width } }) =>
      $width <= 991 &&
      `
  height: 430px !important;
`}

    ${({ theme: { $width } }) =>
      $width <= 768 &&
      `
    height: 310px !important;
  `}
  }
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  margin-bottom: 26px;
  line-height: 1.35em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 28px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 20px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space: nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 45px;
  line-height: 1.35em;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 40px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 70px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 50px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    white-space:nowrap;
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  margin-top: 40px;
  width: auto;
  display: inline-block;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 395px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 345px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 80%;
    padding-top: 24px;
    padding-bottom: 26px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
  `}
`;

const WhiteGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.05) 0.01%, #fff 75.52%);
`;

const DynamicBackground = styled.div`
  width: 100%;
  height: 98%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

const ReactPlayerStyled = styled(ReactPlayer)`
  width: 100% !important;
`;

export const V5: FC<HeroBasicProps> = (props) => {
  const {
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    backgroundColor = COLOR_BLUE_3,
    secondaryColor = COLOR_YELLOW,
    textColor,
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { purchaseElementId } = useSales();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  return (
    <Hero
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(backgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            // id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{
              color: filterBackgroundColor(
                'linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.05) 0.01%, #fff 75.52%)',
                textColor
              )
            }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <Wrapper>
            <HoverableInput disabled={!editable} css={playerStyles as never} meta={meta} index={1}>
              {meta['url_1'] ? (
                <ReactPlayerStyled url={meta['url_1']} controls={true} />
              ) : (
                <ImageComp
                  alt="placeholder image"
                  className="exampleImg"
                  width={500}
                  height={500}
                  src="/img/example_video.png"
                />
              )}
            </HoverableInput>
          </Wrapper>
          <StyledButton to={purchaseElementId} button={button} meta={meta} />
        </TitleWrapper>
      </StyledWrapper>
      <WhiteGradient />
      <DynamicBackground style={{ background: secondaryColor }} />
    </Hero>
  );
};
