import type { BookBaseColor, ColorPalette, Font, FontStyle, Sales } from 'shared/types/index';
import type { FC, PropsWithChildren } from 'react';

import { createContext, useState } from 'react';
import {
  defaultBookBaseColor,
  defaultBookBaseColorCollection,
  defaultColorPalette,
  defaultColorPaletteCollection,
  defaultFont,
  defaultFontCollection,
  defaultFontStyle,
  defaultFontStyleCollection
} from './BrandingDefaultValues';
import { keyToColorMapping } from '../helpers';

const FONT_STYLE_COLLECTION_DEFAULT = [
  {
    id: 0,
    name: 'bold',
    title: 'THICK & BOLD',
    content: 'CAPITALIZED'
  },
  {
    id: 1,
    name: 'lower',
    title: 'lower case',
    content: 'THIN & AIRY'
  },
  {
    id: 2,
    name: 'normal',
    title: 'Classic Headline',
    content: 'Blended Styles'
  }
];

const FONT_STYLE_DEFAULT = {
  id: 0,
  name: 'bold',
  title: 'THICK & BOLD',
  content: 'CAPITALIZED'
};

type BrandingContextData = {
  bookBaseColor: BookBaseColor;
  setBookBaseColor: (data: BookBaseColor) => void;
  websiteBaseColor: BookBaseColor;
  setWebsiteBaseColor: (data: BookBaseColor) => void;
  bookBaseColorCollection: BookBaseColor[];
  setBookBaseColorCollection: (data: BookBaseColor[]) => void;
  colorPalette: ColorPalette;
  setColorPalette: (data: ColorPalette) => void;
  colorPaletteCollection: ColorPalette[];
  setColorPaletteCollection: (data: ColorPalette[]) => void;
  systemPalettes: ColorPalette[];
  setSystemPalettes: (data: ColorPalette[]) => void;
  font: Font;
  setFont: (data: Font) => void;
  fontCollection: Font[];
  setFontCollection: (data: Font[]) => void;
  fontStyle: FontStyle;
  setFontStyle: (data: FontStyle) => void;
  fontStyleCollection: FontStyle[];
  setFontStyleCollection: (data: FontStyle[]) => void;
  setSalesHomePageValues: (data: Sales) => void;
};

const BrandingContext = createContext<BrandingContextData>({
  bookBaseColor: defaultBookBaseColor,
  setBookBaseColor: () => null,
  websiteBaseColor: defaultBookBaseColor,
  setWebsiteBaseColor: () => null,
  bookBaseColorCollection: defaultBookBaseColorCollection,
  setBookBaseColorCollection: () => null,
  colorPalette: defaultColorPalette,
  setColorPalette: () => null,
  colorPaletteCollection: defaultColorPaletteCollection,
  setColorPaletteCollection: () => null,
  systemPalettes: defaultColorPaletteCollection,
  setSystemPalettes: () => null,
  font: defaultFont,
  setFont: () => null,
  fontCollection: defaultFontCollection,
  setFontCollection: () => null,
  fontStyle: defaultFontStyle,
  setFontStyle: () => null,
  fontStyleCollection: defaultFontStyleCollection,
  setFontStyleCollection: () => null,
  setSalesHomePageValues: () => null
});

const BrandingProvider: FC<PropsWithChildren> = ({ children }) => {
  // Colors
  const [colorPalette, setColorPalette] = useState<ColorPalette>(defaultColorPalette);
  const [colorPaletteCollection, setColorPaletteCollection] = useState<ColorPalette[]>([]);
  const [systemPalettes, setSystemPalettes] = useState<ColorPalette[]>([]);
  const [bookBaseColor, setBookBaseColor] = useState<BookBaseColor>(defaultBookBaseColor);
  const [bookBaseColorCollection, setBookBaseColorCollection] = useState<BookBaseColor[]>([]);
  const [websiteBaseColor, setWebsiteBaseColor] = useState<BookBaseColor>(defaultBookBaseColor);

  // Fonts
  const [font, setFont] = useState<Font>(defaultFont);
  const [fontCollection, setFontCollection] = useState<Font[]>([]);
  const [fontStyle, setFontStyle] = useState<FontStyle>(FONT_STYLE_DEFAULT);
  const [fontStyleCollection, setFontStyleCollection] = useState<FontStyle[]>(
    FONT_STYLE_COLLECTION_DEFAULT
  );

  const setSalesHomePageValues = (data: Sales) => {
    const websiteBaseColor = keyToColorMapping(data?.page_data?.base_color, colorPalette);
    const baseColor = defaultBookBaseColorCollection.find(
      (t) => t.value.toLocaleLowerCase() === websiteBaseColor.toLocaleLowerCase()
    );
    if (baseColor) {
      setWebsiteBaseColor(baseColor);
    }
    setColorPalette(data.color_palette);
  };

  return (
    <BrandingContext.Provider
      value={{
        bookBaseColor,
        setBookBaseColor,
        websiteBaseColor,
        setWebsiteBaseColor,
        bookBaseColorCollection,
        setBookBaseColorCollection,
        colorPalette,
        setColorPalette,
        colorPaletteCollection,
        setColorPaletteCollection,
        systemPalettes,
        setSystemPalettes,
        font,
        setFont,
        fontCollection,
        setFontCollection,
        fontStyle,
        setFontStyle,
        fontStyleCollection,
        setFontStyleCollection,
        setSalesHomePageValues
      }}>
      {children}
    </BrandingContext.Provider>
  );
};

export type { BrandingContextData };

export { BrandingProvider, BrandingContext };
