import { useMemo, useState } from 'react';
import type { FC } from 'react';
import styled, { css } from 'styled-components';
import { COLOR_RED_3, COLOR_WHITE } from 'shared/helpers/colors';
import type { ThisIsForYouProps } from './types';
import { ApiService, baseMediaEndpoint } from 'shared/helpers/api';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';
import { filterBackgroundColor } from 'shared/helpers/website';
import { sanitizeRichText } from 'shared/helpers/utils';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { HoverableDropzone } from '../HoverableDropzone';
import useSales from 'shared/hooks/useSales';
import { Wrapper } from '../Common';
import { FlexBox } from 'shared/components/Common';

const CardsInner = styled.div`
  padding: 80px 0 88px;
  display: flex;
  justify-content: space-evenly;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 70px 0 78px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    flex-direction: column;
  `}
`;

const Paragraph = styled.span<{ isEmpty?: boolean; editable?: boolean }>`
  color: ${COLOR_WHITE};

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  border: none;
  position: relative;
  width: 280px;
  height: 390px;
  overflow: hidden;
`;

const ImageInner = styled.div`
  // overflow: hidden;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    height: 270px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    height: 220px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    height: 170px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    height: 260px;
  `}
`;

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: transform 0.3s ease;
`;

const CardItem = styled.div<{ editable?: boolean }>`
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  &:nth-child(1) {
    z-index: 5;
  }

  &:nth-child(2) {
    z-index: 4;
  }

  &:nth-child(3) {
    z-index: 3;
  }

  ${({ editable, theme: { $width } }) =>
    !editable &&
    `
    &:hover img {
      transform: scale(1.05);
      transition: transform 0.3s ease;

      ${
        $width <= 991
          ? `
        transform: scale(1);
      `
          : ''
      }
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 54px;
    }
  `}
`;

const CardItemTitle = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  margin: 22px 0 34px;
  width: 100%;
  text-align: center;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      text-align: center;
      justify-content: center;
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin: 22px 0 24px;
  `}
`;

const CardItemContent = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin: 0 0 34px 0;
  width: 100%;
  text-align: center;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      text-align: center;
      justify-content: center;
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin: 22px 0 24px;
  `}
`;

const Button = styled.a`
  width: 100%;
  min-height: 10px;
  font-size: 20px;
  font-weight: 500;
  padding: 24px 40px;
  border-radius: 40px;
  text-align: center;
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  cursor: pointer;

  span {
    width: 100%;
    min-height: 10px;
    display: block;
  }

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 20px 0;
  `}

  &:hover {
    transform: translateY(-3px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1rem 2rem;
    transition:
      transform 0.2s ease 0s,
      box-shadow 0.2s ease 0s;
  }

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    font-size: 18px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 17px 28px;
    font-size: 16px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    &:hover {
      transform: translateY(0px);
      box-shadow: rgba(0, 0, 0, 0) 0px 0rem 0rem;
    }
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    padding: 18px 10px;
    font-size: 15px;
  `}
`;

export const V3: FC<ThisIsForYouProps> = (props) => {
  const { benefits = [], textColor, meta, hidden } = props;

  const [isLoading, setIsLoading] = useState([false, false, false]);

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { salesPageId, updateImageMeta } = useSales();

  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);
  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);

  const handleUpload = async (file: File, index: number) => {
    setIsLoading((prev) => prev.map((_, i) => (i === index - 1 ? true : false)));
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, `image_${index}`);
    setIsLoading([false, false, false]);
  };

  const images = useMemo(() => {
    const imageItems = [
      '/img/event_for_three_1.jpg',
      '/img/event_for_three_1.jpg',
      '/img/event_for_three_1.jpg'
    ];
    if (meta['image_1']) {
      if (baseMediaEndpoint?.includes('localhost')) return `${baseMediaEndpoint}${meta['image']}`;
      imageItems[0] = meta['image_1'];
    }
    if (meta['image_2']) {
      if (baseMediaEndpoint?.includes('localhost')) return `${baseMediaEndpoint}${meta['image']}`;
      imageItems[1] = meta['image_2'];
    }
    if (meta['image_3']) {
      if (baseMediaEndpoint?.includes('localhost')) return `${baseMediaEndpoint}${meta['image']}`;
      imageItems[2] = meta['image_3'];
    }
    return imageItems;
  }, [meta]);
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  return (
    <FlexBox
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <CardsInner>
          {benefits.map(
            ({ title: benefitTitle, content: benefitContent, button: benefitButton }, index) => {
              const isTitleEmpty = checkTitleBenefits(index);
              const isContentEmpty = checkContentBenefits(index);

              return (
                <CardItem key={index} editable={editable}>
                  <ImageInner>
                    <HoverableDropzone
                      isSalePage
                      css={imageStyles as never}
                      disabled={!editable}
                      allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
                      onUpload={(file) => handleUpload(file, index + 1)}
                      loading={isLoading[index]}
                      alreadyUploaded={Boolean(images[index])}
                      imageFile={images[index]}
                      size="md"
                      initialPosition={{
                        x: meta[`x_${index + 1}`] || 0,
                        y: meta[`y_${index + 1}`] || 0,
                        width: meta[`d_width_${index + 1}`],
                        height: meta[`d_height_${index + 1}`]
                      }}
                      meta={meta}
                      positionEnabled
                      handlePosition={(width: number, height: number, x: number, y: number) => {
                        updateImageMeta(meta['accessorKey'], x, `x_${index + 1}`);
                        updateImageMeta(meta['accessorKey'], y, `y_${index + 1}`);
                        updateImageMeta(meta['accessorKey'], width, `d_width_${index + 1}`);
                        updateImageMeta(meta['accessorKey'], height, `d_height_${index + 1}`);
                      }}
                      resizeSelector={editable}
                      handleResize={({ width, height }) => {
                        setResize({ width, height });
                        updateImageMeta(meta['accessorKey'], width, 'width');
                        updateImageMeta(meta['accessorKey'], height, 'height');
                      }}
                      imageAspectRatio={{
                        ratio: '2x3',
                        pixels: '400x600'
                      }}>
                      <CardImg
                        src={images[index]}
                        alt="Photo"
                        width={resize.width || 500}
                        height={resize.height || 500}
                      />
                    </HoverableDropzone>
                  </ImageInner>
                  <FlexBox
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    <CardItemTitle
                      id={benefitTitle?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefitTitle?.val, editable)
                      }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="title"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isTitleEmpty}
                    />
                    <CardItemContent
                      id={benefitContent?.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRichText(benefitContent?.val, editable)
                      }}
                      style={{ color: filterBackgroundColor('#fff', textColor) }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="content"
                      data-benefits={true}
                      data-placeholder="+"
                      editable={editable}
                      isEmpty={isContentEmpty}
                    />
                  </FlexBox>
                  <Button style={{ backgroundColor: benefitButton?.color }}>
                    <Paragraph
                      id={benefitButton?.id}
                      dangerouslySetInnerHTML={{ __html: sanitizeRichText(benefitButton?.val) }}
                      style={{
                        color: filterBackgroundColor(benefitButton?.color || COLOR_RED_3, textColor)
                      }}
                      onClick={updateActiveElement}
                      onBlur={resetActiveElement}
                      data-accessor-parent={meta['accessorKey']}
                      data-accessorkey="button"
                      data-benefits={true}
                      data-pindex={index}
                      data-placeholder="+"
                      editable={editable}
                    />
                  </Button>
                </CardItem>
              );
            }
          )}
        </CardsInner>
      </Wrapper>
    </FlexBox>
  );
};
