import type { FC, ReactNode } from 'react';

export type ConditionalRenderTypes = {
  hidden?: boolean;
  children: ReactNode;
};

/**
 * ConditionalRender component that conditionally renders its children.
 *
 * This component checks the `hidden` prop and only renders its children if `hidden` is false or undefined.
 *
 * @component
 * @param {boolean} props.hidden - Determines if the children should be hidden.
 * @param {ReactNode} props.children - The content to be conditionally rendered.
 * @returns {JSX.Element | null} Child or render nothing (null).
 * @example
 * return (
 *   <ConditionalRender hidden={false}>
 *     <div>This content will be rendered</div>
 *   </ConditionalRender>
 * )
 */
export const ConditionalRender: FC<ConditionalRenderTypes> = ({ hidden, children }) => {
  if (hidden) return null;

  return <>{children}</>;
};
