import type { ComponentPropsWithRef, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 1300px;
  margin: 0 auto;

  ${({ theme: { $width } }) =>
    $width <= 1300 &&
    `
    width: 1252px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 990px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 768px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 575px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 100%;
    padding: 0 15px;
    `}
`;

export const Wrapper: FC<PropsWithChildren<ComponentPropsWithRef<'div'>>> = (props) => {
  const { children, ...rest } = props;
  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
};

Wrapper.displayName = 'SalesWrapper';
