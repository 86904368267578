import type { FC } from 'react';
import type { ParagraphProps } from './types';

import styled from 'styled-components';

import { defaultWebValue, filterBackgroundColor, sanitizeRichText } from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 60px 0;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 60px 15px;
    `}
`;

// const Heading = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
//   line-height: 1.5em;
//   font-weight: 500;
//   font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
//   ${({ isEmpty, editable }) =>
//     isEmpty
//       ? `
//     display: ${editable ? 'block' : 'none'};
//     &:not(:focus):before {
//       content: attr(data-placeholder);
//       cursor: text;
//       color: #aaa;
//       position: absolute;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   `
//       : `
//     &:before {
//       content: none;
//     }
//   `}
// `;

const Paragraph = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.5em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V1: FC<ParagraphProps> = (props) => {
  const { content = defaultWebValue, textColor, meta, hidden } = props;
  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  return (
    <Container
      className={containerClassname}
      style={{
        display:
          (hidden && !websiteAssembler) || (!websiteAssembler && isTitleEmpty && isContentEmpty)
            ? 'none'
            : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <Paragraph
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{
            color: filterBackgroundColor('#fff', textColor)
          }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-placeholder="+"
          editable={editable}
          isEmpty={isContentEmpty}
        />
      </Wrapper>
    </Container>
  );
};
