import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { FormContext, type IFormContext } from '../../context/FormContext';

import Title from '../Title/Title';
import MobInput from '../Inputs/MobInput';
import TextArea from '../Inputs/TextArea';

const MaxLengthError = styled.div`
  margin-top: 10px;
  background: #ff565d;
  color: #fff;
  padding: 5px 10px;

  span {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

const SmsForm = () => {
  const { setText, setIsRequared, isRequared } = useContext(FormContext) as IFormContext;
  const [lengthSum, setLengthSum] = useState(0);
  const [smsLimitation, setSmsLimitation] = useState(0);
  const [maxLengthError, setMaxLengthError] = useState('');
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState({
    phoneInput: '',
    bodyInput: ''
  });

  const validateRequared = (num: string): boolean => {
    const re = /^\+?[0-9]\d{1,14}$/;
    return re.test(num);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value
    }));

    if (inputs.phoneInput === '') {
      setIsRequared(true);
      setError('Phone number is required');
    } else {
      setIsRequared(false);
      setError('');
    }
  };

  const handlePhoneInputChange = (value: string) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      phoneInput: value
    }));

    if (value === '') {
      setIsRequared(true);
      setError('Phone number is required');
    } else if (!validateRequared(value)) {
      setIsRequared(true);
      setError('Invalid phone number');
    } else {
      setIsRequared(false);
      setError('');
    }
  };

  useEffect(() => {
    // Mask of the email for the QR code
    const onFormSum = `sms:${inputs.phoneInput}&body=${inputs.bodyInput.replace(/[:=&]/g, '')}`;

    // Max length of the text for the QR code and validation of main input
    setLengthSum(onFormSum.length);

    // SMS length limits
    const limit = 174;
    setSmsLimitation(limit);

    if (onFormSum.length < limit) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
      setMaxLengthError(`Max length of the text must be ${limit}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <>
      <Title
        title="SMS Message"
        description="Please provide the phone number and message to generate the QR code."
      />
      <MobInput
        type="text"
        name="phoneInput"
        value={inputs.phoneInput}
        onChange={handlePhoneInputChange}
        placeholder="+123456789"
        errorstyle={!!error}
        required
        error={error}
      />
      <TextArea
        lableName="Message"
        name="bodyInput"
        value={inputs.bodyInput}
        onChange={handleInputChange}
      />
      {lengthSum + 1 > smsLimitation ? (
        <MaxLengthError>
          {maxLengthError} / <span>{lengthSum}</span>
        </MaxLengthError>
      ) : null}
    </>
  );
};

export default SmsForm;
