import type { MutableRefObject } from 'react';

import { useState, useRef, useEffect, useMemo } from 'react';
import useSales from './useSales';
import useWebsiteEditor from './useWebsiteEditor';

interface DraggableImageState {
  position: { x: number; y: number };
  handleMouseDown: (e: React.MouseEvent<HTMLImageElement>) => void;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  imgRef: MutableRefObject<HTMLImageElement | null>;
  styles: Record<string, unknown>;
  containerStyles: Record<string, unknown>;
}

const useDraggableImage = (
  tag: string = '_1',
  accessorKey: string,
  initialPosition: { x: number; y: number } = { x: 0, y: 0 }
): DraggableImageState => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const { updateImageMeta } = useSales();
  const { editable } = useWebsiteEditor();

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    if (!editable || process.env['APP_TYPE'] === 'sales') return;
    setIsDragging(true);
    dragStartPos.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y
    };
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !imgRef.current || !containerRef.current) return;

    const container = containerRef.current;
    const image = imgRef.current;

    // Get container and image dimensions
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const imageWidth = image.clientWidth;
    const imageHeight = image.clientHeight;

    // Calculate new position
    let newX = e.clientX - dragStartPos.current.x;
    let newY = e.clientY - dragStartPos.current.y;

    // Prevent moving past the edges
    newX = Math.min(0, Math.max(newX, containerWidth - imageWidth));
    newY = Math.min(0, Math.max(newY, containerHeight - imageHeight));

    setPosition({ x: newX, y: newY });
    updateImageMeta(accessorKey, newX, `x${tag}`);
    updateImageMeta(accessorKey, newY, `y${tag}`);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof document === 'undefined' ||
      process.env['APP_TYPE'] === 'sales' ||
      !editable ||
      !accessorKey
    )
      return;
    if (!imgRef.current || !containerRef.current) return;

    const container = containerRef.current;
    const image = imgRef.current;

    // Function to handle image loading and scaling
    const updateImageSize = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const imageWidth = image.naturalWidth || 1;
      const imageHeight = image.naturalHeight || 1;

      // Use Math.max to maintain full image size
      const scale = Math.max(containerWidth / imageWidth, containerHeight / imageHeight);

      // Compute scaled dimensions
      const scaledWidth = imageWidth * scale;
      const scaledHeight = imageHeight * scale;

      // Set the full image size
      image.style.width = `${scaledWidth}px`;
      image.style.height = `${scaledHeight}px`;

      // Center the image initially
      const initialX = (containerWidth - scaledWidth) / 2;
      const initialY = (containerHeight - scaledHeight) / 2;

      setPosition({ x: initialX, y: initialY });

      updateImageMeta(accessorKey, initialX, `x${tag}`);
      updateImageMeta(accessorKey, initialY, `y${tag}`);
      updateImageMeta(accessorKey, scaledWidth, `d_width${tag}`);
      updateImageMeta(accessorKey, scaledHeight, `d_height${tag}`);
    };

    // Resize observer to track container size changes
    const resizeObserver = new ResizeObserver(() => {
      updateImageSize();
    });

    resizeObserver.observe(container);

    // Check if the image is already loaded
    if (image.complete && image.naturalWidth > 0) {
      updateImageSize();
    } else {
      // Wait for the image to load before calculating dimensions
      image.onload = updateImageSize;
    }

    return () => {
      image.onload = null; // Cleanup event listener
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorKey]);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof document === 'undefined' ||
      process.env['APP_TYPE'] === 'sales' ||
      !editable
    )
      return;
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const styles = useMemo(() => {
    return {
      ...(position.y && { top: `${position.y}px` }),
      ...(position.x && { left: `${position.x}px` }),
      position: 'absolute',
      ...((process.env['APP_TYPE'] === 'sales' || editable) && {
        cursor: editable ? 'move' : '',
        userSelect: 'none'
      })
    };
  }, [position.x, position.y, editable]);

  const containerStyles = useMemo(() => {
    return { position: 'relative', overflow: 'hidden' };
  }, []);

  return { position, handleMouseDown, containerRef, imgRef, styles, containerStyles };
};

export default useDraggableImage;
