import type { FC } from 'react';
import styled from 'styled-components';
import { Root, Thumb } from '@radix-ui/react-switch';

const Container = styled.div`
  & * {
    all: unset;
  }
  display: flex;
  align-items: center;
`;

const LeftLabel = styled.label`
  padding-right: 15px;
  font-size: 15px;
  line-height: 1;
`;

const RightLabel = styled.label`
  padding-left: 15px;
  font-size: 15px;
  line-height: 1;
`;

const StyledRoot = styled(Root)`
  width: 42px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 9999px;
  position: relative;
  background-color: #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    box-shadow: 0 0 0 2px black;
  }

  [data-state='checked'] {
    background-color: black;
  }
`;

const StyledThumb = styled(Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  [data-state='checked'] {
    transform: translateX(19px);
  }
`;

type SwitchProps = {
  leftLabel?: boolean;
  rightLabel?: boolean;
  rightText?: string;
  leftText?: string;
  id?: string;
  defaultValue?: boolean;
  onChange: (val: boolean) => void;
};

const Switch: FC<SwitchProps> = (props) => {
  const { leftLabel, rightLabel, leftText, rightText, id, onChange, defaultValue } = props;
  return (
    <Container>
      {leftLabel ? <LeftLabel htmlFor={id}>{leftText}</LeftLabel> : null}
      <StyledRoot id={id} onCheckedChange={onChange} defaultChecked={defaultValue}>
        <StyledThumb />
      </StyledRoot>
      {rightLabel ? <RightLabel htmlFor={id}>{rightText}</RightLabel> : null}
    </Container>
  );
};

export type { SwitchProps };
export { Switch };
