import type { FC } from 'react';

import styled from 'styled-components';
import { useMemo } from 'react';

import { FlexBox } from './FlexBox';

import { isLocalhost } from 'shared/helpers/utils';

/**
 * Styled container for the win notification.
 *
 * @typedef {Object} ContainerProps
 * @param {Object} props - The properties passed to the container.
 * @param {string} props.border - The border color of the container.
 * @param {string} props.background - The background color of the container.
 */
type ContainerProps = { border: string; background: string };
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  width: 320px;
  height: 104px;
  top: 823px;
  left: 33px;
  gap: 0px;
  opacity: 0px;
  box-shadow: 0px 4px 9px 0px #9acc3a2b;
  border: 2px solid ${(props) => props.border};
  background: ${(props) => props.background};
  align-items: center;
  border-radius: 25px;
`;

/**
 * Styled paragraph for the user's name.
 */
const Name = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
`;

/**
 * Styled paragraph for the congratulatory text.
 */
const CongrantText = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.42px;
  text-align: left;
`;

/**
 * Styled image for the cup icon.
 */
const CupImg = styled.img`
  position: absolute;
  top: 65%;
  left: 55%;
`;

interface Props {
  name?: string;
  type: 'subscriber' | 'purchase' | 'published';
  media?: string;
}

/**
 * WinComponent that displays a win notification with customizable properties.
 *
 * @param {Props} props - The properties passed to the component.
 * @param {string} props.name - The name of the user.
 * @param  {'subscriber' | 'purchase' | 'published'} props.type - The type of notification.
 * @param {string} props.media - The URL of the media to display.
 * @returns {JSX.Element} The rendered win component.
 * @example
 * return (
 *   <WinComponent
 *     name="John Doe"
 *     type="purchase"
 *     media="/path/to/media.jpg"
 *   />
 * )
 */
const WinComponent: FC<Props> = (props) => {
  const { name = 'Victoria', type = 'subscriber', media } = props;

  // border and background colors for the popup
  const colorMapping = {
    subscriber: {
      background: '#F3FFFF',
      border: '#C2F5F7'
    },
    purchase: {
      background: '#FFFCF0',
      border: '#FFEBA4'
    },
    published: {
      background: '#FDFFF4',
      border: '#E5F3AF'
    }
  };

  // displayed text on different popup events
  const textMapping = {
    published: 'Just published a new product!',
    subscriber: 'Just got a new subscriber!',
    purchase: 'Just had a purchase!'
  };

  const winMedia = useMemo(() => {
    if (isLocalhost()) {
      return `http://localhost:8000/${media}`;
    }
    return media;
  }, [media]);

  return (
    <Container border={colorMapping[type].border} background={colorMapping[type].background}>
      <FlexBox position="relative">
        {media ? (
          <img
            className="hoverable-icons"
            src={winMedia}
            alt="Avatar Image"
            width="71px"
            height="71px"
            style={{
              borderRadius: '50%'
            }}
          />
        ) : (
          <img
            className="hoverable-icons"
            src="/img/avatar.png"
            alt="Avatar Image"
            width="71px"
            height="71px"
          />
        )}
        <CupImg src="/img/win_cup.png" alt="Cup Image" />
      </FlexBox>
      <FlexBox flexDirection="column" maxWidth="200px">
        <Name>{name}</Name>
        <CongrantText>{textMapping[type]}</CongrantText>
      </FlexBox>
      <FlexBox position="absolute" left="62%">
        <img className="hoverable-icons" src="/img/confetti.svg" alt="Avatar Image" />
      </FlexBox>
    </Container>
  );
};

export default WinComponent;
