import styled from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import { FormContext, type IFormContext } from '../../context/FormContext';

import Title from '../Title/Title';
import DefInput from '../Inputs/DefInput';
import TextArea from '../Inputs/TextArea';

const MaxLengthError = styled.div`
  margin-top: 10px;
  background: #ff565d;
  color: #fff;
  padding: 5px 10px;

  span {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

const EmailForm = () => {
  const { setSuccess, setText, setIsRequared, isRequared, maxInputTextLength, data } = useContext(
    FormContext
  ) as IFormContext;

  const [error, setError] = useState('');
  const [maxLengthError, setMaxLengthError] = useState('');
  const [lengthSum, setLengthSum] = useState(0);
  const [inputs, setInputs] = useState({
    emailInput: '',
    subjectInput: '',
    bodyInput: ''
  });

  useEffect(() => {
    if (data?.email) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        emailInput: data.email || ''
      }));
      setIsRequared(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Validation
  const validateRequared = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(value);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value
    }));

    // Max length of the text for the QR code
    if (lengthSum > maxInputTextLength) {
      return;
    }

    // check requare
    if (name === 'emailInput') {
      if (value.trim() === '') {
        setIsRequared(true);
        setError('Email is required');
      } else if (!validateRequared(value)) {
        setIsRequared(true);
        setError('Invalid email address');
      } else {
        setIsRequared(false);
        setError('');
      }
    } else {
      if (inputs.emailInput.trim() === '') {
        setIsRequared(true);
        setError('Email is required');
      } else if (!validateRequared(inputs.emailInput)) {
        setIsRequared(true);
        setError('Invalid email address');
      } else {
        setIsRequared(false);
        setError('');
      }
    }

    setSuccess(false);
  };

  useEffect(() => {
    // Mask of the email for the QR code
    const onFormSum = `mailto:${inputs.emailInput}?subject=${encodeURIComponent(inputs.subjectInput)}&body=${encodeURIComponent(inputs.bodyInput)}`;

    setLengthSum(onFormSum.length);

    if (onFormSum.length < maxInputTextLength) {
      if (!isRequared) {
        setText(onFormSum);
      } else {
        setText('');
      }
    } else {
      setIsRequared(true);
      setMaxLengthError(`Max length of the text must be ${maxInputTextLength}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, setText, isRequared]);

  return (
    <>
      <Title
        title="Email Message"
        description="Please provide your email, subject, and message to generate the QR code."
      />
      <DefInput
        lableName="Email"
        type="text"
        name="emailInput"
        value={inputs.emailInput}
        onChange={handleInput}
        placeholder="user@gmail.com"
        errorstyle={!!error}
        error={error}
      />
      <DefInput
        lableName="Subject"
        type="text"
        name="subjectInput"
        value={inputs.subjectInput}
        onChange={handleInput}
      />
      <TextArea
        lableName="Message"
        name="bodyInput"
        value={inputs.bodyInput}
        onChange={handleInput}
      />
      {lengthSum + 1 > maxInputTextLength ? (
        <MaxLengthError>
          {maxLengthError} / <span>{lengthSum}</span>
        </MaxLengthError>
      ) : null}
    </>
  );
};

export default EmailForm;
