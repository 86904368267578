import type { FC } from 'react';
import type { MockupProps } from './Mockup';

import { useMemo } from 'react';
import styled from 'styled-components';
import { COLOR_BOLD_GRAY } from 'shared/helpers/colors';

export type StackPageCardTypes = {
  variant: 'semi' | 'full';
  pageCount: number;
  mockupId: number;
};

const StackPageWrapper = styled.div<Omit<StackPageCardTypes, 'variant'>>`
  width: 40%;
  position: relative;
`;

const SaleContainerStyled = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
     flex-direction: column;
  `}
`;

const StackPageCard = styled.div<Omit<StackPageCardTypes, 'mockupId'>>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 18em;

  // @media only screen and (max-width: 97.6em) {
  //   ${(props) => (props.variant === 'semi' ? 'margin-top: -3rem;' : '')}
  //   ${(props) => (props.pageCount < 3 ? 'margin-top: -15rem' : '')}
  // }

  @media only screen and (max-width: 56.15em) {
    width: 25em;
  }
  img {
    position: absolute;
    border: 1px solid ${COLOR_BOLD_GRAY};
  }

  ${(props) =>
    props.variant === 'full'
      ? `
    img:nth-child(1) {
    transform: rotate(7deg);
    top: 0;
    left: 100px;
  }

  img:nth-child(2) {
    transform: rotate(-7deg);
    top: 50px;
    left: 0px;
  }

  img:nth-child(3) {
    transform: rotate(4deg);
    top: 60px;
    left: 100px;
  }

  img:nth-child(4) {
    transform: rotate(-3deg);
    top: 120px;
    left: 5px;
  }

  img:nth-child(5) {
    transform: rotate(10deg);
    top: 150px;
    left: 100px;
  }

  img:nth-child(6) {
    transform: rotate(-4deg);
    top: 220px;
    left: 10px;
  }

  img:nth-child(7) {
    transform: rotate(7deg);
    top: 280px;
    left: 90px;
  }`
      : `
      img:nth-child(1) {
        transform: rotate(7deg);
        top: 190px;
        left: 100px;
      }
    
      img:nth-child(2) {
        transform: rotate(3.35deg);
        top: 170px;
        left: 0;
      }
    
      img:nth-child(3) {
        transform: rotate(4.05deg);
        top: 80px;
        left: 90px;
      }
    
      img:nth-child(4) {
        transform: rotate(-3.96deg);
        top: 50px;
        left: 0;
      }
      `};
`;

export type StackedPagesProps = {
  pageImages: string[];
  mockup: MockupProps;
  coverDisabled?: boolean;
};

export const StackedPages: FC<StackedPagesProps> = (props) => {
  const { pageImages, mockup } = props;

  const pages = useMemo(() => {
    const filteredPageImages = pageImages.filter(Boolean);
    if (filteredPageImages.length < 7) return filteredPageImages.slice(0, 4);
    return filteredPageImages.slice(0, 7);
  }, [pageImages]);

  const variant = useMemo(() => {
    if (pages.length < 7) return 'semi';
    return 'full';
  }, [pages]);

  return (
    <StackPageWrapper pageCount={pages.length} mockupId={mockup.mockup_id}>
      <StackPageCard variant={variant} pageCount={pages.length}>
        {pages.map((x, index) => (
          <img key={index} width="180px" src={x} alt="page" />
        ))}
      </StackPageCard>
      <SaleContainerStyled>
        {/* <Mockup
          mockup_id={mockup?.mockup_id}
          cover_thumbnail={coverDisabled ? pageImages[0] : mockup?.cover_thumbnail}
          mockup={mockup?.cover_thumbnail}
        /> */}
      </SaleContainerStyled>
    </StackPageWrapper>
  );
};
