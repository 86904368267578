import type {
  BorderProps,
  ColorProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  TextAlignProps,
  LineHeightProps,
  FontFamilyProps,
  FontStyleProps,
  FontSizeProps,
  FontWeightProps,
  LetterSpacingProps
} from 'styled-system';

import styled, { css } from 'styled-components';
import {
  border,
  color,
  layout,
  position,
  space,
  textAlign,
  lineHeight,
  fontFamily,
  fontStyle,
  fontSize,
  fontWeight,
  letterSpacing
} from 'styled-system';

export type ParagraphProps = FontWeightProps &
  LayoutProps &
  SpaceProps &
  PositionProps &
  FontFamilyProps &
  FontStyleProps &
  TextAlignProps &
  FontSizeProps &
  LineHeightProps &
  BorderProps &
  LetterSpacingProps &
  ColorProps & { cursor?: string };

export const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Plus Jakarta Sans', sans-serif;
  ${fontWeight}
  ${layout}
    ${space}
    ${fontSize}
    ${lineHeight}
    ${fontFamily}
    ${fontStyle}
    ${textAlign}
    ${position}
    ${color}
    ${border}
    ${letterSpacing}
    ${(props) =>
    props.cursor &&
    css`
      cursor: ${`${props.cursor}`};
    `}
`;
