import { useMemo, type FC } from 'react';
import type { GuaranteeProps } from './types';

import styled from 'styled-components';

import {
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText,
  shade
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const WrapperStyled = styled(Wrapper)`
  position: relative;
  z-index: 4;
`;

const IconInner = styled.div`
  width: 186px;
  height: 186px;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 4px 16.6px rgba(0, 0, 0, 0.07));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
`;
type SvgIconProps = {
  backgroundColor: string;
};

const SvgIcon = styled.svg<SvgIconProps>`
  width: 90px;
  height: 130px;
  margin-top: -10px;

  .guarantyOne-cls-1 {
    fill: ${({ backgroundColor }) => backgroundColor};
  }

  .guarantyOne-cls-1,
  .guarantyOne-cls-2,
  .guarantyOne-cls-3 {
    stroke-width: 0px;
  }

  .guarantyOne-cls-2,
  .guarantyOne-cls-3 {
    fill: #fff;
  }

  .guarantyOne-cls-3 {
    opacity: 0.59;
  }
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  text-align: center;
  line-height: 1.35em;
  margin-bottom: 22px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 26px;
  `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subtitle = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  line-height: 1.45em;
  text-align: center;
  position: relative;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

export const V1: FC<GuaranteeProps> = (props) => {
  const {
    content = defaultWebValue,
    title = defaultWebValue,
    textColor,
    backgroundColor = '#fff',
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor, 0.7);
  }, [backgroundColor]);

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: shadedBackgroundColor
      }}>
      <WrapperStyled>
        <IconInner>
          <SvgIcon
            backgroundColor={backgroundColor}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90 130">
            <g>
              <path
                className="guarantyOne-cls-1"
                d="m87.3,90.7c2.89-3.18,3.47-7.82,1.72-11.68-3.67-8.97-16.42-6.59-24.29-6.74-.26-7.87,2.11-15.69-4.14-22.63-3.95-4.23-11.99-8-17.22-3.85-2.42,2.13-2,5.76-2.01,8.74.03,4.67-.06,5.99-.38,12.37l-12.46,8.5c-2.88-.07-17.26-.02-21.63-.04-3.79,0-6.87,3.07-6.87,6.85v38.39c0,3.78,3.08,6.85,6.87,6.85,5.13,0,20.44,1.24,22.8-1.38,9.46,4.43,20.05,3.93,30.11,3.88,4.51,0,8.99-.01,13.44-.03,7.99.24,13.76-8.33,10.48-15.64,2.84-3.31,3.51-7.77,1.79-11.82,2.82-3.17,3.54-7.93,1.79-11.78Zm-61.17,30.91c-.17.1-.37.16-.58.16H6.87c-.65,0-1.17-.52-1.17-1.16v-38.39c0-.64.52-1.16,1.17-1.16,4.11.03,15.7-.03,19.25.03v40.52Zm58.09-37.04c-.24,1.18-.92,2.23-1.88,3.07-2.98,2.51.8,5.51.14,8.4-.26,2.84-4.67,4.68-2.61,7.88,3.57,6.18-4.11,7.02-1.85,11.75.45.75.82,1.72.88,2.42.37,4.91-3.79,6.65-8.07,6.18-12.99-.53-26.53,2.03-39-3.44v-40.78c1.26-1.39,15.37-9.13,14.76-11.43.1-2.03.44-8.68.44-9.15.36-1.51-.81-10.12.84-9.72,9.88.31,12.31,8.82,11.23,17.6.19,5.12-.9,7.73.78,9.88,1.08,1.61,9.8.37,11.39.64,5.07-.11,14.01-.58,12.94,6.72Z"
              />
              <path
                className="guarantyOne-cls-1"
                d="m26.12,81.09v40.52c-.17.1-.37.16-.58.16H6.87c-.65,0-1.17-.52-1.17-1.16v-38.39c0-.64.52-1.16,1.17-1.16,4.11.03,15.7-.03,19.25.03Z"
              />
              <path
                className="guarantyOne-cls-3"
                d="m26.12,81.09v40.52c-.17.1-.37.16-.58.16H6.87c-.65,0-1.17-.52-1.17-1.16v-38.39c0-.64.52-1.16,1.17-1.16,4.11.03,15.7-.03,19.25.03Z"
              />
              <circle className="guarantyOne-cls-2" cx="15.5" cy="112.5" r="5.5" />
            </g>
            <g>
              <path
                className="guarantyOne-cls-1"
                d="m11,36l2.43,6.57,6.57,2.43-6.57,2.43-2.43,6.57-2.43-6.57-6.57-2.43,6.57-2.43,2.43-6.57Z"
              />
              <path
                className="guarantyOne-cls-3"
                d="m11,36l2.43,6.57,6.57,2.43-6.57,2.43-2.43,6.57-2.43-6.57-6.57-2.43,6.57-2.43,2.43-6.57Z"
              />
            </g>
            <g>
              <path
                className="guarantyOne-cls-1"
                d="m41,0l4.86,13.14,13.14,4.86-13.14,4.86-4.86,13.14-4.86-13.14-13.14-4.86,13.14-4.86L41,0Z"
              />
              <path
                className="guarantyOne-cls-3"
                d="m41,0l4.86,13.14,13.14,4.86-13.14,4.86-4.86,13.14-4.86-13.14-13.14-4.86,13.14-4.86L41,0Z"
              />
            </g>
            <g>
              <path
                className="guarantyOne-cls-1"
                d="m70,16l3.51,9.49,9.49,3.51-9.49,3.51-3.51,9.49-3.51-9.49-9.49-3.51,9.49-3.51,3.51-9.49Z"
              />
              <path
                className="guarantyOne-cls-3"
                d="m70,16l3.51,9.49,9.49,3.51-9.49,3.51-3.51,9.49-3.51-9.49-9.49-3.51,9.49-3.51,3.51-9.49Z"
              />
            </g>
            <g>
              <path
                className="guarantyOne-cls-1"
                d="m21.5,27l1.76,4.74,4.74,1.76-4.74,1.76-1.76,4.74-1.76-4.74-4.74-1.76,4.74-1.76,1.76-4.74Z"
              />
              <path
                className="guarantyOne-cls-3"
                d="m21.5,27l1.76,4.74,4.74,1.76-4.74,1.76-1.76,4.74-1.76-4.74-4.74-1.76,4.74-1.76,1.76-4.74Z"
              />
            </g>
          </SvgIcon>
        </IconInner>
        <Title
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
        <Subtitle
          id={content.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor(shadedBackgroundColor, textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-placeholder="+"
          editable={editable}
          isEmpty={isContentEmpty}
        />
      </WrapperStyled>
    </Container>
  );
};
