import type { HeroCoachProps } from './types';

import { V1 } from './V1';
import { V2 } from './V2';

export type { HeroCoachProps };
export default {
  V1,
  V2
};
