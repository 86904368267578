import type { ActiveWinMessage, WinMessageType } from '../context/Utils';

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { FlexBox, WinComponent } from 'shared/components/index';
import { useAuth, useUtils } from 'shared/hooks/index';
import { axiosClientBaseAuth, randomItem } from 'shared/helpers/index';

const WinMessages = () => {
  const accessToken = localStorage.getItem('token');

  const location = useLocation();
  const { activeWinMessage, setActiveWinMessage } = useUtils();
  const { user } = useAuth();

  const isVisibleScreen = useMemo(() => {
    return location.pathname === '/dashboard';
  }, [location.pathname]);

  const isWinMessageDisaplayed = useMemo(() => {
    return !!user?.email && isVisibleScreen;
  }, [isVisibleScreen, user?.email]);

  const { refetch } = useQuery({
    queryFn: () => axiosClientBaseAuth.get('utils/win-message/'),
    queryKey: ['utils/win-message/'],
    onSuccess: (response) => {
      localStorage.setItem('winMessages', JSON.stringify(response.data));
    },
    refetchOnWindowFocus: false,
    enabled: isWinMessageDisaplayed && !!accessToken
  });

  const [intervalTime, setIntervalTime] = useState(15000);

  const randomTime = () => {
    return Math.floor(Math.random() * (90000 - 15000 + 1)) + 15000;
  };

  useEffect(() => {
    const showMessage = () => {
      if (!isWinMessageDisaplayed) return;
      const winMessages = JSON.parse(localStorage.getItem('winMessages') || '{}') as Record<
        WinMessageType,
        ActiveWinMessage[]
      >;
      const types = [];
      if (winMessages['subscriber'] && winMessages['subscriber'].length > 0) {
        types.push('subscriber');
      }
      if (winMessages['purchase'] && winMessages['purchase'].length > 0) {
        types.push('purchase');
      }
      if (winMessages['published'] && winMessages['published'].length > 0) {
        types.push('published');
      }
      const type = randomItem(types) as WinMessageType;
      if (!type) {
        void refetch();
        return;
      }

      if (winMessages[type] && winMessages[type].length > 0) {
        const activeWinMessage = randomItem(winMessages[type]);
        setActiveWinMessage({ type, name: activeWinMessage.name, media: activeWinMessage.media });
        setTimeout(() => {
          setActiveWinMessage({ type, name: '', media: '' });
        }, 8000);
        const activeIndex = winMessages[type].map((x) => x.name).indexOf(activeWinMessage.name);
        const filteredWinMessages = winMessages[type].filter((_, index) => index !== activeIndex);
        localStorage.setItem(
          'winMessages',
          JSON.stringify({ ...winMessages, [type]: filteredWinMessages })
        );
      }
    };

    const interval = setInterval(() => {
      showMessage();
      clearInterval(interval);
      setIntervalTime(randomTime());
    }, intervalTime);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTime]);

  if (!activeWinMessage.name || !isVisibleScreen) return null;

  return (
    <FlexBox
      position="fixed"
      gap={20}
      bottom="50px"
      left="50px"
      flexDirection="column"
      alignItems="flex-start"
      zIndex={99999999999999}
      justifyContent="flex-start">
      <WinComponent
        type={activeWinMessage.type}
        name={activeWinMessage.name}
        media={activeWinMessage.media}
      />
    </FlexBox>
  );
};

export default WinMessages;
