import type { SalesItem } from 'shared/types/Sales';
import type { FC } from 'react';

import { useEffect, useRef, useState, useMemo, lazy } from 'react';

import styled from 'styled-components';
import { useSales, useWebsiteEditor, useBranding } from 'shared/hooks/index';
import { convertColorsToPallete } from 'shared/helpers/index';

import { Wrapper } from '../Common';

import { Dialog } from '@mui/material';
import { Cross1Icon } from '@radix-ui/react-icons';

import Editor from '@monaco-editor/react';
import { FlexBox } from 'shared/components/Common';
import { COLOR_INPUTABLE_ORANGE, COLOR_LIGHT_GRAY, COLOR_WHITE } from 'shared/helpers/colors';
import { useTranslation } from 'react-i18next';
import { injectStyleIntoHtml, shade } from 'shared/helpers/utils';
import { ZOOM_SIZE } from 'shared/helpers/static';
import tinycolor from 'tinycolor2';

const AIToolsGenerator = lazy(
  () => import('shared/components/Modal/AIToolsGenerator/AIToolsGenerator')
);

const CustomContainer = styled.div<{ live?: boolean }>`
  position: relative;
  ${(props) =>
    !props.live &&
    `
      border: 5px dotted ${COLOR_LIGHT_GRAY};
    `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  position: relative;
  z-index: 20;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  padding: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const ImgCode = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 30px;
  opacity: 0.4;
`;

const Modal = styled(Dialog)`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 251231232;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);

  & .MuiDialog-container {
    width: 80%;
    border-radius: 50px;
    height: 95%;
  }
`;

const Header = styled.div`
  background: ${COLOR_WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  & button {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 1.8rem;
    color: #2c3e50;
    background: ${COLOR_WHITE};
    transition: all 0.25s ease;
  }

  & button:first-child {
    background: ${COLOR_INPUTABLE_ORANGE};
    padding: 10px 20px;
    color: ${COLOR_WHITE};
  }

  & button:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
`;

const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 25px;
  justify-content: space-between;
  height: 100%;
  gap: 50px;
`;

export const CustomElement: FC<SalesItem> = (props) => {
  const {
    font: fontBranding,
    colorPalette: colorPaletteBranding,
    colorPaletteCollection
  } = useBranding();
  const {
    meta,
    hidden,
    button,
    backgroundColor,
    id,
    // colorPalette = colorPaletteBranding,
    colorPalette,
    font = fontBranding?.id
  } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const editorIframeRef = useRef<HTMLIFrameElement>(null);

  const [modal, setModal] = useState(false);
  const [toolModalOpen, setToolModalOpen] = useState(false);
  const [bgSectionColor, setBgSectionColor] = useState('#fff');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    containerClassname,
    websiteAssembler,
    updateActiveElement,
    resetActiveElement,
    activeElement,
    updateCustomElement,
    resetToolbarPosition
  } = useWebsiteEditor();
  const { t } = useTranslation();

  const { updateImageMeta, updateForceReloading, removeMetaTag } = useSales();

  const [code, setCode] = useState(meta['custom_code']);
  const [currentColorPalette, setCurrentColorPalette] = useState(
    colorPalette || colorPaletteBranding
  );

  //   <meta http-equiv="Content-Security-Policy" content="
  //   default-src 'none';
  //   script-src 'unsafe-inline';
  //   style-src 'unsafe-inline';
  //   img-src 'none';
  //   font-src 'none';
  // ">
  const isTool = useMemo(() => id?.includes('custom_element_tool'), [id]);

  useEffect(() => {
    const foundColor = colorPaletteCollection.find(
      (color) =>
        color === colorPaletteBranding &&
        Object.values(color).some(
          (value) =>
            typeof value === 'string' &&
            backgroundColor &&
            value.trim().toLowerCase().includes(backgroundColor.trim().toLowerCase())
        )
    );

    setCurrentColorPalette(foundColor || currentColorPalette);
  }, [
    colorPalette,
    colorPaletteBranding,
    currentColorPalette,
    backgroundColor,
    colorPaletteCollection
  ]);

  const generatedToolCode = (value: string | undefined) => {
    if (value) handleSave(value);
    setCode(value);
  };

  const handleOpenModal = () => (isTool ? setToolModalOpen(true) : setModal(true));
  const handleClose = () => {
    updateCustomElement(false);
    setModal(false);
    setToolModalOpen(false);
  };

  const shadedBackgroundColor = useMemo(() => {
    return shade(backgroundColor || '#fff', 0.7);
  }, [backgroundColor]);

  const buttonColor = useMemo(() => {
    return tinycolor(button?.color).isDark() ? '#FFFFFF' : '#000000';
  }, [button?.color]);

  const categorizedColorPalette = useMemo(() => {
    if (currentColorPalette) {
      return convertColorsToPallete(currentColorPalette)
        .mainColors.map((color) => color.value as string)
        .toString();
    }
    return;
  }, [currentColorPalette]);

  const fontLink = useMemo(() => {
    return font === 2
      ? '<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">'
      : font === 6
        ? '<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">'
        : font === 5
          ? '<link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap" rel="stylesheet">'
          : '<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">';
  }, [font]);

  useEffect(() => {
    if (!code) return;
    if (!isTool) return;

    let modifiedCode = code;

    // 1 background color
    const backgroundColorVarRegex = /--background-color:\s*([^;]+);?/i;
    const backgroundColorVarRegex2 = /--background-color2:\s*([^;]+);?/i;
    const isBgColorVarRegex = /--is-bg-color:\s*([^;]+);?/i;

    const backgroundColorVarMatch = code.match(backgroundColorVarRegex);
    const isBgColorVarMatch = code.match(isBgColorVarRegex);

    if (backgroundColorVarMatch && backgroundColorVarMatch[1]) {
      if (isBgColorVarMatch && isBgColorVarMatch[1] === 'true') {
        setBgSectionColor(shadedBackgroundColor || '#fff');
      }

      modifiedCode = code.replace(
        backgroundColorVarMatch[0],
        `--background-color: ${shadedBackgroundColor} !important;`
      );
    }

    modifiedCode = modifiedCode.replace(
      backgroundColorVarRegex2,
      `--background-color2: ${backgroundColor} !important;`
    );

    // 2 button color
    const buttonColorVarRegex = /--button-color:\s*([^;]+);?/i;
    const buttonColorVarMatch = code.match(buttonColorVarRegex);

    if (buttonColorVarMatch && buttonColorVarMatch[1]) {
      modifiedCode = modifiedCode.replace(
        buttonColorVarMatch[0],
        `--button-color: ${button?.color} !important;`
      );
    }

    // 3 button text color
    const buttonTextColorVarRegex = /--text-color:\s*([^;]+);?/i;
    const buttonTextVarMartch = code.match(buttonTextColorVarRegex);

    if (buttonTextVarMartch && buttonTextVarMartch[1]) {
      modifiedCode = modifiedCode.replace(
        buttonTextVarMartch[0],
        `--text-color: ${buttonColor} !important;`
      );
    }

    // 4 chart colors
    const chartColorsRegex = /backgroundColor:\s*\[([^\]]*)\]?/;
    modifiedCode = modifiedCode.replace(
      chartColorsRegex,
      `backgroundColor: [${(categorizedColorPalette || '#5CE1E6,#1CAEB2,#9ACC3A,#FDC600,#FFA924')
        .split(',')
        .map((color) => "'" + color + "'")
        .join(',')}]`
    );

    // 5 font link
    const fontLinkRegex = /<link\s*href="([^"]+)"\s*rel="stylesheet">/gi;
    modifiedCode = modifiedCode.replace(fontLinkRegex, fontLink);

    const currFontFam =
      font === 2
        ? '"Montserrat", sans-serif'
        : font === 6
          ? '"Poppins", sans-serif'
          : font === 5
            ? '"Roboto Slab", serif'
            : '"Montserrat", sans-serif';

    // font family
    const fontFamilyRegex = /font-family:\s*([^;]+);?/gi;
    modifiedCode = modifiedCode.replace(fontFamilyRegex, `font-family: ${currFontFam} !important;`);

    // FINAL update code
    injectStyleIntoHtml(modifiedCode);

    setCode(modifiedCode);
    updateImageMeta(meta['accessorKey'], code, 'custom_code');

    // eslint-disable-next-line
  }, [shadedBackgroundColor, code, fontLink, categorizedColorPalette]);

  //set dynamic height for iframe if it's a tool
  useEffect(() => {
    if (!isTool) return;
    const iframe = iframeRef.current;
    if (!iframe) return;

    const updateHeight = () => {
      try {
        const bodyScrollHeight = iframe.contentWindow?.document.body?.scrollHeight;
        updateImageMeta(meta['accessorKey'], (bodyScrollHeight ?? 0) + 140, 'custom_code_height');
      } catch (error) {
        console.error('Error accessing iframe content:', error);
      }
    };

    const handleLoad = () => {
      const iframeBody = iframe.contentWindow?.document.body;
      if (!iframeBody) return;

      const observer = new ResizeObserver(updateHeight);
      observer.observe(iframeBody);
      updateHeight();
    };

    iframe.addEventListener('load', handleLoad);
    return () => iframe.removeEventListener('load', handleLoad);
  }, [meta, updateImageMeta, code, isTool]);

  const handleSave = (value: string) => {
    if (value) {
      const iframe = editorIframeRef.current;
      if (!iframe) return;
      const iframeDocument = iframe.contentWindow?.document;
      if (!iframeDocument) return;

      // Get the body and document heights
      const bodyScrollHeight = iframe.contentWindow.document.body.scrollHeight;
      const documentScrollHeight = iframeDocument.documentElement.scrollHeight;
      const bodyClientHeight = iframeDocument.body.clientHeight;
      const documentClientHeight = iframeDocument.documentElement.clientHeight;

      // Get the iframe's visible height
      const iframeClientHeight = iframe.clientHeight;

      // Determine if there is scrolling
      const hasScroll =
        bodyScrollHeight > bodyClientHeight || documentScrollHeight > documentClientHeight;

      // If there's a scroll, take the scrollHeight; otherwise, take clientHeight
      const fullHeight = hasScroll
        ? Math.max(bodyScrollHeight, documentScrollHeight)
        : Math.max(bodyClientHeight, documentClientHeight, iframeClientHeight);

      // Adjust for zoom factor
      const adjustedHeight = hasScroll
        ? fullHeight
        : iframe.contentWindow.document.body.scrollHeight;

      const updatedCode = injectStyleIntoHtml(value);

      updateImageMeta(meta['accessorKey'], adjustedHeight, 'custom_code_height'); // Save adjusted height
      updateImageMeta(meta['accessorKey'], updatedCode, 'custom_code'); // Save adjusted height
      updateForceReloading(true);
      setModal(false);
      setTimeout(() => {
        resetToolbarPosition();
        updateForceReloading(false);
      }, 1000);
      return;
    }

    updateForceReloading(true);
    removeMetaTag(meta['accessorKey'], 'custom_code');
    removeMetaTag(meta['accessorKey'], 'custom_code_height');
    setModal(false);
    setTimeout(() => {
      resetToolbarPosition();
      updateForceReloading(false);
    }, 1000);
  };

  useEffect(() => {
    if (typeof document === 'undefined') return;
    const handleClickOutside = (event: MouseEvent) => {
      const element = document.querySelector('#pw_custom_element_toolbar');
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as HTMLElement) &&
        activeElement?.id === meta['accessorKey'] &&
        !element?.contains(event.target as HTMLElement)
      ) {
        resetActiveElement();
        updateCustomElement(false);
      }
    };

    if (activeElement?.id === meta['accessorKey']) {
      const el = document.querySelector('#pw_open_custom_editor_element');
      el?.addEventListener('click', handleOpenModal);
    }

    document.addEventListener('click', handleClickOutside, true);

    // Cleanup: remove the event listener on component unmount.
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      if (activeElement?.id === meta['accessorKey']) {
        const el = document.querySelector('#pw_open_custom_editor_element');
        el?.removeEventListener('click', handleOpenModal);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElement?.id, meta, resetActiveElement, updateCustomElement]);

  return (
    <CustomContainer
      ref={containerRef}
      className={containerClassname}
      id={meta['accessorKey']}
      live={process.env['APP_TYPE'] === 'sales'}
      data-accessor-parent={meta['accessorKey']}
      onClick={(e) => {
        updateActiveElement(e);
        updateCustomElement(true);
      }}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1,
        backgroundColor: bgSectionColor
      }}>
      <StyledWrapper>
        {code ? (
          <iframe
            key={code}
            title="Custom Code"
            sandbox={isTool ? 'allow-scripts allow-same-origin' : 'allow-scripts'}
            srcDoc={code}
            allowFullScreen
            ref={iframeRef}
            width="100%"
            height={meta['custom_code_height'] || '100%'}
            style={{
              border: 'none',
              ...(websiteAssembler && { pointerEvents: 'none' })
            }}
          />
        ) : (
          <Container>
            <h1 onClick={() => (isTool ? setToolModalOpen(true) : setModal(true))}>
              <ImgCode src="/img/code.svg" alt="code icon" />
              {isTool
                ? t('components.customToolElement', { defaultValue: 'Open Tool Editor' })
                : t('components.customElement', { defaultValue: 'Custom Element' })}
            </h1>
          </Container>
        )}
        {isTool ? (
          <AIToolsGenerator
            open={toolModalOpen}
            onClose={() => setToolModalOpen(false)}
            button={{ color: button?.color ?? '' }}
            onResultCode={generatedToolCode}
            backgroundColor={backgroundColor}
            isAddedCode={code}
            shadedBackgroundColor={shadedBackgroundColor}
            buttonColor={buttonColor}
            editorIframeRef={editorIframeRef}
          />
        ) : (
          <Modal open={modal} fullScreen onClose={handleClose}>
            <Header>
              <button onClick={() => handleSave(code)}>
                {t('common.save', { defaultValue: 'Save' })}
              </button>
              <button onClick={handleClose}>
                <Cross1Icon />
              </button>
            </Header>
            <ModalWrapper>
              <FlexBox height="100%" width="45%" justifyContent="space-between" alignItems="center">
                <FlexBox flexDirection="column" width="100%" height="100%">
                  <h4>Code:</h4>
                  <Editor
                    height="100%"
                    width="100%"
                    defaultLanguage="html"
                    language="html"
                    defaultValue={code}
                    onChange={(value) => {
                      if (value) setCode(value);
                    }}
                  />
                </FlexBox>
                {/* <FlexBox flexDirection="column" width="33%" height="100%">
                <h4>CSS:</h4>
                <Editor
                  height="100%"
                  width="100%"
                  defaultLanguage="css"
                  language="css"
                  defaultValue={code.css}
                  onChange={(value = '') => {
                    setCode({
                      ...code,
                      css: value
                    });
                  }}
                />
              </FlexBox>
              <FlexBox flexDirection="column" width="33%" height="100%">
                <h4>Javascript:</h4>
                <Editor
                  height="100%"
                  width="100%"
                  defaultLanguage="javascript"
                  language="javascript"
                  defaultValue={code.js}
                  onChange={(value = '') => {
                    setCode({
                      ...code,
                      js: value
                    });
                  }}
                />
              </FlexBox> */}
              </FlexBox>
              <FlexBox
                width="45%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                border="1px solid black">
                <iframe
                  title="Code Preview"
                  ref={editorIframeRef}
                  allowFullScreen
                  srcDoc={code}
                  width="100%"
                  height="100%"
                  style={{
                    border: 'none',
                    display: 'flex',
                    zoom: ZOOM_SIZE,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                />
              </FlexBox>
            </ModalWrapper>
          </Modal>
        )}
      </StyledWrapper>
    </CustomContainer>
  );
};
