import type { FC } from 'react';
import type { AboutMeProps } from './types';

import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import { ApiService } from 'shared/helpers/api';
import { COLOR_GRAY_13 } from 'shared/helpers/colors';
import { sanitizeRichText } from 'shared/helpers/utils';
import { defaultWebValue, filterBackgroundColor } from 'shared/helpers/website';

import { HoverableDropzone } from '../HoverableDropzone';
import { Wrapper } from '../Common';

import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const About = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
  `}
`;

const Container = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  max-width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  flex-direction: column-reverse;
  gap: 30px;
`}
`;

const TextWrapper = styled.div`
  width: 52%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
  `}
`;

const ImageWrapper = styled.div`
  width: 41%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
  `}
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin-bottom: 18px;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.4em;
  color: ${COLOR_GRAY_13};
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 940px;
  max-width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    margin-top: 30px;
    height: 500px;
  `}

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const V2: FC<AboutMeProps> = (props) => {
  const { content = defaultWebValue, title = defaultWebValue, meta, hidden, textColor } = props;
  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { salesPageId, updateImageMeta } = useSales();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const [loading, setLoading] = useState(false);
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const aboutMeImage = useMemo(() => {
    if (meta['image']) {
      return meta['image'];
    }
    return '/img/lead_bg.png';
  }, [meta]);

  const handleUpload = async (file: File) => {
    setLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setLoading(false);
  };

  return (
    <About
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Container>
        <ImageWrapper>
          <HoverableDropzone
            isSalePage
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={loading}
            alreadyUploaded={Boolean(aboutMeImage)}
            imageFile={aboutMeImage}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '3x4',
              pixels: '600x800'
            }}>
            <ImageComp
              src={aboutMeImage}
              width={resize.width || 500}
              height={resize.height || 500}
              alt="Photo"
            />
          </HoverableDropzone>
        </ImageWrapper>
        <TextWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Text
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </TextWrapper>
      </Container>
    </About>
  );
};

V2.displayName = 'AboutMeVersion2';
