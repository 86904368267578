import type { ComponentPropsWithRef } from 'react';

import { forwardRef } from 'react';
import { Image as ImageComp } from 'shared/components/Common';
import styled from 'styled-components';

const Container = styled.div<{ mockup_id: number }>`
  width: 657px;
  height: 700px;
  position: relative;

  ${({ mockup_id, theme: { $width } }) =>
    // Tablet 1
    mockup_id === 1
      ? `
      width: 600px;

      ${
        $width <= 1254 &&
        `
        height: 570px;
        width: auto;
    `
      }}

      ${
        $width <= 991 &&
        `
        height: 430px;
    `
      }}


      ${
        $width <= 475 &&
        `
        height: auto;
    `
      }}
      `
      : // Notebook 2
        mockup_id === 2
        ? `
      width: 610px;
      ${
        $width <= 1254 &&
        `
        height: 570px;
        width: auto;
    `
      }}

      ${
        $width <= 991 &&
        `
        height: 430px;
    `
      }}


      ${
        $width <= 475 &&
        `
        height: auto;
    `
      }}
      `
        : // Board 3
          mockup_id === 3
          ? `
      width: 530px;
      ${
        $width <= 1254 &&
        `
        height: 570px;
        width: auto;
    `
      }}

      ${
        $width <= 991 &&
        `
        height: 430px;
    `
      }}


      ${
        $width <= 475 &&
        `
        height: auto;
    `
      }}
      `
          : ``};
`;

const MockUpImage = styled(ImageComp)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CoverImage = styled(ImageComp)<{ mockup_id: number }>`
  width: 71%;
  height: 96%;
  top: 2px;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  left: 111px;

  ${({ mockup_id, theme: { $width } }) =>
    // Tablet 1

    mockup_id === 1
      ? `
      height: 80%;
      top: 10%;
      width: 83.3%;
      left: 7.4%;

      ${
        $width <= 1254 &&
        `
        width: 83%;
        left: 7.5%;
    `
      }}
      `
      : // Notebook 2
        mockup_id === 2
        ? `
        height: 87.3%;
        top: 4.1%;
        width: 71.4%;
        left: 20.5%;`
        : // Board 3
          mockup_id === 3
          ? `
        height: 85.3%;
        top: 4.8%;
        width: 80.4%;
        left: 10.5%;`
          : ``}
`;

export interface MockupProps extends ComponentPropsWithRef<'div'> {
  mockup: string;
  mockup_id: number;
  cover_thumbnail: string;
}

export const Mockup = forwardRef<HTMLDivElement, MockupProps>((props, ref) => {
  const { mockup, mockup_id, cover_thumbnail, ...rest } = props;

  return (
    <Container ref={ref} mockup_id={mockup_id} {...rest}>
      <CoverImage
        mockup_id={mockup_id}
        src={cover_thumbnail}
        width={500}
        height={500}
        alt="Cover"
      />
      <MockUpImage src={mockup} alt="MockUp" width={500} height={500} />
    </Container>
  );
});

Mockup.displayName = 'Mockup';
