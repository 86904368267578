import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FlexBox, NavButton } from 'shared/components/Common';

import { Modal } from './Modal';
import { COLOR_ORANGE } from 'shared/helpers/colors';

const cssContent = css`
  height: fit-content !important;
  max-width: 680px !important;
  border-radius: 20px;
`;

const TextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  align-items: center;
`;

const Container = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow: auto;
  max-height: 80rem;
  padding: 58px 30px 50px 30px;
  position: relative;

  @media screen and (max-width: 575px) {
    padding: 57px 10px 30px 10px;
  }
`;

const ImgInner = styled.div`
  width: 105px;
  height: 105px;
  background: ${COLOR_ORANGE};
  margin: 0 auto;
  position: absolute;
  top: -40px;
  z-index: 1;
  left: 0;
  right: 0;
  border-radius: 50%;
  box-shadow: 0px 5.25px 22.837px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 5px;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 22px;
  }
`;

const Paragraph = styled.p`
  color: #444;
  font-size: 20px;
  text-align: center;
  margin-bottom: 12px;
  line-height: 1.5;

  @media screen and (max-width: 575px) {
    font-size: 17px;
    margin-bottom: 12px;
  }
`;

const NavButtonStyled = styled(NavButton)`
  font-size: 18px;
  font-weight: 600;
  padding: 20px 40px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  border: none;

  @media screen and (max-width: 575px) {
    font-size: 17px;
    padding: 18px;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: (open: boolean) => void;
  title?: string;
  text: string;
}

function FreeAccountModal({ isOpen, onClose, title, text }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const upgradeNavigation = () => {
    navigate('/dashboard/account-settings?tab=3');
    setTimeout(() => {
      const plansSection = document.getElementById('plansSection');
      if (plansSection) {
        const offset = -100;
        const sectionTop = plansSection.getBoundingClientRect().top + window.pageYOffset + offset;

        window.scrollTo({
          top: sectionTop,
          behavior: 'smooth'
        });
      }
    }, 200);
  };

  return (
    <Modal
      cssContent={cssContent}
      open={isOpen}
      onOpenChange={(open) => {
        onClose(open);
      }}>
      <ImgInner>
        <img src={'/img/crownUpgradeAcc.svg'} />
      </ImgInner>
      <Container>
        <TextInner>
          <Title>
            {title || t('common.upgratePlan', { defaultValue: 'Upgrate Your Account' })}
          </Title>
          <Paragraph>{text}</Paragraph>
        </TextInner>
        <FlexBox justifyContent="center" alignItems="center">
          <NavButtonStyled
            backgroundColor={COLOR_ORANGE}
            content={t('common.upgradeBtn', { defaultValue: 'Become a Pagewheel Member' })}
            onClick={upgradeNavigation}
          />
        </FlexBox>
      </Container>
    </Modal>
  );
}

export default FreeAccountModal;
