import type { FC } from 'react';
import type { AboutMeProps } from './types';

import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import useSales from 'shared/hooks/useSales';
import useWebsiteEditor from 'shared/hooks/useWebsiteEditor';

import { ApiService } from 'shared/helpers/api';
import { sanitizeRichText } from 'shared/helpers/utils';
import { defaultWebValue, filterBackgroundColor } from 'shared/helpers/website';

import { HoverableDropzone } from '../HoverableDropzone';
import { Wrapper } from '../Common';
import { Image as ImageComp } from 'shared/components/Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const Container = styled.div`
  padding: 80px 0;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 60px 0;
    `}
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const Title = styled.h2<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin-bottom: 18px;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Text = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.4em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
  max-width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    margin-bottom: 30px;
  `}

  & img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

export const V3: FC<AboutMeProps> = (props) => {
  const { content = defaultWebValue, title = defaultWebValue, meta, hidden, textColor } = props;
  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { salesPageId, updateImageMeta } = useSales();

  const [loading, setLoading] = useState(false);

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const aboutMeImage = useMemo(() => {
    if (meta['image']) {
      return meta['image'];
    }
    return '/img/lead_bg.png';
  }, [meta]);

  const handleUpload = async (file: File) => {
    setLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setLoading(false);
  };

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <HoverableDropzone
          isSalePage
          css={imageStyles as never}
          disabled={!editable}
          alreadyUploaded={Boolean(aboutMeImage)}
          imageFile={aboutMeImage}
          allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
          onUpload={handleUpload}
          resizeSelector={editable}
          handleResize={({ width, height }) => {
            setResize({ width, height });
            updateImageMeta(meta['accessorKey'], width, 'width');
            updateImageMeta(meta['accessorKey'], height, 'height');
          }}
          initialPosition={{
            x: meta['x_1'] || 0,
            y: meta['y_1'] || 0,
            width: meta['d_width_1'],
            height: meta['d_height_1']
          }}
          meta={meta}
          positionEnabled
          handlePosition={(width: number, height: number, x: number, y: number) => {
            updateImageMeta(meta['accessorKey'], x, 'x_1');
            updateImageMeta(meta['accessorKey'], y, 'y_1');
            updateImageMeta(meta['accessorKey'], width, 'd_width_1');
            updateImageMeta(meta['accessorKey'], height, 'd_height_1');
          }}
          loading={loading}>
          <ImageComp
            src={aboutMeImage}
            width={resize.width || 500}
            height={resize.height || 500}
            alt="Photo"
          />
        </HoverableDropzone>
        <TextWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Text
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor('#fff', textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

V3.displayName = 'AboutMeVersion3';
