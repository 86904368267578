import { useMemo, useState, type FC } from 'react';
import type { HeroDetailedProps } from './types';

import styled, { css } from 'styled-components';
import {
  ApiService,
  COLOR_YELLOW,
  defaultWebValue,
  filterBackgroundColor,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';

import { Button, Wrapper, Mockup } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { Image as ImageComp } from 'shared/components/Common';
import { HoverableDropzone } from '../HoverableDropzone';

const HeroDetailed = styled.div`
  position: relative;
  min-height: 693px;
  margin-bottom: 30px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  flex-direction: column;
  `}
`;

const StyledWrapper = styled((props) => <Wrapper {...props} />)`
  display: flex;
  justify-content: space-between;
  padding: 70px 0 10px 0px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
      padding: 70px 0 48px 0px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    flex-direction: column-reverse;
    `}

      ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      padding: 50px 0 48px 0px;
    `}
`;

const TitleWrapper = styled.div`
  width: 655px;
  position: relative;
  z-index: 7;
  height: fit-content;
  margin-bottom: 235px;
  padding-top: 30px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 502px;
      margin-bottom: 205px;
      padding-top: 16px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      padding-top: 0px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-top: 40px;
    margin-bottom: 0px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 0 15px;
    `}
`;

const Title = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $largeHeadlineFont } }) => $largeHeadlineFont};
  line-height: 1.15em;
  color: #fff;
  letter-spacing: -0.01em;
  margin-bottom: 25px;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 14px;
    `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  margin-bottom: 38px;
  line-height: 1.35em;
  color: #fff;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    margin-bottom: 29px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    margin-bottom: 40px;
    `}
`;

const BtnInner = styled.div`
  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `

  width: 100%;

`}
`;

const StyledButton = styled(Button)`
  max-width: 465px;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
      max-width: 360px;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 26px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    max-width: 280px;

  `}
`;

const RelativeWrapper = styled.div`
  position: relative;
  z-index: 6;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      display: flex;
      justify-content: center;
    `}
`;

const StyledMockup = styled((props) => <Mockup {...props} />)`
  width: 542px;
  height: auto;
  object-fit: cover;
  object-position: center center;
  z-index: 2;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 438px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 340px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 370 &&
    `
    width: 270px;
  `}
`;

const StyledWrapperBenefits = styled((props) => <Wrapper {...props} />)`
  padding: 0px 0px 10px;
  margin-top: -170px;
  position: relative;
  z-index: 5;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-top: 0px;
    `}
`;

const BenefitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 715px;
  z-index: 6;
  bottom: 20px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 502px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
    `}

    ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    padding: 0 15px 80px;
      flex-direction: column;
      gap: 15px;
      `}
`;

const BenefitItem = styled.div`
  width: 31.5%;
  padding: 17px 13px 28px;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
    width: 32%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    padding: 17px 13px 20px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
    padding: 18px 18px 20px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    padding: 18px 15px;
    border-radius: 12px;
    `}
`;

const Svg = styled.svg`
  width: 62px;
  height: 62px;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
      width: 52px;
      height: 52px;
    `}

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 42px;
    height: 42px;
    margin-bottom: 8px;
  `}

    ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 52px;
    height: 52px;
    margin-bottom: 8px;
  `}
`;

const BenefitTxtInner = styled.div`
  width: 100%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 76%;
    `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
      width: 80%;
    `}
`;

const TitleBenefit = styled.h3<{ isEmpty?: boolean; editable?: boolean }>`
  margin-bottom: 4px;
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  font-weight: 500;
  line-height: 1.35em;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    margin-bottom: 4px;
    `}

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
      display: ${editable ? 'block' : 'none'};
      &:not(:focus):before {
        content: attr(data-placeholder);
        cursor: text;
        color: #aaa;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
      : `
      &:before {
        content: none;
      } 
    `}
`;

const TextBenefit = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $tinyTextFont } }) => $tinyTextFont};
  line-height: 1.3em;

  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const WaveBg = styled.div`
  width: 100%;
  height: 320px;
  position: absolute;
  bottom: -1px;
  z-index: 2;
  background: url('https://pagewheel-public.s3.us-east-1.amazonaws.com/imgs/waveBg_1.svg') no-repeat
    center bottom / cover;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
  height: 80px;
  `}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
  height: 60px;
  `}
`;

const ColoredBg = styled.div`
  position: absolute;
  width: 100%;
  height: 99.5%;
  top: 0;
  opacity: 0.2;

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    height: 100%;
    `}
`;

const imageStyles = css`
  position: relative;
  overflow: hidden;
  height: 685px;
  width: 513px;

  ${({ theme: { $width } }) =>
    $width <= 480 &&
    `
    margin: 0 auto;
  `}
`;

const BookMediaImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V4: FC<HeroDetailedProps> = (props) => {
  const {
    mockup,
    cover_thumbnail,
    title = defaultWebValue,
    content = defaultWebValue,
    button = defaultWebValue,
    backgroundColor = COLOR_YELLOW,
    textColor,
    benefits = [],
    meta,
    hidden
  } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { isCoverDisabled, pages, bookMedia } = useBook();
  const { pagesThumbnails, salesPageId, purchaseElementId, updateImageMeta } = useSales();
  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const coverThumbnail = useMemo(() => {
    if (isCoverDisabled && (pages.length > 0 || pagesThumbnails.length > 0)) {
      return pagesThumbnails[0] || pages[0]?.image_preview;
    }
    return cover_thumbnail;
  }, [isCoverDisabled, pages, pagesThumbnails, cover_thumbnail]);

  const bookMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'book_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <HeroDetailed
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <StyledWrapper>
        <TitleWrapper>
          <Title
            id={title.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
            style={{ color: filterBackgroundColor(backgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="title"
            data-placeholder="+"
            editable={editable}
            isEmpty={isTitleEmpty}
          />
          <Description
            id={content.id}
            dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
            style={{ color: filterBackgroundColor(backgroundColor, textColor) }}
            onClick={updateActiveElement}
            onBlur={resetActiveElement}
            data-accessor-parent={meta['accessorKey']}
            data-accessorkey="content"
            data-placeholder="+"
            editable={editable}
            isEmpty={isContentEmpty}
          />
          <BtnInner>
            <StyledButton to={purchaseElementId} button={button} meta={meta} />
          </BtnInner>
        </TitleWrapper>
        <RelativeWrapper>
          <div>
            {bookMockup ? (
              <HoverableDropzone
                isSalePage
                css={imageStyles as never}
                disabled={!editable}
                allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
                onUpload={handleUpload}
                loading={isLoading}
                alreadyUploaded={Boolean(bookMockup)}
                imageFile={bookMockup}
                resizeSelector={editable}
                handleResize={({ width, height }) => {
                  setResize({ width, height });
                  updateImageMeta(meta['accessorKey'], width, 'width');
                  updateImageMeta(meta['accessorKey'], height, 'height');
                }}
                initialPosition={{
                  x: meta['x_1'] || 0,
                  y: meta['y_1'] || 0,
                  width: meta['d_width_1'],
                  height: meta['d_height_1']
                }}
                meta={meta}
                positionEnabled
                handlePosition={(width: number, height: number, x: number, y: number) => {
                  updateImageMeta(meta['accessorKey'], x, 'x_1');
                  updateImageMeta(meta['accessorKey'], y, 'y_1');
                  updateImageMeta(meta['accessorKey'], width, 'd_width_1');
                  updateImageMeta(meta['accessorKey'], height, 'd_height_1');
                }}
                imageAspectRatio={{
                  ratio: '3x4',
                  pixels: '600x800'
                }}>
                <BookMediaImg
                  isImageUploaded={isImageUploaded}
                  src={bookMockup}
                  alt="Book Mockup"
                  width={resize.width || 500}
                  height={resize.height || 500}
                />
              </HoverableDropzone>
            ) : (
              <StyledMockup
                mockup={mockup?.file}
                mockup_id={mockup?.id}
                cover_thumbnail={coverThumbnail}
              />
            )}
          </div>
        </RelativeWrapper>
      </StyledWrapper>

      <StyledWrapperBenefits style={{ padding: '0px 0px 10px' }}>
        <BenefitWrapper>
          {benefits.map(({ content, icon, title }, index) => {
            const isTitleBenEmpty = checkTitleBenefits(index);
            const isContentBenEmpty = checkContentBenefits(index);
            return (
              <BenefitItem
                // style={{
                //   display: isTitleBenEmpty && isContentBenEmpty && !editable ? 'flex' : 'none'
                // }}
                key={`${icon?.toString()}-${index}`}>
                <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62">
                  <circle cx="31" cy="31" r="31" strokeWidth={0} fill={backgroundColor} />
                  <path
                    d="m44.85,23.17c-.13.64-.53,1.1-.97,1.55-5.07,5.21-10.13,10.43-15.19,15.65-.4.41-.85.64-1.41.46-.23-.07-.46-.21-.63-.38-2.52-2.58-5.03-5.17-7.54-7.76-.86-.89-.84-2.14.01-3.03.28-.3.57-.59.86-.88.87-.88,2.08-.88,2.94,0,1.5,1.54,3,3.09,4.5,4.63.07.07.1.16.2.31.12-.17.17-.26.25-.34,4.15-4.28,8.3-8.56,12.46-12.84.98-1.01,2.15-1.01,3.13,0,.14.14.27.29.42.43.46.43.86.91.99,1.57v.64h0Z"
                    fill="#fff"
                    strokeWidth={0}
                  />
                </Svg>
                <BenefitTxtInner>
                  <TitleBenefit
                    id={title?.id}
                    dangerouslySetInnerHTML={{ __html: sanitizeRichText(title?.val, editable) }}
                    style={{ color: filterBackgroundColor('#fff', textColor) }}
                    onClick={updateActiveElement}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="title"
                    data-benefits={true}
                    data-placeholder="+"
                    editable={editable}
                    isEmpty={isTitleBenEmpty}
                  />
                  <TextBenefit
                    id={content?.id}
                    dangerouslySetInnerHTML={{ __html: sanitizeRichText(content?.val, editable) }}
                    style={{ color: filterBackgroundColor('#fff', textColor) }}
                    onClick={updateActiveElement}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="content"
                    data-benefits={true}
                    data-placeholder="+"
                    editable={editable}
                    isEmpty={isContentBenEmpty}
                  />
                </BenefitTxtInner>
              </BenefitItem>
            );
          })}
        </BenefitWrapper>
      </StyledWrapperBenefits>
      <WaveBg />
      <ColoredBg style={{ background: backgroundColor }} />
    </HeroDetailed>
  );
};
