import type {
  BatchPage,
  Book,
  BookBatch,
  BookIngredients,
  BookPages,
  ColorPalette,
  Cover,
  DatabaseBookPage,
  Font,
  GeneratedText,
  Media,
  Mockup,
  PageData,
  SavedPage,
  SystemBatch
} from 'shared/types/index';

import { useCallback, useMemo } from 'react';
import slugify from 'slugify';

import {
  ApiService,
  areArraysEqual,
  convertColorsToPallete,
  generateSaltSalesCode,
  getFileFromUrl,
  isLocalhost,
  monthNames,
  randomItem,
  subTitlePromptVal,
  toBase64
} from 'shared/helpers/index';

import useBook from './useBook';
import useBranding from './useBranding';
import useErrorLogger from './useErrorLogger';
import useOpenAi from './useOpenAi';
import useRenderImage from './useRenderImage';
import useSales from './useSales';
import useWebsiteCreation from './useWebsiteCreation';
import useWebsiteEditor from './useWebsiteEditor';
import { useMutation, useQuery } from 'react-query';
import { defaultBookBaseColorCollection } from '../context';
import useAlert from './useAlert';
import useAuth from './useAuth';

/**
 * @function useBookAssembler
 *
 * @description This hook is used for assembling the book data and pages.
 * This is the newer version of the bookCreation hook, which is used to create a book.
 * It has methods to add a page, sync pages on the backend, insert saved book page,
 */
const useBookAssembler = () => {
  const entityId = localStorage.getItem('entityId');

  const { logError } = useErrorLogger();
  const { renderImage } = useRenderImage();
  const { updateEditable } = useWebsiteEditor();
  const {
    fontStyle,
    colorPalette,
    systemPalettes,
    setBookBaseColorCollection,
    setBookBaseColor,
    setColorPalette,
    setColorPaletteCollection,
    setSystemPalettes,
    setFont,
    setFontCollection,
    setFontStyle,
    bookBaseColorCollection,
    bookBaseColor,
    font,
    fontStyleCollection
  } = useBranding();
  const {
    bookId,
    pages: bookPages,
    bookName,
    bookSubtitle,
    cover,
    isCoverDisabled,
    setPages,
    setPage,
    setCover,
    setSavedPages,
    updateRenderPage,
    mockup,
    setProductNiches,
    setProductTopics,
    setCoverCollection,
    setIsCoverDisabled,
    setMockup,
    setMockupCollection,
    setBookName,
    setBookSubtitle,
    updateHistory,
    selectedBatches,
    selectedTopics,
    selectedNiches,
    batchSearchKeyword,
    bookFolders,
    page,
    updateLastModified,
    setBookId,
    setAboutMePageIds,
    setBatchSearchKeyword,
    setSalesData,
    setBatchIds,
    setSelectedTopics,
    setSelectedNiches,
    setBookFolders,
    setBooks,
    updatePages,
    setBookMedia
  } = useBook();
  const { addAlert } = useAlert();
  const { user } = useAuth();

  const {
    salesPageValues,
    deliveryPageValues,
    salesPageId,
    updateInitialWebsite,
    setInitialMetaTags,
    updateDownloadUrl,
    updateSubDomain,
    updateWebPrompt,
    updateAi,
    updateCoverDisabled,
    updateSalesPageId
  } = useSales();
  const { generateWebsiteValues } = useWebsiteCreation();
  const { generateText, runAi } = useOpenAi();

  const categorizedColorPalette = useMemo(() => {
    return convertColorsToPallete(colorPalette);
  }, [colorPalette]);

  const { refetch: refetchBookFolders } = useQuery(
    `/book-generator/book-folders/`,
    () => ApiService.get(`/book-generator/book-folders/`),
    {
      select: (response) => response.data,
      onSuccess: (data) => setBookFolders(data.results),
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!user
    }
  );

  const { refetch: refetchBookMedia } = useQuery(
    `/book-generator/books/${bookId}/book_media/`,
    () => ApiService.get(`/book-generator/books/${bookId}/book_media/`),
    {
      select: (response) => response.data,
      onSuccess: async (data: { name: string; file: string }[]) => {
        const results = [];
        for (const media of data) {
          let endpoint = media.file;
          if (isLocalhost(true)) {
            endpoint = `http://localhost:8000${endpoint}`;
          }
          const file = (await getFileFromUrl(endpoint)) as File;
          const isAboutMe = media.name === 'photo';
          const pageId = isAboutMe ? 0 : media?.name?.match(/\d+/)?.[0];
          results.push({
            name: media.name,
            url: endpoint,
            file,
            pageId: Number(pageId),
            isAboutMe
          });
        }
        setBookMedia(results);
      },
      refetchOnWindowFocus: false,
      enabled: !!bookId
    }
  );

  const { refetch: refetchBooks } = useQuery(
    `/book-generator/books`,
    () => ApiService.get(`/book-generator/books/?page_size=500`),
    {
      select: (response) => {
        const books = response.data?.results?.filter((b: Book) => b.is_active) as Book[];
        return books ?? [];
      },
      onSuccess: (data) => {
        setBooks(data);
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!user
    }
  );

  /**
   * @function useBookCreation/defaultBookValues
   *
   * @description This method is used to get all the default values for the book.
   * It includes all the necessary data for creating/updating the book.
   *
   * @returns Default values for the book.
   */
  const defaultBookValues = async (batches: SystemBatch[], pageByPage: boolean) => {
    // Get/Set All Default Data
    const [
      userPalettesResponse,
      palettesResponse,
      fontsResponse,
      coversResponse,
      mockupsResponse,
      savedPagesResponse
    ] = await Promise.all([
      ApiService.get('/bizzy/user-color-palettes/'),
      ApiService.get('/bizzy/color-palettes/'),
      ApiService.get('/bizzy/fonts/'),
      ApiService.get('/book-generator/pages/?type=cover&page_size=250'),
      ApiService.get('/book-generator/book-mockups/'),
      ApiService.get('/book-generator/saved-pages/')
    ]);
    if (
      userPalettesResponse.status !== 200 ||
      palettesResponse.status !== 200 ||
      fontsResponse.status !== 200 ||
      coversResponse.status !== 200 ||
      mockupsResponse.status !== 200
    ) {
      throw new Error('Something went wrong.. Please try again');
    }

    // Set Saved Pages Data
    const savedPagesResults = savedPagesResponse.data.results;
    setSavedPages(savedPagesResults);

    // Get All Pages from Batches
    const selectedPages = batches
      .map((batch) => batch?.pages?.map((item) => item.page))
      .reduce((pre, cur) => pre.concat(cur), []);

    // Get Palette Data
    const paletteResults = palettesResponse.data.results?.sort(
      (a: ColorPalette, b: ColorPalette) => Number(b.is_default) - Number(a.is_default)
    );
    const userPaletteResults = userPalettesResponse.data.results;
    const mergedPaletteResults = [
      ...(userPaletteResults?.[0] ? [userPaletteResults[0]] : []),
      ...paletteResults,
      ...(userPaletteResults?.length ? userPaletteResults.slice(1) : [])
    ];

    const defaultPalette = mergedPaletteResults?.[0];
    const palette = convertColorsToPallete(defaultPalette);
    setColorPalette(defaultPalette);
    setColorPaletteCollection(mergedPaletteResults);
    setSystemPalettes(paletteResults);
    setBookBaseColor(palette.baseColors[0]);
    setBookBaseColorCollection(
      palette.baseColors.map((b) => ({
        ...b,
        dark:
          defaultBookBaseColorCollection.find(
            (p) => p.value.toLocaleLowerCase() === b.value.toLocaleLowerCase()
          )?.dark || '#FFFFFF'
      }))
    );

    // Get Font Data
    const fontResults = fontsResponse.data.results;
    const defaultFont = fontResults[0];
    setFont(defaultFont);
    setFontCollection(fontResults);

    // Get Mockup Data
    const mockupResults = mockupsResponse.data.results;
    const defaultMockup = mockupResults.find((x: Mockup) => x.name === 'imac');
    setMockup(defaultMockup);
    setMockupCollection(mockupResults);

    // Get Title and Subtitle from Batch Pages
    const titles = selectedPages
      .filter((page) => page?.name)
      .map((page) => {
        if (page?.name.split(' ').length > 1) {
          return page?.name.substring(0, page?.name.lastIndexOf(' '));
        }
        return page?.name;
      });
    let title = randomItem(titles);
    const subtitles = selectedPages
      .map((subTitle: BookPages) => subTitle?.wording?.promise)
      .filter((subTitle) => subTitle);
    let subtitle = randomItem(subtitles);
    if (pageByPage) {
      setBookName('');
      setBookSubtitle('');
    } else {
      const pawprintTitle = batches?.[0]?.name || title || '';
      const data = await runAi(subTitlePromptVal, pawprintTitle);
      const pawprintSubtitle =
        data
          .split('\n')
          .map((line: string) => line.replace(/^\d+\.\s*/, ''))
          .map((line: string) => line.replace('"', ''))
          .filter(Boolean)?.[0] || subtitle;

      setBookName(pawprintTitle);
      setBookSubtitle(pawprintSubtitle);
      title = pawprintTitle;
      subtitle = pawprintSubtitle;
    }

    // Get Cover Data
    const coverResults = coversResponse.data.results?.filter(
      (item: Cover) => item.is_active === true
    );
    const randomizedFirstCover = randomItem(coverResults);
    let defaultCover = randomizedFirstCover;
    try {
      const coverResponse = await renderImage(randomizedFirstCover.id, [], true, {
        title,
        subtitle,
        color_palette: defaultPalette,
        font: defaultFont.id,
        base_color: defaultPalette.base_color1
      });
      defaultCover = {
        ...randomizedFirstCover,
        image_preview: toBase64(coverResponse.data.image)
      };
      setCover(defaultCover);
    } catch {
      //
    }
    setCoverCollection(coverResults);
    setIsCoverDisabled(false);

    // Get Default Pages from Batches
    const defaultPages = selectedPages.map((page, index) => {
      const ingredients = page?.ingredients?.map((ingredient) => ({
        page_ingredient: ingredient.id,
        ingredient_data: ingredient.ingredient_data
      }));
      return {
        page: page?.id,
        system_id: page?.system_id,
        order: index,
        ingredients
      };
    });

    setPages(defaultPages as BookPages[]);
    updateHistory(defaultPages as BookPages[]);
    setPage(defaultPages[0] as BookPages);
    updateRenderPage(defaultPages[0] as BookPages);

    return {
      defaultCover,
      defaultMockup,
      defaultPalette,
      defaultFont,
      defaultPages,
      defaultTitle: title,
      defaultSubTitle: subtitle
    };
  };

  /**
   * @function useBookCreation/createBook
   *
   * @description This method is used to create the book. it utilizes
   * defaultvalues method to initialize book and create instance on backend as well.
   *
   * @returns Created Book Instance
   */
  const createBook = async (batches: SystemBatch[], pageByPage = false, isFake = false) => {
    batches = batches || selectedBatches;

    const {
      defaultPalette,
      defaultCover,
      defaultMockup,
      defaultFont,
      defaultPages,
      defaultTitle,
      defaultSubTitle
    } = await defaultBookValues(batches, pageByPage);

    const defaultPalettePayload = {
      ...defaultPalette,
      palette_id: defaultPalette.id
    };
    const selectedBatchIds = batches?.map((item) => ({
      batch: item?.id
    }));

    const nicheNames = selectedNiches.map((item) => item.name);
    const topicNames = selectedTopics.map((item) => item.name);

    const data = {
      batches: selectedBatchIds,
      cover: defaultCover.id,
      mockup: defaultMockup.id,
      name: pageByPage ? '' : defaultTitle,
      options: {
        color_palette: defaultPalettePayload,
        base_color: defaultPalettePayload.base_color1,
        headline_style: fontStyle.name,
        font: defaultFont.id,
        subtitle: defaultSubTitle
      },
      owner_entity: entityId,
      pages: defaultPages,
      extra_data: {
        niches: nicheNames,
        topics: topicNames,
        search_keyword: batchSearchKeyword,
        main_niche: '',
        sub_niches: []
      },
      cover_image_preview: defaultCover.image_preview
    };

    const bookResponse = await ApiService.post('/book-generator/books/', data);
    const book = await ApiService.get(`/book-generator/books/${bookResponse.data.id}/`);
    updateLastModified(bookResponse.data.modified_at);
    const defaultBookPages = book.data?.pages?.map((page: BatchPage) => {
      if (page.bookpage_type === 'pdf') return page;
      const defaultIngredients = page.page?.ingredients;
      const ingredients = page.ingredients.map((ingredient, index) => ({
        ...defaultIngredients[index],
        ...ingredient,
        id: ingredient.page_ingredient,
        page: ingredient.book_page,
        ingredient: ingredient.id
      }));
      return {
        ...page,
        page: page.page.id,
        system_id: page.page.system_id,
        src: page.image_preview,
        ingredients
      };
    });
    const sortedDefaultPages = defaultBookPages.sort(
      (a: BookPages, b: BookPages) => a.order - b.order
    );
    setPages(sortedDefaultPages);
    updateHistory(sortedDefaultPages);
    setPage(sortedDefaultPages[0]);
    updateRenderPage(sortedDefaultPages[0]);
    setBookId(bookResponse.data.id);
    if (!isFake) {
      await ApiService.post(`/book-generator/books/${bookResponse.data.id}/update_book_previews/`);
    }
    const aboutMePagesResponse = await ApiService.get('/book-generator/pages/?type=about_me');

    const aboutMePagesResults = aboutMePagesResponse.data.results;
    const aboutMePageIds = aboutMePagesResults.map((x: BookPages) => x.id);
    setAboutMePageIds(aboutMePageIds);

    if (bookResponse.status !== 201) {
      throw new Error('Something went wrong.. Please try again');
    }

    // Set User Media Data by default for product
    const privateMediaResponse = await ApiService.get(`/private-media/`);
    const userMedia = privateMediaResponse.data.results;
    const avatar = userMedia.find((x: Media) => x.description === 'user_avatar');
    if (avatar) {
      const avatarFile = await getFileFromUrl(avatar.file);
      const formData = new FormData();
      formData.append('name', 'photo');
      formData.append('file', avatarFile as File);
      void ApiService.post(`/book-generator/books/${bookResponse.data.id}/book_media/`, formData);
    }

    return bookResponse.data;
  };

  /**
   * @function useBookCreation/loadBook
   *
   * @description This method is used to load already instatiated book from the backend.
   * Here we fetch all the necessary data for the book and set it to the context.
   *
   * @returns Loaded Book Instance
   */
  const loadBook = async (bookId: number) => {
    const [
      bookResponse,
      palettesResponse,
      fontsResponse,
      coversResponse,
      mockupsResponse,
      bookMediaResponse,
      aboutMePagesResponse,
      userPalettesResponse,
      savedPagesResponse
    ] = await Promise.all([
      ApiService.get(`/book-generator/books/${bookId}/`),
      ApiService.get('/bizzy/color-palettes/'),
      ApiService.get('/bizzy/fonts/'),
      ApiService.get('/book-generator/pages/?type=cover&page_size=250'),
      ApiService.get('/book-generator/book-mockups/'),
      ApiService.get(`/book-generator/books/${bookId}/book_media/`),
      ApiService.get('/book-generator/pages/?type=about_me'),
      ApiService.get('/bizzy/user-color-palettes/?page_size=250'),
      ApiService.get('/book-generator/saved-pages/')
    ]);
    if (
      palettesResponse.status !== 200 ||
      fontsResponse.status !== 200 ||
      coversResponse.status !== 200 ||
      mockupsResponse.status !== 200 ||
      userPalettesResponse.status !== 200
    ) {
      throw new Error('Something went wrong.. Please try again');
    }
    const book = bookResponse.data;

    // Set Pallete Data
    const paletteResults = palettesResponse.data.results?.sort(
      (a: ColorPalette, b: ColorPalette) => Number(b.is_default) - Number(a.is_default)
    );
    const userPaletteResults = userPalettesResponse.data.results;
    const mergedPaletteResults = [
      ...(userPaletteResults?.[0] ? [userPaletteResults[0]] : []),
      ...paletteResults,
      ...(userPaletteResults?.length ? userPaletteResults.slice(1) : [])
    ];

    const defaultPalette = mergedPaletteResults.find(
      (palette) => palette.id === book.options?.color_palette?.palette_id
    );
    const palette = convertColorsToPallete(defaultPalette);
    const baseColor = palette.baseColors.find((item) => item.value === book.options?.base_color);
    updateLastModified(book.modified_at);
    if (defaultPalette) {
      setColorPalette(defaultPalette);
    }
    setColorPaletteCollection(mergedPaletteResults);
    setSystemPalettes(paletteResults);
    if (baseColor) {
      setBookBaseColor(baseColor);
    }
    setBookBaseColorCollection(
      palette.baseColors.map((b) => ({
        ...b,
        dark:
          defaultBookBaseColorCollection.find(
            (p) => p.value.toLocaleLowerCase() === b.value.toLocaleLowerCase()
          )?.dark || '#FFFFFF'
      }))
    );

    // Set Font Data
    const fontResults = fontsResponse.data.results;
    const defaultFont = fontResults.find((font: Font) => font.id === book.options?.font);
    setFont(defaultFont);
    setFontCollection(fontResults);

    // Set Style Data
    const defaultStyle = fontStyleCollection.find(
      (style) => style.name === book.options?.headline_style
    );
    if (defaultStyle) {
      setFontStyle(defaultStyle);
    }

    // Set Mockup Data
    const mockupResults = mockupsResponse.data.results;
    const defaultMockup = mockupResults.find((mockup: Mockup) => mockup.id === book.mockup?.id);
    setMockup(defaultMockup);
    setMockupCollection(mockupResults);

    // Set Cover Data
    const coverResults = coversResponse.data.results?.filter(
      (item: Cover) => item.is_active === true
    );
    const defaultCover = coverResults.find((cover: Cover) => cover.id === book.cover);
    let cover = defaultCover;
    if (defaultCover?.id) {
      const renderedCover = await renderImage(defaultCover.id, [], true, {
        title: book.name,
        subtitle: book.options?.subtitle,
        color_palette: defaultPalette,
        font: defaultFont,
        base_color: baseColor
      });
      cover = {
        ...defaultCover,
        image_preview: toBase64(renderedCover.data.image)
      };
    }
    setCover(cover);
    setCoverCollection(coverResults);
    setIsCoverDisabled(!book.cover);

    // Set Book Data
    // const productType = productTypes.find(
    //   (type) => type.name.toLowerCase() === book.type.toLowerCase()
    // );
    const batchesIds = book.batches.map((item: BookBatch) => item?.batch?.id).filter(Boolean);
    setBookId(book.id);
    if (book.extra_data) {
      setSelectedNiches(book.extra_data.niches);
      setSelectedTopics(book.extra_data.topics);
      setBatchSearchKeyword(book.extra_data.search_keyword);
    }
    setBatchIds(batchesIds);

    // Set Sales Data
    if (book.sale_page) {
      updateSalesPageId(book.sale_page);
    }

    // Set Website Data
    updateAi(book.name);
    const customPage = book.pages.find((p: BookPages) => p.name?.includes('AboutMe'));
    setSalesData({
      mockup: book.mockup,
      cover_thumbnail: book.cover_image_preview,
      pages_thumbnails: book.pages.map((x: BookPages) => x.image_preview),
      about_me_image: customPage?.image_preview || '',
      color_palette: book.options.color_palette,
      book_file: book.book_file,
      book_name: book.name,
      type: 'sales',
      ...(book.sale_page && { id: book.sale_page })
    });

    updateCoverDisabled(!book.cover);
    updateEditable(true);

    if (book.sale_page) {
      updateSalesPageId(book.sale_page);
    } else {
      const salt = generateSaltSalesCode();
      updateSubDomain(
        `${slugify(book.name, { lower: true, remove: new RegExp('[^a-zA-Z0-9-_]+') })}-${salt}`
      );
      updateWebPrompt(book.name);
    }
    if (book.book_file) {
      updateDownloadUrl(book.book_file.full_url);
    }

    // Set Book Main and Sub Niches
    if (book.extra_data?.search_keyword) {
      setBatchSearchKeyword(book.extra_data.search_keyword);
    }

    // Set Title and Subtitle
    setBookName(book.name);
    setBookSubtitle(book.options?.subtitle);

    // Set Pages Data
    const defaultPages = book.pages.map((page: BatchPage) => {
      if (page.bookpage_type === 'pdf') return page;
      const defaultIngredients = page.page?.ingredients;
      const ingredients = page.ingredients.map((ingredient, index) => ({
        // Spread metadata and variables from base page ingredient
        ...defaultIngredients[index],
        // Spread Actual Page Ingredient Data
        ...ingredient,
        id: ingredient.page_ingredient,
        page: ingredient.book_page,
        ingredient: ingredient.id
      }));
      return {
        ...page,
        page: page.page.id,
        system_id: page.page.system_id,
        src: page.image_preview,
        ingredients
      };
    });
    const sortedDefaultPages = defaultPages.sort((a: BookPages, b: BookPages) => a.order - b.order);
    setPages(sortedDefaultPages);
    updateHistory(sortedDefaultPages);
    setPage(sortedDefaultPages[0]);
    updateRenderPage(sortedDefaultPages[0]);

    // Set Saved Pages Data
    const savedPagesResults = savedPagesResponse.data.results;
    setSavedPages(savedPagesResults);

    // Set Book Media
    const bookMedia = bookMediaResponse?.data.reduce(
      (acc: Record<string, File>, item: { name: string; file: File }) => {
        acc[`media__${item.name}`] = item.file;
        return acc;
      },
      {}
    );

    const mediaFiles = {} as Record<string, File>;
    for (const key of Object.keys(bookMedia)) {
      let endpoint = bookMedia[key];
      if (process.env?.['API_URL']?.includes('localhost')) {
        endpoint = `http://localhost:8000/${bookMedia[key]}`;
      }
      const file = await getFileFromUrl(endpoint);
      mediaFiles[key] = file as File;
    }

    // Set Custom Pages Data
    const aboutMePagesResults = aboutMePagesResponse.data.results;
    const aboutMePageIds = aboutMePagesResults.map((x: BookPages) => x.id);
    setAboutMePageIds(aboutMePageIds);

    return bookResponse;
  };

  const initializeValuesForNewProduct = async (
    book: Book,
    funnel: string = 'digital_product',
    image_meta: Record<string, Record<string, unknown>> = {}
  ) => {
    // Once new product is created we need to set some data from book
    const { delivery_page_data, page_data } = await generateWebsiteValues(
      true,
      funnel,
      book.name,
      image_meta
    );
    const salesPageData = { ...salesPageValues, ...page_data } as PageData;
    const deliveryPageData = { ...deliveryPageValues, ...delivery_page_data } as PageData;

    // Make the default button color, color_4 for sales pages
    Object.entries(salesPageData).map(([key, value]) => {
      if (typeof value === 'object') {
        if (value.button) {
          salesPageData[key] = {
            ...value,
            button: {
              ...value.button,
              color: colorPalette['color4'],
              val: value.button?.val || ''
            }
          };
        }
      }
    });

    // Make the default button color, color_4 for delivery pages
    Object.entries(deliveryPageData).map(([key, value]) => {
      if (typeof value === 'object') {
        if (value.button) {
          deliveryPageData[key] = {
            ...value,
            button: {
              ...value.button,
              color: colorPalette['color4'],
              val: value.button?.val || ''
            }
          };
        }
      }
    });

    const salesContent =
      typeof page_data?.['heading_1'] === 'object'
        ? page_data?.['heading_1']?.content?.val?.replaceAll(/<[^>]*>/gi, '') || ''
        : '';
    const content = book.options.subtitle || salesContent || '';

    const salt = generateSaltSalesCode();
    updateSubDomain(
      `${slugify(book.name, { lower: true, remove: new RegExp('[^a-zA-Z0-9-_]+') })}-${salt}`
    );
    updateWebPrompt(book.name);
    updateAi(book.name);
    const extraHeader = {
      title: `GET YOUR ${book.name}`,
      content,
      image: book.cover_image_preview
    };
    setInitialMetaTags(extraHeader);

    if (book.book_file) {
      updateDownloadUrl(book.book_file.full_url);
    }

    const bookPages = book.pages as unknown as DatabaseBookPage[];
    const transformedPages = _transformDatabasePagesToBookPages(bookPages);
    setPages(transformedPages);
    updateInitialWebsite('sales', salesPageData);
    updateInitialWebsite('delivery', deliveryPageData);
    updateEditable(true);
    return { salesPageData, deliveryPageData, extraHeader };
  };

  const updateBranding = async (isBrandingUpdated: boolean) => {
    const isSystemPaletteSelected = systemPalettes?.find(
      (palette) =>
        palette.id === categorizedColorPalette.id && palette.name === categorizedColorPalette.name
    );
    if (!isSystemPaletteSelected) {
      await ApiService.put(
        `/bizzy/user-color-palettes/${categorizedColorPalette.id}/update-last-used/`
      );
    }
    if (!isCoverDisabled && cover) {
      const coverResponse = await renderImage(cover?.id, [], true, {
        title: bookName,
        subtitle: bookSubtitle,
        base_color: bookBaseColor?.value
      });
      setCover({
        ...cover,
        image_preview: toBase64(coverResponse.data.image) ?? ''
      });
    }
    const data = {
      options: {
        color_palette: {
          palette_id: categorizedColorPalette.id,
          name: categorizedColorPalette.name,
          background_color1: categorizedColorPalette.backgroundColors[0].value,
          background_color2: categorizedColorPalette.backgroundColors[1].value,
          background_color3: categorizedColorPalette.backgroundColors[2].value,
          background_color4: categorizedColorPalette.backgroundColors[3].value,
          base_color1: bookBaseColorCollection?.[0].value,
          base_color2: bookBaseColorCollection?.[1].value,
          base_color3: bookBaseColorCollection?.[2].value,
          base_color4: bookBaseColorCollection?.[3].value,
          color1: categorizedColorPalette.mainColors[0].value,
          color2: categorizedColorPalette.mainColors[1].value,
          color3: categorizedColorPalette.mainColors[2].value,
          color4: categorizedColorPalette.mainColors[3].value,
          color5: categorizedColorPalette.mainColors[4].value
        },
        base_color: bookBaseColor?.value,
        headline_style: fontStyle?.name,
        font: font?.id
      }
    };

    const brandingRequests = [await ApiService.patch(`/book-generator/books/${bookId}/`, data)];

    // Update Previews if any branding data is updated
    if (isBrandingUpdated) {
      brandingRequests.push(
        await ApiService.post(`/book-generator/books/${bookId}/update_book_previews/`)
      );
    }
    if (salesPageId) {
      brandingRequests.push(
        await ApiService.put(`/sales/sales-page/${salesPageId}/`, {
          color_palette: data.options.color_palette
        })
      );
    }

    await Promise.allSettled(brandingRequests);

    return { colorPalette: data, categorizedColorPalette };
  };

  const updateBookCover = async () => {
    const data = {
      name: bookName,
      options: {
        subtitle: bookSubtitle
      },
      cover: isCoverDisabled ? null : cover?.id,
      mockup: mockup?.id
    };
    await ApiService.patch(`/book-generator/books/${bookId}/`, data);
    await ApiService.post(`/book-generator/books/${bookId}/update_book_previews/`);
  };

  const syncPagesOnBackend = async (payloadPages: (BookPages | SavedPage)[]) => {
    const payload = _addPagePayload(payloadPages);
    await ApiService.patch(`/book-generator/books/${bookId}/`, payload);
  };

  const _transformDatabasePagesToBookPages = (databasePages: DatabaseBookPage[]) => {
    const defaultPages: BookPages[] = databasePages.map((page) => {
      if (page.bookpage_type === 'pdf') {
        return {
          ...page,
          page: undefined
        };
      }
      const defaultIngredients = page.page?.ingredients || [];
      const ingredients = page.ingredients.map((ingredient, index) => ({
        // Spread metadata and variables from base page ingredient
        ...defaultIngredients[index],
        // Spread Actual Page Ingredient Data
        ...ingredient,
        id: ingredient.page_ingredient,
        page: ingredient.book_page,
        ingredient: ingredient.id
      }));
      return {
        ...page,
        page: page.page.id,
        system_id: page.page.system_id,
        src: page.image_preview,
        ingredients
      };
    });
    return defaultPages;
  };

  const _addPagePayload = (payloadPages: (BookPages | SavedPage)[]) => {
    const modifiedPages = payloadPages?.map((page, index) => {
      const ingredients = page?.ingredients?.map((ingredient) => {
        const ingredient_data = { ...ingredient.ingredient_data };

        // Remove direct color from ingredient data
        Object.keys({ ...ingredient.ingredient_data }).forEach((key) => {
          if (key.includes('color') && !key.includes('color_key')) {
            delete ingredient_data[key];
          }
        });

        return { page_ingredient: ingredient.id, ingredient_data, order: ingredient.order };
      });

      return {
        id: page.id,
        page: page.page,
        order: index,
        ingredients
      };
    });

    return {
      pages: modifiedPages
    };
  };

  const addPage = async (page: BookPages | SavedPage) => {
    try {
      // Modify the page and remove id because it's Page Model id and not BookPage Model id.
      const updatedPage = {
        ...page,
        ...(page?.id && { page: page?.id })
      };
      delete updatedPage.id;
      const payloadPages = [...bookPages, updatedPage];
      const payload = _addPagePayload(payloadPages);

      await ApiService.patch(`/book-generator/books/${bookId}/`, payload);
      const response = await ApiService.get(`/book-generator/books/${bookId}/`);
      const transformedPages = _transformDatabasePagesToBookPages(response.data.pages);
      setPages(transformedPages);
      setPage(transformedPages[transformedPages.length - 1]);
      updateRenderPage(transformedPages[transformedPages.length - 1]);
      return transformedPages[transformedPages.length - 1];
    } catch (_error) {
      logError('Something wrong happened while adding the page');
      return;
    }
  };

  const loadPagesFromDatabase = async (book?: Book) => {
    if (!book) {
      const bookResponse = await ApiService.get(`/book-generator/books/${bookId}/`);
      book = bookResponse.data as Book;
    }
    const bookPages = book.pages as unknown as DatabaseBookPage[];
    const transformedPages = _transformDatabasePagesToBookPages(bookPages);
    setPages(transformedPages);
  };

  const handleAiPageGeneration = async (page: BookPages, aiValue: string) => {
    let generatedTexts: GeneratedText[] = [];
    let mergedPrompts: Record<string, string> = {};
    for (let i = 0; i < 4; i++) {
      generatedTexts = (await Promise.all(
        page?.prompts?.map((prompt) => generateText(prompt, aiValue, '', 'diy')) || []
      )) as GeneratedText[];
      mergedPrompts = generatedTexts.reduce((result, currentObject) => {
        return { ...result, ...currentObject.prompt };
      }, {}) as Record<string, string>;

      const generatedKeys = Object.keys(mergedPrompts);
      const promptKeys = Object.keys(
        Object.values(page?.prompts?.map((x) => x.mapping) ?? {})?.reduce((result, current) => {
          return { ...result, ...current };
        }, {})
      );

      if (areArraysEqual(generatedKeys, promptKeys)) {
        break;
      }
    }

    const ingredients = page?.ingredients?.map((x) => {
      const ingredient_data = { ...(x.ingredient_data || {}) };

      Object.keys(x.ingredient_metadata ?? {}).forEach((key) => {
        const find = Object.keys(mergedPrompts).find((k) => {
          const removed = k.substring(k.indexOf('_') + 1);
          return removed === `${x.id}_${key}`;
        });
        if (find && mergedPrompts[find]) {
          ingredient_data[key] = mergedPrompts[find];
        }
      });

      return {
        ...x,
        ingredient_data
      };
    });

    return {
      ...page,
      ingredients
    };
  };

  const deleteSavedBookPage = async (savedPage: SavedPage) => {
    await ApiService.delete(`/book-generator/saved-pages/${savedPage?.id}/`);
    const savedPagesResponse = await ApiService.get(`/book-generator/saved-pages/`);
    setSavedPages(savedPagesResponse.data.results);
  };

  const insertSavedBookPage = async (savedPage: SavedPage) => {
    const savedPageIngredients = savedPage?.ingredients?.map((x) => ({
      ...x,
      id: x.page_ingredient
    }));
    savedPage = { ...savedPage, ingredients: savedPageIngredients };
    delete savedPage.id;
    const newBookPage = await addPage(savedPage);

    if (!newBookPage) return;

    for (const media of savedPage?.saved_page_media || []) {
      let fileEndpoint = media.file;
      if (isLocalhost()) {
        fileEndpoint = `http://localhost:8000${media.file}`;
      }
      const isAboutMe = media.name === 'photo';
      const pageId = isAboutMe ? 0 : media?.name?.match(/\d+/)?.[0];
      const file = (await getFileFromUrl(fileEndpoint)) as File;
      const formData = new FormData();
      if (pageId && file && newBookPage['id']) {
        formData.append('name', media.name?.replace(pageId, String(newBookPage?.id)));
        formData.append('file', file);
        await ApiService.post(`/book-generator/books/${bookId}/book_media/`, formData);
      }
    }
  };

  const saveBookPage = async (
    page: BookPages,
    savedPageName: string,
    mediaPhotos: Media[] = []
  ) => {
    if (!page.page) {
      page = {
        ...page,
        page: page.id
      };
      delete page.id;
    }
    const ingredients = page?.ingredients?.map((item) => {
      return {
        page_ingredient: item.id,
        ingredient_data: item.ingredient_data
      };
    });

    const payload = {
      name: savedPageName,
      page: page?.page,
      ingredients,
      owner_entity: entityId
    };

    const response = await ApiService.post('/book-generator/saved-pages/', payload);
    const savedPageId = response.data.id;

    for (const mediaPhoto of mediaPhotos) {
      const form = new FormData();
      form.append('name', mediaPhoto.name);
      form.append('file', mediaPhoto.file);
      await ApiService.post(`/book-generator/saved-pages/${savedPageId}/saved_page_media/`, form);
    }

    const savedPagesResponse = await ApiService.get(`/book-generator/saved-pages/`);
    setSavedPages(savedPagesResponse.data.results);
  };

  const updateLastUsed = (palette: ColorPalette) => {
    const isSystemPaletteSelected = systemPalettes.find(
      (p) => p.id === palette.id && p.name === palette.name
    );
    if (!isSystemPaletteSelected) {
      void ApiService.put(
        `/bizzy/user-color-palettes/${categorizedColorPalette.id}/update-last-used/`
      );
    }
  };

  const deletePagePayload = (selectedPage: BookPages) => {
    const pages = bookPages
      .filter((page) => selectedPage?.id !== page.id)
      .map((page, index) => {
        const ingredients = page.ingredients.map((ingredient) => ({
          page_ingredient: ingredient.id,
          ingredient_data: ingredient.ingredient_data
        }));
        return {
          id: page.id,
          page: page.page,
          order: index,
          ingredients
        };
      });
    return {
      pages
    };
  };

  const duplicatePagePayload = (selectedPage: BookPages) => {
    const duplicatedPage = bookPages.find((page) => page.id === selectedPage?.id);
    const copiedDuplicatedPage = { ...duplicatedPage };
    delete copiedDuplicatedPage.id;

    const duplicatedPageIndex = bookPages.findIndex((page) => page.id === selectedPage?.id) + 1;

    const updatedPages = [
      ...bookPages.slice(0, duplicatedPageIndex),
      { ...copiedDuplicatedPage },
      ...bookPages.slice(duplicatedPageIndex)
    ];

    const pages = updatedPages.map((page, index) => {
      const ingredients = page.ingredients?.map((ingredient) => ({
        page_ingredient: ingredient.id,
        ingredient_data: ingredient.ingredient_data
      }));
      return {
        id: page.id,
        page: page.page,
        order: index,
        ingredients
      };
    });

    return {
      pages
    };
  };

  const orderPreviewPagesPayload = (pages: BookPages[]) => {
    const reorderedPages = pages.map((page, index) => {
      const ingredients = page.ingredients.map((ingredient) => ({
        page_ingredient: ingredient.id,
        ingredient_data: ingredient.ingredient_data
      }));
      return {
        id: page.id,
        page: page.page,
        order: index,
        ingredients
      };
    });
    return {
      pages: reorderedPages
    };
  };

  const resetBookCreationData = useCallback(() => {
    setProductNiches([]);
    setProductTopics([]);
  }, [setProductNiches, setProductTopics]);

  const editBookMutation = useMutation({
    mutationFn: (params: { bookId: number; data: unknown }) =>
      ApiService.patch(`/book-generator/books/${params.bookId}/`, params.data)
  });

  const createFakeBookForWebsite = async (productName: string) => {
    const batches = await ApiService.get(`/book-generator/batches/`);
    const batch = batches.data.results[2] || batches.data.results[0];
    const createdBook = await createBook([batch], true, true);
    await ApiService.patch(`/book-generator/books/${createdBook.id}/`, {
      name: productName,
      pages: [],
      is_only_for_website: true
    });
    await ApiService.post(`/book-generator/books/${createdBook.id}/publish/`);
    const bookResponse = await ApiService.get(`/book-generator/books/${createdBook.id}/`);

    setBookName(productName);
    updateAi(productName);
    updateWebPrompt(productName);
    return bookResponse.data;
  };

  const createBookFolder = async (name: string) => {
    if (bookFolders.find((folder) => folder.name === name)) {
      addAlert('Folder with this name already exists', 'error');
      return;
    }
    await ApiService.post('/book-generator/book-folders/', {
      name,
      owner_entity: entityId
    });
    await refetchBookFolders();
    addAlert('Folder created successfully', 'success');
  };

  const deleteBookFolder = async (folderName: string) => {
    const folderId = bookFolders.find((folder) => folder.name === folderName)?.id;
    if (!folderId) {
      addAlert('Folder not found', 'error');
      return;
    }
    await ApiService.delete(`/book-generator/book-folders/${folderId}/`);
    await refetchBookFolders();
    addAlert('Folder deleted successfully', 'success');
  };

  const renameBookFolder = async (folderName: string, newName: string) => {
    const folderId = bookFolders.find((folder) => folder.name === folderName)?.id;
    if (!folderId) {
      addAlert('Folder not found', 'error');
      return;
    }
    await ApiService.patch(`/book-generator/book-folders/${folderId}/`, {
      name: newName,
      owner_entity: entityId
    });
    await refetchBookFolders();
    addAlert('Folder renamed successfully', 'success');
  };

  const addBookToFolder = async (name: string, bookId: number) => {
    const folderId = bookFolders.find((folder) => folder.name === name)?.id;
    if (!folderId) {
      addAlert('Folder not found', 'error');
      return;
    }
    await ApiService.post(`/book-generator/books/${bookId}/add-book-to-folder/`, {
      folder_id: folderId
    });
    await refetchBooks?.();
    addAlert('Book added to folder successfully', 'success');
  };

  const handleCleanUpText = (cleanUpAllText = false, isCalendarCleanUp = false) => {
    let cleanUpItems = page?.extra_settings?.content_cleanup;
    if (isCalendarCleanUp) {
      cleanUpItems = page?.extra_settings?.calendar_cleanup;
    }
    const ingredients = page?.ingredients || [];
    const updatedIngredients = ingredients.map((ingredient: BookIngredients, index: number) => {
      const ingredientData = ingredient.ingredient_data;
      if (ingredientData) {
        ingredient?.variables?.['text']?.forEach((key) => {
          const lastPart = `${index}_${key.split('_').pop()}`;
          if ((cleanUpItems?.includes(lastPart) && !key.includes('color_key')) || cleanUpAllText) {
            ingredientData[key] = ' ';
          }
        });
      }
      return {
        ...ingredient,
        ingredient_data: ingredientData
      };
    });
    const updatedPage = {
      ...page,
      ingredients: updatedIngredients
    };
    setPage(updatedPage as BookPages);
    updateRenderPage(updatedPage as BookPages);
    const updatedPages = bookPages.map((p) => {
      if (p.id === updatedPage.id) {
        return updatedPage;
      }
      return p;
    });
    updatePages(updatedPages as BookPages[]);
  };

  const handleCalendarFiller = (calendarFillerData: { month: string; year: number }) => {
    const month = monthNames.findIndex((m) => m === calendarFillerData.month) + 1;
    const newDate = new Date(calendarFillerData.year, month, 0).getDate();

    const nextMonth = month === 12 ? 1 : month + 1;
    const nextMonthYear = month === 12 ? calendarFillerData.year + 1 : calendarFillerData.year;
    const nextMonthDays = new Date(nextMonthYear, Number(nextMonth), 0).getDate();

    const array1 = Array.from({ length: newDate + 1 }, (_, i) => i);
    const array2 = Array.from({ length: nextMonthDays + 1 }, (_, i) => i + 1);
    const numbering = [...array1, ...array2];

    const calendarSettings = page?.extra_settings?.calendar_days;

    const ingredients = page?.ingredients || [];
    const updatedIngredients = ingredients.map((ingredient: BookIngredients, index: number) => {
      const ingredientData = ingredient.ingredient_data;
      if (ingredientData) {
        ingredient?.variables?.['text']?.forEach((key) => {
          const lastPart = `${index}_${key.split('_').pop()}`;

          if (calendarSettings?.[lastPart] && !key.includes('color_key')) {
            ingredientData[key] = String(
              numbering[calendarSettings?.[lastPart] as unknown as number]
            );
          }
        });
      }
      return {
        ...ingredient,
        ingredient_data: ingredientData
      };
    });
    const updatedPage = {
      ...page,
      ingredients: updatedIngredients
    };
    setPage(updatedPage as BookPages);
    updateRenderPage(updatedPage as BookPages);
    const updatedPages = bookPages.map((p) => {
      if (p.id === updatedPage.id) {
        return updatedPage;
      }
      return p;
    });
    updatePages(updatedPages as BookPages[]);
  };

  return {
    // Book Creation
    createBook,
    loadBook,
    initializeValuesForNewProduct,
    createFakeBookForWebsite,

    // Book Actions
    addPage,
    loadPagesFromDatabase,
    syncPagesOnBackend,
    saveBookPage,
    insertSavedBookPage,
    deleteSavedBookPage,
    updateBranding,
    resetBookCreationData,
    updateLastUsed,
    updateBookCover,
    createBookFolder,
    deleteBookFolder,
    renameBookFolder,
    addBookToFolder,

    // Payloads
    duplicatePagePayload,
    deletePagePayload,
    orderPreviewPagesPayload,
    _addPagePayload,

    // Others
    handleAiPageGeneration,
    editBookMutation,
    handleCleanUpText,
    handleCalendarFiller,
    bookActions: {
      refetchBookMedia
    }
  };
};

export default useBookAssembler;
