import type { JSXElementConstructor, ReactElement } from 'react';
import type { SalesItem } from '../types';
import { renderToString } from 'react-dom/server';
import slugify from 'slugify';
import uniqBy from 'lodash/uniqBy';
import { boldKeys, generateUUID } from './static';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

export const generateComponentPreview = (
  Component: ReactElement<unknown, string | JSXElementConstructor<unknown>>
) => {
  return renderToString(Component);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjEmpty = (obj: any) =>
  Object.values(obj).length === 0 && obj.constructor === Object;

export const getFileExtensionFromUrl = (url: string) => {
  const lastSlashIndex = url.lastIndexOf('/');
  const fileName = url.substring(lastSlashIndex + 1);

  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return null;
  }

  const fileExtension = fileName.substring(lastDotIndex + 1).toLowerCase();
  const questionMarkIndex = fileExtension.indexOf('?');
  if (questionMarkIndex !== -1) {
    return fileExtension.substring(0, questionMarkIndex);
  }
  return fileExtension.toLowerCase();
};

export const getFileFromUrl = async (url: string, filename: string = 'file') => {
  try {
    const randomParameter = generateRandomParameter(url.includes('?'));
    url = `${url}${randomParameter}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const ext = getFileExtensionFromUrl(url);
    const file = new File([blob], `${filename}.${ext}`);
    return file;
  } catch (error) {
    return error;
  }
};

export const isWrappedInHTML = (str: string) => {
  // This regex checks for a simple pattern where the string starts with
  // an opening HTML tag and ends with a closing tag. It's a basic check
  // and might not cover all valid HTML structures.
  const regex = /^\s*<[^>]+>.*<\/[^>]+>\s*$/;

  return regex.test(str);
};

// Wrap the string input into a paragraph tag
export const wrapInPTag = (str: string) => {
  const p = document.createElement('p');
  p.innerHTML = str;
  return p;
};

export const sanitizeRichText = (
  text = '',
  editable?: boolean,
  check = false,
  defaultValue = ''
) => {
  if (!editable) {
    if (text === '<p><br></p>' || !text) return defaultValue;
    return text;
  }
  const tag = document.createElement('div');
  tag.innerHTML = text.trim();

  if (editable && !tag.textContent?.trim()) {
    if (check) return '';
    return defaultValue || '<p><br></p>';
  }

  if (text === '<p><br></p>' && defaultValue) return defaultValue;

  return text;
};

export function hex2(c: number) {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  let s = c.toString(16);
  if (s.length < 2) s = `0${s}`;

  return s;
}

export function color(r: number, g: number, b: number) {
  return `#${hex2(r)}${hex2(g)}${hex2(b)}`;
}
export function shade(col: string, light: number) {
  // TODO: Assert that col is good and that -1 < light < 1
  if (!col) col = '#000';

  let r = parseInt(col.substr(1, 2), 16);
  let g = parseInt(col.substr(3, 2), 16);
  let b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r *= 1 + light;
    g *= 1 + light;
    b *= 1 + light;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return color(r, g, b);
}

export const capitalizeHyphenWords = (word: string) => {
  return word.replace(/(^|[\s-])\S/g, (match) => match.toUpperCase());
};

export const capitalizeAllWords = (string: string) => {
  if (string?.length > 0) {
    if (string.includes('-')) {
      return capitalizeHyphenWords(string);
    }
    const words = string.split(' ');
    return words.map((word) => word[0]?.toUpperCase() + word.substring(1)).join(' ');
  }
  return '';
};

export const randomNichePhraseGenerator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wording: any[],
  capitalize = true,
  singular = false
): Array<string> => {
  if (wording.length > 0) {
    const nichePhrases = wording
      .flatMap((b) => b.niche_phrases)
      .flatMap((b) => b[singular ? 1 : 0])
      .map((item) => (capitalize ? capitalizeAllWords(item) : item));

    return nichePhrases;
  }
  return [];
};

export const matchWordsAndHtml = (
  inputString: string,
  sentencesToMatch: string,
  baseColor: string
) => {
  const tagPlaceholder = '\uFFFC';
  const tags: string[] = [];

  // Replace HTML tags with unicode placeholders
  const stringWithoutTags = inputString.replace(/<[^>]*>/g, (match) => {
    tags.push(match);
    return tagPlaceholder;
  });

  const sentencesArray = sentencesToMatch.split(' ');
  // Create a regex pattern for sentences to match, while ignoring the tagPlaceholder
  const sentencesPattern = new RegExp(
    `(?:${sentencesArray
      .map((word) => `(?:\\s*[${tagPlaceholder}]s*|\\s*)${word === '&' ? '&amp;' : word}`)
      .join(`(?:\\s*[${tagPlaceholder}]s*|\\s*)`)})`,
    'i'
  );

  const modifiedString = stringWithoutTags.replace(sentencesPattern, (match) => {
    if (match.includes(tagPlaceholder)) {
      const split = match.split(tagPlaceholder);
      return split.map((s) => `<span style="color: ${baseColor}">${s}</span>`).join(tagPlaceholder);
    }
    return `<span style="color: ${baseColor}">${match}</span>`;
  });

  // Restore the HTML tags to their original positions
  let currentIndex = 0;
  const stringWithTagsRestored = modifiedString.replace(new RegExp(tagPlaceholder, 'g'), () => {
    const originalTag = tags.shift();
    if (originalTag) {
      const index = inputString.indexOf(originalTag, currentIndex);
      currentIndex = index + originalTag.length;
      return originalTag;
    }
    return '';
  });

  return stringWithTagsRestored;
};

export const shuffleArray = (arr: string[] | number[] | object[]) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const generateSaltSalesCode = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';

  let code = '';

  for (let i = 0; i < 2; i++) {
    code += letters.charAt(Math.floor(Math.random() * letters.length));
  }

  code += numbers.charAt(Math.floor(Math.random() * numbers.length));

  return shuffleArray(code.split('')).join('');
};

export const capitalizeFirstLetter = (sentence: string) => {
  if (!sentence) {
    return sentence;
  }

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const wrapTextWithHtmlTag = (
  text: string,
  tag: string,
  replaceRichTextCharacters = false
) => {
  if (!text) {
    return text;
  }
  text = text.replaceAll(/[\r\n]/g, '');
  // text = text.replaceAll('<p><br></p>', '<p> </p>');
  text = text.replaceAll('<br><br>', '<br>');
  // text = text.replaceAll('<br>', '</p> <p>');

  // text = text.replaceAll('<b>', '<strong>').replaceAll('</b>', '</strong>');
  text = text.replaceAll(/<span\b[^>]*>.*?<\/span>/gi, '');

  if (replaceRichTextCharacters) {
    text = text.replace(/&(?!amp;|gt;|lt;)/g, '&amp;');
  }

  if (text.startsWith(`<${tag}>`) && text.endsWith(`</${tag}>`)) {
    return text;
  }

  return `<${tag}>${text}</${tag}>`;
};

export const areArraysEqual = (array1: Array<unknown>, array2: Array<unknown>) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};

const concreteCleanUp = (key: string, value: string) => {
  if (key.includes('thisisfor')) {
    value = value.replace('This result is for you if y', 'Y');
    value = value.replace('This success is for you if y', 'Y');
    value = value.replace('This goal is for you if y', 'Y');
    return value;
  }
  return value;
};

export const makeBold = (key: string, value: string) => {
  if (!value) {
    return value;
  }
  value = concreteCleanUp(key, value);
  if (boldKeys.includes(key)) {
    return `<strong>${value.replaceAll('"', '')}</strong>`;
  }
  return value;
};

export const isLocalhost = (isS3Storage = false) => {
  if (isS3Storage) {
    return process.env['API_URL']?.includes('localhost');
  }
  return (
    (process.env['API_URL']?.includes('localhost') ||
      window.location.hostname.includes('localhost')) &&
    process.env['API_URL']?.includes('localhost')
  );
};

export const isDummyDataLocalHost = () => {
  return window.location.href.includes('localhost:3000');
};

export const isLocalhostIP = () => {
  return (
    window.location.hostname === 'localhost' ||
    // IPv4 address for localhost
    window.location.hostname === '127.0.0.1' ||
    // IPv6 address for localhost
    window.location.hostname === '[::1]'
  );
};

export const isStaging = () => {
  return process.env['API_URL']?.includes('.fun');
};

export const isProduction = () => {
  return process.env['API_URL']?.includes('.ai') || process.env['API_URL']?.includes('.com');
};

export const isPermanentProduction = () => {
  return process.env['API_URL']?.includes('.ai') || process.env['API_URL']?.includes('.com');
};

export const isImpersonating = () => {
  return localStorage.getItem('imp-active') || localStorage.getItem('imp-token');
};

export const isChildImpersonating = () => {
  return localStorage.getItem('child-imp-active') || localStorage.getItem('child-imp-token');
};

export const showIntercom = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.Intercom('show');
};

const TLD = isPermanentProduction() ? 'com' : 'fun';
export const COPYSHOP_WEBSITE = `https://packs.pagewheel.${TLD}/auth/pagewheel`;
export const COPYSHOP_WEBSITE_COM = `https://packs.pagewheel.com/auth/pagewheel`;
export const PRODUCTION_URL = 'https://app.pagewheel.com';
export const STAGING_URL = 'https://app.pagewheel.fun';
export const REFERRAL_LINK = 'https://pagewheel.com/become-a-partner';
export const GHL_PURCHASE_WEBSITE = `https://pagewheel.com`;
export const GHL_PURCHASE_WEBSITE_SHARED = 'https://pagewheel.com/shared';
export const COPY_PACKS_WEBSITE = `https://packs.pagewheel.${TLD}/dashboard`;
export const CURRENT_FRONTEND_URL = isPermanentProduction()
  ? PRODUCTION_URL
  : isStaging()
    ? STAGING_URL
    : 'http://localhost:3000';
export const LOCAL_SALES_URL = 'http://localhost:3001';

export const removeDuplicatesFromObjectArray = (arr: string[] | ArrayLike<string>, key: string) => {
  return uniqBy(arr, key);
};

export function randomDifferentElement<T>(arr: T[], exclude?: T): T | undefined {
  if (arr.length === 0) return undefined;

  let filteredArray = arr;
  if (exclude !== undefined) {
    filteredArray = arr.filter((item) => item !== exclude);
  }

  if (filteredArray.length === 0) return undefined;

  const randomIndex = Math.floor(Math.random() * filteredArray.length);
  return filteredArray[randomIndex];
}

export const readFileAsBuffer = (file: File): Promise<string | ArrayBuffer | null | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const buffer = event?.target?.result;
      resolve(buffer);
    };

    reader.onerror = () => {
      reject(new Error('Error reading file'));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const b64toBlob = (base64: string, contentType = 'application/octet-stream') => {
  contentType = contentType || '';
  const parts = base64.split(';base64,');
  const sliceSize = 1024;
  const byteCharacters = parts.length > 1 ? window.atob(parts[1]) : window.atob(base64);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const imageUrlToBase64 = async (url: string): Promise<string | null | undefined> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result?.toString();
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const limitText = (text: string, limit: number) => {
  if (text.length <= limit) {
    return text;
  }

  const cutOffIndex = text.lastIndexOf(' ', limit);
  if (cutOffIndex === -1) {
    return text.slice(0, limit);
  }

  return text.slice(0, cutOffIndex);
};

export const toBase64 = (code?: string) => {
  if (code) {
    return `data:image/png;base64,${code}`;
  }
  return undefined;
};

export const deCapitalizeFirstLetter = (sentence: string) => {
  if (!sentence) {
    return sentence;
  }

  return sentence.charAt(0).toLowerCase() + sentence.slice(1);
};

export function getCustomWebsiteUrl(subdomain: string) {
  const slugifiedSubdomain = slugify(subdomain, { lower: true });
  const base = window.location.host;
  const { protocol } = window.location;
  if (!isLocalhost()) {
    return `https://${slugifiedSubdomain}.${process.env['CUSTOM_DOMAIN']}`;
  }
  return `${protocol}//${base}/${slugifiedSubdomain}/${process.env['CUSTOM_DOMAIN']}`;
}

export const customDeliveryUrl = (subdomain: string, deliveryToken: string) => {
  const slugifiedSubdomain = slugify(subdomain, { lower: true });
  if (!isLocalhost()) {
    return `https://${slugifiedSubdomain}.${process.env['CUSTOM_DOMAIN']}/thankyou/${deliveryToken}`;
  }
  return `${LOCAL_SALES_URL}/${subdomain}/thankyou/${deliveryToken}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefinedObjValues = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export const removeEmptyValues = (obj: { [key: string]: unknown }) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ''));
};

export const naturalSort = (a: string, b: string) => {
  const aParts = a.split(/(\d+)/).filter(Boolean);
  const bParts = b.split(/(\d+)/).filter(Boolean);

  for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
    const aPart = aParts[i];
    const bPart = bParts[i];

    if (aPart !== bPart) {
      const isANumber = !Number.isNaN(aPart);
      const isBNumber = !Number.isNaN(bPart);

      if (isANumber && isBNumber) {
        return parseInt(aPart, 10) - parseInt(bPart, 10);
      }
      if (isANumber) {
        return -1;
      }
      if (isBNumber) {
        return 1;
      }
      return aPart?.localeCompare(bPart);
    }
  }

  return aParts.length - bParts.length;
};

export const randomArrayEl = <T>(array: Array<T>, currVal: T) => {
  const selectFrom = array.filter((i) => i !== currVal);

  if (selectFrom.length <= 1) return array[0];

  const pos = Math.floor(Math.random() * selectFrom.length);
  return selectFrom[pos];
};

export const RgbToHexColor = (val: string) => {
  if (val.includes('#')) return val;

  const rgbValues = val.match(/\((.*)\)/)?.pop();
  if (rgbValues) {
    const rgbArr = rgbValues.split(',').map((v) => Number(v.trim()));
    const rgb = (rgbArr[0] << 16) | (rgbArr[1] << 8) | (rgbArr[2] << 0);
    const hexColor = `#${(0x1000000 + rgb).toString(16).slice(1)}`;

    return hexColor;
  }

  return val;
};

export const dropdownValues = <T>(
  array: Array<T & { id: number }>,
  selectedIdsArray: Array<number>
) => {
  const selectedValues = array.filter((t) => selectedIdsArray.includes(t.id));
  const returnedValue = Array.from(new Set([...selectedValues, ...array]));

  return returnedValue;
};

export const isObjEmptyOrUndefined = (obj: Record<string, unknown>) => {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.values(obj).length === 0;
};

declare global {
  interface Window {
    Intercom?: (command: string) => void;
  }
}

export const shutDownIntercom = () => {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
};

export const isDeviceMacintosh = () => {
  return navigator.userAgent.includes('Macintosh');
};

export const validateEmail = (email: string) => {
  const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegEx.test(email);
};

export const HSLToHex = (h: number, s: number, l: number): string => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  let rs = Math.round((r + m) * 255).toString(16);
  let gs = Math.round((g + m) * 255).toString(16);
  let bs = Math.round((b + m) * 255).toString(16);

  if (rs.length === 1) rs = `0${r}`;
  if (gs.length === 1) gs = `0${g}`;
  if (bs.length === 1) bs = `0${b}`;

  return `#${rs}${gs}${bs}`;
};

export const applyColorSaturationToHexColor = (
  H: string | undefined,
  percentage: number
): string => {
  if (!H) return '';
  let r: number = 0;
  let g: number = 0;
  let b: number = 0;

  if (H.length === 4) {
    r = parseInt(`${H[1]}${H[1]}`, 16);
    g = parseInt(`${H[2]}${H[2]}`, 16);
    b = parseInt(`${H[3]}${H[3]}`, 16);
  } else if (H.length === 7) {
    r = parseInt(`${H[1]}${H[2]}`, 16);
    g = parseInt(`${H[3]}${H[4]}`, 16);
    b = parseInt(`${H[5]}${H[6]}`, 16);
  }

  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1) + percentage;

  const hex = HSLToHex(h, Math.max(s, 70), Math.min(99, Math.max(l, 97)));

  return hex;
};

export const truncateUrlToTLD = (url: string) => {
  const tldRegex =
    /\.(com|net|org|edu|gov|co|io|ai|me|us|ca|uk|de|fr|it|es|nl|au|br|in|jp|kr|ru|sg|tr)\b/i;

  const match = url.match(tldRegex);

  if (match && match.index) {
    const endIndex = match.index + match[0].length;
    return url.slice(0, endIndex);
  }

  return url;
};

export const validateUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_e) {
    return false;
  }
};

// Utility function for formatting strings
export const formatHTMLTitle = (str: string, ...values: string[]): string => {
  return str.replace(/\{(\d+)\}/g, (_match, index) => {
    return values.length > index ? values[index] : '';
  });
};

export const removeAllTags = (str: string) => {
  if (!str) return '';
  return str.replace(/<[^>]*>/g, '');
};

export const isBase64 = (v: string): boolean => {
  if (typeof v === 'boolean' || v === '') {
    return false;
  }

  const regex =
    '^(data:\\w+/[a-zA-Z+\\-.]+;base64,)?(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$';

  return new RegExp(regex, 'gi').test(v);
};

export const updateComponentIds = (item: SalesItem) => {
  if (item.title) {
    item.title.id = generateUUID();
  }
  if (item.content) {
    item.content.id = generateUUID();
  }
  if (item.button) {
    item.button.id = generateUUID();
  }
  if (item.benefits) {
    item.benefits.forEach((benefit) => {
      if (benefit.title) {
        benefit.title.id = generateUUID();
      }
      if (benefit.content) {
        benefit.content.id = generateUUID();
      }
      if (benefit.button) {
        benefit.button.id = generateUUID();
      }
    });
  }
  return item;
};

function parseNumber(input: string) {
  const sanitizedInput = input.replace(/[^0-9,.\\-]/g, '');

  if (sanitizedInput.includes(',') && !sanitizedInput.includes('.')) {
    const parts = sanitizedInput.split(',');
    const lastPart = parts[parts.length - 1];

    if (lastPart.length === 3) {
      return Number(sanitizedInput.replace(/,/g, ''));
    }
    if (lastPart.length === 2) {
      return Number(
        sanitizedInput.replace(/,/g, (_match, offset, string) => {
          return offset === string.lastIndexOf(',') ? '.' : '';
        })
      );
    }
  }

  return Number(sanitizedInput);
}

export const parseCurrencyAndAmount = (text: string) => {
  const regex = /([^\d,.]+)\s*([\d,]+(?:\.\d+)?)/;

  const match = text.match(regex);

  if (match) {
    const currency = match[1].trim();
    const amount = parseNumber(match[2]);

    return { currency, amount };
  }

  // Fallback to handle cases where currency follows the amount (e.g., "100 zł")
  const fallbackRegex = /([\d,]+(?:\.\d+)?)\s*([^\d,.]+)/;

  const fallbackMatch = text.match(fallbackRegex);

  if (fallbackMatch) {
    const amount = parseNumber(fallbackMatch[1]);
    const currency = fallbackMatch[2].trim();

    return { currency, amount };
  }

  // Return null if no match is found
  return null;
};

export const findKeyByValueForString = (obj: Record<string, string>, value: string) => {
  for (const [key, val] of Object.entries(obj)) {
    if (val.normalize() === value.normalize()) {
      return key;
    }
  }
  return null;
};

export const hasEditorResizer = (element: Element): boolean => {
  if (!element) return false;

  const hasResizer = Array.from(element.children).some(
    (child) => child.id === 'editor-resizer' || child.id === 'editor-resizer_blur'
  );

  if (hasResizer) {
    return true;
  }

  return Array.from(element.children).some((child) => hasEditorResizer(child));
};

export const imageViewer = (url?: string) => {
  if (isLocalhost() && !url?.includes('localhost')) {
    return `http://localhost:8000/${url}`;
  }
  return url;
};

export const generateRandomParameter = (hasQueryQuestionMark = false) => {
  if (hasQueryQuestionMark) {
    return `&v=${new Date().getTime()}`;
  }
  return `?v=${new Date().getTime()}`;
};

export const removeStrings = (text: string, removeList: string[]) => {
  const pattern = new RegExp(
    removeList.map((s) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'),
    'g'
  );
  return text.replace(pattern, '');
};

export const generateRandomEmail = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const domain = ['gmail.com', 'yahoo.com', 'outlook.com', 'example.com'];

  const nameLength = Math.floor(Math.random() * 10) + 5; // Random length between 5-15
  let username = '';

  for (let i = 0; i < nameLength; i++) {
    username += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  const randomDomain = domain[Math.floor(Math.random() * domain.length)];

  return `${username}@${randomDomain}`;
};

export const injectScriptIntoHtml = (htmlString: string, scriptContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Check if script with specific data-id already exists
  if (doc.querySelector('script[data-pw-script="pw-resize"]')) {
    return htmlString; // Script already injected, return original HTML
  }

  // Create the script element
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('data-pw-script', 'pw-resize');
  scriptElement.textContent = scriptContent;

  // Inject before closing body tag
  if (doc.body) {
    doc.body.appendChild(scriptElement);
  } else {
    doc.documentElement.appendChild(scriptElement);
  }

  return doc.documentElement.outerHTML;
};

export const injectStyleIntoHtml = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Check if a style tag with specific styles already exists
  let styleElement = doc.querySelector('style[data-pw-style]');
  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.setAttribute('data-pw-style', 'pw-styles');
    styleElement.textContent = `body {
      margin: 60px 0 60px 0;
      padding: 0;
      height: auto;
      min-height: 100%;
      overflow: hidden;
}`;
    doc.head.appendChild(styleElement);
  }

  return doc.documentElement.outerHTML;
};

export const formatNumber = (num: string) => {
  const floatNum = parseFloat(num);
  const stringFloat = floatNum % 1 === 0 ? floatNum.toFixed(0) : floatNum.toString();
  return Number(stringFloat);
};

export const generateInvoicePdf = async ({
  userName,
  lastStripePaid,
  clientCur,
  paidDate,
  totalPaids,
  // stripeLink,
  // yearlyInvoices,
  selectedYear
}: {
  userName: string;
  lastStripePaid: string;
  clientCur: string;
  paidDate: string;
  totalPaids: string;
  // stripeLink: string;
  // yearlyInvoices: { date: string; price: string; currency: string; hosted_invoice_url: string }[];
  selectedYear: number;
}) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([595, 842]); // A4
  const { width, height } = page.getSize();

  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const marginX = 40;
  const lineHeight = 18;
  let y = height;

  const drawText = (
    text: string,
    x = marginX,
    overrideFont = font,
    size = 12,
    options: Partial<Parameters<typeof page.drawText>[1]> = {}
  ) => {
    page.drawText(text, {
      x,
      y,
      size,
      font: overrideFont,
      color: rgb(0, 0, 0),
      ...options
    });
    y -= lineHeight;
  };

  // === Header Section ===
  const cardHeight = 250;
  const cardY = y;

  page.drawRectangle({
    x: 0,
    y: cardY - cardHeight,
    width,
    height: cardHeight,
    color: rgb(0.93, 0.99, 1)
  });

  page.drawRectangle({
    x: 100,
    y: cardY - cardHeight + 200,
    width: width - 200,
    height: 0.5,
    color: rgb(1, 0.874, 0.376) // #ffdf60
  });

  // === Logo ===
  const imageUrl =
    'https://assets.cdn.filesafe.space/N0vBLbtWDoH5wr8KGSW7/media/65d5fd2030a6509024e6f00e.png';
  const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
  const image = await pdfDoc.embedPng(imageBytes);
  page.drawImage(image, {
    x: width - image.width + 140,
    y: cardY - cardHeight + 120,
    width: image.width / 2,
    height: image.height / 2
  });

  // === Invoice Details ===
  y -= 115;
  drawText('Invoice for', 30, boldFont, 18);
  drawText(userName, 30, boldFont, 18);
  drawText(`Last paid: ${lastStripePaid} ${clientCur} (${paidDate})`, 30);
  drawText(`Total in ${selectedYear}: ${totalPaids} ${clientCur}`, 30);

  y -= 100;
  drawText(`Dear ${userName.split(' ')[0] || 'customer'},`);
  drawText("Here's your invoice! We appreciate having you in our community.");

  // === Stripe Invoice Link ===
  y -= 20;
  // const stripeText = 'View your Stripe invoice';

  // page.drawText(stripeText, {
  //   x: 30,
  //   y,
  //   size: 10,
  //   font,
  //   color: rgb(0, 0, 1)
  // });

  y -= 30;

  page.drawRectangle({
    x: 100,
    y: cardY - cardHeight - 520,
    width: width - 200,
    height: 0.5,
    color: rgb(255 / 255, 223 / 255, 96 / 255)
  });
  y -= 300;

  // === Footer Text ===
  drawText('Thanks for being part of Pagewheel!', marginX, boldFont);

  const email = 'hello@pagewheel.com';
  const emailText = `Contact us at: ${email}`;
  page.drawText(emailText, {
    x: marginX,
    y,
    font,
    size: 12,
    color: rgb(0, 0, 1)
  });

  y -= lineHeight;

  const websiteText = 'Website: https://pagewheel.com';
  page.drawText(websiteText, {
    x: marginX,
    y,
    font,
    size: 12,
    color: rgb(0, 0, 1)
  });

  // === Save and Download ===
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `Pagewheel-Invoice-${selectedYear}.pdf`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
