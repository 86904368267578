import type { FC } from 'react';
import type { HeadingProps } from './types';

import styled from 'styled-components';

import {
  sanitizeRichText,
  defaultWebValue,
  filterBackgroundColor,
  COLOR_GRAY_13,
  COLOR_YELLOW
} from 'shared/helpers/index';
import { useWebsiteEditor } from 'shared/hooks/index';

import { Wrapper } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';

const HeadSubhead = styled.div`
  padding: 60px 0;

  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

const Headline = styled.h1<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $headlineFont } }) => $headlineFont};
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 32px;
  position: relative;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    margin-bottom: 22px;
  `}

  strong {
    color: ${COLOR_YELLOW};
  }

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
  `}
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Subheadline = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
  line-height: 1.4em;
  color: ${COLOR_GRAY_13};
  position: relative;
  
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
      line-height: 1.4em;
      margin-bottom: 32px;
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `
      : `
    &:before {
      content: none;

    } 
  `}
`;

export const V3: FC<HeadingProps> = (props) => {
  const { title = defaultWebValue, content = defaultWebValue, textColor, meta, hidden } = props;

  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();

  const { isEmpty: isTitleEmpty } = useIsEmpty(meta, 'title');
  const { isEmpty: isContentEmpty } = useIsEmpty(meta, 'content');

  return (
    <HeadSubhead
      className={containerClassname}
      style={{
        display:
          (hidden && !websiteAssembler) || (!websiteAssembler && isTitleEmpty && isContentEmpty)
            ? 'none'
            : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <Wrapper>
        <Headline
          id={title.id}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(title.val, editable) }}
          style={{ color: filterBackgroundColor('#FFF', textColor) }}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="title"
          data-placeholder="+"
          editable={editable}
          isEmpty={isTitleEmpty}
        />
        <Subheadline
          id={content.id}
          onClick={updateActiveElement}
          onBlur={resetActiveElement}
          dangerouslySetInnerHTML={{ __html: sanitizeRichText(content.val, editable) }}
          style={{ color: filterBackgroundColor('#FFF', textColor) }}
          data-accessor-parent={meta['accessorKey']}
          data-accessorkey="content"
          data-placeholder="+"
          editable={editable}
          isEmpty={isContentEmpty}
        />
      </Wrapper>
    </HeadSubhead>
  );
};
