import { useMemo, useState, type FC } from 'react';
import type { CheckSectionWithPagesProps } from './types';

import styled, { css } from 'styled-components';
import {
  ApiService,
  COLOR_BLACK,
  filterBackgroundColor,
  imageViewer,
  sanitizeRichText
} from 'shared/helpers/index';
import { useBook, useSales, useWebsiteEditor } from 'shared/hooks/index';
import { Wrapper, StackedImages } from '../Common';
import useIsEmpty from 'shared/hooks/useIsEmpty';
import { HoverableDropzone } from '../HoverableDropzone';
import { Image as ImageComp } from 'shared/components/Common';

const Container = styled.div`
  padding: 40px 0 80px 0;
  overflow-x: hidden;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    padding: 22px 0 60px;
  `}
`;

const WrapperStyled = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  gap: 50px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    flex-direction: column;
    gap: 40px;
  `}
`;

const StackedImagesStyled = styled((props) => <StackedImages {...props} />)`
  transform: scale(1.15);
  margin-left: 11%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    transform: scale(1);
    margin-left: 5%;
    margin-bottom: 13rem;
  `}

  ${({ theme: { $width } }) =>
    $width <= 575 &&
    `
    width: 70%;
    margin-left: -7%;
    margin-bottom: 100px;

  `}

${({ theme: { $width } }) =>
    $width <= 375 &&
    `
    width: 70%;
    margin-left: -7%;
  `}

${({ theme: { $width } }) =>
    $width <= 295 &&
    `
    width: 70%;
    margin-left: -18%;
  `}
`;

const Benefits = styled.div`
  width: 50%;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    width: 100%;
  `}
`;

const Item = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 70px;
  }

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  `}
`;

const Svg = styled.svg`
  width: 49px;
  height: 36px;
  margin-right: 24px;
  margin-top: 6px;

  ${({ theme: { $width } }) =>
    $width <= 991 &&
    `
      width: 39px;
      margin-right: 20px;
  `}
`;

const TextWrapper = styled.div`
  width: 87%;
`;

const Title = styled.h4<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $subheadlineFont } }) => $subheadlineFont};
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.3em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const Description = styled.p<{ isEmpty?: boolean; editable?: boolean }>`
  font-size: ${({ theme: { $paragraphFont } }) => $paragraphFont};
  line-height: 1.4em;
  ${({ isEmpty, editable }) =>
    isEmpty
      ? `
    display: ${editable ? 'block' : 'none'};
    &:not(:focus):before {
      content: attr(data-placeholder);
      cursor: text;
      color: #aaa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : `
    &:before {
      content: none;
    } 
  `}
`;

const imageStyles = css`
  position: relative;
  width: 500px;
  height: 840px;
  overflow: hidden;

  ${({ theme: { $width } }) =>
    $width <= 1254 &&
    `
  width: 438px;
`}

  ${({ theme: { $width } }) =>
    $width <= 768 &&
    `
    width: 100%;
`}

  ${({ theme: { $width } }) =>
    $width <= 420 &&
    `
  width: 90%;
  margin: 0 auto;
`}
`;

const HeroMockupImg = styled(ImageComp)<{ isImageUploaded: boolean }>`
  width: 100%;
  height: 100%;
  // object-fit: ${(props) => (props.isImageUploaded ? 'cover' : 'contain')};
  object-fit: cover;
  object-position: center center;
  border-radius: ${(props) => (props.isImageUploaded ? '5px' : '0px')};
`;

export const V2: FC<CheckSectionWithPagesProps> = (props) => {
  const { mockup, pageImages, cover_thumbnail, benefits = [], textColor, meta, hidden } = props;
  const {
    updateActiveElement,
    resetActiveElement,
    containerClassname,
    editable,
    websiteAssembler
  } = useWebsiteEditor();
  const { bookMedia } = useBook();
  const { salesPageId, updateImageMeta } = useSales();
  const [resize, setResize] = useState({
    width: meta?.['width'] ?? 0,
    height: meta?.['height'] ?? 0
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const heroMockup = useMemo(() => {
    if (meta['image']) {
      setIsImageUploaded(true);
      return meta['image'];
    }

    const media = bookMedia?.find((media) => media.name === 'hero_mockup');
    return media?.url;
  }, [bookMedia, meta]);

  const { checkBenefits: checkTitleBenefits } = useIsEmpty(meta, 'title', true);
  const { checkBenefits: checkContentBenefits } = useIsEmpty(meta, 'content', true);

  const handleUpload = async (file: File) => {
    setIsLoading(true);
    if (!meta['accessorKey'] || !editable) return;
    const formData = new FormData();
    formData.append('type', meta['accessorKey']);
    formData.append('file', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setResize({ width, height });
        updateImageMeta(meta['accessorKey'], width, 'width');
        updateImageMeta(meta['accessorKey'], height, 'height');
      };
      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
    const response = await ApiService.post(`/sales/sales-page/${salesPageId}/media/`, formData);
    updateImageMeta(meta['accessorKey'], response.data.file, 'image');
    setIsLoading(false);
  };

  return (
    <Container
      className={containerClassname}
      style={{
        display: hidden && !websiteAssembler ? 'none' : 'block',
        opacity: hidden ? 0.3 : 1
      }}>
      <WrapperStyled>
        {heroMockup ? (
          <HoverableDropzone
            isSalePage
            css={imageStyles as never}
            disabled={!editable}
            allowedExtensions={['image/png', 'image/jpg', 'image/jpeg']}
            onUpload={handleUpload}
            loading={isLoading}
            alreadyUploaded={Boolean(heroMockup)}
            imageFile={heroMockup}
            resizeSelector={editable}
            handleResize={({ width, height }) => {
              setResize({ width, height });
              updateImageMeta(meta['accessorKey'], width, 'width');
              updateImageMeta(meta['accessorKey'], height, 'height');
            }}
            initialPosition={{
              x: meta['x_1'] || 0,
              y: meta['y_1'] || 0,
              width: meta['d_width_1'],
              height: meta['d_height_1']
            }}
            meta={meta}
            positionEnabled
            handlePosition={(width: number, height: number, x: number, y: number) => {
              updateImageMeta(meta['accessorKey'], x, 'x_1');
              updateImageMeta(meta['accessorKey'], y, 'y_1');
              updateImageMeta(meta['accessorKey'], width, 'd_width_1');
              updateImageMeta(meta['accessorKey'], height, 'd_height_1');
            }}
            imageAspectRatio={{
              ratio: '3x4',
              pixels: '600x800'
            }}>
            <HeroMockupImg
              isImageUploaded={isImageUploaded}
              src={imageViewer(heroMockup) || ''}
              alt="Book Mockup"
              width={resize.width || 500}
              height={resize.height || 500}
            />
          </HoverableDropzone>
        ) : (
          <StackedImagesStyled
            pageImages={pageImages}
            mockup={mockup}
            cover_thumbnail={cover_thumbnail}
          />
        )}
        <Benefits>
          {benefits.map(({ title, content }, index) => {
            const isTitleEmpty = checkTitleBenefits(index);
            const isContentEmpty = checkContentBenefits(index);
            return (
              <Item
                key={`checkmark-${index}`}
                style={{ display: isTitleEmpty && isContentEmpty && !editable ? 'none' : 'flex' }}>
                <Svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="37"
                  viewBox="0 0 50 37"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.9372 0.597371C42.7793 0.676719 39.0324 4.27483 34.6109 8.59326C19.9437 22.9182 19.4379 23.4022 19.0072 23.5256C18.1473 23.7719 18.3366 23.9284 12.6822 18.2948C9.84793 15.4711 7.42468 13.0785 7.29721 12.978C6.95544 12.7088 6.08281 12.6723 5.70803 12.9116C5.14691 13.2699 0.919246 17.0765 0.70793 17.4136C0.460533 17.8085 0.433831 18.2355 0.628207 18.6914C0.794447 19.0813 11.0744 29.6549 15.0532 33.5285C17.3879 35.8014 17.9267 36.2804 18.2423 36.3638C19.1112 36.5931 18.056 37.5558 34.286 21.7268C45.6478 10.6457 49.2271 7.10188 49.3421 6.82032C49.7255 5.88119 49.5874 5.66697 46.8843 3.00931C45.6127 1.75916 44.4348 0.67231 44.2666 0.594182C43.8751 0.412039 43.3035 0.413447 42.9372 0.597371Z"
                    fill={COLOR_BLACK}
                  />
                </Svg>
                <TextWrapper>
                  <Title
                    id={title?.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeRichText(title?.val, editable)
                    }}
                    style={{
                      color: filterBackgroundColor('#fff', textColor)
                    }}
                    onClick={updateActiveElement}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="title"
                    data-benefits={true}
                    data-placeholder="+"
                    editable={editable}
                    isEmpty={isTitleEmpty}
                  />
                  <Description
                    id={content?.id}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeRichText(content?.val, editable)
                    }}
                    style={{
                      color: filterBackgroundColor('#fff', textColor)
                    }}
                    onClick={updateActiveElement}
                    onBlur={resetActiveElement}
                    data-accessor-parent={meta['accessorKey']}
                    data-accessorkey="content"
                    data-benefits={true}
                    data-placeholder="+"
                    editable={editable}
                    isEmpty={isContentEmpty}
                  />
                </TextWrapper>
              </Item>
            );
          })}
        </Benefits>
      </WrapperStyled>
    </Container>
  );
};

V2.displayName = 'CheckSectionWithPagesV2';
