export const renderSVG = (index: number, iconColor?: string) => {
  const svgs = [
    <path
      key={`svg-${index}-${iconColor}`}
      fill={iconColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19646 31.5466H8.31119L8.93781 30.3352C9.2381 29.7546 9.37832 29.2065 9.37832 28.6677C9.37832 28.1172 9.23181 27.5477 8.91724 26.9371L7.54514 24.2738H26.8332V1.72795C26.8332 1.21972 26.3252 0.868215 25.8496 1.04732L20.3226 3.12869L14.7228 1.04627C14.5567 0.984509 14.3738 0.985504 14.2084 1.04907L8.75266 3.14575L3.08544 1.04597C2.61041 0.869966 2.10547 1.22137 2.10547 1.72795V26.4556C2.10547 29.2673 4.38478 31.5466 7.19646 31.5466ZM8.65227 9.7273H14.4705C14.8722 9.7273 15.1978 10.0529 15.1978 10.4546C15.1978 10.8563 14.8722 11.1819 14.4705 11.1819H8.65227C8.2506 11.1819 7.92498 10.8563 7.92498 10.4546C7.92498 10.0529 8.2506 9.7273 8.65227 9.7273ZM20.2888 15.5448H8.65227C8.2506 15.5448 7.92498 15.8705 7.92498 16.2721C7.92498 16.6738 8.2506 16.9994 8.65227 16.9994H20.2888C20.6905 16.9994 21.0161 16.6738 21.0161 16.2721C21.0161 15.8705 20.6905 15.5448 20.2888 15.5448ZM31.9549 29.3638C31.9549 27.3788 30.3646 25.7654 28.3886 25.728H9.37917C10.5726 26.9223 11.21 28.1348 11.2124 29.3896C11.2147 30.6441 10.5822 31.8389 9.39353 33.0002H28.3185C30.3269 33.0002 31.9549 31.3721 31.9549 29.3638Z"
    />,

    <>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M31.9986 10.5702H1.99609V4.14108C1.99609 2.95752 2.95556 1.99805 4.13913 1.99805H29.8556C31.0391 1.99805 31.9986 2.95752 31.9986 4.14108V10.5702ZM9.13758 6.28383C9.13758 7.07288 8.49793 7.71252 7.70889 7.71252C6.91984 7.71252 6.2802 7.07288 6.2802 6.28383C6.2802 5.49479 6.91984 4.85514 7.70889 4.85514C8.49793 4.85514 9.13758 5.49479 9.13758 6.28383ZM11.9971 7.71252C12.7861 7.71252 13.4258 7.07288 13.4258 6.28383C13.4258 5.49479 12.7861 4.85514 11.9971 4.85514C11.208 4.85514 10.5684 5.49479 10.5684 6.28383C10.5684 7.07288 11.208 7.71252 11.9971 7.71252Z"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M31.9986 27.0013V12H1.99609V27.0013C1.99609 29.7629 4.23486 32.0017 6.99651 32.0017H26.9982C29.7598 32.0017 31.9986 29.7629 31.9986 27.0013ZM21.7849 17.9213C21.5042 17.6441 21.0519 17.6469 20.7747 17.9276L15.6304 23.1359L13.211 20.7628C12.9293 20.4865 12.4771 20.4909 12.2008 20.7726C11.9245 21.0542 11.9289 21.5065 12.2106 21.7828L15.1382 24.6543C15.4191 24.9299 15.8701 24.9263 16.1466 24.6463L21.7912 18.9315C22.0684 18.6509 22.0656 18.1986 21.7849 17.9213Z"
      />
    </>,
    <>
      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9555 9.38061C29.9555 6.67736 27.9443 4.44385 25.3366 4.09473C25.3679 4.32843 25.3841 4.56692 25.3841 4.80919C25.3841 7.7547 22.9963 10.1425 20.0508 10.1425H13.9555C11.01 10.1425 8.6222 7.7547 8.6222 4.80919C8.6222 4.56692 8.63836 4.32843 8.66965 4.09473C6.06196 4.44385 4.05078 6.67736 4.05078 9.38061V27.6663C4.05078 30.6118 6.43859 32.9996 9.38411 32.9996H24.6222C27.5677 32.9996 29.9555 30.6118 29.9555 27.6663V9.38061ZM23.1005 20.8092H10.91C10.4892 20.8092 10.1481 20.4681 10.1481 20.0473C10.1481 19.6265 10.4892 19.2854 10.91 19.2854H23.1005C23.5212 19.2854 23.8624 19.6265 23.8624 20.0473C23.8624 20.4681 23.5212 20.8092 23.1005 20.8092ZM10.91 14.7129H23.1005C23.5212 14.7129 23.8624 14.3718 23.8624 13.951C23.8624 13.5302 23.5212 13.1891 23.1005 13.1891H10.91C10.4892 13.1891 10.1481 13.5302 10.1481 13.951C10.1481 14.3718 10.4892 14.7129 10.91 14.7129ZM23.1005 26.9044H10.91C10.4892 26.9044 10.1481 26.5633 10.1481 26.1425C10.1481 25.7217 10.4892 25.3806 10.91 25.3806H23.1005C23.5212 25.3806 23.8624 25.7217 23.8624 26.1425C23.8624 26.5633 23.5212 26.9044 23.1005 26.9044Z"
      />
      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3333 1H19.6667C21.5076 1 23 2.567 23 4.5C23 6.433 21.5076 8 19.6667 8H14.3333C12.4924 8 11 6.433 11 4.5C11 2.567 12.4924 1 14.3333 1Z"
      />
    </>
  ];
  return svgs[index];
};
